import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { EmailSenderListModal } from './email-sender-from-email-list/email-sender-from-email-list.component';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [EmailSenderListModal],
    exports:[EmailSenderListModal],
    providers: [],
    entryComponents:[EmailSenderListModal]
})
export class EmailSenderSharedModule { }

