import { required, email } from '@rxweb/reactive-form-validators';


export class BreadCrumb {
  title: string;
  pagename: string;
  name1: string;
  link1: string;
  name2: string;
  link2: string;
  name3: string;
  link3: string;
}
