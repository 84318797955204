import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IntakeFormSortingBase {

        //#region intakeFormSortingId Prop
        @prop()
        intakeFormSortingId: number;
        //#endregion intakeFormSortingId Prop


        //#region eventId Prop
        eventId: number;
        //#endregion eventId Prop


        //#region adIntakeFormId Prop
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region sequence Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        sequence: number;
        //#endregion sequence Prop


        //#region houseAndBrandAdsDignitariesLettersId Prop
        houseAndBrandAdsDignitariesLettersId: number;
        //#endregion houseAndBrandAdsDignitariesLettersId Prop


        //#region brandTypeId Prop
        brandTypeId: number;
        //#endregion brandTypeId Prop


        //#region pageTypeId Prop
        pageTypeId: number;
        //#endregion pageTypeId Prop


        //#region createdOn Prop
        @required()
        createdOn: any;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop



}