import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCampaignYearLookupBase {

//#region campaignYearId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'campaignYearId', keyColumn: true})
        campaignYearId : number;
//#endregion campaignYearId Prop


//#region campaignYear Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'campaignYear', keyColumn: false})
        campaignYear : string;
//#endregion campaignYear Prop

}