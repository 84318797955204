import { PageTypeBase, EventBase, AdIntakeFormBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class PageType extends PageTypeBase {
    //#region Generated Reference Properties

    //#region event Prop
    event: EventBase;
    //#endregion event Prop

    //#region adIntakeForms Prop
    adIntakeForms: AdIntakeFormBase[];
    //#endregion adIntakeForms Prop

    //#region arrayIndex Prop
    @prop()
    arrayIndex: number;
    //#endregion arrayIndex Prop

    //#endregion Generated Reference Properties
}