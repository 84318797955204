import { ApplicationData } from '@app/models';
export enum RolePermissionModule {
    User = 1,
    Role = 2,
    Division = 3,
    Event = 4,
    EventAdDesignTemplate = 5,
    EmailTemplates = 6,
    HouseBrandAdsAndDignitariesLetters = 7,
    Approvals = 8,
    Pagination = 9,
    CreateDownloadPDF = 10,
    CustomAds = 11,
    AdManagement = 12,
    AdIntakeForm = 13,
    AdDesignTemplate = 14,
    EmailNotifications = 15,
    Reports = 16,
    Dashboard = 17,
    AuditLog = 18,
    SectionDivider = 19,
    DownloadPDFforApproval = 20,
    ApprovalInWIP = 21,
    ApplicationData=22
}

export enum ActionType {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}