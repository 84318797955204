import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventDetailBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop


//#region eventType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'eventType', keyColumn: false})
        eventType : string;
//#endregion eventType Prop


//#region eventManager Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'eventManager', keyColumn: false})
        eventManager : string;
//#endregion eventManager Prop


//#region eventFundraiser Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventFundraiser', keyColumn: false})
        eventFundraiser : string;
//#endregion eventFundraiser Prop


//#region eventManagerEmail Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventManagerEmail', keyColumn: false})
        eventManagerEmail : string;
//#endregion eventManagerEmail Prop


//#region eventFundriserEmail Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'eventFundriserEmail', keyColumn: false})
        eventFundriserEmail : string;
//#endregion eventFundriserEmail Prop


//#region eventDate Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'eventDate', keyColumn: false})
        eventDate : any;
//#endregion eventDate Prop


//#region journalAdDeadlineDate Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'journalAdDeadlineDate', keyColumn: false})
        journalAdDeadlineDate : any;
//#endregion journalAdDeadlineDate Prop


//#region otherManagerEmail Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'otherManagerEmail', keyColumn: false})
        otherManagerEmail : string;
//#endregion otherManagerEmail Prop

}