import { range, required, prop } from '@rxweb/reactive-form-validators';

export class MandatoryHouseAndBrandDignitaryLettersBase {

    //#region mandatoryHouseAndBrandDignitaryLettersId Prop
    @required()
    mandatoryHouseAndBrandDignitaryLettersId: number;
    //#endregion mandatoryHouseAndBrandDignitaryLettersId Prop


    //#region eventId Prop
    @required()
    eventId: number;
    //#endregion eventId Prop


    //#region HouseAndBrandDignitaryLettersId Prop
    @required()
    HouseAndBrandDignitaryLettersId: number;
    //#endregion HouseAndBrandDignitaryLettersId Prop


    //#region brandTypeId Prop
    @required()
    brandTypeId: number;
    //#endregion brandTypeId Prop


    //#region sequence Prop
    @required()
    sequence: number;
    //#endregion sequence Prop


    //#region createdBy Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    createdBy: number;
    //#endregion createdBy Prop


    //#region createdOn Prop
    @required()
    createdOn: any;
    //#endregion createdOn Prop


    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop


    //#region modifiedOn Prop
    @prop()
    modifiedOn: any;
    //#endregion modifiedOn Prop

}