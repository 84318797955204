import { TemplateConfig } from '@rxweb/grid';
export const BIND_PDF_BUTTON: TemplateConfig = {
    div: {
        class: ["btn-group", "ml-2"],
        childrens: [
            {
                div: {
                    event: {
                        click: "exportToPdf"
                    },
                    class: ["btn", "btnGroup", "bg-white"],
                    attributes: {
                        title: "Export to PDF"
                    },
                    childrens: [
                        {
                            i: {
                                class: ["fa", "fa-file-pdf-o", "text-red", "pr-1"]
                            },
                            text: {
                                text: "PDF"
                            }
                        }
                    ]
                }
            }
        ]
    }
}