import { Component, Input, OnInit } from '@angular/core';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';

@Component({
    selector: "ad-intake-form-modal",
    templateUrl: './ad-intake-form-modal.component.html'
})

export class AdIntakeFormModal implements OnInit {

    @Input() componentType: any;
    @Input() hide: Function;
    showConfirmationComponent: boolean;
    showInfoComponent: boolean;

    ngOnInit(): void {

        if (this.componentType == CONSTANT_MESSAGES.confirmationSaveModal) {
            this.showConfirmationComponent = true;
            this.showInfoComponent = false;
        }

        if (this.componentType == CONSTANT_MESSAGES.infoModal) {
            this.showConfirmationComponent = false;
            this.showInfoComponent = true;
        }
    }

    saveAdIntakeForm(): any {
        this.hide(CONSTANT_MESSAGES.yes)
    }

    ngOnDestroy(): void {

    }
}