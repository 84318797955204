import {vDivisionLookupBase,} from '@app/database-models'
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class vDivisionLookup extends vDivisionLookupBase {




//#region Generated Reference Properties
    //#region isChecked Prop
@prop()
isChecked : boolean = false;
    //#endregion isChecked Prop

    //#region isDisabled Prop
@prop()
isDisabled : boolean = false;
    //#endregion isDisabled Prop
//#endregion Generated Reference Properties





































}