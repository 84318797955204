import {RequestLogBase} from '../database-models/request-log-base';
//Generated Imports
export class RequestLog extends RequestLogBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}