import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailTemplateRecordBase {

//#region emailTemplateId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'emailTemplateId', keyColumn: true})
        emailTemplateId : number;
//#endregion emailTemplateId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region templateBody Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'templateBody', keyColumn: false})
        templateBody : string;
//#endregion templateBody Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region eventManager Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventManager', keyColumn: false})
        eventManager : any;
//#endregion eventManager Prop


//#region adCreator Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'adCreator', keyColumn: false})
        adCreator : any;
//#endregion adCreator Prop


//#region fundraiser Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'fundraiser', keyColumn: false})
        fundraiser : any;
//#endregion fundraiser Prop




//#region otherManager Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'otherManager', keyColumn: false})
        otherManager : any;
//#endregion otherManager Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'subject', keyColumn: false})
        subject : string;
//#endregion subject Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region emailTemplateType Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'emailTemplateType', keyColumn: false})
        emailTemplateType : number;
//#endregion emailTemplateType Prop


//#region mailFrom Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'mailFrom', keyColumn: false})
        mailFrom : string;
//#endregion mailFrom Prop


//#region mailTo Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'mailTo', keyColumn: false})
        mailTo : string;
//#endregion mailTo Prop


//#region cC Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'cC', keyColumn: false})
        cC : string;
//#endregion cC Prop


//#region mostRecentEmail Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'mostRecentEmail', keyColumn: false})
        mostRecentEmail : any;
//#endregion mostRecentEmail Prop


//#region adDesignStatus Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'adDesignStatus', keyColumn: false})
        adDesignStatus : any;
//#endregion adDesignStatus Prop


//#region eventFundraiser Prop
@gridColumn({visible: true, columnIndex:16, allowSorting: true, headerKey: 'event Fundraiser', keyColumn: false})
eventFundraiser : any;
//#endregion eventFundraiser Prop


//#region templateBody Prop
@gridColumn({visible: false, columnIndex:17, allowSorting: true, headerKey: 'sequence', keyColumn: false})
sequence : string;
//#endregion templateBody Prop

}