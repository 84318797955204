import { AppGrid } from '../../domain/app-grid';
import { TemplateConfig } from '@rxweb/dom';
import { BIND_EXCEL_BUTTON } from './grid-custom-templates';
import { DivGrid } from 'src/app/domain/app-grid-div';

export class GridWithExcelDiv extends DivGrid
{
    private _leftTemplate: TemplateConfig;
    get footerLeftTemplate() {
        return this._leftTemplate;
    }
    set footerLeftTemplate(value: TemplateConfig) {
        this._leftTemplate = value;
        this._leftTemplate.div.childrens.push(BIND_EXCEL_BUTTON)
    }

}