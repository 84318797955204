import {LanguageBase} from '../database-models/language-base';
//Generated Imports
export class Language extends LanguageBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}