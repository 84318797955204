import {vUsersLookupBase} from '../database-models/v-users-lookup-base';
//Generated Imports
export class vUsersLookup extends vUsersLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}