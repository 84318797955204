import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventHonoreeBase {

//#region eventHonoreeId Prop
        @prop()
        eventHonoreeId : number;
//#endregion eventHonoreeId Prop


//#region eventId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        eventId : number;
//#endregion eventId Prop


//#region personName Prop
        @required()
        @prop()
        @maxLength({value:100})
        personName : string;
//#endregion personName Prop


//#region nameOfAward Prop
        // @required()
        @prop()
        @maxLength({value:100})
        nameOfAward : string;
//#endregion nameOfAward Prop


//#region honoreeTitle Prop
        @prop()
        honoreeTitle : string;
//#endregion honoreeTitle Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region isModified Prop
        @required()
        isModified : boolean;
//#endregion isModified Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number=1;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any=new Date;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop



}