import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUsersListBase {

        //#region userId Prop
        @gridColumn({ style: { width: "100px" }, visible: false, columnIndex: 0, allowSorting: true, headerKey: 'userId', keyColumn: true })
        userId: number;
        //#endregion userId Prop


        //#region firstName Prop
        @gridColumn({ isAscending: true, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'firstName', keyColumn: false, headerTitle: "First Name" })
        firstName: string;
        //#endregion firstName Prop


        //#region lastName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'lastName', keyColumn: false, headerTitle: "Last Name" })
        lastName: string;
        //#endregion lastName Prop


        //#region email Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'email', keyColumn: false, headerTitle: "Email" })
        email: string;
        //#endregion email Prop


        //#region roleName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'roleName', keyColumn: false, headerTitle: "Role Name" })
        roleName: string;
        //#endregion roleName Prop


        //#region division Prop
        @gridColumn({ visible: false, columnIndex: 5, allowSorting: true, headerKey: 'division', keyColumn: false })
        division: string;
        //#endregion division Prop


        //#region status Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" } })
        status: string;
        //#endregion status Prop

}