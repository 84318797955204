import {vUsersDivisionBase} from '../database-models/v-users-division-base';
//Generated Imports
export class vUsersDivision extends vUsersDivisionBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}