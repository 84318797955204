import {vRepeatAdBase} from '../database-models/v-repeat-ad-base';
//Generated Imports
export class vRepeatAd extends vRepeatAdBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







}