import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractAuditLog } from '../domain/abstract-audit-log';
import { vAuditLog } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { RxHttp } from '@rxweb/http';
import { AuditSearch } from 'src/app/viewModel/auditSearch';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { access } from '@rxweb/angular-router';
import { vAuditLogBase } from '@app/database-models';
import { EVENT_LOOKUPS } from 'src/app/const/uris/event-lookups.uri';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { UserLogModel } from 'src/app/viewModel/user-logs';
import { CUSTOM_URI } from 'src/app/const/uris/custom.uri';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { pageDataQuery, Query, spParameter } from "src/app/viewModel/spGridClass";

@access({ accessLevel: RolePermissionModule.AuditLog, action: ActionType.GET })

@Component({
  selector: "app-audit-log-list",
  templateUrl: './audit-log-list.component.html'
})

export class AuditLogListComponent extends AbstractAuditLog implements OnInit, OnDestroy {
  auditLogs: List<vAuditLog>;
  subscription: Subscription;
  auditLogGrid: AppGrid;
  audit: any;
  auditSearch: AuditSearch;
  auditSearchGroup: IFormGroup<AuditSearch>;
  rowCount: number = 10;
  pageIndex: number = 1;
  sortOrder: any = CONSTANT_MESSAGES.descending;
  orderByColumn: string = CONSTANT_MESSAGES.rtOutTime;
  isFirstTime: boolean = true;
  searchValue: string
  auditLogParsedData: any
  lookupDetails: any;
  totalCount: number;
  tab: string

  constructor(private http: RxHttp, private formBuilder: RxFormBuilder, private apiCallSerive: ApiCallService) {
    super();
    this.tab = CONSTANT_MESSAGES.eventLog;
    // this.bindBreadCrumb();
  }

  ngOnInit(): void {
    if (this.tab != CONSTANT_MESSAGES.userLog) {
      this.orderByColumn = CONSTANT_MESSAGES.rtOutTime
      this.auditSearch = new AuditSearch();
      this.auditSearchGroup = this.formBuilder.formGroup(this.auditSearch) as IFormGroup<AuditSearch>;

      this.lookup<any>([
        { path: EVENT_LOOKUPS.divisionLookUps, propName: "divisionLookUps" },
        { path: EVENT_LOOKUPS.eventNameLookUps, propName: "eventNameLookUps" }
      ]).subscribe(response => {
        this.lookupDetails = response;
      })

      this.bindGrid();
    }
    else {
      this.auditSearch = new AuditSearch();

      this.auditSearchGroup = this.formBuilder.formGroup(this.auditSearch) as IFormGroup<AuditSearch>;

      this.isFirstTime = true
      this.orderByColumn = 'date';

      this.bindUserGrid();
    }
  }

  bindGrid(page: number = 0) {
    this.spin = true;

    let jsonObject = new spParameter();
    jsonObject.searchQuery = this.auditSearchGroup.value;

    let pageQuery = new pageDataQuery();

    pageQuery.PageRow = this.rowCount;
    pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
    pageQuery.OrderColumn = this.orderByColumn;
    pageQuery.OrderType = (this.sortOrder == false || this.sortOrder == CONSTANT_MESSAGES.descending) ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;

    jsonObject.pageQuery = JSON.stringify(pageQuery);

    let sendJson = new Query();
    sendJson.query = JSON.stringify(jsonObject);

    this.apiCallSerive.postAPI(CUSTOM_URI.auditLogs, sendJson).subscribe((t: any) => {
      this.isFirstTime = false;
      var parsedData = JSON.parse(t);

      this.auditLogParsedData = parsedData[0].result.data;
      this.totalCount = parsedData[0].result.footer[0].totalCount;

      if (page == 0) {
        this.auditLogGrid = new AppGrid(this.auditLogParsedData, vAuditLogBase);

        if (this.auditLogParsedData.length > 0) {
          this.auditLogGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
        }
        else {
          this.auditLogGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.auditLogGrid.updateSource([]);
        }

        setTimeout(() => {
          this.auditLogGrid.design(document.getElementById(CONSTANT_MESSAGES.auditLogsUsedInTag));
        }, 10)
      }
      else {
        if (this.auditLogParsedData.length > 0) {
          this.auditLogGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.auditLogGrid.updateSource(this.auditLogParsedData);
        }
        else {
          this.auditLogGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.auditLogGrid.updateSource([]);
        }
      }

      //setTimeout(() => {
      this.isFirstTime = false;
      this.auditshowComponent = true
      this.spin = false
      //  }, 20)
    })
  }

  bindUserGrid(page: number = 0) {
    this.spin = true;

    let jsonObject = new spParameter();
    jsonObject.searchQuery = this.auditSearchGroup.value;

    let pageQuery = new pageDataQuery();

    pageQuery.PageRow = this.rowCount;
    pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
    pageQuery.OrderColumn = this.orderByColumn;
    pageQuery.OrderType = (this.sortOrder == false || this.sortOrder == CONSTANT_MESSAGES.descending) ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;

    jsonObject.pageQuery = JSON.stringify(pageQuery);

    let sendJson = new Query();
    sendJson.query = JSON.stringify(jsonObject);

    this.apiCallSerive.postAPI(CUSTOM_URI.userLogs, sendJson).subscribe((t: any) => {
      this.isFirstTime = false
      var parsedData = JSON.parse(t);

      this.auditLogParsedData = parsedData[0].result.data;
      this.totalCount = parsedData[0].result.footer[0].totalCount;

      if (page == 0) {
        this.auditLogGrid = new AppGrid(this.auditLogParsedData, UserLogModel);

        if (this.auditLogParsedData.length > 0) {
          this.auditLogGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
        }
        else {
          this.auditLogGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.auditLogGrid.updateSource([]);
        }

        setTimeout(() => {
          this.auditLogGrid.design(document.getElementById("userLogs"));
        }, 10)
      }
      else {
        if (this.auditLogParsedData.length > 0) {
          this.auditLogGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.auditLogGrid.updateSource(this.auditLogParsedData);
        }
        else {
          this.auditLogGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.auditLogGrid.updateSource([]);
        }
      }

      this.isFirstTime = false;
      this.auditshowComponent = true
      this.spin = false
    })
  }

  search(value) {
    //this.searchValue = value.target.value;
    if (this.tab == CONSTANT_MESSAGES.eventLog) {
      this.auditLogGrid.refresh(CONSTANT_MESSAGES.auditLogsUsedInTag);

      this.bindGrid();
    }
    else {
      this.auditLogGrid.refresh(CONSTANT_MESSAGES.userLogs);

      this.bindUserGrid();
    }
  }

  selectTab(value: string) {
    this.tab = value;

    this.ngOnInit()
  }

  onPageChanging(x) {
    this.auditLogGrid.storeProcedure.nextPage = x;
    this.pageIndex = x;

    this.rowCount = this.auditLogGrid.maxPerPage;

    if (this.tab != CONSTANT_MESSAGES.userLog)
      this.bindGrid(this.pageIndex);
    else
      this.bindUserGrid(this.pageIndex);
  }

  onPageSorting(x, y, z) {
    this.sortOrder = y;
    this.orderByColumn = x;

    if (!this.isFirstTime) {
      this.sortOrder = y == false ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;

      if (this.tab != CONSTANT_MESSAGES.userLog) {
        this.auditLogGrid.refresh(CONSTANT_MESSAGES.auditLogsUsedInTag);
        this.bindGrid();
      }
      else {
        this.bindUserGrid(this.pageIndex);
      }
    }
  }

  onUserPageChanging(x) {
    this.auditLogGrid.storeProcedure.nextPage = x;
    this.pageIndex = x;
    this.rowCount = this.auditLogGrid.maxPerPage;
    this.bindUserGrid(x);
  }

  onUserPageSorting(x, y, z) {
    this.sortOrder = y;
    this.orderByColumn = x;

    if (!this.isFirstTime) {
      this.sortOrder = y == false ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;
      this.auditLogGrid.refresh(CONSTANT_MESSAGES.userLogs);
      this.bindUserGrid();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
