import { EmailDetailBase } from '../database-models/email-detail-base';
import { EmailTemplateBase } from '../database-models/email-template-base';
import { EmailNotificationBase } from '../database-models/email-notification-base';
import { TemplateApprovalBase } from '../database-models/template-approval-base';
//Generated Imports
export class EmailDetail extends EmailDetailBase {

    //#region Generated Reference Properties

    //#region emailTemplate Prop
    emailTemplate: EmailTemplateBase;
    //#endregion emailTemplate Prop

    //#region emailNotifications Prop
    emailNotifications: EmailNotificationBase[];
    //#endregion emailNotifications Prop

    //#region templateApprovals Prop
    templateApprovals: TemplateApprovalBase[];
    //#endregion templateApprovals Prop

    //#endregion Generated Reference Properties
}