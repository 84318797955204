import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Division } from '@app/models';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog, DialogEnum } from 'src/app/domain/customize-design/dialog';
import { CoreComponent } from '@rxweb/angular-router';
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";

@http({
    path: APPLICATION_URL_CONSTANTS.divisions,
})

export class AbstractDivision extends CoreComponent {
    divisionFormGroup: IFormGroup<Division>
    breadCrumb: BreadCrumb;
    spin: boolean = false;
    showComponent: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr
    dialog: BaseDialog;
    isShowGridColumn: boolean = false;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = "dashboard"
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.name2 = "Master Data Management"
        this.breadCrumb.title = "Divisions"
        this.breadCrumb.pagename = "Divisions"
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }
}