import { UserBase } from '../database-models/user-base';
import { ApplicationUserTokenBase } from '../database-models/application-user-token-base';
import { TemplateApprovalBase } from '../database-models/template-approval-base';
import { UserRoleBase } from '../database-models/user-role-base';
import { UserDivisionBase } from '../database-models/user-division-base';
import { EmailNotificationBase } from '../database-models/email-notification-base';
//Generated Imports
import { required, maxLength, compare, pattern, different } from '@rxweb/reactive-form-validators';
export class User extends UserBase {

    //#region userPassword Prop
    //@different({ fieldName: 'userOldPassword', messageKey: 'differentMessageKey' })
    @pattern({ expression: { 'onlypassword': /^(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,15}$/ } })
    @maxLength({ value: 15 })
    @required()
    userPassword: string;
    //#endregion userPassword Prop

    //#region userConfirmPassword Prop
    @pattern({ expression: { 'onlypassword': /^(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,15}$/ } })
    @maxLength({ value: 15 })
    @required()
    @compare({ fieldName: 'userPassword' })
    userConfirmPassword: string;
    //#endregion userConfirmPassword Prop

    //#region userOldPassword Prop
    //@pattern({expression:{'onlypassword': /^(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,15}$/} })
    @maxLength({ value: 15 })
    @required()
    userOldPassword: string;
    //#endregion userOldPassword Prop

    //#region isMyProfile Prop
    //@required()
    isMyProfile: boolean = false;
    //#endregion isMyProfile Prop

    //#region isChangePassword Prop
    //@required()
    isChangePassword: boolean = false;
    //#endregion isChangePassword Prop

    //#region status Prop
    @required()
    status: boolean = false;
    //#endregion status Prop

    //#region resetPasswordTime Prop
    //@required()
    resetPasswordTime: any
    //#endregion resetPasswordTime Prop

    //#region Generated Reference Properties
    //#region applicationUserTokens Prop
    applicationUserTokens: ApplicationUserTokenBase[];
    //#endregion applicationUserTokens Prop

    //#region templateApprovals Prop
    templateApprovals: TemplateApprovalBase[];
    //#endregion templateApprovals Prop

    //#region userRoles Prop
    userRoles: UserRoleBase[];
    //#endregion userRoles Prop

    //#region userDivisions Prop
    userDivisions: UserDivisionBase[];
    //#endregion userDivisions Prop

    //#region emailNotifications Prop
    emailNotifications: EmailNotificationBase[];
    //#endregion emailNotifications Prop

    //#endregion Generated Reference Properties

}