import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGetLastEmailDetailBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region adDesignStatusId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'adDesignStatusId', keyColumn: false})
        adDesignStatusId : number;
//#endregion adDesignStatusId Prop


//#region intakeFromAdDesignStatusId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'intakeFromAdDesignStatusId', keyColumn: false})
        intakeFromAdDesignStatusId : number;
//#endregion intakeFromAdDesignStatusId Prop


//#region lastAdDesignStatus Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'lastAdDesignStatus', keyColumn: false})
        lastAdDesignStatus : string;
//#endregion lastAdDesignStatus Prop


//#region emailDetailId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'emailDetailId', keyColumn: false})
        emailDetailId : number;
//#endregion emailDetailId Prop


//#region emailTemplateId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'emailTemplateId', keyColumn: false})
        emailTemplateId : number;
//#endregion emailTemplateId Prop


//#region intakeTemplateId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'intakeTemplateId', keyColumn: false})
        intakeTemplateId : any;
//#endregion intakeTemplateId Prop

}