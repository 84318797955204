import { Component, OnInit, Input } from "@angular/core";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@Component({
    selector: "app-donor-upload-modal",
    templateUrl: './donor-upload-modal.component.html'
})

export class DonorUploadModalComponent implements OnInit {
    @Input() componentType: any;
    @Input() hide: Function;
    showDeleteComponent: boolean;
    showComponent: boolean;

    ngOnInit(): void {
        this.showComponent = false;

        if (this.componentType == CONSTANT_MESSAGES.delete) {
            this.showDeleteComponent = true;
        }
    }

    delete(): any {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    ngOnDestroy(): void {

    }
}