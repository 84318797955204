import { Component, OnInit, OnDestroy } from "@angular/core"
import { AbstractEvent } from '../domain/abstract-event';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppGrid } from 'src/app/domain/app-grid';
import { EventListModel } from 'src/app/viewModel/EventListModel';
import { EventLookupGroup } from 'src/app/viewModel/event-lookup-group';
import { EVENT_LOOKUPS } from 'src/app/const/uris/event-lookups.uri';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { EventSearch } from 'src/app/viewModel/EventSerach';
import { EventModalComponent } from '../event-modal.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { access } from '@rxweb/angular-router';
import { EventAddComponent } from '../add/event-add.component';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { ValidationMessage } from "src/app/const/validation-msg/custom-validation";
import { pageDataQuery, Query, spParameter } from "src/app/viewModel/spGridClass";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { Role } from "src/app/enums/role.enum";

declare const $: any;

@access({ accessLevel: RolePermissionModule.Event, action: ActionType.GET })

@Component({
    selector: "app-event-list",
    templateUrl: './event-list.component.html'
})

export class EventListComponent extends AbstractEvent implements OnInit, OnDestroy {
    eventAdd: any = EventAddComponent;
    eventSearch: EventSearch;
    //events: List<Event>;
    test: AppGrid;
    subscription: Subscription;
    eventSearchGroup: IFormGroup<EventSearch>;
    //eventsGrid: GridWithStatusButton;
    eventsGrid: AppGrid;
    eventLookupGroup: EventLookupGroup;
    rowCount: number = sessionStorage.getItem('eventSetup') != null ? parseInt(sessionStorage.getItem('eventSetup')) : 10;
    //rowCount: number = 10;
    pageIndex: number = 1;
    sortOrder: string = "desc";
    orderByColumn: string = "E.CreatedOn";
    isFirstTime: boolean = true;
    gridSearchString: string = "";
    isFromSearch: boolean = false;
    eventData: any;
    totalCount: any;
    searchValue: string;
    isArchiveEvent: boolean = false;
    isFromGridSearch: boolean = false;
    dbCampaignYear: string
    checkBoxActiveEvents: boolean = false;
    eventStatusArray: Array<EventStatusArray>;

    constructor(private formBuilder: RxFormBuilder,
      private route: Router,
      modalView: ModalView,
      private apiCallService: ApiCallService,
      private storagedata: BrowserStorage) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.spin = true;

        this.eventSearch = new EventSearch();
        this.eventLookupGroup = new EventLookupGroup();
        this.eventSearchGroup = this.formBuilder.formGroup(this.eventSearch) as IFormGroup<EventSearch>;

        this.lookup<EventLookupGroup>([
            { path: EVENT_LOOKUPS["campaignYearLookUps"], propName: "campaignYearLookUps" },
            { path: EVENT_LOOKUPS["divisionLookUps"], propName: "divisionLookUps" },
            { path: EVENT_LOOKUPS["userLookUps"], propName: "userLookUps" },
            { path: EVENT_LOOKUPS["eventManagerLookUps"], propName: "eventManagerLookUps" },
            { path: EVENT_LOOKUPS["eventFundraiserLookUps"], propName: "eventFundraiserLookUps" },
            { path: EVENT_LOOKUPS["eventNameLookUps"], propName: "eventNameLookUps" }
        ]).subscribe(response => {
            this.eventLookupGroup = response;
            this.eventStatusArray = new Array<EventStatusArray>();
            this.eventStatusArray.push({ EventStatusId: 1, EventStausValue: "Active" });
            this.eventStatusArray.push({ EventStatusId: 2, EventStausValue: "Inactive" });
            this.spin = false;
        })

        this.bindGrid();
    }

    search(value) {
        this.spin = true;
        this.searchValue = value;
        this.isFromGridSearch = true;

        this.bindGrid(this.pageIndex);
    }

    bindGrid(page: number = 0) {
        this.showComponent = true;
        let jsonObject = new spParameter();

        jsonObject.searchQuery = this.eventSearchGroup.value;
        jsonObject.searchQuery[CONSTANT_MESSAGES.searchDetails] = this.searchValue;
        jsonObject.searchQuery[CONSTANT_MESSAGES.isArchiveEvent] = this.isArchiveEvent;

        let pageQuery = new pageDataQuery();

        pageQuery.PageRow = this.rowCount;
        pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        jsonObject.pageQuery = JSON.stringify(pageQuery);

        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonObject);

        this.apiCallService.postAPI(CUSTOM_URI.searchEvents, sendJson).subscribe((t: any) => {
            this.isFirstTime = false;
            var parsedData = JSON.parse(t);
            this.eventData = parsedData[0].result.data;
            this.totalCount = parsedData[0].result.footer[0].totalCount;

            if (page == 0) {
                this.eventsGrid = new AppGrid(this.eventData, EventListModel, {
                    actions: {
                        onEdit: this.onEdit.bind(this),
                        onCopy: this.onCopy.bind(this),
                        //onDelete: this.onDelete.bind(this),
                        adDesign: this.adDesign.bind(this),
                        //adManagement: this.adManagement.bind(this),
                        // onArciveEvent: this.onArciveEvent.bind(this),
                        // '$onChecked': this.onChecked.bind(this),
                        onLink: this.onLink.bind(this)
                    }
                });

                this.eventsGrid.authorization = {
                    'edit': { accessLevel: RolePermissionModule.Event, action: ActionType.PUT },
                    'repeat': { accessLevel: RolePermissionModule.Event, action: ActionType.POST },
                    //'adManagement':{accessLevel: RolePermissionModule.AdIntakeForm, action : ActionType.GET},
                    //Commented below line because those Access code has been done in 'event-ops-template' component.
                    'adEventDesign': { accessLevel: RolePermissionModule.EventAdDesignTemplate, action: ActionType.GET },
                    //'delete':{accessLevel: RolePermissionModule.Event, action : ActionType.DELETE}
                };

                this.eventsGrid.designClass.tableClass = "themeTable eventtable donorstable table table-striped mb-0".split(' ');
                this.eventsGrid.footerDesignClass.dropDownTemplateClass.rootClass = "col-auto d-flex flex-wrap align-items-center".split(' ');
                //this.eventsGrid.designClass.headerCellClass = "text-center".split(' ');
                this.eventsGrid.maxPerPage = this.rowCount;

                if (this.eventData.length > 0) {
                    this.eventsGrid.storeProcedure = {
                        length: this.totalCount,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                }
                else {
                    this.eventsGrid.storeProcedure = {
                        length: 0,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }

                    this.eventsGrid.updateSource([]);
                }

                this.eventsGrid.design(document.getElementById(CONSTANT_MESSAGES.events));
            }
            else {
                if (this.eventData.length > 0) {
                    this.eventsGrid.storeProcedure = {
                        length: this.totalCount,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: this.pageIndex,
                        onPageSorting: this.onPageSorting.bind(this)
                    }

                    this.eventsGrid.updateSource(this.eventData);
                }
                else {
                    this.eventsGrid.storeProcedure = {
                        length: 0,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: this.pageIndex,
                        onPageSorting: this.onPageSorting.bind(this)
                    }

                    this.eventsGrid.updateSource([]);
                }
                // this.eventsGrid.reDesign();
            }
            this.spin = false;
            this.isFirstTime = false;
            // if (this.isArchiveEvent) {
            //     document.getElementsByClassName("spancustomSwitch1")[0].innerHTML = "Archived Events"
            // }
            // else {
            //     document.getElementsByClassName("spancustomSwitch1")[0].innerHTML = "Current Events"
            // }
            this.addTooltip();
        })
    }

    addTooltip() {
        $(document).ready(function () {
            $(".clone").hover(function () {
                $(this).attr(CONSTANT_MESSAGES.title, CONSTANT_MESSAGES.copyEvent);
            })

            $(".edit").hover(function () {
                $(this).attr(CONSTANT_MESSAGES.title, CONSTANT_MESSAGES.editEventSetup);
            })

            $(".adDesign").hover(function () {
                $(this).attr(CONSTANT_MESSAGES.title, CONSTANT_MESSAGES.adTemplatesAndSectionDividers);
            })
        })
    }

    onPageChanging(x) {
        this.spin = true;
        this.eventsGrid.storeProcedure.nextPage = x;
        this.pageIndex = x;
        this.rowCount = this.eventsGrid.maxPerPage;

        if (sessionStorage.getItem(CONSTANT_MESSAGES.eventSetup) != null && this.rowCount == parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.eventSetup))) {
            this.rowCount = parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.eventSetup));
        } else {
            sessionStorage.setItem(CONSTANT_MESSAGES.eventSetup, this.rowCount.toString());
        }

        this.bindGrid(x);
    }

    onPageSorting(x, y, z) {
        this.sortOrder = y;
        this.orderByColumn = x;

        if (!this.isFirstTime) {
            this.sortOrder = y == false ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;
            this.bindGrid(this.pageIndex);
        }
    }

    searchData() {
        this.isFromSearch = true;
        this.searchValue = "";
        this.eventsGrid.refresh(CONSTANT_MESSAGES.events);

        this.bindGrid();
    }

    onEdit(category: any) {

        this.route.navigateByUrl(CONSTANT_MESSAGES.eventsPath + category.eventId);
    }

    onCopy(category: any) {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.copy }).then(t => {
            if (t == CONSTANT_MESSAGES.yes) {

                this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.events + category.eventId + APPLICATION_URL_CONSTANTS.repeats).subscribe(data => {
                    this.spin = false;

                    this.eventsGrid.refresh(CONSTANT_MESSAGES.events);
                    this.bindGrid();
                    this.toastr.success(ValidationMessage.dataAdded);
                })
            }
        });
    }

    onDelete(category: any) {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.delete }).then(t => {
            if (t == CONSTANT_MESSAGES.yes) {
                this.apiCallService.deleteAPI(CUSTOM_URI.searchEvents, null, category.eventId).subscribe(data => {
                    this.spin = false;
                    this.toastr.success(ValidationMessage.dataDeleted)
                    this.eventsGrid.refresh(CONSTANT_MESSAGES.events);

                    this.bindGrid();
                })
            }
        });
    }

    onLink(event: any) {
         if(this.isSystemAdmin || event.eventStatus == CONSTANT_MESSAGES.active)
           this.route.navigateByUrl(CONSTANT_MESSAGES.eventsPath + event.eventId + CONSTANT_MESSAGES.adIntakeFormsPath);
    }

    get isSystemAdmin():boolean{
      return this.storagedata.local.get(CONSTANT_MESSAGES.roleId, true) == Role.SystemAdmin;
    }

    adDesign(value: any) {
        this.route.navigate([CONSTANT_MESSAGES.eventsPath + value.eventId + CONSTANT_MESSAGES.eventOpsTemplate])
    }

    adManagement(value: any) {
        this.route.navigate([CONSTANT_MESSAGES.eventsPath + value.eventId + CONSTANT_MESSAGES.adIntakeFormsPath])
    }

    onChecked(x, y) {
        return this.isArchiveEvent
    }

    onArciveEvent(x, y) {
        this.isArchiveEvent = y.target.checked;
        this.eventsGrid.refresh(CONSTANT_MESSAGES.events);
        this.bindGrid();
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}

class EventStatusArray {
    EventStatusId: number;
    EventStausValue: string;
}
