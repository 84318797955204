import {ApplicationObjectTypeBase} from '../database-models/application-object-type-base';
import {ApplicationObjectBase} from '../database-models/application-object-base';
//Generated Imports
export class ApplicationObjectType extends ApplicationObjectTypeBase 
{




//#region Generated Reference Properties
//#region applicationObjects Prop
applicationObjects : ApplicationObjectBase[];
//#endregion applicationObjects Prop

//#endregion Generated Reference Properties









































}