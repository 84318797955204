import {vSendApprovalTypeLookupBase} from '../database-models/v-send-approval-type-lookup-base';
//Generated Imports
export class vSendApprovalTypeLookup extends vSendApprovalTypeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}