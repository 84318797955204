export const CUSTOM_URI: { [key: string]: string } = {
    userLogs: "api/SearchAuditLogs/UserLogs",

    auditLogs: "api/SearchAuditLogs",

    postPdfExcel: "api/SearchAuditLogs/PostPdfExcel",

    searchAdIntakForms: "api/SearchAdIntakeForms",

    donorDetails: "api/SearchAdIntakeForms/DonorDetails",

    updateIntakeFormPaginationToWIP: "api/SearchAdIntakeForms/UpdateIntakeFormPaginationToWIP", //Added on 31/03/2022 for Phase 2 Enhancement

    pinnedEvents: "api/SearchAdIntakeForms/PinnedEvents",

    countPagesInPDF: "api/SearchAuditLogs/CountPagesInPDF",

    childGrid: "api/SearchAdIntakeForms/childGrid",

    searchEvents: "api/SearchEvents",

    eventView: "api/SearchEvents/EventView",

    access: "api/Authorize/access",

    deletePageSequeceHouseAndBrandDignitaries: "api/SearchAuditLogs/DeletePageSequece_HouseAndBrandDignitaries",

    updateMandatoryHouseAndBrandDignitaryLetters: "api/SearchAuditLogs/UpdateMandatoryHouseAndBrandDignitaryLetters",

    updatePageSequences: "api/SearchAuditLogs/UpdatePageSequeces",

    getHouseAndBrandDignitaries: "api/SearchAuditLogs/GetHouseAndBrandDignitaries",

    sortingPageTypeAscendingOrder: "api/SearchAuditLogs/SortingPageTypeAscendingOrder",

    downloadFileFromWIP: "api/SearchAuditLogs/DownloadFileFromWIP",

    markAsReadEmailNotification: "api/SearchAuditLogs/MarkAsReadEmailNotification",

    deleteAllEmailNotification: "api/SearchAuditLogs/DeleteAllEmailNotification",

    getPageTypeExceptCurrent: "api/SearchAuditLogs/GetPageTypeExceptCurrent",

    changePageType: "api/SearchAuditLogs/ChangePageType",

    downloadPDFInApproval: "api/SearchApprovals/DownloadPDFInApproval",

    rejectAdWithCommentsInApproval: "api/SearchApprovals/RejectAdWithCommentsInApproval",

    downloadPdfFromDonorApproval: "api/SearchApprovals/DownloadPdfFromDonorApproval"
}