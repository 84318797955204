import { TemplateConfig } from '@rxweb/grid';


export const USER_ACTION_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction"],
        childrens: [{
            li: {
                childrens: [{
                    a: {
                        authorize: "edit",
                        class: ["edit"],
                        event: {
                            click: "onEdit"
                        },
                        childrens: [{
                            i: { class: "fa-fw uja-Edit".split(" ") }
                        }],
                    }
                }]
            }

        },
        {
            li: {
                childrens: [{
                    label: {
                        class: "division-btn".split(" "),
                        attributes:{
                            for:function (e) {
                              return "user"+ this.userId;
                            },
                          },
                        childrens:[{
                            i: { class: "fa fa-building".split(" ") }
                        }],
                    },
                    input:
                    {
                        class: ["custom-control-input","abc"],
                        event: {
                            input: "onCheckboxSelect"
                        },
                        attributes: {
                            id:function (e) {

                              return "user"+ this.userId;
                            },
                            type: "checkbox",
                            value: function (e) {
                                //var a = this[e.name];
                                return this[e.name];
                            },
                            checked: '$onChecked'
                            // checked: function (e) {

                            //     // var a = this[e.name];
                            //     return this[e.name];
                            // },
                        }
                    }
                }]
            }
        }]
    }
};
