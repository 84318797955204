import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomeDesineBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region journalAdCopy Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'journalAdCopy', keyColumn: false})
        journalAdCopy : string;
//#endregion journalAdCopy Prop


//#region journalAdAlphaName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'journalAdAlphaName', keyColumn: false})
        journalAdAlphaName : string;
//#endregion journalAdAlphaName Prop

}