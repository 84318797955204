import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDonorIdLookupBase {

//#region donorID Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'donorID', keyColumn: true})
        donorID : number;
//#endregion donorID Prop


//#region apiDonorID Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'apiDonorID', keyColumn: false})
        apiDonorID : number;
//#endregion apiDonorID Prop

}