import { Injectable } from '@angular/core';
import { RxHttp } from '@rxweb/http';
import { APPLICATION_URL_CONSTANTS } from 'src/app/const/uris/application-url-constants';

@Injectable({
    providedIn: 'root'
})

export class LoginService {
    constructor(private http: RxHttp) {
        this.http.badRequest = (data) => { }
    }

    login(userCredentialModel: any) {
        return this.http.post<any>({ body: userCredentialModel, path: APPLICATION_URL_CONSTANTS.authentication, withCredentials: true })
    }
    verifyUser(userCredentialModel: any) {
        return this.http.post<any>({ body: userCredentialModel, path: APPLICATION_URL_CONSTANTS.verifyUser, withCredentials: true })
    }

    forgotPassword(userCredentialForgotPasswordModel: any) {
        return this.http.post<any>({ body: userCredentialForgotPasswordModel, path: APPLICATION_URL_CONSTANTS.forgotPassword, withCredentials: true })
    }
}
