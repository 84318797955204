import { vDivisionBase } from '../database-models/v-division-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';

@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["text-center"]
})

export class vDivision extends vDivisionBase {

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties

}