import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EmailNotificationBase {

        //#region emailNotificationId Prop
        @prop()
        emailNotificationId: number;
        //#endregion emailNotificationId Prop


        //#region emailDetailId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        emailDetailId: number;
        //#endregion emailDetailId Prop


        //#region userId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        userId: number;
        //#endregion userId Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        statusId: number;
        //#endregion statusId Prop


        //#region isPreview Prop
        @prop()
        isPreview: boolean;
        //#endregion isPreview Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        //@required()
        @prop()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop

}