import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EmailDetailBase {

        //#region emailDetailId Prop
        @prop()
        emailDetailId: number;
        //#endregion emailDetailId Prop


        //#region emailTemplateId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        emailTemplateId: number;
        //#endregion emailTemplateId Prop


        //#region intakeTemplateId Prop
        @prop()
        intakeTemplateId: number;
        //#endregion intakeTemplateId Prop


        //#region adIntakeFormId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region intakeFromAdDesignStatusId Prop
        @prop()
        intakeFromAdDesignStatusId: number;
        //#endregion intakeFromAdDesignStatusId Prop


        //#region mostRecentEmailId Prop
        @prop()
        mostRecentEmailId: number;
        //#endregion mostRecentEmailId Prop


        //#region from Prop
        @required()
        @maxLength({ value: 200 })
        from: string;
        //#endregion from Prop


        //#region cC Prop
        @maxLength({ value: 200 })
        cc: string;
        //#endregion cC Prop


        //#region to Prop
        @required()
        @maxLength({ value: 200 })
        to: string;
        //#endregion to Prop


        //#region subject Prop
        @required()
        @maxLength({ value: 200 })
        subject: string;
        //#endregion subject Prop


        //#region templateBody Prop
        @required()
        templateBody: string;
        //#endregion templateBody Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop







}