import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
  name: 'action',
  allowSorting: false,
  //configuredHeaderTemplate: { templateName: 'bindCheckBox' },
  headerCellClass: ["w-50", "text-center"], columnIndex: 0, class: ["w-auto", "actionFild"], configuredTemplate: { templateName: 'pinnedEventTemplate' }
})

export class DashboardListModel {

    //#region Generated Reference Properties
    @gridColumn({ headerCellClass: ["w-50"], class: ["w-50"], isAscending: false, visible: false, columnIndex: 1, allowSorting: false, headerKey: 'isChecked', keyColumn: false })
    isPinnedEvent: boolean;
    //#endregion Generated Reference Properties

    //#region divisionName Prop
    @gridColumn({headerCellClass:["left-190","w-200"],class:["left-190","w-200"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'divisionName', keyColumn: false, headerTitle: "Division Name" })
    divisionName: string;
    //#endregion divisionName Prop

    //#region event Prop
    @gridColumn({ headerCellClass:["left-250","w-250"],class:["left-250","w-250"],isAscending: false, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'event', keyColumn: false, headerTitle: "Event", configuredTemplate: { templateName: "redirectLink" }})
    event: string;
    //#endregion event Prop

    //#region campaignYear Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'Campaignyear', keyColumn: false, headerTitle: "Campaign Year" })
    campaignYear: string;
    //#endregion campaignYear Prop

    //#region donorId Prop
    // @gridColumn({ isAscending: false, visible: false, columnIndex: 2, allowSorting: true, headerKey: 'donorId', keyColumn: false, headerTitle: "Donor ID" })
    // donorId: number;
    //#endregion donorId Prop

    //#region donorName Prop
    // @gridColumn({ isAscending: false, visible: false, columnIndex: 3, allowSorting: true, headerKey: 'donorName', keyColumn: false, headerTitle: "Donor Name" })
    // donorName: string;
    //#endregion donorName Prop

    //#region eventDate Prop
    @gridColumn({ headerCellClass:["left-400","w-150"],class:["left-400","w-150"],isAscending: false, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'eventDate', keyColumn: false, headerTitle: "Event Date", configuredTemplate: { templateName: "dateFormat" } })
    eventDate: Date;
    //#endregion eventDate Prop

    //#region journalAdDeadlineDate Prop
    @gridColumn({ headerCellClass:["left-500","w-250"],class:["left-500","w-250"],isAscending: false, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'journalAdDeadlineDate', keyColumn: false, headerTitle: "Journal Ad Deadline Date", configuredTemplate: { templateName: "dateFormat" } })
    journalAdDeadlineDate: Date;
    //#endregion journalAdDeadlineDate Prop

    //#region eventType Prop
    @gridColumn({ isAscending: false, visible: false, columnIndex: 7, allowSorting: true, headerKey: 'eventType', keyColumn: false, headerTitle: "Event Type" })
    eventType: string;
    //#endregion eventType Prop


    //#region eventLocation Prop
    @gridColumn({ isAscending: false, visible: false, columnIndex: 8, allowSorting: true, headerKey: 'eventLocation', keyColumn: false, headerTitle: "Event Location" })
    eventLocation: string;
    //#endregion eventLocation Prop

    //#region eventManager Prop
    @gridColumn({headerCellClass:["left-600","w-200"],class:["left-600","w-200"], isAscending: false, visible: true, columnIndex: 9, allowSorting: true, headerKey: 'eventManager', keyColumn: false, headerTitle: "Event Team Lead" })
    eventManager: string;
    //#endregion eventManager Prop

    //#region eventFundriser Prop
    @gridColumn({headerCellClass:["left-700","w-200"],class:["left-700","w-200"] ,isAscending: false, visible: true, columnIndex: 10, allowSorting: true, headerKey: 'eventFundriser', keyColumn: false, headerTitle: "FRD Journal Lead" })
    eventFundriser: string;
    //#endregion eventFundriser Prop

    //#region adJournalRelationshipOwner Prop
    // @gridColumn({ isAscending: false, visible: true, columnIndex: 10, allowSorting: true, headerKey: 'adJournalRelationshipOwner', keyColumn: false, headerTitle: "Ad Journal Relationship Owner" })
    // adJournalRelationshipOwner: string;
    //#endregion adJournalRelationshipOwner Prop

    //#region adCreator Prop
    // @gridColumn({ isAscending: false, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'adCreator', keyColumn: false, headerTitle: "Ad Creator" })
    // adCreator: string;
    //#endregion adCreator Prop

    //#region donorFundraiser Prop
    // @gridColumn({ isAscending: false, visible: false, columnIndex: 11, allowSorting: true, headerKey: 'donorFundraiser', keyColumn: false, headerTitle: "Donor Funraiser" })
    // donorFundraiser: string;
    //#endregion donorFundraiser Prop

    // //#region adIntakeFormId Prop
    // @gridColumn({ visible: false, columnIndex: 11, allowSorting: true, headerKey: 'divisionName', keyColumn: false, headerTitle: "adIntakeFormId" })
    // adIntakeFormId: number;
    // //#endregion adIntakeFormId Prop


      //#region donorId Prop
      @gridColumn({ isAscending: false, visible: false, columnIndex: 12, allowSorting: true, headerKey: 'eventId', keyColumn: true, headerTitle: "EventId" })
      eventId: number;
      //#endregion donorId Prop

}