import {vDonorNameLookupBase} from '../database-models/v-donor-name-lookup-base';
//Generated Imports
export class vDonorNameLookup extends vDonorNameLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}