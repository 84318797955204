import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventSelectionHeaderLookupBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop


//#region eventType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'eventType', keyColumn: false})
        eventType : string;
//#endregion eventType Prop


//#region eventManager Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'eventManager', keyColumn: false})
        eventManager : string;
//#endregion eventManager Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region eventDate Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'eventDate', keyColumn: false})
        eventDate : any;
//#endregion eventDate Prop


//#region eventFundraiserEmail Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'eventFundraiserEmail', keyColumn: false})
        eventFundraiserEmail : string;
//#endregion eventFundraiserEmail Prop


//#region journalAdDeadlineDate Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'journalAdDeadlineDate', keyColumn: false})
        journalAdDeadlineDate : any;
//#endregion journalAdDeadlineDate Prop


//#region otherManagerEmail Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'otherManagerEmail', keyColumn: false})
        otherManagerEmail : string;
//#endregion otherManagerEmail Prop

//#region divisionName Prop
	@gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'divisionName', keyColumn: false})
	divisionName : string;
//#endregion divisionName Prop

}