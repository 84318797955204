import { RxHttp } from '@rxweb/http';
import { MultiLingualData } from '@rxweb/core';
//import { IMultilingual } from '@rxweb/framework'
export class BaseMultilingual extends RxHttp {
    load(name: string): boolean | Promise<boolean> {
        var languageCode = localStorage.getItem("lcode") || "en";
        var promise = new Promise<boolean>((resolve, reject) => {
            this.get({ hostUri: window.location.origin, path: `assets/localization/${name}-${languageCode}.json` }).subscribe((t: string) => {
                var jObject = {};
                if (typeof t == "string")
                    jObject = JSON.parse(t)
                else
                    jObject = t;
                MultiLingualData.addOrUpdate(name, jObject);
                resolve(true);
            }, (t) => {
                resolve(true)
            });
        })
        return promise;
    }
    //onError = (x) => {
    //    console.log(x);
    //}
}
