export const EVENT_LOOKUPS: { [key: string]: string } = {
    divisionLookUps: 'api/EventsLookup/DivisionLookUps',

    campaignYearLookUps: 'api/EventsLookup/CampaignYearLookUps',

    userLookUps: 'api/EventsLookup/UserLookUps',

    eventManagerLookUps: 'api/EventsLookup/EventManagerLookUps',

    eventFundraiserLookUps: 'api/EventsLookup/EventFundraiserLookUps',

    eventNameLookUps: 'api/EventsLookup/EventNameLookUps',

    eventSelectionHeaderLookUps: 'api/EventsLookup/EventSelectionHeaderLookUps',

    eventPageType: 'api/EventsLookup/EventPageType',

    eventDetail: 'api/EventsLookup/EventDetail',

    eventHonoree: 'api/EventsLookup/EventHonoree',

    intakeFormHonoreeLookup: 'api/EventsLookup/IntakeFormHonoreeLookUps',

    intakeFormRecords: 'api/EventsLookup/IntakeFormRecords',

    emailTemplateLookUps: 'api/EventsLookup/EmailTemplateLookUps',

    wipEmailTemplateLookUps: 'api/EventsLookup/WIPEmailTemplateLookUps',

    paginationDefault: 'api/EventsLookup/PaginationDefault',

    eventLocationLookUps: 'api/EventsLookup/EventLocationLookUps',

    eventTypeLookUps: 'api/EventsLookup/EventTypeLookUps',

    donorFundraiserLookUps: 'api/EventsLookup/DonorFundraiserLookUps',

    donorNameLookUps: 'api/EventsLookup/DonorNameLookUps',

    adCreatorLookUps: 'api/EventsLookup/AdCreatorLookUps',

    adJournalRelationshipOwnerLookUps: 'api/EventsLookup/AdJournalRelationshipOwnerLookUps',

    donorIdLookUps: 'api/EventsLookup/DonorIdLookUps'
}