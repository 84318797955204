import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { AbstractEvent } from './domain/abstract-event';

@Component({
    selector: "app-event-modal",
    templateUrl: './event-modal.component.html'
})

export class EventModalComponent extends AbstractEvent implements OnInit {
    @Input() componentType: any;
    @Input() hide: Function;
    @Input() parsedJSON_Response_PageTypeExceptCurrent: any;
    @Input() addedReason: any

    showDeleteComponent: boolean;
    showDefaultColor: boolean;
    showCopyComponent: boolean;
    showMarkAsReadComponent: boolean;
    showDeleteAllComponent: boolean;
    showApprovalComponent: boolean;
    showSortingPageTypesAscendingOrder: boolean;
    showAtleastOneTemplateApprovedAdIntakeFormConfirmation: boolean;
    showParsedJSON_Response_PageTypeExceptCurrent: boolean;
    showRejectReasonAsComments: boolean;
    showRejectedReason: boolean;

    selectedPageTypeId: number = 0;

    selectedPageTypeName: string;
    addedComments: string = "";
    selectedColor: string;

    ngOnInit(): void {
        switch (this.componentType) {
            case CONSTANT_MESSAGES.delete:
                this.showDeleteComponent = true;
                break;
            case CONSTANT_MESSAGES.copy:
                this.showCopyComponent = true;
                break;
            case CONSTANT_MESSAGES.markAsRead:
                this.showMarkAsReadComponent = true;
                break;
            case CONSTANT_MESSAGES.deleteAll:
                this.showDeleteAllComponent = true;
                break;
            case CONSTANT_MESSAGES.approval:
                this.showApprovalComponent = true;
                break;
            case CONSTANT_MESSAGES.AtleadOneTemplateApprovedAdIntakeFormConfirmation:
                this.showAtleastOneTemplateApprovedAdIntakeFormConfirmation = true;
                break;
            case CONSTANT_MESSAGES.getPageTypeExceptCurrent:
                this.showParsedJSON_Response_PageTypeExceptCurrent = true;
                break;
            case CONSTANT_MESSAGES.rejectComments:
                this.showRejectReasonAsComments = true;
                break;
            case CONSTANT_MESSAGES.addedRejectedReason:
                this.showRejectedReason = true;
                break;
            case CONSTANT_MESSAGES.defaultcolor:
                    this.showDefaultColor = true;
                    break;
            default:
                this.selectedPageTypeName = this.componentType.split('_@_')[1];
                this.showSortingPageTypesAscendingOrder = true;
                break;
        }
    }

    deleteHonoree(): any {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    selectcolor(color: string){
        this.selectedColor = color;
    }

    setcolor(): any {

        if(this.selectedColor){
            this.hide(this.selectedColor);
        }
    }
    
    copyEvent(): any {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    markAsRead(): any {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    approveAd() {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    sortingPageTypesAscendingOrder() {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    alreadyOneTemplateIsApproved() {
        this.hide(CONSTANT_MESSAGES.yes);
    }

    changePageType(event: any) {
        this.selectedPageTypeId = Number(event.target.value);
    }

    submitPageType() {
        if (this.selectedPageTypeId != 0) {
            this.hide(this.selectedPageTypeId);
        }
    }

    addedCommentsAsRejectReason() {
        if (this.addedComments) {
            this.hide(this.addedComments)
        }
    }

    ngOnDestroy(): void {

    }
}