import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IntakeTemplateBase {

        //#region intakeTemplateId Prop
        @prop()
        intakeTemplateId: number;
        //#endregion intakeTemplateId Prop


        //#region adIntakeFormId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region oPSTemplateId Prop
        @prop()
        oPSTemplateId: boolean;
        //#endregion oPSTemplateId Prop


        //#region oPSTokenId Prop
        @maxLength({ value: 50 })
        oPSTokenId: string;
        //#endregion oPSTokenId Prop


        //#region adDesignTemplateTypeId Prop
        @prop()
        adDesignTemplateTypeId: number;
        //#endregion adDesignTemplateTypeId Prop


        //#region version Prop
        @maxLength({ value: 50 })
        version: string;
        //#endregion version Prop


        //#region comment Prop
        @maxLength({ value: 500 })
        comment: string;
        //#endregion comment Prop


        //#region imageURL Prop
        @prop()
        imageURL: string;
        //#endregion imageURL Prop


        //#region thumbImageURL Prop
        @prop()
        thumbImageURL: string;
        //#endregion thumbImageURL Prop


        //#region create_new_version Prop
        @prop()
        create_new_version: string;
        //#endregion create_new_version Prop


        //#region design_url Prop
        @prop()
        design_url: string;
        //#endregion design_url Prop


        //#region pdf_preview_url Prop
        @prop()
        pdf_preview_url: string;
        //#endregion pdf_preview_url Prop


        //#region uploadedBy Prop
        @maxLength({ value: 100 })
        uploadedBy: string;
        //#endregion uploadedBy Prop


        //#region createdOn Prop
        @prop()
        createdOn: any;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @prop()
        createdBy: number;
        //#endregion createdBy Prop





}