import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractUser } from '../domain/abstract-user';
import { vUsersList } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RxHttp } from '@rxweb/http';
import { UserAddComponent } from '../add/user-add.component';
import { access } from '@rxweb/angular-router';
import { DivChildGrid } from 'src/app/domain/app-grid-div-child';
import { UserDivisionChildTbl } from 'src/app/viewModel/user-child-model';
import { GridWithExcelDiv } from 'src/app/custom-templates/grid/add-excel-div';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';
import { CUSTOM_URI } from 'src/app/const/uris/custom.uri';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { downloadDocument, PdfExcelDetail } from "src/app/viewModel/spGridClass";

@access({ accessLevel: RolePermissionModule.User, action: ActionType.GET })

@Component({
    selector: "app-user-list",
    templateUrl: './user-list.component.html'
})

export class UserListComponent extends AbstractUser implements OnInit, OnDestroy {
    userAdd: any = UserAddComponent

    users: vUsersList[];
    subscription: Subscription;
    usersGrid: GridWithExcelDiv;
    list: any[];
    usersGridColumns: any[];
    pdfExcelDetail = new PdfExcelDetail();
    openArray: Array<number> = new Array<number>();
    searchValue: string = "";

    constructor(private route: Router, private http: RxHttp, private apiCallService: ApiCallService) {
        super();
    }

    ngOnInit(): void {
        this.bindGrid();
    }

    bindGrid() {
        this.spin = true;

        this.subscription = this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.users).subscribe((t: any) => {
            this.users = t;
            this.spin = false;

            this.usersGrid = new GridWithExcelDiv(t, vUsersList, {
                actions: {
                    onCheckboxSelect: this.onCheckboxSelect.bind(this),
                    exportToExcel: this.exportToExcel.bind(this),
                    onEdit: this.onEdit.bind(this)
                }
            });

            this.usersGrid.designClass.tableClass = "themeTable table div-table-striped mb-0 div-table user-table".split(' ');
            this.usersGrid.authorization = {
                'edit': {
                    accessLevel: RolePermissionModule.User,
                    action: ActionType.PUT
                }
            };
            this.usersGrid.design(document.getElementById(CONSTANT_MESSAGES.usersUsedInTag));
            this.usersGridColumns = this.usersGrid.gridColumns.filter(x => x.keyColumn == false);

            this.showComponent = true;
        })
    }

    onEdit(category: vUsersList) {
         
        this.route.navigate([CONSTANT_MESSAGES.usersAsPath, category.userId]);
    }

    onCheckboxSelect(t, x, y, z) {
        var element = this.usersGrid.getRowElement(t.userId);

        let index = this.openArray.findIndex(x => x == t.userId);

        if (index > -1) {
            this.openArray.splice(index, 1)
            this.usersGrid.removeChildGrid(t.userId);
        }
        else {
            let user = this.users.find(p => p.userId == t.userId)
            let userDivision = new UserDivisionChildTbl();

            userDivision.division = this.convertDivision(user.division);

            let childGrid = new DivChildGrid([userDivision], UserDivisionChildTbl);

            childGrid.designClass.tableClass = "div-table themeTable nested-table table table-striped m-0 abc".split(' ');
            childGrid.designClass.rowClass = "div-tr w-1546".split(" ");
            childGrid.hideHeaderFooter = true;

            if (element) {
                this.usersGrid.addChildGrid(t.userId, childGrid);
                this.openArray.push(t.userId);
            }
        }
    }

    exportToExcel() {
        this.spin = true;
        this.list = this.usersGrid.appGridSource;

        this.pdfExcelDetail.FileName = CONSTANT_MESSAGES.usersAsXlsx;
        this.pdfExcelDetail.Type = CONSTANT_MESSAGES.excel;

        let returnedRequestData = this.requestDataPdfExcel(this.list);

        this.pdfExcelDetail.Result = JSON.stringify(returnedRequestData);

        return this.apiCallService.postAPI(CUSTOM_URI.postPdfExcel, this.pdfExcelDetail).subscribe(Response => {
            downloadDocument(Response, CONSTANT_MESSAGES.users, CONSTANT_MESSAGES.mimeExcel);
            this.spin = false;
        }, error => {
            this.spin = false;
            this.toastr.error(ValidationMessage.problemDownloadExcel);
        })
    }

    exportToPdf() {
        this.list = this.usersGrid.appGridSource;

        this.pdfExcelDetail.FileName = CONSTANT_MESSAGES.usersAsPdf;
        this.pdfExcelDetail.Type = CONSTANT_MESSAGES.pdfType;

        let returnedRequestData = this.requestDataPdfExcel(this.list);

        this.pdfExcelDetail.Result = JSON.stringify(returnedRequestData);

        return this.apiCallService.postAPI(CUSTOM_URI.postPdfExcel, this.pdfExcelDetail).subscribe(Response => {
            downloadDocument(Response, CONSTANT_MESSAGES.users, CONSTANT_MESSAGES.mimePdf);
        }, error => {
            this.toastr.error(error);
        })
    }

    requestDataPdfExcel(userGrid: any) {
        let usersDetail = new List<UserDetails>();
        userGrid.forEach(element => {
            let userDetails = new UserDetails();
            userDetails[CONSTANT_MESSAGES.firstName] = element.firstName;
            userDetails[CONSTANT_MESSAGES.lastName] = element.lastName;
            userDetails.Email = element.email;
            userDetails[CONSTANT_MESSAGES.roleName] = element.roleName;
            userDetails.Status = element.status;

            userDetails.Division = this.convertDivision(element.division);
            usersDetail.add(userDetails);
        });
        return usersDetail;
    }

    convertDivision(division) {
        let DivisionString = "";

        if (division != "" && division != null) {

            JSON.parse(division).forEach((elementDivisions, index) => {
                if (index === (JSON.parse(division).length - 1)) {
                    DivisionString = DivisionString + elementDivisions.DivisionName;
                } else {
                    DivisionString = DivisionString + elementDivisions.DivisionName + ", ";
                }
            });
        }

        return DivisionString;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    search(value) {
        this.searchValue = value;
        this.usersGrid.search = this.searchValue
    }
}

class UserDetails {
    'First Name': string;
    'Last Name': string;
    Email: string;
    'Role Name': string;
    Status: string;
    Division: string;
}