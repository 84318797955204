import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TemplateApprovalBase {

//#region templateApprovalId Prop
        @prop()
        templateApprovalId : number;
//#endregion templateApprovalId Prop


//#region intakeTemplateId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        intakeTemplateId : number;
//#endregion intakeTemplateId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region emailDetailId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        emailDetailId : number;
//#endregion emailDetailId Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region isDeleted Prop
        @required()
        isDeleted : boolean;
//#endregion isDeleted Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : Date;
//#endregion modifiedOn Prop







}