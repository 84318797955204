import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAdCreatorLookupBase {

//#region adCreatorId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'adCreatorId', keyColumn: false})
        adCreatorId : any;
//#endregion adCreatorId Prop


//#region adCreator Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'adCreator', keyColumn: false})
        adCreator : string;
//#endregion adCreator Prop

}