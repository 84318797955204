import {vAuditLogBase} from '../database-models/v-audit-log-base';
//Generated Imports
export class vAuditLog extends vAuditLogBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}