import {vAdJournalRelationShipOwnerLookupBase} from '../database-models/v-ad-journal-relation-ship-owner-lookup-base';
//Generated Imports
export class vAdJournalRelationShipOwnerLookup extends vAdJournalRelationShipOwnerLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}