import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIntakeFormStatusLookupBase {

//#region intakeFormStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'intakeFormStatusId', keyColumn: true})
        intakeFormStatusId : number;
//#endregion intakeFormStatusId Prop


//#region intakeFormStatus Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'intakeFormStatus', keyColumn: false})
        intakeFormStatus : string;
//#endregion intakeFormStatus Prop

}