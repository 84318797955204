import { RoleBase } from '../database-models/role-base';
import { RolePermissionBase } from '../database-models/role-permission-base';
import { UserRoleBase } from '../database-models/user-role-base';
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class Role extends RoleBase {

    //#region Generated Reference Properties

    //#region rolePermissions Prop
    @prop()
    rolePermissions: RolePermissionBase[];
    //#endregion rolePermissions Prop

    //#region userRoles Prop
    userRoles: UserRoleBase[];
    //#endregion userRoles Prop

    //#endregion Generated Reference Properties

}