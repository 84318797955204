import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SectionDividerBase {

//#region sectionDividersId Prop
        @prop()
        sectionDividersId : number;
//#endregion sectionDividersId Prop


//#region pageTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pageTypeId : number;
//#endregion pageTypeId Prop


//#region sequence Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        sequence : number;
//#endregion sequence Prop


//#region imageUrl Prop
        @prop()
        imageUrl : string;
//#endregion imageUrl Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}