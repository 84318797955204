import {vAdDesignStatusLookupBase} from '../database-models/v-ad-design-status-lookup-base';
//Generated Imports
export class vAdDesignStatusLookup extends vAdDesignStatusLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}