import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserEmailTemplateBase {

//#region emailNotificationId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'emailNotificationId', keyColumn: true})
        emailNotificationId : number;
//#endregion emailNotificationId Prop


//#region senderName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'senderName', keyColumn: false})
        senderName : string;
//#endregion senderName Prop


//#region emailId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'emailId', keyColumn: false})
        emailId : string;
//#endregion emailId Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'subject', keyColumn: false})
        subject : string;
//#endregion subject Prop


//#region dateAndTime Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'dateAndTime', keyColumn: false})
        dateAndTime : string;
//#endregion dateAndTime Prop


//#region isPreview Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'isPreview', keyColumn: false})
        isPreview : any;
//#endregion isPreview Prop

}