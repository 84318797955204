import { TemplateConfig } from '@rxweb/grid';

export const NUMBER_DISPLAY: TemplateConfig = {
    div: {
        childrens: [{
            text: {
                text: function (e) { return this[e.name] == 0 ? "NA" : this[e.name].toString() }
            }
        }]
    }
}
