import { TemplateConfig } from "@rxweb/grid";

export const AD_REJECTED_BY_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [{
            a: {
                event: {
                    click: "onRejectedBy"
                },
                childrens: [{
                    div: {
                        class: [function (e) {
                            if (this.rejectedBy != "NA") {
                                return "anchor-hover"
                            }
                        }, function (e) {
                            if (this.rejectedBy != "NA") {
                                return "underline"
                            }
                        }],
                        childrens: [{
                            text: {
                                text: function (e) {
                                    return this[e.name]
                                }
                            }
                        }]
                    }
                }]
            }
        }],
    }
};
