import { Injectable } from '@angular/core';
import { IAuthorize, AuthorizeConfig } from '@rxweb/angular-router';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { RxHttp } from '@rxweb/http';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';

export var dbAccessModule: any;

@Injectable({
    providedIn: 'root',
})

export class AuthorizationResolver implements IAuthorize {
    emailNotificationCount: number;

    constructor(private http: RxHttp, private router: Router, private applicationBroadCaster: ApplicationBroadcaster) { }

    authorize(authorizeConfig: AuthorizeConfig, route: ActivatedRouteSnapshot): Promise<boolean> | boolean {

        var promise = new Promise<boolean>((resolve, reject) => {

            if (dbAccessModule == undefined || dbAccessModule == null) {
                this.http.get<string>({ path: "api/Authorize/access", }).subscribe(response => {
                    dbAccessModule = JSON.parse(response);
                    //resolve(dbAccessModule);
                    let isAccess = this.verifyAuthorization(authorizeConfig);

                    resolve(isAccess);

                    if (!isAccess)
                        this.router.navigate(["/dashboard"])
                })
            }
            else {
                let isAccess = this.verifyAuthorization(authorizeConfig);

                resolve(isAccess);

                if (!isAccess)
                    this.router.navigate(["/dashboard"])
            }

        })
        return promise;
    }

    verifyAuthorization(authorizeConfig: AuthorizeConfig): boolean {
        return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action]
    }

    authorizeChildren(authorizeConfig: AuthorizeConfig, pageAuthorizeConfig: AuthorizeConfig) {
        if (dbAccessModule == undefined || dbAccessModule == null) {
            var promise = new Promise<boolean>((resolve, reject) => {

                this.http.get<string>({ path: "api/Authorize/access" }).subscribe(response => {
                    dbAccessModule = JSON.parse(response);
                    var result = this.verifyAuthorization(authorizeConfig);
                    resolve(result);
                })
            })

            return promise;
        }
        else {
            return this.verifyAuthorization(authorizeConfig);
        }
    }

    clearPermissions() {
      dbAccessModule = null;
  }
}
