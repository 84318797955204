import {vOPSReplaceDataBase} from '../database-models/v-o-p-s-replace-data-base';
//Generated Imports
export class vOPSReplaceData extends vOPSReplaceDataBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}