import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from '../authentication/login/login.component';
import { BaseCanActivate } from '@rxweb/angular-router';
import { DonorUploadComponent } from '../master/donor/donor-upload/donor-upload.component';
import { ForgotPasswordComponent } from '../authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../authentication/reset-password/reset-password.component';
import { ChangePasswordComponent } from '../authentication/change-password/change-password.component';


const ROUTES: Routes = [
	{
		path: '', redirectTo: 'dashboard', pathMatch: 'full', data: { anonymous: true }
	},
	{
		path: "dashboard",
		loadChildren: () => import("../dashboard/dashboard.module").then(m => m.DashboardModule)
	},
	{
		path: 'login', component: LoginComponent, canActivate: [BaseCanActivate]
	},
	{
		path: 'forgotpassword', component: ForgotPasswordComponent, canActivate: [BaseCanActivate]
	},
	{
		path: 'changepassword', component: ChangePasswordComponent, canActivate: [BaseCanActivate]
	},
	{
		path: 'reset-password/:data', component: ResetPasswordComponent, canActivate: [BaseCanActivate]
	},
	{
		path: "events/:eventId/ad-intake-forms/:adIntakeFormId/email-sender",
		loadChildren: () => import("../master/email-sender/email-sender.module").then(m => m.EmailSenderModule)
	},
	{
		path: "events/:eventId/ad-intake-forms",
		loadChildren: () => import("../event/ad-intake-forms/ad-intake-form.module").then(m => m.AdIntakeFormModule)
	},
	{
		path: "events/:eventId/event-ops-template",
		loadChildren: () => import("../event/event-ops-template/event-ops-template.module").then(m => m.EventOpsModule)
	},
	{
		path: "donor-report",
		loadChildren: () => import("../universal-report/universal-report.module").then(m => m.UniversalReportModule)
	},
	{
		path: "events/:eventId/ad-intake-forms/:adIntakeFormId/intake-form-ad-design-template/:tab",
		loadChildren: () => import("../event/intake-form-ad-design-template/intake-form-ad-design-template.module").then(m => m.IntakeFormAdDesignTemplateModule)
	},
	{
		path: "events",
		loadChildren: () => import("../event/events/event.module").then(m => m.EventModule)
	},
	{
		path: "donor/intake-templates",
		loadChildren: () => import("../event/intake-templates/intake-template.module").then(m => m.IntakeTemplateModule)
	},
	{
		path: "divisions",
		loadChildren: () => import("../master/divisions/division.module").then(m => m.DivisionModule)
	},
	{
		path: "email-notifications",
		loadChildren: () => import("../master/email-notifications/email-notification.module").then(m => m.EmailNotificationModule)
	},
	{
		path: "email-templates",
		loadChildren: () => import("../master/email-templates/email-template.module").then(m => m.EmailTemplateModule)
	},
	{
		path: "roles",
		loadChildren: () => import("../user/roles/role.module").then(m => m.RoleModule)
	},
	{
		path: "users",
		loadChildren: () => import("../user/users/user.module").then(m => m.UserModule)
	},
	{
		path: "audit-logs",
		loadChildren: () => import("../audit-log/audit-logs/audit-log.module").then(m => m.AuditLogModule)
	},
	{
		path: "applicationdatas",
		loadChildren: () => import("../application-data/application-data.module").then(m => m.ApplicationDataModule)
	},
	{
		path: "intake-from-ad-design-statuses",
		loadChildren: () => import("../event/intake-from-ad-design-statuses/intake-from-ad-design-status.module").then(m => m.IntakeFromAdDesignStatusModule)
	},
	{
		path: "house-and-brand-ads-tribute-letters",
		loadChildren: () => import("../event/house-and-brand-ads-dignitaries-letters/house-and-brand-ads-dignitaries-letter.module").then(m => m.HouseAndBrandAdsDignitariesLetterModule)
	},
	{
		path: "intake-form-sortings",
		loadChildren: () => import("../event/intake-form-sortings/intake-form-sorting.module").then(m => m.IntakeFormSortingModule)
	},
	{
		path: "repeats",
		loadChildren: () => import("../event/repeats/repeat.module").then(m => m.RepeatModule)
	},
	{
		path: "a-w-s-image-uploads",
		loadChildren: () => import("../event/a-w-s-image-uploads/a-w-s-image-upload.module").then(m => m.AWSImageUploadModule)
	},
	{
		path: "donor-templates",
		loadChildren: () => import("../event/donor-templates/donor-template.module").then(m => m.DonorTemplateModule)
	},
	{
		path: "emails",
		loadChildren: () => import("../event/emails/email.module").then(m => m.EmailModule)
	},
	{
		path: "donor-templates",
		loadChildren: () => import("../event/donor-templates/donor-template.module").then(m => m.DonorTemplateModule)
	},
	{
		path: "pdf-excel-generates",
		loadChildren: () => import("../master/pdf-excel-generates/pdf-excel-generate.module").then(m => m.PdfExcelGenerateModule)
	},
	{
		path: "event-template",
		loadChildren: () => import("../event/event-template/event-template.module").then(m => m.EventTemplateModule)
	},
	{
		path: "donor",
		loadChildren: () => import("../master/donor/donor.module").then(m => m.DonorModule)

	},
	{
		path: 'daily-notifications',
		loadChildren: () => import("../master/daily-notifications/daily-notifications.module").then(m => m.DailyNotificationsModule)
	},
	{
		path: '**', redirectTo: 'dashboard'
	},


	//generated code
];

export const ROUTING: ModuleWithProviders = RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' });
