import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventHonoreeBase {

//#region eventId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventHonoreeId Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'eventHonoreeId', keyColumn: false})
        eventHonoreeId : number;
//#endregion eventHonoreeId Prop


//#region personName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'personName', keyColumn: false})
        personName : string;
//#endregion personName Prop


//#region honoreeTitle Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'honoreeTitle', keyColumn: false})
        honoreeTitle : string;
//#endregion honoreeTitle Prop


//#region nameOfAward Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'nameOfAward', keyColumn: false})
        nameOfAward : string;
//#endregion nameOfAward Prop

}