import { TemplateConfig } from '@rxweb/dom';

export const MANDATORY_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [
            function (e) {
                return this[e.name] ? "label bg-light-green text-white".split(" ") : 'label bg-light-gray text-white'.split(" ");
            }
        ],
        childrens: [
            {
                text: {
                    text: function (e) {
                        return this[e.name] ? "Yes" : "No"
                    }
                }
            }
        ]
    }
}