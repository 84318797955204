import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventFundriserLookupBase {

//#region fundraiserId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'fundraiserId', keyColumn: true})
        fundraiserId : number;
//#endregion fundraiserId Prop


//#region eventFundraiser Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventFundraiser', keyColumn: false})
        eventFundraiser : string;
//#endregion eventFundraiser Prop

}