import {vDonorFundriserLookupBase} from '../database-models/v-donor-fundriser-lookup-base';
//Generated Imports
export class vDonorFundriserLookup extends vDonorFundriserLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}