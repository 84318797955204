import { TemplateConfig } from '@rxweb/grid';

export const EMAIL_NOTIFICATION_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction"],
        childrens: [
            {
                li: {
                    authorize:'preview',
                    childrens: [
                        {
                            a: {
                                class: [function (e) {
                                    return this.isPreview ? "" : "edit";
                                }],
                                event: {
                                    click: "onView"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa fa-eye".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {
                    
                    authorize:'delete',
                    childrens: [
                        {
                            a: {
                                class: ["delete"],
                                event: {
                                    click: "onDelete"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fw uja-Delete".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            }
        ]
    }
}