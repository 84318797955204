import { AdIntakeFormBase } from '../database-models/ad-intake-form-base';
import { EventBase } from '../database-models/event-base';
import { PageTypeBase } from '../database-models/page-type-base';
import { IntakeFormSortingBase } from '../database-models/intake-form-sorting-base';
import { IntakeFormCommentBase } from '../database-models/intake-form-comment-base';
import { IntakeFromHonoreeBase } from '../database-models/intake-from-honoree-base';
import { IntakeFromAdDesignStatusBase } from '../database-models/intake-from-ad-design-status-base';
import { prop } from '@rxweb/reactive-form-validators';
import { MandatoryHouseAndBrandDignitaryLettersBase } from '../database-models/mandatory-house-and-brand-dignitary-letters-base';

//Generated Imports
export class MandatoryHouseAndBrandDignitaryLetters extends MandatoryHouseAndBrandDignitaryLettersBase {

    //#region Generated Reference Properties

    //#region sequences prop
    sequences: number[];
    //#endregion sequences prop

    //#endregion Generated Reference Properties

}
