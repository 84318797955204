import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { EmailTemplate, EmailDetail } from '@app/models';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent } from '@rxweb/framework';
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@http({
    path: APPLICATION_URL_CONSTANTS.emails,
})

export class AbstractEmailSender extends CoreComponent {
    // emailTemplateFormGroup: IFormGroup<EmailTemplate>
    emailFormGroup: IFormGroup<EmailDetail>
    breadCrumb: BreadCrumb;
    spin: boolean = false;
    showComponent: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr
    dialog: BaseDialog;
    isShowGridColumn: boolean = false;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard
        this.breadCrumb.name1 = CONSTANT_MESSAGES.home
        this.breadCrumb.name2 = CONSTANT_MESSAGES.emailManagement
        this.breadCrumb.title = CONSTANT_MESSAGES.emailSenderText
        this.breadCrumb.pagename = CONSTANT_MESSAGES.emailSenderText
    }

    badRequest = (data) => {
        this.spin = false;
        // this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        // })
    }
}
