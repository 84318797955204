import {vEventManagerLookupBase} from '../database-models/v-event-manager-lookup-base';
//Generated Imports
export class vEventManagerLookup extends vEventManagerLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}