import {vIntakeFormStatusLookupBase} from '../database-models/v-intake-form-status-lookup-base';
//Generated Imports
export class vIntakeFormStatusLookup extends vIntakeFormStatusLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}