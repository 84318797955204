import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { User, UserDivision } from '@app/models';
import { AbstractUser } from '../domain/abstract-user';
import { Router } from '@angular/router';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { StatusEnum } from 'src/app/enums/status.enum';
import { UserLookupGroup } from 'src/app/viewModel/user-lookup-group';
import { access } from '@rxweb/angular-router';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { FormBuilder } from '@angular/forms';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@access({ accessLevel: RolePermissionModule.User, action: ActionType.POST })

@Component({
    selector: "app-user-add",
    templateUrl: './user-add.component.html'
})

export class UserAddComponent extends AbstractUser implements OnInit, OnDestroy {
    user: User;
    userLookupGroup: UserLookupGroup
    userDivisionList: Array<UserDivision>;
    //userLookupFormGroup: IFormGroup<UserLookupGroup>
    subscription: Subscription;
    showComponent: boolean = false;
    SelectAll: boolean = false;
    selectAllErrorMsg: string;

    constructor(private formBuilder: RxFormBuilder, private formBuilder_2: FormBuilder, private route: Router, private apiCallService: ApiCallService) {
        super();
        this.spin = false;
    }

    ngOnInit(): void {
        this.breadCrumb.title = "Add User";
        this.spin = true;
        this.lookup<UserLookupGroup>([
            { path: USER_LOOKUPS.roleLookUps, propName: "roleLookUps" },
            { path: USER_LOOKUPS.divisionLookUps, propName: "divisionLookUps" }])
            .subscribe(responce => {

                this.userLookupGroup = responce;
                this.user = new User();
                this.user.userId = 0;
                this.user.statusId = StatusEnum.Active;
                this.user.status = true;
                this.userDivisionList = Array<UserDivision>();
                this.userFormGroup = this.formBuilder.formGroup(this.user) as IFormGroup<User>;
                this.userFormGroup.controls.userOldPassword.clearValidators();
                this.userFormGroup.controls.userOldPassword.updateValueAndValidity();
                this.showComponent = true;
                this.spin = false;
            })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    addUser() {
        this.userFormGroup.submitted = true;
        let selectAllValidation = this.checkSelectAllValidation()

        if (this.userFormGroup.valid && selectAllValidation) {
            this.spin = true;
            this.userFormGroup.value.userDivisions = this.userDivisionList;

            this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.users, this.userFormGroup.value).subscribe(data => {
                this.spin = false;
                this.toastr.success(ValidationMessage.dataAdded)
                this.route.navigate([CONSTANT_MESSAGES.usersAsPath]);
            }, error => {
                this.spin = false;
                this.toastr.error(ValidationMessage.errorOccurred);
            }
            )
        }
    }

    setStatusValue() {
        if (this.userFormGroup.controls.status.value) {
            this.userFormGroup.controls.statusId.setValue(StatusEnum.Active);
        } else {
            this.userFormGroup.controls.statusId.setValue(StatusEnum.Inactive);
        }
    }

    setDivisionValue(checked: any, division: any, isdefault: boolean = false) {
        if (checked) {
            let userDivision = new UserDivision();

            userDivision.userId = this.user.userId;
            userDivision.divisionId = division.divisionId;
            userDivision.statusId = StatusEnum.Active;

            this.userDivisionList.push(userDivision)
        } else {
            let userDivisionDetails = this.userDivisionList.findIndex(t => t.divisionId == division.divisionId);

            this.userDivisionList.splice(userDivisionDetails, 1);
        }

        this.userLookupGroup.divisionLookUps.find(t => t.divisionId == division.divisionId).isChecked = checked;

        if (!isdefault)
            this.setDefaultSelectAll()

        this.selectAllErrorMsg = null;
        this.checkSelectAllValidation()
    }

    setSelectAll(checked: boolean) {
        this.userLookupGroup.divisionLookUps.forEach(element => {
            element.isChecked = checked;
            this.setDivisionValue(checked, element);
        });
    }

    setDefaultSelectAll(): void {
        let SelectAllList = this.userLookupGroup.divisionLookUps.find(d => !d.isChecked);

        if (SelectAllList != null) {
            this.SelectAll = false;
        } else {
            this.SelectAll = true;
        }
    }

    checkSelectAllValidation() {
        if (this.userDivisionList != null && this.userDivisionList.length > 0) {
            this.selectAllErrorMsg = null;
            return true;
        }
        else {
            this.selectAllErrorMsg = ValidationMessage.selectOne;
            return false;
        }
    }
}