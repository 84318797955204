import { Component, Input, OnInit } from '@angular/core';
import { AbstractEmailNotification } from '../domain/abstract-email-notification';

@Component({
    selector: "email-notification-details",
    templateUrl: './email-notification-details.component.html'
})

export class EmailNotificationDetails extends AbstractEmailNotification implements OnInit {
    @Input() componentType: any;
    @Input() params: string[];

    showComponent: boolean;

    id: number;
    dateAndTime: string;
    subject: string;
    body: string;

    ngOnInit(): void {
        if (this.componentType = 'modal') {
            this.showComponent = true;
            this.id = Number(this.params[0]);
            this.dateAndTime = this.params[1];
            this.subject = this.params[2]
            this.body = this.params[3];
        }
    }

    hide() {

    }
}