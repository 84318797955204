import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventNameLookupBase {

        //#region eventName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'eventName', keyColumn: false })
        eventName: string;
        //#endregion eventName Prop

        //#region eventId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'eventId', keyColumn: true })
        eventId: number;
        //#endregion eventId Prop

        //#region divisionId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'divisionId', keyColumn: false })
        divisionId: number;
        //#endregion divisionId Prop

}