import {vEventTypeLookupBase} from '../database-models/v-event-type-lookup-base';
//Generated Imports
export class vEventTypeLookup extends vEventTypeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}