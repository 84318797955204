import {vPagination_SectionDividersBase} from '../database-models/v-pagination-_-section-dividers-base';
//Generated Imports
export class vPagination_SectionDividers extends vPagination_SectionDividersBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}