import { TemplateConfig } from '@rxweb/grid';
import { IntakeFormStatusEnum } from 'src/app/enums/intake-form-status.enum';
import { INTAKE_FORM_STATUS } from 'src/app/collections/intake-form-status.collection';

export const ADINTAKEFORM_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction", "d-flex", "w-100", "justify-content-center", "align-items-center"],
        childrens: [
            {
                li: {
                    authorize: 'edit',
                    childrens: [
                        {
                            a: {
                                class: [
                                    function (e) {
                                        // let id = INTAKE_FORM_STATUS.find(t => t.name == this.intakeFormStatus).id
                                        // return id == IntakeFormStatusEnum.Completed ? "adDesign" : "disabled";
                                        if (this.isDisabled == true) {
                                            return "disabled";
                                        }

                                        else {
                                            return "intakeForm";
                                        }
                                    }
                                ],
                                event: {
                                    click: "onEdit"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fw uja-Intake-Form".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {
                    authorize: 'adDesign',
                    childrens: [
                        {
                            a: {
                                class: [
                                    function (e) {
                                        // let id = INTAKE_FORM_STATUS.find(t => t.name == this.intakeFormStatus).id
                                        // return id == IntakeFormStatusEnum.Completed ? "adDesign" : "disabled";
                                        if (this.isDisabled == true || this.isIncompleteDisabled == true) {
                                            return "disabled";
                                        }
                                        else {
                                            return "adDesign";
                                        }
                                    }
                                ],
                                event: {
                                    click: "adDesign"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fw uja-Ad-Design".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {
                    childrens: [
                        {
                            a: {
                                class: [
                                    function (e) {
                                        // let id = INTAKE_FORM_STATUS.find(t => t.name == this.intakeFormStatus).id
                                        // return id == IntakeFormStatusEnum.Completed ? "send" : "disabled";
                                        if (this.isIncompleteDisabled == true) {
                                            return "disabled";
                                        }
                                        else {
                                            return "send";
                                        }
                                    }
                                ],
                                event: {
                                    click: "onSend"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fw uja-Send".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {
                    childrens: [
                        {
                            label: {
                                class: [
                                    function (e) {
                                        // let id = INTAKE_FORM_STATUS.find(t => t.name == this.intakeFormStatus).id
                                        // return id == IntakeFormStatusEnum.Completed ? "history-btn mb-0".split(" ") : "disabled history-btn-disable mb-0".split(" ");
                                        if (this.isIncompleteDisabled == true) {
                                            return "disabled history-btn-disable mb-0".split(" ");
                                        }
                                        else {
                                            return "history-btn mb-0".split(" ");
                                        }
                                    }
                                ],
                                attributes: {
                                    for: function (e) {
                                        return "approval" + this.adIntakeFormId;
                                    },
                                },
                                childrens: [
                                    {
                                        i: { class: "fa fa-history".split(" ") }
                                    }
                                ],
                            },
                            input:
                            {
                                class: ["custom-control-input", "abc"],
                                event: {
                                    input: "onCheckboxSelect"
                                },
                                attributes: {
                                    id: function (e) {

                                        return "approval" + this.adIntakeFormId;
                                    },
                                    type: "checkbox",
                                    value: function (e) {
                                        //var a = this[e.name];
                                        return this[e.name];
                                    },
                                    checked: '$onChecked'
                                    // checked: function (e) {

                                    //     // var a = this[e.name];
                                    //     return this[e.name];
                                    // },
                                }
                            }
                        }
                    ]
                }
            }
        ]
    }
}