import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAuditLogBase {

        //#region auditRecordDetailId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'auditRecordDetailId', keyColumn: true })
        auditRecordDetailId: number;
        //#endregion auditRecordDetailId Prop

        //#region eventId Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'eventId', keyColumn: false })
        eventId: number;
        //#endregion eventId Prop


        //#region divisonName Prop
        @gridColumn({ headerCellClass:["left-0","w-200"],class:["left-0","w-200"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'divisonName', keyColumn: false, headerTitle: "Division" })
        divisonName: string;
        //#endregion divisonName Prop


        //#region eventName Prop
        @gridColumn({ headerCellClass:["left-200","w-250"],class:["left-200","w-250"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'eventName', keyColumn: false, headerTitle: "Event Name" })
        eventName: string;
        //#endregion eventName Prop


        //#region columnName Prop
        @gridColumn({ headerCellClass:["left-450","w-200"],class:["left-450","w-200"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'fieldName', keyColumn: false, headerTitle: "Field Name" })
        fieldName: string;
        //#endregion columnName Prop


        //#region oldName Prop
        @gridColumn({ headerCellClass:["w-300"],class:["w-300"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'oldName', keyColumn: false, headerTitle: "Old Value" })
        oldValue: string;
        //#endregion oldName Prop


        //#region newValue Prop
        @gridColumn({ headerCellClass:["w-300"],class:["w-300"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'newValue', keyColumn: false, headerTitle: "New Value" })
        newValue: string;
        //#endregion newValue Prop


        //#region modifiedDate Prop
        @gridColumn({ headerCellClass:["w-200"],class:["w-200"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'modifiedDate', keyColumn: false, headerTitle: "Modified Date", configuredTemplate: { templateName: "dateTimeFormat" } })
        modifiedDate: any;
        //#endregion modifiedDate Prop


        //#region modifiedBy Prop
        @gridColumn({ headerCellClass:["w-200"],class:["w-200"],visible: true, columnIndex: 8, allowSorting: true, headerKey: 'modifiedBy', keyColumn: false, headerTitle: "Modified By" })
        modifiedBy: string;
        //#endregion modifiedBy Prop

        //#region userId Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerKey: 'userId', keyColumn: false })
        userId: number;
        //#endregion userId Prop

        //#region clientIp Prop
        @gridColumn({ headerCellClass:["w-150"],class:["w-150"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'clientIp', keyColumn: false, headerTitle: "Client Ip" })
        clientIp: any;
        //#endregion clientIp Prop

}