import { HouseAndBrandAdsDignitariesLetterBase, } from '@app/database-models'
import { required } from '@rxweb/reactive-form-validators';
//Generated Imports

export class HouseAndBrandAdsDignitariesLetter extends HouseAndBrandAdsDignitariesLetterBase {

    //#region Generated Reference Properties

    //#region datasendToAWS Prop
    @required()
    datasendToAWS: string;
    //#endregion datasendToAWS Prop

    //#endregion Generated Reference Properties


}