import {vRepeatAdListBase} from '../database-models/v-repeat-ad-list-base';
//Generated Imports
export class vRepeatAdList extends vRepeatAdListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}