import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIntakeFormTemplateBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region intakeTemplateId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'intakeTemplateId', keyColumn: false})
        intakeTemplateId : number;
//#endregion intakeTemplateId Prop


//#region donorImage Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'donorImage', keyColumn: false})
        donorImage : any;
//#endregion donorImage Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region version Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'version', keyColumn: false})
        version : string;
//#endregion version Prop

}