import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailTemplateWIPLookupBase {

//#region emailTemplateId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'emailTemplateId', keyColumn: true})
        emailTemplateId : number;
//#endregion emailTemplateId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop

}