import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn, actionColumn } from "@rxweb/grid"


export class vEmailTemplateBase {

        //#region emailTemplateId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'emailTemplateId', keyColumn: true })
        emailTemplateId: number;
        //#endregion emailTemplateId Prop


        //#region name Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: false, headerKey: 'name', keyColumn: false, headerTitle: "Name" })
        name: string;
        //#endregion name Prop

        //#region templateTrigger Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'templateTrigger', keyColumn: false })
        templateTrigger: number;
        //#endregion templateTrigger Prop


        //#region status Prop
        @gridColumn({ visible: false, columnIndex: 3, allowSorting: false, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" } })
        status: string;
        //#endregion status Prop

        //#region status Prop
        @gridColumn({ visible: false, columnIndex: 4, allowSorting: false, headerKey: 'sequence', keyColumn: false, headerTitle: "sequence" })
        sequence: string;
        //#endregion status Prop

}