import { prop, propObject, propArray, required, maxLength, range, numeric, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { Pipe } from '@angular/core';


export class PageTypeBase {

        //#region pageTypeId Prop
        @prop()
        pageTypeId: number;
        //#endregion pageTypeId Prop

        //#region eventId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        eventId: number;
        //#endregion eventId Prop

        //#region pageTypeName Prop
        @required()
        @prop()
        @maxLength({ value: 50 })
        pageTypeName: string;
        //#endregion pageTypeName Prop

        //#region amount Prop
        @required()
        @numeric()
        @range({ minimumNumber: 0, maximumNumber: 99999999 })
        amount: any;
        //#endregion amount Prop

        //#region sequences Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @prop()
        @required()
        sequences: number;
        //#endregion sequences Prop

        //#region comments Prop
        @maxLength({ value: 500 })
        @prop()
        comments: string;
        //#endregion comments Prop

        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        // //Uncomment below code for Ad Type.
        // //#region adTypeId Prop
        // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        // @required()
        // adTypeId: number;
        // //#endregion adTypeId Prop

        //#region  pageTypeColor Prop
        @maxLength({ value: 500 })
        @prop()
        pageTypeColor: string;
        //#endregion pageTypeColor Prop

        //#region isHalfPage Prop
        @prop()
        isHalfPage: boolean;
        //#endregion isHalfPage Prop

        // //#region fillerAdDesign Prop
        // @prop()
        // @required()
        // fillerAdDesign: string;
        // //#endregion fillerAdDesign Prop

        //#region isModified Prop
        @required()
        isModified: boolean;
        //#endregion isModified Prop

        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number = 1;
        //#endregion createdBy Prop

        //#region createdOn Prop
        @required()
        createdOn: any = new Date;
        //#endregion createdOn Prop

        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop

        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop
}