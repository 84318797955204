import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './components/start/app.component';
import { ROUTING } from './components/start/routing';
import { RouterModule } from '@angular/router';
import { TopBarComponent } from './components/shared/top-bar/top-bar.component';
import { SideBarComponent } from './components/shared/side-bar/side-bar.component';
import { FooterBarComponent } from './components/shared/footer-bar/footer-bar.component';

import { LoginComponent } from './components/authentication/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { LoginService } from './components/authentication/login.service';
import { HttpClientModule } from '@angular/common/http';
import { RxHttp } from '@rxweb/http';
import { ApplicationBroadcaster } from './temp-service/application-broadcaster';
import { BedRequestComponent } from './components/shared/global/bed-request/bed-request.component'
import { UnauthorizedComponent } from './components/shared/global/unauthorized/unauthorized.component';
import { ModalView } from './domain/customize-design/modal';
import { AppPopup } from './components/shared/app-popup/app-popup.component';
import { MultiLingualData } from "@rxweb/localization"
import { SharedModule } from './components/shared/shared.module';
import { RxWebModule } from './rxweb.module';
import { RxRoutingModule } from "@rxweb/angular-router"
import { EventSharedModule } from './components/event/events/event-shared.module';
import { EmailSenderSharedModule } from './components/master/email-sender/email-sender-shared.module';
import { EmailNotificationSharedModule } from './components/master/email-notifications/email-notification-shared.module';
import { WIPSharedModule } from './components/event/wip/wip-shared.module';
import { AdIntakeFormModalSharedModule } from './components/event/ad-intake-forms/confirmation-save-modal/ad-intake-form-modal-shared.module';
import { AdIntakeFormSharedModule } from './components/event/ad-intake-forms/ad-intake-form-shared.module';
import { DonorUploadModalSharedModule } from './components/master/donor/donor-upload-modal/donor-upload-modal-shared.module';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/authentication/change-password/change-password.component';


import { IntakeFormSortingSharedModule } from './components/event/intake-form-sortings/intake-form-sorting-shared.module'; // For Point #5

MultiLingualData.addOrUpdate("global", {
  internationalization: {
    date: {
      format: 'mdy',
      separator: '/'
    }
  }
})

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    SideBarComponent,
    FooterBarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    BedRequestComponent,
    UnauthorizedComponent,
    AppPopup
  ],
  imports: [
    BrowserModule,
    ROUTING,
    FormsModule,
    RxWebModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RxRoutingModule
  ],
  providers: [
    LoginService,
    RxHttp,
    ApplicationBroadcaster,
    ModalView
  ],
  bootstrap: [AppComponent],
  exports: [
    RouterModule,
    EventSharedModule,
    EmailSenderSharedModule,
    EmailNotificationSharedModule,
    WIPSharedModule,
    AdIntakeFormModalSharedModule,
    AdIntakeFormSharedModule,
    DonorUploadModalSharedModule,

    IntakeFormSortingSharedModule //For Ponint #5
  ],
  entryComponents: [BedRequestComponent]//, SelectionHeaderComponent
})
export class AppModule { }