import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailNotificationRecordBase {

        //#region emailNotificationId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'emailNotificationId', keyColumn: true })
        emailNotificationId: number;
        //#endregion emailNotificationId Prop

        //#region emailDetailId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'emailDetailId', keyColumn: true })
        emailDetailId: number;
        //#endregion emailDetailId Prop

        //#region userId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'userId', keyColumn: true })
        userId: number;
        //#endregion userId Prop


        //#region divisionName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'divisionName', keyColumn: false })
        divisionName: string;
        //#endregion divisionName Prop


        //#region eventName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'eventName', keyColumn: false })
        eventName: string;
        //#endregion eventName Prop


        //#region donorId Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'donorId', keyColumn: false })
        donorId: number;
        //#endregion donorId Prop


        //#region donorName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'donorName', keyColumn: false })
        donorName: string;
        //#endregion donorName Prop


        //#region from Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'from', keyColumn: false })
        from: string;
        //#endregion from Prop


        //#region to Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'to', keyColumn: false })
        to: string;
        //#endregion to Prop


        //#region cC Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'cC', keyColumn: false })
        cC: string;
        //#endregion cC Prop


        //#region subject Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'subject', keyColumn: false })
        subject: string;
        //#endregion subject Prop


        //#region templateBody Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'templateBody', keyColumn: false })
        templateBody: string;
        //#endregion templateBody Prop

        //#region createdOn Prop
        @gridColumn({ visible: true, columnIndex: 10, allowSorting: true, headerKey: 'createdOn', keyColumn: false })
        createdOn: string;
        //#endregion createdOn Prop

}