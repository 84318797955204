import {vSectionDividerBase} from '../database-models/v-section-divider-base';
//Generated Imports
export class vSectionDivider extends vSectionDividerBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}