import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, date, RxwebValidators } from '@rxweb/reactive-form-validators';

import { Division } from '@app/models';
import { AbstractDivision } from '../domain/abstract-division';
import { Router } from '@angular/router';
import { StatusEnum } from 'src/app/enums/status.enum';
import { HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { error } from '@angular/compiler/src/util';
import { multilingual } from '@rxweb/localization'
import { access } from '@rxweb/angular-router';
import { ActionType, RolePermissionModule } from 'src/app/enums/user-role-permission';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { ValidationMessage } from "src/app/const/validation-msg/custom-validation";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@multilingual("DivisionAddComponent")

@access({ accessLevel: RolePermissionModule.Division, action: ActionType.POST })

@Component({
    selector: "app-division-add",
    templateUrl: './division-add.component.html'
})

export class DivisionAddComponent extends AbstractDivision implements OnInit, OnDestroy {
    division: Division;
    subscription: Subscription;
    userId: number;

    constructor(private formBuilder: RxFormBuilder, private route: Router, private apiCallService: ApiCallService) {
        super();
        //this.onInit()
    }

    ngOnInit(): void {
        this.division = new Division();

        //let user = this.user;
        this.divisionFormGroup = this.formBuilder.formGroup(this.division) as IFormGroup<Division>
        this.divisionFormGroup.controls.divisionName.setValidators([
            RxwebValidators.required({ message: ValidationMessage.canNotLeaveEmpty }),
            RxwebValidators.maxLength({ value: 100, message: ValidationMessage.moreThan100CharactersAreNotPermitted }),
            RxwebValidators.pattern({ expression: { 'email': /^[a-zA-Z0-9& ]*$/ }, message: ValidationMessage.enterValidDivisionName })
        ]);

        this.divisionFormGroup.controls.status.setValue(true);
        this.divisionFormGroup.controls.createdBy.setValue(this.user.userId);
        this.divisionFormGroup.controls.createdOn.setValue(new Date());

        this.showComponent = true;

        this.divisionFormGroup.controls.divisionId.setValue(0);
    }

    setStatusValue() {
        if (this.divisionFormGroup.controls.status.value)
            this.divisionFormGroup.controls.statusId.setValue(StatusEnum.Active);
        else
            this.divisionFormGroup.controls.statusId.setValue(StatusEnum.Inactive);
    }

    addDivisions() {
        this.setStatusValue();

        this.divisionFormGroup.submitted = true;

        if (this.divisionFormGroup.valid) {
            this.spin = true;

            this.subscription = this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.divisions, this.divisionFormGroup.value).subscribe(data => {
                this.spin = false;

                this.toastr.success(ValidationMessage.dataAdded);

                this.route.navigate([CONSTANT_MESSAGES.divisionPath]);
            })
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}