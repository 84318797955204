import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { EmailTemplate } from '@app/models';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';

@http({
    path: "api/EmailTemplates",
})

export class AbstractEmailTemplate extends RxHttp {
    emailTemplateFormGroup: IFormGroup<EmailTemplate>
    breadCrumb: BreadCrumb;
    spin: boolean = false;
    showComponent: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr
    dialog: BaseDialog;
    isShowGridColumn: boolean = false;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.name2 = "Master Data Management"
        this.breadCrumb.title = 'Email Template'
        this.breadCrumb.pagename = 'Email Template'
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
