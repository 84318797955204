import { UserSharedModule } from "src/app/components/user/users/user-shared.module";

export const APPLICATION_URL_CONSTANTS: { [key: string]: string } = {
    divisions: "api/Divisions",
    applicationdatas: "api/ApplicationData",

    users: "api/Users",

    forgotPassword: "api/ForgotPassword",

    events: "api/events/",

    eventWithoutBackSlash: "api/events",

    adIntakeForms: "/AdIntakeForms",

    eventTemplate: "api/eventTemplate",

    intakeTemplates: "api/IntakeTemplates",

    eventsApiPath: "api/Events",

    repeats: "/Repeats",

    houseAndBrandAdsDignitariesLettes: "api/HouseAndBrandAdsDignitariesLetters",

    intakeFormSortings: "api/IntakeFormSortings",

    intakeFormAdDesignStatuses: "api/IntakeFromAdDesignStatuses",

    searchApprovals: "api/SearchApprovals",

    searchRepeates: "api/SearchRepeates",

    searchAdIntakeForms: "api/SearchAdIntakeForms",

    sectionDividers: "api/SectionDividers",

    emails: "api/emails",

    dailyNotification: "api/DailyNotification",

    donorTemplates: "api/donorTemplates",

    emailNotifications: "api/EmailNotifications",

    emailTemplates: "api/EmailTemplates",

    roles: "api/Roles",

    auditLogs: "api/AuditLogs",

    authentication: "api/Authentication/PostLogin",

    approvalInWIP: "api/SearchApprovals/ApprovalInWIP",
    verifyUser: "api/Authentication/VerifyUser"
}
