import { RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Role, vRoleRecord } from '@app/models';
import { http } from '@rxweb/http';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BreadCrumb } from '../../../../viewModel/bread-crumb';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { ComponentFactoryResolver } from '@angular/core';
import { CoreComponent } from "@rxweb/angular-router"
import { vRole } from '../../../../models/extended-models/v-role';
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@http({
    path: APPLICATION_URL_CONSTANTS.roles,
})

export class AbstractRole extends CoreComponent {
    roleFormGroup: IFormGroup<Role>
    vRoleFormGroup: IFormGroup<vRoleRecord>
    showComponent: boolean = false;
    isShowGridColumn: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr
    dialog: BaseDialog;
    modalView: ModalView;
    breadCrumb: BreadCrumb;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    bindBreadCrumb() {

        this.breadCrumb = new BreadCrumb();

        this.breadCrumb.name1 = CONSTANT_MESSAGES.home
        this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard

        this.breadCrumb.name2 = CONSTANT_MESSAGES.userManagement

        this.breadCrumb.title = CONSTANT_MESSAGES.roles
        this.breadCrumb.pagename = CONSTANT_MESSAGES.roles
    }

    badRequest = (data) => {
        this.spin = false;

        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
