import {vIntakeFormListDetailBase} from '../database-models/v-intake-form-list-detail-base';
//Generated Imports
export class vIntakeFormListDetail extends vIntakeFormListDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}