import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGetDataforLinkBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventDate Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventDate', keyColumn: false})
        eventDate : any;
//#endregion eventDate Prop


//#region adSpec Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'adSpec', keyColumn: false})
        adSpec : string;
//#endregion adSpec Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop


//#region eventLocation Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'eventLocation', keyColumn: false})
        eventLocation : string;
//#endregion eventLocation Prop


//#region eventType Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventType', keyColumn: false})
        eventType : string;
//#endregion eventType Prop


//#region emailDetailId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'emailDetailId', keyColumn: false})
        emailDetailId : number;
//#endregion emailDetailId Prop


//#region intakeTemplateId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'intakeTemplateId', keyColumn: false})
        intakeTemplateId : any;
//#endregion intakeTemplateId Prop


//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: false})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region imageURL Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'imageURL', keyColumn: false})
        imageURL : string;
//#endregion imageURL Prop

}