import {vCampaignYearLookupBase} from '../database-models/v-campaign-year-lookup-base';
//Generated Imports
export class vCampaignYearLookup extends vCampaignYearLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}