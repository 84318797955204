import { TemplateConfig } from '@rxweb/grid';

export const WIP_Action_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction", "d-flex"],
        childrens: [
            {
                li: {
                    childrens: [
                        {
                            // a: {
                            //     class: ["edit"],
                            //     event: {
                            //         click: "onEdit"
                            //     },
                            //     childrens: [{
                            //         i: { class: "fa-fw uja-Edit".split(" ") }
                            //     }],
                            // }
                            a: {
                                class: [
                                    function (e) {
                                        if (this.isEditDisabled)
                                            return "disabled";
                                        else
                                            return "edit";
                                    }
                                ],
                                event: {
                                    click: "onEdit"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fa uja-Edit".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {

                    childrens: [
                        {
                            a: {
                                class: [
                                    function (e) {

                                        if (this.isSendDisabled)
                                            return "disabled";
                                        else
                                            return "send";
                                    }
                                ],
                                event: {
                                    click: "onSend"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa fa-envelope-o".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {
                    authorize: 'approve',
                    childrens: [
                        {
                            a: {
                                class: [
                                    function (e) {

                                        if (this.isApproveButtonClicked) {
                                            return "disabled";
                                        }
                                        else {
                                            return "approve";
                                        }
                                    }
                                ],
                                event: {
                                    click: "onApproval"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa fa-check".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            {
                li: {
                    childrens: [
                        {
                            a: {
                                class: ["download"],
                                event: {
                                    click: "onDownload"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa fa-download".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            }
        ]
    }
}