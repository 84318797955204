import {IntakeFromAdDesignStatusBase} from '../database-models/intake-from-ad-design-status-base';
import {AdIntakeFormBase} from '../database-models/ad-intake-form-base';
import {IntakeTemplateBase} from '../database-models/intake-template-base';
//Generated Imports
export class IntakeFromAdDesignStatus extends IntakeFromAdDesignStatusBase 
{




//#region Generated Reference Properties
//#region adIntakeForm Prop
adIntakeForm : AdIntakeFormBase;
//#endregion adIntakeForm Prop
//#region intakeTemplate Prop
intakeTemplate : IntakeTemplateBase;
//#endregion intakeTemplate Prop

//#endregion Generated Reference Properties







































}