import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IntakeFormCommentBase {

//#region intakeFormCommentId Prop
        @prop()
        intakeFormCommentId : number;
//#endregion intakeFormCommentId Prop


//#region adIntakeFormId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region comment Prop
        @required()
        @maxLength({value:500})
        comment : string;
//#endregion comment Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop



}