import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventTemplateBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventTemplateId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventTemplateId', keyColumn: false})
        eventTemplateId : number;
//#endregion eventTemplateId Prop


//#region oPSTemplateId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'oPSTemplateId', keyColumn: false})
        oPSTemplateId : string;
//#endregion oPSTemplateId Prop

}