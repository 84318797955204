import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vApprovalListBase {

        //#region adIntakeFormId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true })
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region intakeTemplateId Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: false, headerKey: 'intakeTemplateId', keyColumn: false })
        intakeTemplateId: number;
        //#endregion intakeTemplateId Prop


        //#region divisionId Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'divisionId', keyColumn: false })
        divisionId: number;
        //#endregion divisionId Prop


        //#region eventId Prop
        @gridColumn({ visible: false, columnIndex: 3, allowSorting: true, headerKey: 'eventId', keyColumn: false })
        eventId: number;
        //#endregion eventId Prop


        //#region donorName Prop
        @gridColumn({ headerCellClass: ["w-150"], class: ["w-150"], isAscending: false, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'donorName', keyColumn: false, headerTitle: 'Donor Name', configuredTemplate: { templateName: "redirectApprovalLink" } })
        donorName: string;
        //#endregion donorName Prop


        //#region uploadedBy Prop
        @gridColumn({ headerCellClass: ["dw-100"], class: ["dw-100"], isAscending: false, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'uploadedBy', keyColumn: false, headerTitle: "Ad Name" })
        uploadedBy: string;
        //#endregion uploadedBy Prop


        //#region pageTypeName Prop
        @gridColumn({ headerCellClass: ["w-150"], class: ["w-150"], isAscending: false, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'pageTypeName', keyColumn: false, headerTitle: 'Page Type' })
        pageTypeName: string;
        //#endregion pageTypeName Prop


        //#region oPSTokenId Prop
        @gridColumn({ visible: false, columnIndex: 7, allowSorting: true, headerKey: 'oPSTokenId', keyColumn: false })
        oPSTokenId: string;
        //#endregion oPSTokenId Prop


        //#region imageURL Prop
        @gridColumn({ class: ["text-center"], visible: false, columnIndex: 8, allowSearch: false, allowSorting: false, headerKey: 'imageURL', keyColumn: false })
        imageURL: string;
        //#endregion imageURL Prop


        //#region thumbImageURL Prop
        @gridColumn({ headerCellClass: ["w-150"], class: ["text-center", "w-150"], isAscending: false, visible: true, columnIndex: 9, allowSearch: false, allowSorting: false, headerKey: 'thumbImageURL', keyColumn: false, headerTitle: 'Thumbnail Image', configuredTemplate: { templateName: "thumbnailImage" } })
        thumbImageURL: string;
        //#endregion thumbImageURL Prop


        //#region version Prop
        @gridColumn({ headerCellClass: ["dw-100"], class: ["dw-100"], isAscending: false, visible: true, columnIndex: 10, allowSorting: true, headerKey: 'version', keyColumn: false, headerTitle: "Version" })
        version: string;
        //#endregion version Prop


        //#region oPSTemplateId Prop
        @gridColumn({ visible: false, columnIndex: 11, allowSorting: true, headerKey: 'oPSTemplateId', keyColumn: false })
        oPSTemplateId: any;
        //#endregion oPSTemplateId Prop


        //#region createdOn Prop
        @gridColumn({ headerCellClass: ["w-200"], class: ["w-200"], isAscending: true, visible: true, columnIndex: 12, allowSorting: true, headerKey: 'createdOn', keyColumn: false, headerTitle: 'Upload Date & Time', configuredTemplate: { templateName: "dateTimeFormat" } })
        createdOn: Date;
        //#endregion createdOn Prop


        //#region donorApproved Prop
        @gridColumn({ headerCellClass: ["w-150"], class: ["w-150"], isAscending: false, visible: true, columnIndex: 13, allowSorting: true, headerKey: 'donorApproved', keyColumn: false, headerTitle: 'Donor Approved' })
        donorApproved: string;
        //#endregion donorApproved Prop

}