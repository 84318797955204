import {vHouseAdsAndLetterBase} from '../database-models/v-house-ads-and-letter-base';
//Generated Imports
export class vHouseAdsAndLetter extends vHouseAdsAndLetterBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}