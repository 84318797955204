import { prop } from '@rxweb/reactive-form-validators';

export class DashboardViewModel{
    @prop()
    public divisionId:any;
    // @prop()
    // public donorFundraiser:any;
    // @prop()
    // public donorId:any;
    // @prop()
    // public adCreator:any;
    @prop()
    public campaignYear: any
    @prop()
    public eventManager:any;
    @prop()
    public eventFundriser:any;
    // @prop()
    // public adJournalRelationshipOwner:any;

}
