import { prop, propObject, propArray, required, maxLength, range, email, alpha } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserBase {

        //#region userId Prop
        @prop()
        userId: number;
        //#endregion userId Prop


        //#region applicationLocaleId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        applicationLocaleId: number = 1;
        //#endregion applicationLocaleId Prop


        //#region applicationTimeZoneId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        applicationTimeZoneId: number = 124;
        //#endregion applicationTimeZoneId Prop


        //#region languageCode Prop
        @required()
        @maxLength({ value: 3 })
        languageCode: string = 'En';
        //#endregion languageCode Prop


        //#region password Prop
        @maxLength({ value: 132 })
        password: any;
        //#endregion password Prop


        //#region salt Prop
        @maxLength({ value: 140 })
        salt: any;
        //#endregion salt Prop


        //#region firstName Prop
        @required()
        @maxLength({ value: 100 })
        //@alpha()
        firstName: string;
        //#endregion firstName Prop


        //#region lastName Prop
        @required()
        @maxLength({ value: 100 })
        //@alpha()
        lastName: string;
        //#endregion lastName Prop


        //#region email Prop
        @required()
        @email()
        @maxLength({ value: 50 })
        email: string;
        //#endregion email Prop


        //#region roleId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        roleId: number;
        //#endregion roleId Prop


        //#region loginBlocked Prop
        @required()
        loginBlocked: boolean = false;
        //#endregion loginBlocked Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region verificationCode Prop
        @prop()
        verificationCode: any;
        //#endregion verificationCode Prop


        //#region createdBy Prop
        @prop()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @prop()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop











}