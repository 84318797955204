import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, from } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from '../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../domain/customize-design/dialog';

import { Router } from '@angular/router';
import { anonymous, middleware } from '@rxweb/angular-router'
import { CoreComponent } from '@rxweb/angular-router';
import { LoggedInMiddleware } from 'src/app/domain/security/middleware/logged-in.middleware';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { UserCredentialForgotPasswordModel } from 'src/app/viewModel/user-credential-forgot-password';
import { ApiCallService } from 'src/app/temp-service/api-call-service';
import { APPLICATION_URL_CONSTANTS } from 'src/app/const/uris/application-url-constants';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';

@middleware([LoggedInMiddleware])

@anonymous()

@Component({
    selector: 'app-login',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent extends CoreComponent implements OnInit {
    spin: boolean = false;
    isApplied: boolean = false;
    timeOutId: number;
    userCredentialForgotPasswordModel: UserCredentialForgotPasswordModel;
    userCredentialFormGroup: IFormGroup<UserCredentialForgotPasswordModel>;
    subscription: Subscription;
    currentYear: Date = new Date();
    showComponent: boolean = false;
    toastr: BaseToastr = new BaseToastr();
    dialog: BaseDialog;

    constructor(private route: Router, private formBuilder: RxFormBuilder, private storagedata: BrowserStorage, private apiCallService: ApiCallService) {
        super();

        this.storagedata = new BrowserStorage();
        this.showComponent = true;
    }

    ngOnInit(): void {
        this.userCredentialForgotPasswordModel = new UserCredentialForgotPasswordModel();
        this.userCredentialFormGroup = this.formBuilder.formGroup(this.userCredentialForgotPasswordModel) as IFormGroup<UserCredentialForgotPasswordModel>;

        this.spin = false;
        var auth = this.storagedata.local.get(CONSTANT_MESSAGES.auth, false);
        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

        if (auth) {
            element.classList.remove("login_page_view");
            this.route.navigate([CONSTANT_MESSAGES.dashboardPath])
        }

        this.timeOutId = window.setTimeout(() => {
            window.clearTimeout(this.timeOutId);

            this.isApplied = true;
            this.showComponent = true;
        }, 500)
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    forgotPassword(event): void {
        if (event.key == "Enter" || event.key == undefined) {
            this.userCredentialFormGroup.submitted = true;
            this.isApplied = false;

            if (this.userCredentialFormGroup.valid) {
                this.spin = true;

                this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.forgotPassword, this.userCredentialFormGroup.value).subscribe(response => {
                    if (response.failedLogin) {
                        this.toastr.error(response.validationMessage);
                    }
                    else {
                        this.showComponent = false;
                        this.toastr.success(ValidationMessage.mailSentSuccessfully);

                        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
                        element.classList.add("login-page-view");

                        this.route.navigate([CONSTANT_MESSAGES.loginPath]);
                    }

                    this.spin = false;
                }, error => {
                    this.spin = false;

                    let messages = JSON.parse(error);
                    this.toastr.warning(messages[0]);

                    var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
                    element.classList.add("login-page-view");

                    window.location.href = CONSTANT_MESSAGES.loginPath
                })
            }
        }
    }

    login(): void {
        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
        element.classList.add("login-page-view");

        window.location.href = CONSTANT_MESSAGES.loginPath
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);

        this.toastr.error(messages[0]);

        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

        element.classList.add("login-page-view");

        this.route.navigate([CONSTANT_MESSAGES.loginPath]);
    }
}