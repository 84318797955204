import { TemplateConfig } from '@rxweb/grid';

export const AMOUNT_FORMAT_GRID_TEMPLATE: TemplateConfig = {
    span: {
        childrens: [{
            text: {
                text: function (e) {

                    if(this[e.name] != null){
                      
                     
                      return Number(this[e.name]).toLocaleString('en-US',{style:'currency',currency:'USD'});
                    }else{
                        return 'NA'
                    }
                  }
        }}]
    }
}
