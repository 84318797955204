import { actionColumn, gridColumn } from "@rxweb/grid";

@actionColumn({
    name: 'deleteAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"]
})

export class HouseAndBrandAdsDignitariesLetterModel {

    @gridColumn({ visible: false, columnIndex: 0, allowSorting: false, headerKey: 'houseAndBrandAdsDignitariesLettersId', keyColumn: true })
    houseAndBrandAdsDignitariesLettersId: number;

    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'brandTypeId', keyColumn: false, headerTitle: "Ads & Letters" })
    brandTypeId: number;

    @gridColumn({ class: ["text-center"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'dataFromAWS', keyColumn: false, headerTitle: "Thumbnail Image", configuredTemplate: { templateName: "thumbnailImage" } })
    dataFromAWS: string;

    @gridColumn({ class: ["text-center"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'isMandatory', keyColumn: false, headerTitle: "Mandatory ?", configuredTemplate: { templateName: 'mandatoryCheckboxTemplate' } })
    isMandatory: string;

    @gridColumn({ visible: false, columnIndex: 3, allowSorting: true, headerKey: 'dataFromAWS', keyColumn: false, headerTitle: "Image" })
    Image: string;

}
