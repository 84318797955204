import { Component, OnInit } from '@angular/core';
import { HttpClientConfig, HttpResponse } from '@rxweb/http';
import { ApplicationBroadcaster } from '../../temp-service/application-broadcaster';
import { Router } from '@angular/router';
import { AuthFilter, HttpResponseCode } from '../../temp-service/AuthFilter';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { GridCustomTemplate } from "@rxweb/grid"
import { GRID_CUSTOM_TEMPLATES } from '../../custom-templates/grid';
import { ErrorMessageBindingStrategy } from '@rxweb/reactive-form-validators';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { SetSystemTimeout } from 'src/app/enums/set-system-timeout';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';

export var WIPpathName: any;
export var OpsUrl: string;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})

export class AppComponent extends CoreComponent implements OnInit {
    title = 'ujafederation';
    isShowDashboard: boolean = false;
    isSideBarShow: boolean = false;
    baseToastr: BaseToastr
    appApplyActive: boolean = false;
    HttpResponseCodes: any;
    isShowHeaderFooter: boolean = true;
    isEvent: boolean;
    eventPath: any;

    constructor(applicationBroadCaster: ApplicationBroadcaster, private route: Router, private storagedata: BrowserStorage) {
        super();

        this.storagedata = new BrowserStorage();
        this.HttpResponseCodes = HttpResponseCode;
        this.baseToastr = new BaseToastr();
        this.isEvent = location.pathname.includes('WIP');

        if (this.isEvent)
            this.eventPath = location.pathname;

        applicationBroadCaster.loginSubscriber.subscribe(t => {
            this.isShowDashboard = false;
            this.isShowHeaderFooter = true;
            this.loginClicked(t);
        });
    }

    ngOnInit(): void {
        const isDonorReport = location.pathname.includes(CONSTANT_MESSAGES.donorReport);
        const isDonor = location.pathname.startsWith(CONSTANT_MESSAGES.donor);
        const isRestPassword = location.pathname.startsWith(CONSTANT_MESSAGES.resetPassword);
        const isChangePassword = location.pathname.startsWith(CONSTANT_MESSAGES.changePassword);
        // const isEvent = location.pathname.startsWith('/events');

        //OPS Url

        // While deployment please see 'half-full-page-type.enum.ts file

        //Live 1 Or Local
        //#region Local or Live1
        // OpsUrl = 'https://ujafederationops.dev.radixweb.net';
        //#endregion

        //#region AWS Stagging Server
        OpsUrl = 'https://tmpjaslinuxqa.ujafedny.org';
        //#endregion

        //#region AWS Production Server
        // OpsUrl = 'https://jaslinux.ujafedny.org';
        //#endregion

        GridCustomTemplate.register(GRID_CUSTOM_TEMPLATES);
        HttpClientConfig.register({
            hostURIs: [{
                name: 'server',
                default: true,

                //Live1
                // uri: "https://ujafederation-api.live1.dev.radixweb.net"

                //AWS Stagging
                uri: "https://jasqa-api.ujafedny.org"

                //AWS Production
                // uri: "https://jas-api.ujafedny.org"
            },
            {
                name: 'local',
                default: false,
                //Local
                uri: "https://localhost:44397"

                //Live1
                // uri: "https://ujafederation-api.live1.dev.radixweb.net"

                //AWS Stagging
                //uri: "https://jasqa-api.ujafedny.org"

                //AWS Production
                //uri: "https://jas-api.ujafedny.org"
            }],
            filters: [{ model: AuthFilter }],
            onError: (response: HttpResponse) => {
                if (!(!isDonorReport && isDonor) && !isRestPassword) {
                    if (response.statusCode == HttpResponseCode.InvalidStatusCode) {
                        this.LoginRedirect(false, true, false);
                    }
                    else if (response.statusCode == HttpResponseCode.InternalServerError) {
                        this.baseToastr.error(ValidationMessage.errorOccured)
                    }
                    else if (response.statusCode == HttpResponseCode.Unauthorized || this.HttpResponseCodes.Unauthorized) {
                        this.LoginRedirect(false, true, false);
                    }
                }
                else {
                    this.storagedata.local.clearAll();
                    var data = CONSTANT_MESSAGES.error
                    this.route.navigateByUrl(CONSTANT_MESSAGES.donorThankyouPath + data);
                }
            }
        })

        if ((!isDonorReport && isDonor) || isRestPassword || isChangePassword) {
            document.body.className = "bg-transparent"
            this.isShowHeaderFooter = false;
            this.route.navigateByUrl(location.pathname + location.search)
        }
        else {
            var auth = this.storagedata.local.get(CONSTANT_MESSAGES.auth, false);
            var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

            if (auth) {
                this.changePasswordValidate();
                element.classList.remove("login_page_view");
                this.isSideBarShow = true;
                this.isShowDashboard = true;
                this.isShowHeaderFooter = true;
                if (this.isEvent)
                    this.route.navigate[location.pathname]
            }
            else {
                this.LoginRedirect(false, true, false);
                WIPpathName = location.pathname;
            }

            if (this.isEvent) {
                if (auth) {
                    this.changePasswordValidate();
                    element.classList.remove("login_page_view");
                    this.isSideBarShow = true;
                    this.route.navigate[location.pathname]
                    this.isShowHeaderFooter = true;
                }
                else {
                    this.LoginRedirect(false, true, false);
                    WIPpathName = location.pathname;
                }
            }
            else {
                if (auth) {
                    this.changePasswordValidate();
                    element.classList.remove("login_page_view");
                    this.isSideBarShow = true;
                    this.isShowDashboard = true;
                    this.isShowHeaderFooter = true;
                }
                else {
                    this.LoginRedirect(false, true, false);
                }
            }
        }

        ReactiveFormConfig.set({
            "validationMessage": {
                "required": "You can't leave this empty",
                "minLength": "Minimum {{1}}  characters required",
                "maxLength": "More than {{1}} characters are not permitted",
                "pattern": "The specified input format is not recognized",
                "compare": "The specified values of New Password and Confirm Password must be the same",
                "contains": "The specified value must ' in the input",
                "alpha": "You can use letters only",
                "alphaNumeric": "You can use letters, numbers and periods only",
                "range": "You need to select appropriate value in this field",
                "maxNumber": "You can not enter value more than #n#",
                "numeric": "Only number required",
                "email": "Please enter valid email address",
                "latitude": "Please enter a valid latitude",
                "longitude": "Please enter a valid longitude",
                "onlypassword": "Password must contains mini. 8 & max. 15 chars including upper/lowercase, alphanumeric, special chars and numbers.",
            }, "reactiveForm": {
                "errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnTouchedOrSubmit
            }

        });
    }

    LoginRedirect(isShowDashboard: boolean, isShowHeaderFooter: boolean, isSideBarShow: boolean) {
        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
        this.storagedata.local.clearAll();
        this.route.navigate(["/login"])
        element.classList.add("login_page_view");
        this.isShowDashboard = isShowDashboard;
        this.isShowHeaderFooter = isShowHeaderFooter;
    }

    loginClicked(isClicked: boolean): void {
        if (this.isEvent) {
            var auth = this.storagedata.local.get(CONSTANT_MESSAGES.auth, false);
            var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

            if (auth) {
                this.changePasswordValidate();
                element.classList.remove("login_page_view");
                this.isSideBarShow = true;
                this.route.navigateByUrl(this.eventPath)
                this.eventPath = "";
                this.isShowHeaderFooter = true;
            }
            else {
                this.LoginRedirect(false, true, false);
                WIPpathName = this.eventPath;
            }
        }
        else {
            let isValid = this.changePasswordValidate();

            if (isClicked && !isValid) {
                this.isShowDashboard = true;
                this.isSideBarShow = true;
                var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
                element.classList.remove("login_page_view");
                this.route.navigate(["/dashboard"])

                setTimeout(() => { this.isShowDashboard = true; }, 50)
            }
        }
    }

    changePasswordValidate(): boolean {
        let resetPasswordTime = this.storagedata.local.get(CONSTANT_MESSAGES.resetPasswordTime);

        if (resetPasswordTime == null || resetPasswordTime == undefined) {
            // window.location.href = "/changepassword"
            this.route.navigateByUrl(CONSTANT_MESSAGES.changePassword);

            return true;
        }
        else {
            let diff = this.date_diff_indays(new Date(resetPasswordTime), new Date())
            let time = SetSystemTimeout.chanpasswordTime;

            if (diff > time) {
                //window.location.href = "/changepassword"
                this.route.navigateByUrl(CONSTANT_MESSAGES.changePassword);
                return true;
            }
            else
                return false;
        }
    }

    date_diff_indays(date1, date2) {
        let dt1 = new Date(date1);
        let dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }
}
