import {vEmailNotificationRecordBase} from '../database-models/v-email-notification-record-base';
//Generated Imports
export class vEmailNotificationRecord extends vEmailNotificationRecordBase {

    //#region Generated Reference Properties

//#endregion Generated Reference Properties



}