import { TemplateConfig } from '@rxweb/grid';

export const RECORD_ACTIVE_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [function (e) {
            return this[e.name] == 'Active' ? "label bg-light-green text-white".split(" ") : 'label bg-light-gray text-white'.split(" ");
        }],
        childrens: [{
            text: {
                text: function (e) { return this[e.name] == 'Active' ? "Active" : "Inactive" }
            }
        }]
    }
}
