import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EmailSenderBase {

//#region emailSenderId Prop
        @prop()
        emailSenderId : number;
//#endregion emailSenderId Prop


//#region fromUserId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        fromUserId : number;
//#endregion fromUserId Prop


//#region emailReceipients Prop
        @required()
        emailReceipients : string;
//#endregion emailReceipients Prop


//#region emailSubject Prop
        @required()
        emailSubject : string;
//#endregion emailSubject Prop


//#region emailBody Prop
        @prop()
        emailBody : string;
//#endregion emailBody Prop


//#region emailAttachments Prop
        @prop()
        emailAttachments : string;
//#endregion emailAttachments Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : Date;
//#endregion modifiedOn Prop



}