import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { DonorUploadModalComponent } from './donor-upload-modal.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [DonorUploadModalComponent],
    exports: [],
    providers: [],
    entryComponents: [DonorUploadModalComponent]
})

export class DonorUploadModalSharedModule { }