export const CONSTANT_MESSAGES: { [key: string]: string } = {
    dashboard: "dashboard",
    home: "Home",
    auditLogs: "audit-logs",
    auditLog: "Audit Log",
    rtOutTime: "RT.OutTime",
    userLog: "UserLog",
    eventLog: "EventLog",
    descending: "desc",
    ascending: "asc",
    auditLogsUsedInTag: "auditLogs",
    userLogs: "userLogs",
    badRequestStatusCode: "400",
    login: "login",
    dashboardPath: "/dashboard",
    loginPath: "/login",
    gtrac: "gtrac",
    auth: "auth",
    key: "key",
    userName: "userName",
    resetPasswordTime: "resetPasswordTime",
    changePassword: "/changepassword",
    forgotPassword: "/forgotpassword",
    activationKey: "activationkey",
    emailId: "emailId",
    donor_thankYou_resetPasswordError: "donor/thank-you/ResetPasswordError",
    divisionName: "divisionName",
    pinnedEvents: "PinEventId",
    campaignyear: "campaignYear",
    eventDashboard: "Events",
    searchDetails: "searchDetails",
    categoryAsDashboard: "Dashboard",
    eventsPath: "/events/",
    adIntakeFormsPath: "/ad-intake-forms",
    DivisionName: "Division Name",
    Campaignyear: "Campaign Year",
    event: "event",
    Event: "Event",
    eventDate: "eventDate",
    EventDate: "Event Date",
    journalAdDeadlineDate: "journalAdDeadlineDate",
    AdJournalDeadlineDate: "Ad Journal Deadline Date",
    donorFundraiser: "donorFundraiser",
    DonorFundraiser: "Donor Fundraiser",
    eventManager: "eventManager",
    EventManager: "Event Manager",
    eventFundraiser: "eventFundriser",
    EventFundraiser: "Event Fundraiser",
    adJournalRelationshipOwner: "adJournalRelationshipOwner",
    AdJournalRelationshipOwner: "Ad Journal Relationship Owner",
    adCreator: "adCreator",
    AdCreator: "Ad Creator",
    excel: "Excel",
    eventDashboardAsXlsx: "Events.xlsx",
    adManagementAsXlsx: "Ad Management.xlsx",
    mimeExcel: "application/vnd.ms-excel",
    eventsList: "Events List",
    eventView: "events/view",
    adManagement: "Ad Management",
    eventsSingleSlash: "events/",
    adIntake: "Ad Intake",
    adIntakeForm: "Ad Intake Form",
    tab: "Tab",
    enter: "Enter",
    browseAd: "BrowseAd",
    customDesign: "CustomDesign",
    repeatAd: "RepeatAd",
    submittedPredesigned: "SubmittedPredesigned",
    donorSubmittedTemplate: "DonorSubmittedTemplate",
    infoModal: "infoModal",
    confirmationSaveModal: "confirmationSaveModal",
    adIntakeFormsPathForwardSlash: "/ad-intake-forms/",
    intakeFormAdDesignTemplate: "/intake-form-ad-design-template/",
    emailSender: "/email-sender/",
    mimePdfAndBase64: "data:application/pdf;base64,",
    mimePngAndBase64: "data:image/png;base64,",
    mimeJpegAndBase64: "data:image/jpeg;base64,",
    mimeTiffAndBase64: "data:image/tiff;base64,",
    mimeJpgAndBase64: "data:image/jpg;base64,",
    journalAdCopyLogo: "Journal Ad Copy Logo",
    mimePdf: "application/pdf",
    pdf: "pdf",
    jpg: "jpg",
    png: "png",
    jpeg: "jpeg",
    tiff: "tiff",
    tif: "tif",
    staticPdfPathAsPng: '../../../../../assets/images/default_Pdf.png',
    yes: "yes",
    adIntakeFormId: "ad-Intake-form-id",
    jvber: "JVBER",
    sukqa: "SUKQA",
    adIntakeJournalGrid: "adIntakeJournalGrid",
    aiAndAdIntakeFormId: "ai.adIntakeFormId",
    intakeListAsCategory: "IntakeList",
    adManagementAsTab: "AdManagement",
    adIntakeForms: "adIntakeForms",
    approved: "Approved",
    incompleted: "Incompleted",
    title: "title",
    intakeForm: "Intake Form",
    adDesign: "Ad Design",
    send: "Send",
    history: "History",
    donorId: "donorId",
    DonorId: "Donor Id",
    donorName: "donorName",
    DonorName: "Donor Name",
    relationshipOwner: "relationshipOwner",
    RelationshipOwner: "Relationship Owner",
    pageType: "pageType",
    PageTye: "Page Type",
    amount: "amount",
    Amount: "Amount",
    adType: "adType",
    AdType: "Ad Type",
    compUpgradeAd: "compUpgradeAd",
    CompOrUpgradeAd: "Comp or Upgrade Ad",
    adDesignStatus: "adDesignStatus",
    AdDesignStatus: "Ad Design Status",
    intakeFormStatus: "intakeFormStatus",
    IntakeFormStatus: "Intake Form Status",
    mostRecentEmailSent: "mostRecentEmailSent",
    MostRecentEmailSent: "Most Recent Email Sent",
    emailSentDate: "emailSentDate",
    EmailSentDate: "Email Sent Date",
    adContactName: "adContactName",
    AdContactName: "Ad Contact Name",
    adContactPhone: "adContactPhone",
    AdContactPhone: "Ad Contact Phone",
    adContactEmailAddress: "adContactEmailAddress",
    AdContactEmailAddress: "Ad Contact Email Address",
    updatedBy: "updatedBy",
    UpdatedBy: "Updated By",
    updatedDate: "updatedDate",
    UpdatedDate: "Updated Date",
    updatedActionType: "updatedActionType",
    UpdatedActionType: "Updated Action Type",
    adIntakeFormIdDisplay: "adIntakeFormIdDisplay",
    AdIntakeFormId: "Ad Intake Form ID",
    delete: "delete",
    downloadmodal:"downloadmodal",
    defaultcolor: "defaultcolor",
    adIntakeFormsAdd: "/ad-intake-forms/add",
    intakeFormAdDesignTemplateUploadDesign: "/intake-form-ad-design-template/UploadDesign",
    intakeFormAdDesignTemplateWIP: "/intake-form-ad-design-template/WIP",
    intakeFormAdDesignTemplateRepeatAd: "/intake-form-ad-design-template/RepeatAd",
    intakeFormAdDesignTemplateBrowseAd: "/intake-form-ad-design-template/BrowseAd",
    donorSubmittedPredesignedAdType: "Donor Submitted Predesigned",
    donorSubmittedTemplateAdType: "Donor Submitted Template",
    ujaCustomAdType: "UJA Custom Ad",
    ujaRepeatAdType: "UJA Repeat Ad",
    atleatOneTemplateApprovedAdIntakeFormId: "atleatOneTemplateApprovedAdIntakeFormId",
    templates: "templates",
    AtleadOneTemplateApprovedAdIntakeFormConfirmation: "AtleadOneTemplateApprovedAdIntakeFormConfirmation",
    adDesignTemplateAsTab: "AdDesignTemplate",
    eventManagement: "Event Management",
    eventSetup: "Event Setup",
    events: "events",
    adDesignTemplate: "Ad Design Template",
    eventBackSlash: "/events",
    ivbor: "IVBOR",
    subStringOfPNG: "/9j/4",
    subStringOfJPEG: "/9J/2",
    eventsAsTitle: "Events",
    isArchiveEvent: "isArchiveEvent",
    copyEvent: "Copy Event",
    editEventSetup: "Edit Event Set up",
    adTemplatesAndSectionDividers: "Ad Templates and Section Dividers",
    copy: "copy",
    active: "Active",
    inactive: "Inactive",
    eventOpsTemplate: "/event-ops-template",
    eventList: "eventList",
    eCreatedOn: "E.CreatedOn",
    customSwitch1: "customSwitch1",
    checked: "checked",
    markAsRead: "markAsRead",
    deleteAll: "deleteAll",
    approval: "approval",
    houseOrBrandAds: "House / Brand Ads",
    tributeLetters: "Tribute Letters",
    houseAndBrandAdsDignitariesLetters: "houseAndBrandAdsDignitariesLetters",
    thumbnailImage: "Thumbnail Image",
    eventId: "eventId",
    houseAndBrandAds: "House and Brand Ads",
    digital: "_Digital",
    pdfExtention: ".pdf",
    finalDigitalPdf: "FinalDigitalPdf",
    finalPrintReadyPdf: "FinalPrintReadyPdf",
    printReady: "_Print - Ready",
    sortingPageTypesAscendingOrder: "sortingPageTypesAscendingOrder_@_",
    getPageTypeExceptCurrent: "getPageTypeExceptCurrent",
    aidsCreatedOn: "aids.createdon",
    intakeFromAdDesignStatus: "IntakeFromAdDesignStatus",
    overview: "Overview",
    aiDonorId: "ai.donorId",
    adManagementOverview: "adManagementOverview",
    selectedValue: "selectedValue",
    adIntakeFormIdVariable: "adIntakeFormId",
    donorIdForReport: "Donor ID",
    excelExtension: ".xlsx",
    pdfType: "Pdf",
    pageTypeReport: "PageTypeReport",
    adDesignStatusReport: "AdDesignStatusReport",
    intakeStatusReport: "IntakeStatusReport",
    journalAdDonorsByPageType: "Journal Ad Donors By Page Type",
    allPages: "All Pages",
    journalAdByAdDesignStatus: "Journal Ad By Ad Design Status",
    allDesignStatus: "All Design Status",
    journalAdByIntakeFormStatus: "Journal Ad By Intake Form Status",
    allIntakeFormStatus: "All Intake Form Status",
    journalAdManagementOverview: "Journal Ad Management Overview",
    sectionDividers: "sectionDividers",
    imgPath: "imgPath",
    fontSize: "FontSize",
    fontSizeValue: "12pt",
    fontName: "FontName",
    fontNameValue: "Verdana",
    uploadDesign: "uploadDesign",
    uploadImage: "Upload Image",
    inReviewUJA: "In Review – UJA",
    isAlreadyAnyTemplateApproved: "isAlreadyAnyTemplateApproved",
    wipList: "WIPList",
    ujaTemplateAd: "UJA Template Ad",
    donorImage: "Donor Image",
    sendAdDesign: "Send Ad Design",
    edit: "Edit",
    approve: "Approve",
    downloadFile: "Download File",
    divisionPath: "/divisions/",
    applicationDataPath: "/applicationdatas/",
    divisions: "divisions",
    applicationdatas: "applicationdatas",
    error: "Error",
    donorThankyouPath: "donor/thank-you/",
    approveAsIs: "Approve as is",
    changesNeeded: "Changes needed",
    ad: "Ad",
    thankYou: "ThankYou",
    pdfDownloaded: "pdf-downloaded",
    resetPasswordError: "ResetPasswordError",
    data: "data",
    template: "Template",
    emailNotification: "emailNotification",
    enCreatedOn: "EN.CreatedOn",
    emailManagement: "Email Management",
    emailSenderText: "Email Sender",
    from: "from",
    cc: "cc",
    id: "id",
    intakeTemplateId: "intakeTemplateId",
    fundraiser: "fundraiser",
    otherManager: "otherManager",
    emailTemplatesPath: "/email-templates/",
    emailTemplates: "emailTemplates",
    donorReport: "/donor-report",
    donor: "/donor",
    resetPassword: "/reset-password",
    universalReport: "universalReport",
    report: "Report",
    donorAndJournalReport: "Donor & Journal Report",
    universalReportAsCategory: "UniversalReport",
    divisionAsColumn: "Division",
    eventStatus: "Event Status",
    status: "status",
    donorReportAsXlsx: "Donor Report.xlsx",
    donorReportText: "Donor Report",
    userManagement: "User Management",
    roles: "Roles",
    canView: "CanView",
    canAdd: "CanAdd",
    canDelete: "CanDelete",
    canEdit: "CanEdit",
    pagination: "Pagination",
    approvals: "Approvals",
    createAndDownloadPDF: "Create & Download PDF (Final PDF)",
    // rolesPath: "roles",
    rolesAsPath: "roles",
    usersAsPath: "users",
    users: "Users",
    editUser: "Edit User",
    usersUsedInTag: "users",
    usersAsXlsx: "Users.xlsx",
    usersAsPdf: "Users.pdf",
    firstName: "First Name",
    lastName: "Last Name",
    roleName: "Role Name",
    sorted: "sorted",
    changed: "changed",
    downloadPDFForApproval: "Download PDF for Approval",
    rejectComments: "rejectComments",
    addedRejectedReason: "addedRejectedReason",
    rejectAd: "Reject Ad.",
    approvalInWIP: "Approval in WIP",
    adSpecKey:"AdSpec",
    roleId:'roleId'
}
