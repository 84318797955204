import { ViewContainerRef, ElementRef, Component } from '@angular/core';

import { ModalView } from "../../../domain/customize-design/modal"
@Component({
    selector: 'app-popup',
    template: '',
})
export class AppPopup {
    constructor(
        public viewContainerRef: ViewContainerRef,
        public modalView: ModalView,
        elementRef: ElementRef
    ) {
        this.modalView.viewContainerRef = viewContainerRef;
        this.modalView.element = elementRef.nativeElement;
    }
}
