import { vEmailNotificationBase } from '../database-models/v-email-notification-base';
import { actionColumn } from '@rxweb/grid';
//Generated Imports
@actionColumn({
    name: 'emailNotificationAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'emailNotificationAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild","w-105"], headerCellClass: [ "w-105"], 
})

export class vEmailNotification extends vEmailNotificationBase {

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties

}