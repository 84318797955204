import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPaginationBase {

        //#region eventId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'eventId', keyColumn: true })
        eventId: number;
        //#endregion eventId Prop


        //#region divisionId Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'divisionId', keyColumn: false })
        divisionId: number;
        //#endregion divisionId Prop


        //#region pageTypeId Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'pageTypeId', keyColumn: false })
        pageTypeId: number;
        //#endregion pageTypeId Prop


        //#region adIntakeFormId Prop
        @gridColumn({ visible: true, columnIndex: 12, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: false })
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region sequece Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'sequence', keyColumn: false })
        sequence: number;
        //#endregion sequece Prop


        //#region oPSTokenId Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'oPSTokenId', keyColumn: false })
        oPSTokenId: string;
        //#endregion oPSTokenId Prop


        //#region oPSTemplateId Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'oPSTemplateId', keyColumn: false })
        oPSTemplateId: any;
        //#endregion oPSTemplateId Prop


        //#region imageURL Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'imageURL', keyColumn: false })
        imageURL: string;
        //#endregion imageURL Prop


        //#region thumbImageURL Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'thumbImageURL', keyColumn: false })
        thumbImageURL: string;
        //#endregion thumbImageURL Prop


        //#region intakeFormSortingId Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'intakeFormSortingId', keyColumn: false })
        intakeFormSortingId: any;
        //#endregion intakeFormSortingId Prop


        //#region adDesignStatusId Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'adDesignStatusId', keyColumn: false })
        adDesignStatusId: any;
        //#endregion adDesignStatusId Prop


        //#region colorCode Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'colorCode', keyColumn: false })
        colorCode: number;
        //#endregion colorCode Prop


        //#region imageData Prop
        @gridColumn({ visible: true, columnIndex: 11, allowSorting: true, headerKey: 'imageData', keyColumn: false })
        imageData: string;
        //#endregion imageData Prop

}