import { vUsersListBase } from '../database-models/v-users-list-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { templateJitUrl } from '@angular/compiler';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'userAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"], headerCellClass: ["text-center"]
})
export class vUsersList extends vUsersListBase {

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties

}