import { SectionDividerBase } from '../database-models/section-divider-base';
import { required } from '@rxweb/reactive-form-validators';

//Generated Imports
export class SectionDivider extends SectionDividerBase {
    //#region Generated Reference Properties

    //#region datasendToAWS Prop
    @required()
    datasendToAWS: string;
    //#endregion datasendToAWS Prop

    //#endregion Generated Reference Properties

}