import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserEmailNotificationTemplateBase {

//#region emailNotificationId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'emailNotificationId', keyColumn: true})
        emailNotificationId : number;
//#endregion emailNotificationId Prop


//#region dateAndTime Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'dateAndTime', keyColumn: false})
        dateAndTime : string;
//#endregion dateAndTime Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subject', keyColumn: false})
        subject : string;
//#endregion subject Prop


//#region templateBody Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'templateBody', keyColumn: false})
        templateBody : string;
//#endregion templateBody Prop

}