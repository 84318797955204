import { prop } from '@rxweb/reactive-form-validators';

export class EventSearch{
    @prop()
    public campaignYear:any;
    @prop()
    public divisionId:any;
    @prop()
    public fundraiserId:any;
    @prop()
    public eventManagerId:any;
    @prop()
    public eventId:any;
    @prop()
    public eventStatus : any;
}