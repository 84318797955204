import {vPagination_BrandsAndLettersBase} from '../database-models/v-pagination-_-brands-and-letters-base';
//Generated Imports
export class vPagination_BrandsAndLetters extends vPagination_BrandsAndLettersBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}