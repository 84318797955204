import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPagination_SectionDividersBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region sectionDividersId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'sectionDividersId', keyColumn: false})
        sectionDividersId : number;
//#endregion sectionDividersId Prop


//#region sequence Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'sequence', keyColumn: false})
        sequence : number;
//#endregion sequence Prop


//#region imageUrl Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'imageUrl', keyColumn: false})
        imageUrl : string;
//#endregion imageUrl Prop

}