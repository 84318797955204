import { IntakeFormSortingBase } from '../database-models/intake-form-sorting-base';
import { AdIntakeFormBase } from '../database-models/ad-intake-form-base';
//Generated Imports
export class IntakeFormSorting extends IntakeFormSortingBase {

    //#region Generated Reference Properties

    //#region adIntakeForm Prop
    adIntakeForm: AdIntakeFormBase;
    //#endregion adIntakeForm Prop

    //#endregion Generated Reference Properties

}