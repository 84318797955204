import { Component, OnInit, Input } from '@angular/core';
import { ModalView } from '../../../../domain/customize-design/modal';
// import { parse } from 'path';

@Component({
    selector: 'app-bed-request',
    templateUrl: './bed-request.component.html',
    styleUrls: ['./bed-request.component.css']
})
export class BedRequestComponent implements OnInit {
    @Input() validationMessages: string;
    showComponent: boolean = false;
    validationMessagesList: string[];
    data: string;

    constructor() {
    }

    ngOnInit() {
        this.showComponent = true;
        this.data = JSON.parse(this.validationMessages)["title"];
        let messages = JSON.parse(this.validationMessages)["errors"];
        //let array = messages.split(",")
        this.validationMessagesList = new Array<any>();

        //this.validationMessagesList =  messages

        for (var property in messages) {
            this.validationMessagesList.push(property.toString() + " : " + (messages[property]).toString());
        }

        this.showComponent = true;
    }

    hide() {

    }
}