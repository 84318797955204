import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIntakeFormRecordBase {

        //#region adIntakeFormId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true })
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region eventId Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'eventId', keyColumn: false })
        eventId: number;
        //#endregion eventId Prop


        //#region donorId Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'donorId', keyColumn: false })
        donorId: number;
        //#endregion donorId Prop


        //#region donorName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'donorName', keyColumn: false })
        donorName: string;
        //#endregion donorName Prop


        //#region donorPhoneNumber Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'donorPhoneNumber', keyColumn: false })
        donorPhoneNumber: string;
        //#endregion donorPhoneNumber Prop


        //#region donorPreferredSignatureAttribute Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'donorPreferredSignatureAttribute', keyColumn: false })
        donorPreferredSignatureAttribute: string;
        //#endregion donorPreferredSignatureAttribute Prop


        //#region donorEmail Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'donorEmail', keyColumn: false })
        donorEmail: string;
        //#endregion donorEmail Prop


        //#region amount Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'amount', keyColumn: false })
        amount: number;
        //#endregion amount Prop


        //#region node Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'node', keyColumn: false })
        node: string;
        //#endregion node Prop


        //#region fundraiserId Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'fundraiserId', keyColumn: false })
        fundraiserId: any;
        //#endregion fundraiserId Prop


        //#region fundraiserName Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'fundraiserName', keyColumn: false })
        fundraiserName: string;
        //#endregion fundraiserName Prop


        //#region fundraiserEmail Prop
        @gridColumn({ visible: true, columnIndex: 10, allowSorting: true, headerKey: 'fundraiserEmail', keyColumn: false })
        fundraiserEmail: string;
        //#endregion fundraiserEmail Prop


        //#region adContactIsSameAsDonor Prop
        @gridColumn({ visible: true, columnIndex: 11, allowSorting: true, headerKey: 'adContactIsSameAsDonor', keyColumn: false })
        adContactIsSameAsDonor: boolean;
        //#endregion adContactIsSameAsDonor Prop


        //#region adContactDonorID Prop
        @gridColumn({ visible: true, columnIndex: 12, allowSorting: true, headerKey: 'adContactDonorID', keyColumn: false })
        adContactDonorID: string;
        //#endregion adContactDonorID Prop


        //#region adContactName Prop
        @gridColumn({ visible: true, columnIndex: 13, allowSorting: true, headerKey: 'adContactName', keyColumn: false })
        adContactName: string;
        //#endregion adContactName Prop


        //#region adContactPhoneNumber Prop
        @gridColumn({ visible: true, columnIndex: 14, allowSorting: true, headerKey: 'adContactPhoneNumber', keyColumn: false })
        adContactPhoneNumber: string;
        //#endregion adContactPhoneNumber Prop


        //#region adContactEmailAddress Prop
        @gridColumn({ visible: true, columnIndex: 15, allowSorting: true, headerKey: 'adContactEmailAddress', keyColumn: false })
        adContactEmailAddress: string;
        //#endregion adContactEmailAddress Prop


        //#region donorRelationship Prop
        @gridColumn({ visible: true, columnIndex: 16, allowSorting: true, headerKey: 'donorRelationship', keyColumn: false })
        donorRelationship: string;
        //#endregion donorRelationship Prop


        //#region relationshipOwner Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'relationshipOwner', keyColumn: false })
        relationshipOwner: number;
        //#endregion relationshipOwner Prop


        //#region fundraiserContactPhoneNumber Prop
        @gridColumn({ visible: true, columnIndex: 19, allowSorting: true, headerKey: 'fundraiserContactPhoneNumber', keyColumn: false })
        fundraiserContactPhoneNumber: string;
        //#endregion fundraiserContactPhoneNumber Prop


        //#region eventManagerId Prop
        @gridColumn({ visible: true, columnIndex: 17, allowSorting: true, headerKey: 'eventManagerId', keyColumn: false })
        eventManagerId: any;
        //#endregion eventManagerId Prop


        //#region eventManagerName Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'eventManagerName', keyColumn: false })
        eventManagerName: string;
        //#endregion eventManagerName Prop


        //#region eventManagerEmail Prop
        @gridColumn({ visible: true, columnIndex: 19, allowSorting: true, headerKey: 'eventManagerEmail', keyColumn: false })
        eventManagerEmail: string;
        //#endregion eventManagerEmail Prop


        //#region eventFundraiserId Prop
        @gridColumn({ visible: true, columnIndex: 20, allowSorting: true, headerKey: 'eventFundraiserId', keyColumn: false })
        eventFundraiserId: any;
        //#endregion eventFundraiserId Prop


        //#region eventFundriserName Prop
        @gridColumn({ visible: true, columnIndex: 21, allowSorting: true, headerKey: 'eventFundriserName', keyColumn: false })
        eventFundriserName: string;
        //#endregion eventFundriserName Prop


        //#region eventFundriserEmail Prop
        @gridColumn({ visible: true, columnIndex: 22, allowSorting: true, headerKey: 'eventFundriserEmail', keyColumn: false })
        eventFundriserEmail: string;
        //#endregion eventFundriserEmail Prop


        //#region name Prop
        @gridColumn({ visible: true, columnIndex: 26, allowSorting: true, headerKey: 'name', keyColumn: false })
        name: number;
        //#endregion name Prop

        //#region phoneNumber Prop
        @gridColumn({ visible: true, columnIndex: 27, allowSorting: true, headerKey: 'phoneNumber', keyColumn: false })
        phoneNumber: string;
        //#endregion phoneNumber Prop


        //#region otherEmailId Prop
        @gridColumn({ visible: true, columnIndex: 28, allowSorting: true, headerKey: 'otherEmailId', keyColumn: false })
        otherEmailId: string;
        //#endregion otherEmailId Prop


        //#region otherManagerName Prop
        @gridColumn({ visible: true, columnIndex: 24, allowSorting: true, headerKey: 'otherManagerName', keyColumn: false })
        otherManagerName: string;
        //#endregion otherManagerName Prop


        //#region otherManagerEmail Prop
        @gridColumn({ visible: true, columnIndex: 25, allowSorting: true, headerKey: 'otherManagerEmail', keyColumn: false })
        otherManagerEmail: string;
        //#endregion otherManagerEmail Prop


        //#region adCreatorNameId Prop
        @gridColumn({ visible: true, columnIndex: 31, allowSorting: true, headerKey: 'adCreatorNameId', keyColumn: false })
        adCreatorNameId: any;
        //#endregion adCreatorNameId Prop


        //#region adCreatorName Prop
        @gridColumn({ visible: true, columnIndex: 27, allowSorting: true, headerKey: 'adCreatorName', keyColumn: false })
        adCreatorName: string;
        //#endregion adCreatorName Prop


        //#region adCreatorEmail Prop
        @gridColumn({ visible: true, columnIndex: 28, allowSorting: true, headerKey: 'adCreatorEmail', keyColumn: false })
        adCreatorEmail: string;
        //#endregion adCreatorEmail Prop


        //#region allowAdCreatorToSendEmails Prop
        @gridColumn({ visible: true, columnIndex: 29, allowSorting: true, headerKey: 'allowAdCreatorToSendEmails', keyColumn: false })
        allowAdCreatorToSendEmails: boolean;
        //#endregion allowAdCreatorToSendEmails Prop


        //#region journalAdAlphaName Prop
        @gridColumn({ visible: true, columnIndex: 30, allowSorting: true, headerKey: 'journalAdAlphaName', keyColumn: false })
        journalAdAlphaName: string;
        //#endregion journalAdAlphaName Prop


        //#region pageTypeId Prop
        @gridColumn({ visible: true, columnIndex: 31, allowSorting: true, headerKey: 'pageTypeId', keyColumn: false })
        pageTypeId: number;
        //#endregion pageTypeId Prop


        //#region pageTypeName Prop
        @gridColumn({ visible: true, columnIndex: 32, allowSorting: true, headerKey: 'pageTypeName', keyColumn: false })
        pageTypeName: string;
        //#endregion pageTypeName Prop


        //#region compUpgradId Prop
        @gridColumn({ visible: true, columnIndex: 39, allowSorting: true, headerKey: 'compUpgradId', keyColumn: false })
        compUpgradId: any;
        //#endregion compUpgradId Prop


        //#region comments Prop
        @gridColumn({ visible: true, columnIndex: 35, allowSorting: true, headerKey: 'comments', keyColumn: false })
        comments: string;
        //#endregion comments Prop


        //#region journalAdCopy Prop
        @gridColumn({ visible: true, columnIndex: 36, allowSorting: true, headerKey: 'journalAdCopy', keyColumn: false })
        journalAdCopy: string;
        //#endregion journalAdCopy Prop


        //#region journalAdCopyLogo Prop
        @gridColumn({ visible: false, columnIndex: 42, allowSorting: true, headerKey: 'journalAdCopyLogo', keyColumn: false })
        journalAdCopyLogo: string;
        //#endregion journalAdCopyLogo Prop


        //#region adDesignTemplateType Prop
        @gridColumn({ visible: true, columnIndex: 37, allowSorting: true, headerKey: 'adDesignTemplateType', keyColumn: false })
        adDesignTemplateType: any;
        //#endregion adDesignTemplateType Prop


        //#region createdBy Prop
        @gridColumn({ visible: true, columnIndex: 44, allowSorting: true, headerKey: 'createdBy', keyColumn: false })
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @gridColumn({ visible: true, columnIndex: 45, allowSorting: true, headerKey: 'createdOn', keyColumn: false })
        createdOn: any;
        //#endregion createdOn Prop


        //#region intakeFormHonoreeId Prop
        @gridColumn({ visible: true, columnIndex: 38, allowSorting: true, headerKey: 'intakeFormHonoreeId', keyColumn: false })
        intakeFormHonoreeId: string;
        //#endregion intakeFormHonoreeId Prop


        //#region statusId Prop
        @gridColumn({ visible: true, columnIndex: 40, allowSorting: true, headerKey: 'statusId', keyColumn: false })
        statusId: number;
        //#endregion statusId Prop


        //#region isCompletedMailSend Prop
        @gridColumn({ visible: true, columnIndex: 41, allowSorting: true, headerKey: 'isCompletedMailSend', keyColumn: false })
        isCompletedMailSend: number;
        //#endregion isCompletedMailSend Prop

                
        //#region otherJROName Prop
        @gridColumn({ visible: true, columnIndex: 26, allowSorting: true, headerKey: 'otherJROName', keyColumn: false })
        otherJROName: string;
        //#endregion otherJROName Prop

}