import {vEventFundriserLookupBase} from '../database-models/v-event-fundriser-lookup-base';
//Generated Imports
export class vEventFundriserLookup extends vEventFundriserLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}