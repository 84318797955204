import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { HouseAndBrandAdsDignitariesLetter } from '@app/models';
import { AbstractHouseAndBrandAdsDignitariesLetter } from '../domain/abstract-house-and-brand-ads-dignitaries-letter';
import { access } from '@rxweb/angular-router';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';

@access({ accessLevel: RolePermissionModule.HouseBrandAdsAndDignitariesLetters, action: ActionType.POST })

@Component({
    selector: "app-house-and-brand-ads-dignitaries-letter-add",
    templateUrl: './house-and-brand-ads-dignitaries-letter-add.component.html'
})
export class HouseAndBrandAdsDignitariesLetterAddComponent extends AbstractHouseAndBrandAdsDignitariesLetter implements OnInit, OnDestroy {
    houseAndBrandAdsDignitariesLetter: HouseAndBrandAdsDignitariesLetter;
    subscription: Subscription;

    constructor(private formBuilder: RxFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.houseAndBrandAdsDignitariesLetter = new HouseAndBrandAdsDignitariesLetter();
        this.houseAndBrandAdsDignitariesLetterFormGroup = this.formBuilder.formGroup(this.houseAndBrandAdsDignitariesLetter) as IFormGroup<HouseAndBrandAdsDignitariesLetter>;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
