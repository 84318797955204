import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { EventModalComponent } from './event-modal.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [EventModalComponent],
    exports: [EventModalComponent],
    providers: [],
    entryComponents: [EventModalComponent]
})

export class EventSharedModule { }