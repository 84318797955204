import {vHouseTypeBase} from '../database-models/v-house-type-base';
//Generated Imports
export class vHouseType extends vHouseTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}