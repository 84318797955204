import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPagination_BrandsAndLettersBase {

//#region houseAndBrandAdsDignitariesLettersId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'houseAndBrandAdsDignitariesLettersId', keyColumn: true})
        houseAndBrandAdsDignitariesLettersId : number;
//#endregion houseAndBrandAdsDignitariesLettersId Prop


//#region brandTypeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'brandTypeId', keyColumn: false})
        brandTypeId : number;
//#endregion brandTypeId Prop


//#region sequence Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'sequence', keyColumn: false})
        sequence : number;
//#endregion sequence Prop


//#region imageURL Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'imageURL', keyColumn: false})
        imageURL : string;
//#endregion imageURL Prop

}