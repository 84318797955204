import { Component, OnInit } from '@angular/core';
import { BreadCrumb } from '../../viewModel/bread-crumb';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { EventLookupGroup } from 'src/app/viewModel/event-lookup-group';
import { EVENT_LOOKUPS } from 'src/app/const/uris/event-lookups.uri';
import { DashboardViewModel } from 'src/app/viewModel/dashboardViewModel';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { RxHttp } from '@rxweb/http';
import { DashboardListModel } from 'src/app/viewModel/dashboardListModel';
import { GridWithExcel } from 'src/app/custom-templates/grid/add-excel';
import { Router } from '@angular/router';
import { ApiCallService } from 'src/app/temp-service/api-call-service';
import { CUSTOM_URI } from 'src/app/const/uris/custom.uri';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';
import { downloadDocument, pageDataQuery, Query, QueryForDownload, spParameter } from 'src/app/viewModel/spGridClass';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent extends CoreComponent implements OnInit {
  breadCrumb: BreadCrumb;
  eventLookupGroup: EventLookupGroup;
  dashboardViewModel: DashboardViewModel;
  dashboardViewModelGroup: IFormGroup<DashboardViewModel>;
  spin: boolean;
  isShowDashboard: boolean = false;
  isFromGridSearch: boolean = false;
  pageIndex: number = 1;
  searchValue: string = "";
  rowCount: number = sessionStorage.getItem(CONSTANT_MESSAGES.dashboard) != null ? parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.dashboard)) : 10;
  //rowCount: number = 10;
  orderByColumn: string = " CASE WHEN " + CONSTANT_MESSAGES.pinnedEvents + " IS NULL THEN 1 ELSE 0 END, " + CONSTANT_MESSAGES.pinnedEvents + " ASC, E." + CONSTANT_MESSAGES.eventId; //CASE WHEN PINEVENTID IS NULL then 1 else 0 end, PINEVENTID ASC, Eventid DESC;
  sortOrder: string = CONSTANT_MESSAGES.descending;
  isFirstTime: boolean = true;
  dashboardData: any;
  totalCount: any;
  dashboardGrid: GridWithExcel;
  isFromSearch: boolean = false;

  constructor(private storagedata: BrowserStorage, private formBuilder: RxFormBuilder, private http: RxHttp, private route: Router, private apiCallService: ApiCallService) {
    super();

    this.bindBreadCrumb();
    this.storagedata = new BrowserStorage();

    this.dashboardViewModel = new DashboardViewModel();
    this.dashboardViewModelGroup = this.formBuilder.formGroup(this.dashboardViewModel) as IFormGroup<DashboardViewModel>;
  }

  bindBreadCrumb() {
    this.breadCrumb = new BreadCrumb();

    this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard;
    this.breadCrumb.name1 = CONSTANT_MESSAGES.home;
    this.breadCrumb.title = CONSTANT_MESSAGES.eventDashboard
    this.breadCrumb.pagename = CONSTANT_MESSAGES.eventDashboard
  }

  ngOnInit() {
    this.spin = true;

    this.checkAuthorization();
    this.eventLookupGroup = new EventLookupGroup();

    this.lookup<EventLookupGroup>([
      { path: EVENT_LOOKUPS["campaignYearLookUps"], propName: "campaignYearLookUps" },
      { path: EVENT_LOOKUPS["divisionLookUps"], propName: "divisionLookUps" },
      // { path: EVENT_LOOKUPS["donorFundraiserLookUps"], propName: "donorFundraiserLookUps" },
      // { path: EVENT_LOOKUPS["donorNameLookUps"], propName: "donorNameLookUps" },
      // { path: EVENT_LOOKUPS["adCreatorLookUps"], propName: "adCreatorLookUps" },
      { path: EVENT_LOOKUPS["eventManagerLookUps"], propName: "eventManagerLookUps" },
      { path: EVENT_LOOKUPS["eventFundraiserLookUps"], propName: "eventFundraiserLookUps" },
      // { path: EVENT_LOOKUPS["adJournalRelationshipOwnerLookUps"], propName: "adJournalRelationshipOwnerLookUps" },
    ]).subscribe(response => {
      this.eventLookupGroup = response;
    })

    this.bindGrid();
  }

  checkAuthorization() {
    var auth = this.storagedata.local.get(CONSTANT_MESSAGES.auth, false);

    if (auth) {
      // element.classList.remove("login_page_view");
      this.isShowDashboard = true;

      // if (this.user.resetPasswordTime == null || this.user.resetPasswordTime == undefined) {
      //   //window.location.href = "/changepassword"
      //   this.isShowDashboard = false;
      //   this.route.navigateByUrl('/changepassword');
      // }
      // else {
      //   let diff = this.date_diff_indays(new Date(this.user.resetPasswordTime), new Date())
      //   let time = SetSystemTimeout.chanpasswordTime;
      //   if (diff > time) {
      //     //window.location.href = "/changepassword"
      //     this.isShowDashboard = false;
      //     this.route.navigateByUrl('/changepassword');
      //   }
      // }
    }
    else {
      //element.classList.add("login_page_view");
      this.isShowDashboard = false;
      //window.location.href = "/login";
    }
  }

  search(value) {
    this.spin = true;
    this.searchValue = value;
    this.isFromGridSearch = true;
    this.pageIndex = 0;
    this.dashboardGrid.refresh(CONSTANT_MESSAGES.dashboard);
    this.bindGrid(this.pageIndex);
  }

  date_diff_indays(date1, date2) {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);

    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }

  bindGrid(page: number = 0) {
    let jsonObject = new spParameter();

    jsonObject.searchQuery = this.dashboardViewModelGroup.value;
    jsonObject.searchQuery[CONSTANT_MESSAGES.searchDetails] = this.searchValue;

    let pageQuery = new pageDataQuery();

    pageQuery.PageRow = this.rowCount;
    pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
    pageQuery.OrderColumn = this.orderByColumn;
    pageQuery.OrderType = this.sortOrder;

    jsonObject.pageQuery = JSON.stringify(pageQuery);

    let sendJson = new Query();

    sendJson.query = JSON.stringify(jsonObject);
    sendJson.category = CONSTANT_MESSAGES.categoryAsDashboard;
    sendJson.eventId = 0;

    this.apiCallService.postAPI(CUSTOM_URI.searchAdIntakForms, sendJson).subscribe((t: any) => {
      this.isShowDashboard = true;
      this.spin = false;
      this.isFirstTime = false;

      var parsedData = JSON.parse(t);

      this.dashboardData = parsedData[0].result.data;
      this.totalCount = parsedData[0].result.footer[0].totalCount;

      if (page == 0) {
        this.dashboardGrid = new GridWithExcel(this.dashboardData, DashboardListModel, {
          actions:
          {
            exportToExcel: this.exportToExcel.bind(this),
            onCheckBoxSelect: this.onCheckboxSelect.bind(this),
            onLink: this.onLink.bind(this)
          }
        });

        this.dashboardGrid.designClass.tableClass = "themeTable table table-striped mb-0".split(' ');
        //this.dashboardGrid.designClass.headerClass = ["text-white", "bg-dark-gray"];
        this.dashboardGrid.footerDesignClass.dropDownTemplateClass.rootClass = "col-md-auto d-flex flex-wrap align-items-center justify-content-center mb-3 mb-md-0".split(' ');
        //this.dashboardGrid.designClass.headerCellClass = "text-center".split(' ');

        this.dashboardGrid.maxPerPage = this.rowCount;
        //this.dashboardGrid.pagination
        if (this.dashboardData.length > 0) {
          this.dashboardGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
        }
        else {
          this.dashboardGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.dashboardGrid.updateSource([]);
        }

        this.dashboardGrid.design(document.getElementById(CONSTANT_MESSAGES.dashboard));
      }
      else {
        if (this.dashboardData.length > 0) {
          this.dashboardGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.dashboardGrid.updateSource(this.dashboardData);
        }
        else {
          this.dashboardGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.dashboardGrid.updateSource([]);
        }
      }
      this.isFirstTime = false;
    })
    // this.spin=false;

  }

  onLink(event: any) {
    this.route.navigateByUrl(CONSTANT_MESSAGES.eventsPath + event.eventId + CONSTANT_MESSAGES.adIntakeFormsPath);
  }

  onPageChanging(x) {
    this.spin = true;
    this.dashboardGrid.storeProcedure.nextPage = x;
    this.pageIndex = x;
    this.rowCount = this.dashboardGrid.maxPerPage;

    if (sessionStorage.getItem(CONSTANT_MESSAGES.dashboard) != null && this.rowCount == parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.dashboard)))
      this.rowCount = parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.dashboard));
    else
      sessionStorage.setItem(CONSTANT_MESSAGES.dashboard, this.rowCount.toString());

    this.bindGrid(x);
  }

  onPageSorting(x, y, z) {
    //this.pageIndex = 0;
    this.sortOrder = y;
    this.orderByColumn = x;

    if (!this.isFirstTime) {
      this.sortOrder = y == false ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;

      // if(this.pageIndex > 0)
      //   this.dashboardGrid.updateSource([]);
      // else
      //   this.dashboardGrid.refresh(CONSTANT_MESSAGES.dashboard);

      this.bindGrid(this.pageIndex);
    }
  }

  searchData() {
    this.spin = true;
    this.isFromSearch = true;
    this.dashboardGrid.refresh(CONSTANT_MESSAGES.dashboard);
    this.bindGrid();
  }

  onCheckboxSelect(t, x, y, z) {
    this.dashboardData.forEach(data => {
      if (data.eventId == t.eventId) {
        data.isChecked = x.target.checked;
        this.apiCallService.postAPI(CUSTOM_URI.pinnedEvents, { "eventId": data.eventId, "isPinnedEvent": data.isChecked }).subscribe((t: any) => {
          if (this.pageIndex > 0)
            this.dashboardGrid.updateSource([]);
          else
            this.dashboardGrid.refresh(CONSTANT_MESSAGES.dashboard);
          this.bindGrid(this.pageIndex);
        });
      }
    });
  }

  exportToExcel() {
    this.spin = true;

    let jsonObject = new spParameter();

    jsonObject.searchQuery = this.dashboardViewModelGroup.value;
    jsonObject.searchQuery[CONSTANT_MESSAGES.searchDetails] = this.searchValue;

    let pageQuery = new pageDataQuery();

    pageQuery.PageRow = this.rowCount;
    //pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
    pageQuery.PageIndex = 1;

    let orderByColumnExcel = "";

    switch (this.orderByColumn) {
      case " CASE WHEN PinEventId IS NULL THEN 1 ELSE 0 END, PinEventId ASC, E.eventId":
        orderByColumnExcel = " CASE WHEN PinEventId IS NULL THEN 1 ELSE 0 END, PinEventId ASC, E.eventId"
        break;
      case CONSTANT_MESSAGES.divisionName:
        orderByColumnExcel = CONSTANT_MESSAGES.DivisionName
        break;
      case CONSTANT_MESSAGES.campaignyear:
        orderByColumnExcel = CONSTANT_MESSAGES.Campaignyear
        break;
      case CONSTANT_MESSAGES.event:
        orderByColumnExcel = CONSTANT_MESSAGES.Event
        break;
      case CONSTANT_MESSAGES.eventDate:
        orderByColumnExcel = CONSTANT_MESSAGES.EventDate
        break;
      case CONSTANT_MESSAGES.journalAdDeadlineDate:
        orderByColumnExcel = CONSTANT_MESSAGES.AdJournalDeadlineDate
        break;
      case CONSTANT_MESSAGES.eventManager:
        orderByColumnExcel = CONSTANT_MESSAGES.EventManager
        break;
      case CONSTANT_MESSAGES.eventFundraiser:
        orderByColumnExcel = CONSTANT_MESSAGES.EventFundraiser
        break;
      case CONSTANT_MESSAGES.adJournalRelationshipOwner:
        orderByColumnExcel = CONSTANT_MESSAGES.AdJournalRelationshipOwner
        break;
      case CONSTANT_MESSAGES.adCreator:
        orderByColumnExcel = CONSTANT_MESSAGES.AdCreator
        break;
    }

    pageQuery.OrderColumn = orderByColumnExcel;
    pageQuery.OrderType = this.sortOrder;

    jsonObject.pageQuery = JSON.stringify(pageQuery);

    let sendJson = new QueryForDownload();

    //sendJson.query = "";
    sendJson.query = JSON.stringify(jsonObject);
    sendJson.category = CONSTANT_MESSAGES.categoryAsDashboard;
    sendJson.eventId = 0;
    sendJson.isDownload = true;
    sendJson.type = CONSTANT_MESSAGES.excel;
    sendJson.fileName = CONSTANT_MESSAGES.eventDashboardAsXlsx;

    this.apiCallService.postAPI(CUSTOM_URI.searchAdIntakForms, sendJson).subscribe((Response: any) => {
      this.spin = false;

      downloadDocument(Response, CONSTANT_MESSAGES.eventDashboard, CONSTANT_MESSAGES.mimeExcel);
    });
  }
}