import { DivisionBase, UserDivisionBase, EventBase, } from '@app/database-models'
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class Division extends DivisionBase {




//#region Generated Reference Properties
    //#region userDivisions Prop
    userDivisions: UserDivisionBase[];
    //#endregion userDivisions Prop
//#region events Prop
events : EventBase[];
//#endregion events Prop

//#region userDivisions Prop
@prop()
status : boolean;
//#endregion userDivisions Prop

//#endregion Generated Reference Properties






































}