import {vRoleLookupBase} from '../database-models/v-role-lookup-base';
//Generated Imports
export class vRoleLookup extends vRoleLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}