// @ts-nocheck
import { GridDesigner, ElementConfig, TemplateConfig } from "@rxweb/grid"
import { BIND_EXCEL_BUTTON } from '../custom-templates/grid/grid-custom-templates';
import { dbAccessModule } from './security/authorization-resolver';

export class AppGrid extends GridDesigner {

    constructor(source: any[], model: Function, gridConfiguration?: any) {
        super(source, model, gridConfiguration);
        this.designClass.tableClass = "themeTable row-toggler usertable w-110 table table-striped mb-0".split(' ');
        this.designClass.headerClass = ["bg-blue", "text-white"];
        this.designClass.cellClass = ["sorting"];
        this.designClass.ascendingClass =  ["fa".split(" "), "fa-long-arrow-up".split(" "), "float-right".split(" "),"sort-indicator".split(" ")];
        this.designClass.descendingClass = ["fa".split(" "), "fa-long-arrow-down".split(" "), "float-right".split(" "),"sort-indicator".split(" ")];
        this.designClass.rowClass = ["odd".split(" "), "even".split(" ")];
        this.footerDesignClass.rootClass = "row selectList justify-content-between".split(' ');
        this.footerDesignClass.dropDownTemplateClass.rootClass = "col-sm-auto".split(' ');
        this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm ml-1 mr-1".split(" ");
        this.footerDesignClass.paginatorClass.rootClass = "col-md-auto text-center".split(" ");
        this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination themePagination mb-0".split(" ");;
        this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
        this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
        this.footerDesignClass.dropDownTemplateClass.labelClass = "show-entries".split(' ');
        this.maxPerPage = 10;
        this.pagingSource = [10,20,50,100];
        this.startNodeName = "div";

    }

    authorize(authorizeConfig:{[key:string]:any}){
        if(dbAccessModule)
            return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action];
        else
            return false;
    }

    get tableElementConfig(): ElementConfig {
        return {
            class: ["table-responsive","themeresponsivetable"],
            childrens: [{
                table: {
                    class: this.designClass.tableClass,
                    childrens: [this.headerTemplate, this.bodyTemplate]
                }
            }]
        };
    }

    get footerCenterTemplate() {
        return {};
    }
    set footerCenterTemplate(value: TemplateConfig) {

    }

    refresh(elementId) {
        let element = document.getElementById(elementId);
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }

    get appGridSource(){
        return this.bindingSource;
    }
}
