import { gridColumn } from '@rxweb/grid';
import { actionColumn } from '@rxweb/grid';

export class AdManagementOverviewReportModel {

    //#region adIntakeFormId Prop
    @gridColumn({ isAscending: true, visible: false, columnIndex: 0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true })
    adIntakeFormId: number;
    //#endregion adIntakeFormId Prop

    //#region donorId Prop
    @gridColumn({ headerCellClass: ["left-0", "dw-100"], class: ["left-0", "dw-100"], isAscending: true, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'donorId', keyColumn: false, headerTitle: "Donor ID", configuredTemplate: { templateName: "numberDisplay" } })
    donorId: any;
    //#endregion donorId Prop

    //#region donorName Prop
    @gridColumn({ headerCellClass: ["left-100", "w-200"], class: ["left-100", "w-200"], isAscending: true, visible: true, columnIndex: 2, allowSorting: true, headerKey: 'donorName', keyColumn: false, headerTitle: "Donor Name" })
    donorName: string;
    //#endregion donorName Prop

    //#region relationshipOwner Prop
    @gridColumn({ headerCellClass: ["left-300", "w-200"], class: ["left-300", "w-200"], isAscending: true, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'relationshipOwner', keyColumn: false, headerTitle: "Relationship Owner" })
    relationshipOwner: string;
    //#endregion relationshipOwner Prop

    //#region pageType Prop
    @gridColumn({ headerCellClass: [ "w-150"], class: ["w-150"],isAscending: true, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'pageType', keyColumn: false, headerTitle: "Page Type" })
    pageType: number;
    //#endregion pageType Prop

    //#region adType Prop
    @gridColumn({ headerCellClass: [ "w-228"], class: ["w-228"],isAscending: true, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'adType', keyColumn: false, headerTitle: "Ad Type" })
    adType: string;
    //#endregion adType Prop

    //#region compUpgradeAd Prop
    @gridColumn({ headerCellClass: [ "w-190"], class: ["w-190"],isAscending: true, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'compUpgradeAd', keyColumn: false, headerTitle: "Comp or Upgrade Ad" })
    compUpgradeAd: string;
    //#endregion compUpgradeAd Prop

    //#region adDesignStatus Prop
    @gridColumn({ headerCellClass: [ "w-205"], class: ["w-205"],isAscending: true, visible: true, columnIndex: 7, allowSorting: true, headerKey: 'adDesignStatus', keyColumn: false, headerTitle: "Ad Design Status" })
    adDesignStatus: string;
    //#endregion adDesignStatus Prop

    //#region intakeFormStatus Prop
    @gridColumn({ headerCellClass: ["w-190"], class: ["w-190"],isAscending: true, visible: true, columnIndex: 8, allowSorting: true, headerKey: 'intakeFormStatus', keyColumn: false, headerTitle: "Intake Form Status" })
    intakeFormStatus: string;
    //#endregion intakeFormStatus Prop

    //#region emailSentDate Prop
    @gridColumn({ headerCellClass: [ "w-200"], class: ["w-200"],isAscending: true, visible: true, columnIndex: 9, allowSorting: true, headerKey: 'emailSentDate', keyColumn: false, headerTitle: "Email Sent Date", configuredTemplate: { templateName: "dateTimeFormat" } })
    emailSentDate: Date;
    //#endregion emailSentDate Prop

    //#region adContactName Prop
    @gridColumn({ headerCellClass: [ "w-200"], class: ["w-200"],isAscending: true, visible: true, columnIndex: 10, allowSorting: true, headerKey: 'adContactName', keyColumn: false, headerTitle: "Ad Contact Name" })
    adContactName: string;
    //#endregion adContactName Prop

    //#region adContactPhone Prop
    @gridColumn({ headerCellClass: ["w-160"], class: ["w-160"], isAscending: true, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'adContactPhone', keyColumn: false, headerTitle: "Ad Contact Phone" })
    adContactPhone: string;
    //#endregion adContactPhone Prop

    //#region adContactEmailAddress Prop
    @gridColumn({ headerCellClass: ["w-250"], class: ["w-250"],isAscending: true, visible: true, columnIndex: 12, allowSorting: true, headerKey: 'adContactEmailAddress', keyColumn: false, headerTitle: "Ad Contact Email Address" })
    adContactEmailAddress: string;
    //#endregion adContactEmailAddress Prop

    //#region updatedBy Prop
    @gridColumn({ headerCellClass: [ "w-200"], class: ["w-200"],isAscending: true, visible: true, columnIndex: 13, allowSorting: true, headerKey: 'updatedBy', keyColumn: false, headerTitle: "Updated By" })
    updatedBy: string;
    //#endregion updatedBy Prop

    //#region updatedDate Prop
    @gridColumn({ headerCellClass: [ "w-200"], class: ["w-200"],isAscending: true, visible: true, columnIndex: 14, allowSorting: true, headerKey: 'updatedDate', keyColumn: false, headerTitle: "Updated Date", configuredTemplate: { templateName: "dateFormat" } })
    updatedDate: Date;
    //#endregion updatedDate Prop

    //#region updatedActionType Prop
    @gridColumn({ headerCellClass: ["w-275"], class: ["w-275"],isAscending: true, visible: true, columnIndex: 15, allowSorting: true, headerKey: 'updatedActionType', keyColumn: false, headerTitle: "Updated Action Type" })
    updatedActionType: string;
    //#endregion updatedActionType Prop

}