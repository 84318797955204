import { gridColumn } from '@rxweb/grid';
import { actionColumn } from '@rxweb/grid';

export class UserLogModel {

    //#region adIntakeFormId Prop
    @gridColumn({ isAscending: false, visible: false, columnIndex: 0, allowSorting: true, headerKey: 'userLogId', keyColumn: true })
    userLogId: number;
    //#endregion adIntakeFormId Prop

    //#region userId Prop
    @gridColumn({ isAscending: false, visible: false, columnIndex: 1, allowSorting: true, headerKey: 'userId', keyColumn: false, headerTitle: "User Id" })
    userId: any;
    //#endregion userId Prop

    //#region date Prop
    @gridColumn({ headerCellClass: ["w-175"], class: ["w-175"], isAscending: true, visible: true, columnIndex: 2, allowSorting: true, headerKey: 'date', keyColumn: false, headerTitle: "Date", configuredTemplate: { templateName: "dateTimeFormat" } })
    date: any;
    //#endregion date Prop

    //#region sectionDetails Prop
    @gridColumn({ headerCellClass: ["w-175"], class: ["w-175"], isAscending: false, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'sectionDetails', keyColumn: false, headerTitle: "Section Detail" })
    sectionDetails: string;
    //#endregion sectionDetails Prop

    //#region message Prop
    @gridColumn({ headerCellClass: ["w-500"], class: ["w-500"], isAscending: false, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'message', keyColumn: false, headerTitle: "Message" })
    message: string;
    //#endregion message Prop

    //#region modifiedby Prop
    @gridColumn({ headerCellClass: ["w-200"], class: ["w-200"], isAscending: false, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'modifiedby', keyColumn: false, headerTitle: "Modified by" })
    modifiedby: string;
    //#endregion modifiedby Prop

    //#region clientIP Prop
    @gridColumn({ headerCellClass:["w-150"],class:["w-150"], isAscending: false, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'clientIP', keyColumn: false, headerTitle: "Client IP" })
    clientIP: string;
    //#endregion clientIP Prop

}