import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventManagerLookupBase {

//#region eventManagerId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventManagerId', keyColumn: true})
        eventManagerId : number;
//#endregion eventManagerId Prop


//#region eventManager Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventManager', keyColumn: false})
        eventManager : string;
//#endregion eventManager Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop

}