import { CoreComponent } from '@rxweb/framework';
import { EventTemplateModel } from 'src/app/viewModel/event-template-model';
import { IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';

export class AbstractReportTemplate extends CoreComponent {

    showComponent: boolean = false;
    constructor() {
        super()

    }
}
