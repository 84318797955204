import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { AbstractEmailSender } from '../domain/abstract-event-sender';

@Component({
    selector: "app-email-sender-from-email-list",
    templateUrl: './email-sender-from-email-list.component.html'
})

export class EmailSenderListModal extends AbstractEmailSender implements OnInit {
    @Input() componentType: any;
    @Input() hide: Function;
    @Input() CCFromData: any;
    @Input() FirstTime: any;

    showFromComponent: boolean;
    showccComponent: boolean;

    radioSelected: any;
    fromData: any;

    ccData: Array<any>;
    selectCC: Array<any>;

    ngOnInit(): void {
        if (this.componentType == CONSTANT_MESSAGES.from) {
            this.fromData = new Array<any>();
            this.showFromComponent = true;

            // this.fromData=ITEMS;
            let length = Object.keys(this.CCFromData).length;

            for (let index = 0; index < length; index++) {
                let data = new Item();

                data.name = Object.keys(this.CCFromData)[index];
                data.value = this.CCFromData[data.name].value;
                data.isChecked = this.CCFromData[data.name].isChecked;
                data.isDefault = this.CCFromData[data.name].isDefault;

                this.fromData.push(data);
            }

            this.onInit();
        }
        else if (this.componentType == CONSTANT_MESSAGES.cc) {

            this.ccData = new Array<any>();
            this.selectCC = new Array<any>();

            if (this.FirstTime == true) {
                let length = Object.keys(this.CCFromData).length;

                for (let index = 0; index < length; index++) {
                    let data = new Item();

                    data.name = Object.keys(this.CCFromData)[index];
                    data.value = this.CCFromData[data.name].value;
                    data.isChecked = this.CCFromData[data.name].isChecked;
                    data.isDefault = this.CCFromData[data.name].isDefault;

                    this.ccData.push(data);
                }
            }
            else {
                this.ccData = this.CCFromData;
            }

            this.showccComponent = true;
            this.onInit();
        }
    }

    selectTemplate(): any {
        this.hide(this.radioSelected);
    }

    setCC(checked: any, value: any) {
        if (checked)
            this.ccData.find(t => t.name === value.name).isChecked = true;
        else
            this.ccData.find(t => t.name === value.name).isChecked = false;
    }

    addCC(): any {
        let checkedData = this.ccData.filter(t => t.isChecked === true)

        var sendStringCC = new Array<any>();

        let data = new SendData();

        data.ccData = this.ccData;

        checkedData.forEach(element => {
            sendStringCC.push(element.value)
        });

        data.selectcc = sendStringCC.join(";");

        this.hide(data);
    }

    ngOnDestroy(): void {
    }
}

class Item {
    name: string;
    value: string;
    isChecked: boolean;
    isDefault: boolean;
}

class SendData {
    selectcc: any;
    ccData: any;
}