import { TemplateConfig } from '@rxweb/grid';

export const PINNED_EVENT_TEMPLATE: TemplateConfig = {
  ul: {
    class: ["formAction", "text-center"],
    childrens: [
      {
        li: {
          childrens: [
            {
              label: {
                class: [
                  function (e) {
                    if (this.isPinnedEvent == false) {
                      return "disabled pin-btn-disable mb-0".split(" ");
                    }
                    else {
                      return "pin-btn mb-0".split(" ");
                    }
                  }
                ],
                attributes: {
                  for: function (e) {
                    return "pinnedId" + this.eventId;
                  },
                },
                  childrens: [
                    {
                      i: { class: "fa fa-thumb-tack".split(" ") }
                    }
                  ],
                
              },
              input: {
                class: ["custom-control-input"],
                event: {
                  input: "onCheckBoxSelect"
                },
                
                attributes: {
                  id: function (e) {
                    return "pinnedId" + this.eventId;
                  },
                  type: "checkbox",
                  checked: function (e) {
                    return this.isPinnedEvent;
                  },
                  value: function (e) {
                    return this.isPinnedEvent;
                  },
                },
              },
            }
          ]
        }
      }
    ]
  }
};
