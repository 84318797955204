import {vIntakeFormHonoreeLookupBase} from '../database-models/v-intake-form-honoree-lookup-base';
//Generated Imports
export class vIntakeFormHonoreeLookup extends vIntakeFormHonoreeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}