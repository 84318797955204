import { vCampaignYearLookup, vUsersLookup, vEventManagerLookup, vEventFundriserLookup, vEventNameLookup, vEventSelectionHeaderLookup, vEventPageType, vEventDetail, vEventHonoree, vEventRecord, vIntakeFormHonoreeLookup, vIntakeFormRecord, vEmailTemplateInternalExternalLookup, vPaginationDefault, vEmailTemplateWIPLookup, vEventLocation, vEventTypeLookup, vDonorFundriserLookup, vDonorNameLookup, vAdCreatorLookup, vAdJournalRelationShipOwnerLookup, vDonorIdLookup } from '@app/models';
import { vDivisionLookup } from '@app/models';


export class EventLookupGroup {
    divisionLookUps: vDivisionLookup[];
    campaignYearLookUps: vCampaignYearLookup[]
    userLookUps: vUsersLookup[];
    eventManagerLookUps: vEventManagerLookup[];
    eventFundraiserLookUps: vEventFundriserLookup[];
    eventNameLookUps: vEventNameLookup[];
    eventSelectionHeaderLookUps: vEventSelectionHeaderLookup[];
    eventPageType: vEventPageType[];
    eventDetail: vEventDetail[];
    eventHonoree: vEventHonoree[];
    eventRecord: vEventRecord;
    event: Event;
    intakeFormHonoreeLookup: vIntakeFormHonoreeLookup[];
    intakeFormRecords: vIntakeFormRecord[];
    emailTemplateLookUps: vEmailTemplateInternalExternalLookup;
    wipEmailTemplateLookUps: vEmailTemplateWIPLookup;
    paginationDefault: vPaginationDefault[];
    eventLocationLookUps: vEventLocation[];
    eventTypeLookUps: vEventTypeLookup[];
    donorFundraiserLookUps: vDonorFundriserLookup[];
    donorNameLookUps: vDonorNameLookup[];
    adCreatorLookUps: vAdCreatorLookup[];
    adJournalRelationshipOwnerLookUps: vAdJournalRelationShipOwnerLookup[];
    donorIdLookUps: vDonorIdLookup[];

}
