import {vPaginationDefaultBase} from '../database-models/v-pagination-default-base';
//Generated Imports
export class vPaginationDefault extends vPaginationDefaultBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties
















}