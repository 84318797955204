import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAdDesignStatusLookupBase {

//#region adDesignStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adDesignStatusId', keyColumn: true})
        adDesignStatusId : number;
//#endregion adDesignStatusId Prop


//#region adDesignStatus Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'adDesignStatus', keyColumn: false})
        adDesignStatus : string;
//#endregion adDesignStatus Prop

}