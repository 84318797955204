import { TemplateConfig } from '@rxweb/grid';


export const ACTION_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction"],
        childrens: [{
            li: {
                childrens: [{
                    a: {
                        authorize: "edit",
                        class: ["edit"],
                        event: {
                            click: "onEdit"
                        },
                        childrens: [{
                            i: { class: "fa-fw uja-Edit".split(" ") }
                        }],
                    }
                }]
            }

        }]
    }
};
