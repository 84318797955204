import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUploadDesignBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region adSpec Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'adSpec', keyColumn: false})
        adSpec : string;
//#endregion adSpec Prop

}