import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserRecordBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region applicationLocaleId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'applicationLocaleId', keyColumn: false})
        applicationLocaleId : number;
//#endregion applicationLocaleId Prop


//#region applicationTimeZoneId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'applicationTimeZoneId', keyColumn: false})
        applicationTimeZoneId : number;
//#endregion applicationTimeZoneId Prop


//#region languageCode Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'languageCode', keyColumn: false})
        languageCode : string;
//#endregion languageCode Prop


//#region firstName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'firstName', keyColumn: false})
        firstName : string;
//#endregion firstName Prop


//#region lastName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'lastName', keyColumn: false})
        lastName : string;
//#endregion lastName Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region roleId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'roleId', keyColumn: false})
        roleId : number;
//#endregion roleId Prop


//#region loginBlocked Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'loginBlocked', keyColumn: false})
        loginBlocked : boolean;
//#endregion loginBlocked Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region verificationCode Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'verificationCode', keyColumn: false})
        verificationCode : any;
//#endregion verificationCode Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : any;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'modifiedBy', keyColumn: false})
        modifiedBy : any;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'modifiedOn', keyColumn: false})
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'roleName', keyColumn: false})
        roleName : string;
//#endregion roleName Prop


//#region division Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'division', keyColumn: false})
        division : string;
//#endregion division Prop


//#region division Prop
@gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'ResetPasswordTime', keyColumn: false})
resetPasswordTime : any;
//#endregion division Prop

}