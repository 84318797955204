import {vEventSelectionHeaderLookupBase} from '../database-models/v-event-selection-header-lookup-base';
//Generated Imports
export class vEventSelectionHeaderLookup extends vEventSelectionHeaderLookupBase {

    //#region Generated Reference Properties

//#endregion Generated Reference Properties



}