import { SpinnerDirective } from "@rxweb/framework"

export class AppSpinner extends SpinnerDirective {
    constructor(element: HTMLElement) {
        super(element);

        this.inClass = ["sk-loading"];
        this.designClass.root = ["sk-spinner", "sk-spinner-wave"];
        this.designClass.childrens = ["sk-rect1", "sk-rect2", "sk-rect3", "sk-rect4", "sk-rect5"]
    }
}