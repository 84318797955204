import {vCustomeDesineBase} from '../database-models/v-custome-desine-base';
//Generated Imports
export class vCustomeDesine extends vCustomeDesineBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}