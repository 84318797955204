import {vEventHonoreeBase} from '../database-models/v-event-honoree-base';
//Generated Imports
export class vEventHonoree extends vEventHonoreeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}