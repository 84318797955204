import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, from } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from '../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../domain/customize-design/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { User } from '@app/models';
import { SetSystemTimeout } from 'src/app/enums/set-system-timeout';
import { ApiCallService } from 'src/app/temp-service/api-call-service';
import { APPLICATION_URL_CONSTANTS } from 'src/app/const/uris/application-url-constants';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';

@Component({
    selector: 'app-login',
    templateUrl: './change-password.component.html'
})

export class ChangePasswordComponent extends CoreComponent implements OnInit {
    spin: boolean = false;
    isApplied: boolean = false;
    timeOutId: number;
    userCredentialChangePasswordModel: User;
    userCredentialFormGroup: IFormGroup<User>;
    subscription: Subscription;
    currentYear: Date = new Date();
    showComponent: boolean = false;
    toastr: BaseToastr = new BaseToastr();
    dialog: BaseDialog;
    accessKey: string;
    email: string;
    user: User

    constructor(private route: Router, private formBuilder: RxFormBuilder,
        private storagedata: BrowserStorage, private apiCallService: ApiCallService) {
        super();

        this.storagedata = new BrowserStorage();
        this.badRequest;
    }

    ngOnInit(): void {
        this.spin = true;

        this.apiCallService.getByIdAPI(APPLICATION_URL_CONSTANTS.users, this.user.userId).subscribe(response => {
            if (response.StatusCode == Number(CONSTANT_MESSAGES.badRequestStatusCode)) {
                this.toastr.error(response.data);
                this.route.navigateByUrl(CONSTANT_MESSAGES.login);
            }
            else {
                this.userCredentialChangePasswordModel = response;

                if (this.userCredentialChangePasswordModel.resetPasswordTime != null) {
                    let diffDate = this.date_diff_indays(new Date(this.userCredentialChangePasswordModel.resetPasswordTime), new Date());

                    let time = SetSystemTimeout.chanpasswordTime;

                    if (diffDate < time) {
                        window.location.href = CONSTANT_MESSAGES.dashboardPath;
                    }
                }

                this.userCredentialChangePasswordModel.isChangePassword = true;
                this.userCredentialChangePasswordModel.status = true;
                this.userCredentialFormGroup = this.formBuilder.formGroup(User, this.userCredentialChangePasswordModel) as IFormGroup<User>;
            }

            document.body.className = "fixed-sidebar fixed-nav fixed-nav-basic login_page_view pace-done pace-done";

            this.showComponent = true;
            this.spin = false;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    changePassword() {
        if (this.userCredentialFormGroup.valid) {
            this.userCredentialChangePasswordModel.isChangePassword = true;

            if (this.userCredentialFormGroup.controls.userOldPassword.value != null &&
                this.userCredentialFormGroup.controls.userPassword.value != null &&
                this.userCredentialFormGroup.controls.userConfirmPassword.value != null) {

                if (this.userCredentialFormGroup.controls.userOldPassword.value != this.userCredentialFormGroup.controls.userPassword.value) {

                    if (this.userCredentialFormGroup.controls.userPassword.value == this.userCredentialFormGroup.controls.userConfirmPassword.value) {

                        this.userCredentialChangePasswordModel.userOldPassword = this.userCredentialFormGroup.controls.userOldPassword.value;
                        this.userCredentialChangePasswordModel.userPassword = this.userCredentialFormGroup.controls.userPassword.value;
                        this.userCredentialChangePasswordModel.userConfirmPassword = this.userCredentialFormGroup.controls.userConfirmPassword.value;

                        this.apiCallService.putAPI(APPLICATION_URL_CONSTANTS.users, this.userCredentialChangePasswordModel, this.user.userId).subscribe(data => {
                            this.spin = false;

                            this.toastr.success(ValidationMessage.passwordChanged);
                            this.storagedata.local.clearAll();

                            var element = document.getElementById("gtrac");

                            element.classList.add("login-page-view");
                            //window.location.href = '/login'
                            this.route.navigate([CONSTANT_MESSAGES.loginPath]);
                        })
                    }
                    else {
                        this.toastr.error(ValidationMessage.passwordConfirmPasswordMustBeSame);
                    }
                }
                else {
                    this.toastr.error(ValidationMessage.OldNewPasswordNotSame);
                }
            }
        }
    }

    login(): void {
        this.storagedata.local.clearAll();
        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
        element.classList.add("login-page-view");
        //window.location.href = '/login'
        this.route.navigate([CONSTANT_MESSAGES.loginPath]);
    }

    date_diff_indays(date1, date2) {
        let dt1 = new Date(date1);
        let dt2 = new Date(date2);

        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }

    badRequest = (data) => {
        this.spin = false;

        let messages = JSON.parse(data);
        this.toastr.error(messages[0]);

        this.storagedata.local.clearAll();

        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

        element.classList.add("login-page-view");
        //window.location.href = '/login'
        //this.route.navigate(['/login']);
    }
}