import { TemplateConfig } from '@rxweb/grid';

export const Date_TIME_FORMAT_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [{
            span: {
                class: ["date"],
                childrens: [{
                    text: {
                        text: function (e) {
                            if (this[e.name] != null) {
                                var date = null;
                                if (this[e.name].toLowerCase() == "eventdate" || this[e.name].toLowerCase() == "journaladdeadlinedate")
                                    date = new Date(this[e.name] + 'Z');
                                else
                                    date = new Date(this[e.name]);
                                return (date.toLocaleString('default', { month: 'short' })) + ' ' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear();
                            }
                        }
                    }
                }]
            }
        }, {
            span: {
                childrens: [{
                    text: {
                        text: function (e) {
                            if (this[e.name] != null) {
                                var date = null;
                                if (this[e.name].toLowerCase() == "eventdate" || this[e.name].toLowerCase() == "journaladdeadlinedate")
                                    date = new Date(this[e.name] + 'Z');
                                else
                                    date = new Date(this[e.name]);
                                return (((date.getHours() < 10 ? '0' : '') + date.getHours()) + ':' + ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes()) + ':' + ((date.getSeconds() < 10 ? '0' : '') + date.getSeconds()));
                            } else {
                                return 'NA'
                            }
                        }
                    }
                }]
            }
        }]
    }
}
