import { prop } from '@rxweb/reactive-form-validators';

export class AuditSearch{
    @prop()
    public divisionId:any;
    @prop()
    public eventId:any;
    @prop()
    public fieldName:any;
    @prop()
    public modifiedDate:any;
    @prop()
    public modifiedBy:any;

}