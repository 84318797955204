import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventTemplateBase {

//#region eventTemplateId Prop
        @prop()
        eventTemplateId : number;
//#endregion eventTemplateId Prop


//#region eventId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        eventId : number;
//#endregion eventId Prop


//#region oPSTemplateId Prop
        @required()
        @maxLength({value:50})
        oPSTemplateId : string;
//#endregion oPSTemplateId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : Date;
//#endregion modifiedOn Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop



}