import { AppGrid } from '../../domain/app-grid';


export class RoleGrid extends AppGrid
{
    get footerTemplate()
    {
        return {div: {
            class:  [],
            childrens: []
        }};
    }

}