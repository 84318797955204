import { TemplateConfig } from '@rxweb/grid';

export const Date_FORMAT_GRID_TEMPLATE: TemplateConfig = {
    span: {
        childrens: [{
            text: {
                text: function (e) {
                    if (this[e.name] != null) {
                        var date = null;
                        if (this[e.name].toLowerCase() == "eventdate" || this[e.name].toLowerCase() == "journaladdeadlinedate")
                            date = new Date(this[e.name] + 'Z');
                        else
                            date = new Date(this[e.name]);

                        return (date.toLocaleString('default', { month: 'short' })) + ' ' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear();
                    } else {
                        return 'NA'
                    }

                }
            }
        }]
    }
}
