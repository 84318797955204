import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"

export class HouseAndBrandAdsDignitariesLetterBase {

        //#region houseAndBrandAdsDignitariesLettersId Prop
        @prop()
        houseAndBrandAdsDignitariesLettersId: number;
        //#endregion houseAndBrandAdsDignitariesLettersId Prop


        //#region brandTypeId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        brandTypeId: number;
        //#endregion brandTypeId Prop


        //#region sequence Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        sequence: number;
        //#endregion sequence Prop


        //#region imageURL Prop
        @prop()
        imageURL: string;
        //#endregion imageURL Prop


        //#region isMandatory Prop
        @prop()
        isMandatory: boolean;
        //#endregion isMandatory Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop

}