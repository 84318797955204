import { required, email, maxLength, minLength, pattern } from '@rxweb/reactive-form-validators';
import { ValidationMessage } from '../const/validation-msg/custom-validation';

export class UserCredentialModel {
  @required()
  @email()
  @maxLength({ value: 50 })
  email: string = undefined;
  @required()
  @maxLength({ value: 15 })
  password: string = undefined;
  resetPasswordTime: any
}


export class OTPModel {
  @required()
  @pattern({ expression: { 'otp': /^\d{6}$/ }, message: ValidationMessage.otpSixDigitValidator })
  otp: string = undefined;
}
