import {vGetDataforLinkBase} from '../database-models/v-get-datafor-link-base';
//Generated Imports
export class vGetDataforLink extends vGetDataforLinkBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







}