import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_GRID_TEMPLATE_WIP: TemplateConfig = {
  div: {
    childrens: [{
        a: {
          event: {
            click: "onLinkWIP"
          },
          childrens: [{
            div:{
              style:{
                "text-decoration":"underline",
                "cursor":"pointer",
              },
              class:["anchor-hover"],
              childrens: [{
            text: {
              text: function (e) { return this[e.name] }
              }
            }]
          }
        }]
      }
  }],
  }
};
