import {vEventDetailBase} from '../database-models/v-event-detail-base';
//Generated Imports
export class vEventDetail extends vEventDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}