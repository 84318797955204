import { AdIntakeFormBase } from '../database-models/ad-intake-form-base';
import { EventBase } from '../database-models/event-base';
import { PageTypeBase } from '../database-models/page-type-base';
import { IntakeFormSortingBase } from '../database-models/intake-form-sorting-base';
import { IntakeFormCommentBase } from '../database-models/intake-form-comment-base';
import { IntakeFromHonoreeBase } from '../database-models/intake-from-honoree-base';
import { IntakeFromAdDesignStatusBase } from '../database-models/intake-from-ad-design-status-base';
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class AdIntakeForm extends AdIntakeFormBase {
    //#region Generated Reference Properties

    //#region event Prop
    event: EventBase;
    //#endregion event Prop

    //#region pageType Prop
    pageType: PageTypeBase;
    //#endregion pageType Prop

    //#region intakeFormSorting Prop
    intakeFormSorting: IntakeFormSortingBase[];
    //#endregion intakeFormSorting Prop

    //#region intakeFormComment Prop
    intakeFormComment: IntakeFormCommentBase[];
    //#endregion intakeFormComment Prop

    //#region intakeFromHonoree Prop
    @prop()
    intakeFromHonoree: IntakeFromHonoreeBase[];
    //#endregion intakeFromHonoree Prop

    //#region intakeFromAdDesignStatuses Prop
    intakeFromAdDesignStatuses: IntakeFromAdDesignStatusBase[];
    //#endregion intakeFromAdDesignStatuses Prop

    //#region isModifiedJournalAdCopyLogo Prop
    @prop()
    isModifiedJournalAdCopyLogo: boolean;
    //#endregion isModifiedJournalAdCopyLogo Prop

    //#endregion Generated Reference Properties
}
