import {vUserEmailNotificationTemplateBase} from '../database-models/v-user-email-notification-template-base';
//Generated Imports
export class vUserEmailNotificationTemplate extends vUserEmailNotificationTemplateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}