import { TemplateConfig } from '@rxweb/grid';

export const EVENT_ACTION_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction"],
        childrens: [
            {
                li: {
                    authorize: "edit",
                    childrens: [
                        {
                            a: {
                                class: ["edit"],
                                event: {
                                    click: "onEdit"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fw uja-Edit".split(" ") }
                                    }
                                ],
                            }
                        }

                    ]
                }
            },
            {
                li: {
                    authorize: "repeat",
                    childrens: [
                        {
                            a: {
                                class: ["clone"],
                                event: {
                                    click: "onCopy"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa fa-copy".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            // {
            //     li: {
            //         authorize: "adManagement",
            //         childrens: [
            //             {
            //                 a: {
            //                     class: ["ad-management"],
            //                     event: {
            //                         click: "adManagement"
            //                     },
            //                     childrens: [
            //                         {
            //                             i: { class: "fa fa-file-o".split(" ") }
            //                         }
            //                     ],
            //                 }
            //             }
            //         ]
            //     }
            // },
            {
                li: {
                    authorize: "adEventDesign",
                    childrens: [
                        {
                            a: {
                                class: ["adDesign"],
                                event: {
                                    click: "adDesign"
                                },
                                childrens: [
                                    {
                                        i: { class: "fa-fw uja-Ad-Design".split(" ") }
                                    }
                                ],
                            }
                        }
                    ]
                }
            },
            // {
            //     li: {
            //         authorize: "delete",
            //         childrens: [
            //             {
            //                 a: {
            //                     class: ["delete"],
            //                     event: {
            //                         click: "onDelete"
            //                     },
            //                     childrens: [
            //                         {
            //                             i: { class: "fa-fw uja-Delete".split(" ") }
            //                         }
            //                     ],
            //                 }
            //             }
            //         ]
            //     }
            // }
        ]
    }
}