import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRepeatAdBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: false})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region adDesignStatusId Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'adDesignStatusId', keyColumn: false})
        adDesignStatusId : number;
//#endregion adDesignStatusId Prop


//#region donorId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'donorId', keyColumn: false})
        donorId : string;
//#endregion donorId Prop


//#region donorName Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'donorName', keyColumn: false})
        donorName : string;
//#endregion donorName Prop


//#region divisionId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'divisionId', keyColumn: false})
        divisionId : number;
//#endregion divisionId Prop


//#region divisionName Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'divisionName', keyColumn: false})
        divisionName : string;
//#endregion divisionName Prop


//#region eventId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop


//#region imageURL Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'imageURL', keyColumn: false})
        imageURL : string;
//#endregion imageURL Prop


//#region eventDate Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventDate', keyColumn: false})
        eventDate : any;
//#endregion eventDate Prop


//#region eventYear Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventYear', keyColumn: false})
        eventYear : string;
//#endregion eventYear Prop

}