import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { EVENT_LOOKUPS } from "src/app/const/uris/event-lookups.uri";
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { EventLookupGroup } from "src/app/viewModel/event-lookup-group";
import { AbstractIntakeFormSorting } from "../domain/abstract-intake-form-sorting";
import { IntakeFormSortingListComponent } from "./intake-form-sorting-list.component"; //For

@Component({
    selector: "app-download-modal",
    templateUrl: './download-modal.component.html'
})

export class DownloadPdfModalComponent extends AbstractIntakeFormSorting implements OnInit {
    @Input() componentType: any;
    @Input() hide: Function;
    @Input() eventId: number;
    @Input() DownloadTypeLabel: string;
    downloadType: number = 1;
    selectedPageType: number;
    showDownloadComponent: boolean;
    downloadPdfDetail = new DownloadPdfDetail();
    pageTypeData: any;
    filteredPageTypeData: any;
    pageNumbers: string;
    isValidPageNumber: boolean = true;
    isValidPageNumberMsg: string = "";
    spin: boolean = false;
    isPagesExist: boolean = true;
    constructor(public activatedRoute: ActivatedRoute, private apiCallService: ApiCallService) {
        super();
    }

    ngOnInit(): void {
        switch (this.componentType) {
            case CONSTANT_MESSAGES.downloadmodal:
                this.showDownloadComponent = true;
                this.bindEventSelectHeader();
                break;

            default:
                break;
        }
    }
    ngOnDestroy(): void {

    }
    downloadPdf() {
        this.spin = true;
        this.downloadPdfDetail.PageTypeId = this.selectedPageType;
        this.downloadPdfDetail.DownloadTypeValue = this.downloadType;
        this.downloadPdfDetail.PageNumbers = this.pageNumbers;
        if (this.downloadType == 3) {
            let regexp = new RegExp('^[0-9,]*$');
            let isValid = regexp.test(this.pageNumbers);
            let pageLength: number = 0;
            if (isValid) {
                this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.intakeFormSortings, { "Id": this.selectedPageType, "EventId": this.eventId }).subscribe((t: any) => {
                    let pageSequenceData = t.filter(t => t.sequence != null);
                    pageLength = pageSequenceData.length;

                    const splitStr = this.pageNumbers.split(',');

                    for (let idx = 0; idx < splitStr.length; idx++) {
                        const splitElement = Number(splitStr[idx]);
                        if (splitElement == 0 || splitElement > pageLength) {
                            isValid = false;
                            break;
                        }
                    }
                    if (isValid) {
                        this.isValidPageNumber = true;
                        this.hide(this.downloadPdfDetail);
                        this.spin = false;
                    }
                    else {
                        this.isValidPageNumberMsg = "Invlid page number";
                        this.isValidPageNumber = false;
                        this.spin = false;
                    }
                });
            }
            else {
                this.isValidPageNumberMsg = "Please enter page number like 1,2,3";
                this.isValidPageNumber = false;
                this.spin = false;
            }
        }
        else if (this.downloadType == 2) {
            this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.intakeFormSortings, { "Id": this.selectedPageType, "EventId": this.eventId }).subscribe((t: any) => {
                let pageSequenceData = t.filter(t => t.sequence != null);
                if (pageSequenceData.length > 0) {
                    this.isValidPageNumber = true;
                    this.hide(this.downloadPdfDetail);
                    this.spin = false;
                }
                else {
                    this.isPagesExist = false;
                    this.spin = false;
                }
            });
        }
        else {
            this.isValidPageNumber = true;
            this.hide(this.downloadPdfDetail);
            this.spin = false;
        }

    }
    bindEventSelectHeader() {
        this.lookup<EventLookupGroup>([
            { path: EVENT_LOOKUPS["eventNameLookUps"], propName: "eventNameLookUps" },
            { path: EVENT_LOOKUPS["eventPageType"], propName: "eventPageType" },
            { path: EVENT_LOOKUPS["paginationDefault"], propName: "paginationDefault" }
        ]).subscribe(Response => {
            this.pageTypeData = Response.eventPageType;
            this.filteredPageTypeData = this.pageTypeData.filter(t => t.eventId == this.eventId);
            this.selectedPageType = this.filteredPageTypeData[0].pageTypeId;
        });
    }
}
class DownloadPdfDetail {
    PageTypeId: number;
    DownloadTypeValue: number;
    PageNumbers: string;
}