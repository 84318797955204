import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIntakeFormHonoreeLookupBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region intakeFormHonoreeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'intakeFormHonoreeId', keyColumn: false})
        intakeFormHonoreeId : number;
//#endregion intakeFormHonoreeId Prop


//#region eventHonoreeId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'eventHonoreeId', keyColumn: false})
        eventHonoreeId : number;
//#endregion eventHonoreeId Prop


//#region personName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'personName', keyColumn: false})
        personName : string;
//#endregion personName Prop

}