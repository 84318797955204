import { GridDesigner, ElementConfig, TemplateConfig } from "@rxweb/grid"
import { BIND_EXCEL_BUTTON } from '../custom-templates/grid/grid-custom-templates';
import { dbAccessModule } from './security/authorization-resolver';

export class DivChildGrid extends GridDesigner {

    constructor(source: any[], model: Function, gridConfiguration?: any) {
        super(source, model, gridConfiguration);
        this.isDivBase = true;
        this.startNodeName = "div";
        this.designClass.tableClass = "div-table themeTable table table-striped".split(' ');
        this.designClass.headerClass = ["d-thead", "bg-dark-gray", "text-white"];
        this.designClass.headerRowClass = "div-tr".split(" ");
        this.designClass.headerCellClass = "div-th".split(' ')
        this.designClass.bodyClass = "div-tbody".split(" ")
        this.designClass.cellClass = ["div-td"];
        this.designClass.rowClass = "div-tr".split(" ");
        this.maxPerPage = 1000
    }

    authorize(authorizeConfig:{[key:string]:any}){
        if(dbAccessModule)
            return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action];
        else
            return false;
    }

    get tableElementConfig(): ElementConfig {
        return {
            class: ["div-tr", "odd"],
            childrens: [{
                div: {
                    class: ["div-td", "p-0"],
                    childrens: [{
                        div: {
                            class: ["collapse", "show"],
                            childrens: [{
                                div: {
                                    class: this.designClass.tableClass,
                                    childrens: [this.headerTemplate, this.bodyTemplate]
                                }
                            }]
                        }
                    }]
                }
            }]
        };
    }

    get footerTemplate()
    {
        return {div: {
            class:  [],
            childrens: []
        }};
    }

    get footerCenterTemplate() {
        return {};
    }
    set footerCenterTemplate(value: TemplateConfig) {

    }

    // get footerTemplate()
    // {
    //     return {div: {
    //         class:  [],
    //         childrens: []
    //     }};
    // }

    refresh(elementId) {
        let element = document.getElementById(elementId);
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }

    get appGridSource(){
        return this.bindingSource;
    }
}
