import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSectionDividerBase {

//#region sectionDividersId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'sectionDividersId', keyColumn: true})
        sectionDividersId : number;
//#endregion sectionDividersId Prop


//#region pageType Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'pageTypeName', keyColumn: false})
        pageTypeName : string;
//#endregion pageType Prop


//#region sectionDividers Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'sectionDividers', keyColumn: false})
        sectionDividers : string;
//#endregion sectionDividers Prop

}