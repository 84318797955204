import { gridColumn } from '@rxweb/grid';
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'eventAction',
    allowSorting: false,
    headerCellClass:["w-150","text-center"],configuredTemplate: { templateName: 'eventAction' }, columnIndex: 0, headerTitle: "Action",class: ["actionFild","w-150"]
})
export class EventListModel {

    //#region EventId Prop
    @gridColumn({ style: { width: "100px" }, visible: false, columnIndex: 0, allowSorting: true, isAscending: false, headerKey: 'EventId', keyColumn: true })
    eventId: number;
    //#endregion EventId Prop

    //#region campaignYear Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'Campaignyear', keyColumn: false, headerTitle: "Campaign Year" })
    campaignYear: string;
    //#endregion campaignYear Prop

    //#region divisionName Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 2, allowSorting: true, headerKey: 'DivisionName', keyColumn: false, headerTitle: "Division" })
    divisionName: string;
    //#endregion divisionName Prop

    //#region eventName Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'eventName', keyColumn: false, headerTitle: "Event Name",
    configuredTemplate: { templateName: "redirectLink" } })
    eventName: string;
    //#endregion eventName Prop

    //#region eventNumber Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'EventNumber', keyColumn: false, headerTitle: "Event Number" })
    eventNumber: number;
    //#endregion eventNumber Prop

    //#region eventManager Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'EventManager', keyColumn: false, headerTitle: "Event Team Lead" })
    eventManager: string;
    //#endregion eventManager Prop

    //#region eventDate Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'EventDate', keyColumn: false, headerTitle: "Event date", configuredTemplate: { templateName: "dateFormat" } })
    eventDate: Date;
    //#endregion eventDate Prop


    //#region journalAdDeadlineDate Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 7, allowSorting: true, headerKey: 'JournalAdDeadlineDate', keyColumn: false, headerTitle: "Journal Ad Deadline Date", configuredTemplate: { templateName: "dateFormat" } })
    journalAdDeadlineDate: Date;
    //#endregion journalAdDeadlineDate Prop

    //#region eventLocation Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 8, allowSorting: true, headerKey: 'EventLocation', keyColumn: false, headerTitle: "Event Location" })
    eventLocation: string;
    //#endregion eventLocation Prop

    //#region eventCreatedDate Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 9, allowSorting: true, headerKey: 'EventCreatedDate', keyColumn: false, headerTitle: "Event Created Date", configuredTemplate: { templateName: "dateFormat" } })
    eventCreatedDate: Date;
    //#endregion eventCreatedDate Prop

    // //#region eventStatus Prop
    // @gridColumn({ isAscending: false, visible: true, columnIndex: 10, allowSorting: true, headerKey: 'EventStatus', keyColumn: false, headerTitle: "Event Status", configuredTemplate: { templateName: "eventStatus" } })
    // eventStatus: string;
    // //#endregion eventStatus Prop

    // //#region Status Prop
    // @gridColumn({ isAscending: false, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'Status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" } })
    // Status: string;
    // //#endregion Status Prop

    //#region Status Prop
    @gridColumn({ isAscending: false, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'Status', keyColumn: false, headerTitle: "Event Status", configuredTemplate: { templateName: "recordActive" } })
    eventStatus: string;
    //#endregion Status Prop


}