import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSendApprovalTypeLookupBase {

//#region approvalStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'approvalStatusId', keyColumn: true})
        approvalStatusId : number;
//#endregion approvalStatusId Prop


//#region approvalStatus Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'approvalStatus', keyColumn: false})
        approvalStatus : string;
//#endregion approvalStatus Prop

}