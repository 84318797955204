import {vEventTemplateBase} from '../database-models/v-event-template-base';
//Generated Imports
export class vEventTemplate extends vEventTemplateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}