export const ValidationMessage: { [key: string]: string } = {
    dataAdded: "Data added successfully.",

    dataUpdated: "Data updated successfully.",

    dataDeleted: "Data deleted successfully.",

    errorOccurred: "Error occurred.",

    selectOne: "Please select atleast one.",

    passwordChanged: "Password changed successfully.",

    problemDownloadExcel: "There was some problem for downloading an excel file.",

    oldPassworRequired: "Old password is required.",

    newPassworRequired: "New password is required.",

    confirmPassworRequired: "Confirm password is required.",

    newPassworNotValid: "New password isn't valid.",

    confirmPassworNotValid: "Confirm password isn't valid.",

    OldNewPasswordNotSame: "The specified values of Old Password and New Password can't be the same.",

    passwordConfirmPasswordMustBeSame: "The specified values of Password and Confirm Password must be the same.",

    mailSentSuccessfully: "Mail sent successfully.",

    mailDidNotSendSuccessfully: "Mail did not send successfully.",

    loginSuccessful: "Login Successful.",

    resetPasswordSuccessfully: "Reset password successfully.",

    donorFundraiserNeedsSetupInSystem: "Donor Fundraiser needs to be setup in system.",

    invalidDonorId: "Invalid Donor Id.",

    uploadSinglePagePDFOnly: "Please upload single page PDF only.",

    canNotLeaveEmpty: "You can't leave this empty.",

    fileSizeExceeds: "File size exceeds 5MB limit.",

    tiffFilesAreNotSupported: "TIFF files are not supported. Please utilize PDF, JPG, PNG, JPEG files.",

    supportPdfJpgJpeg: "We support only PDF, JPG, and JPEG formats.",

    errorOccuredTemplateCouldNotAddSuccessfully: "Error occured - Template could not add successfully - Please try again.",

    template_s_addedSuccessfully: "Template(s) added successfully.",

    addAtleastOneHonoree: "Please add atleast one Honoree.",

    addAtleaestPageType: "Please add atleast one Page type.",

    selectDateAccordingToSelectedCampaignYear: "Please select date according to selected Campaign Year.",

    selectDateGreaterThanToday: "Please select date greater than today.",

    selectDateLessThanEventDate: "Please select date less than Event Date.",

    enterAppropriateValueInThisField: "You need to enter appropriate value in this field.",

    uploadedFillerAdDesign: "Uploaded Filler Ad Design.",

    eventHonoreeCannotBeDeleted: "This Event Honoree cannot be deleted because one or more than one 'Intake Form(s)' have already been used it.",

    pageTypeCannotBeDeleted: "This Page Type cannot be deleted because one or more than one 'Intake Form(s)' have already been used it.",

    pageTypeDoNotHaveUploadFillerAdDesign: "One or more than one Page Type(s) do not have 'Uploaded Filler Ad Design', please upload those.",

    sequenceIsUpdated: "Sequece is updated.",

    sortedAscendingFirst: "Ads have been sorted in Ascending order for the '",

    sortedAscedningSecond: "' Page Type.",

    template_s_approvedSuccessfully: "Template(s) approved successfully.",

    templateIsApprovedSuccessfully: "Template is approved successfully.",

    moreThan100CharactersAreNotPermitted: "More than 100 characters are not permitted",

    enterValidDivisionName: "Please enter valid division name.",

    errorOccuredWhileGettingTemplate: "Error occured while getting template -- please try again. If continued error, please reach out to journals@ujafedny.org to have a link resent",

    emailNotificationMarkedAsReadSuccessfully: "Email notifications marked as read successfully.",

    allEmailNotificationDeletedSuccessfully: "All email notifications deleted successfully",

    emailNotificationDeletedSuccessfully: "Email notification deleted successfully.",

    errorOccured: "Error Occured.",

    pageTypeChangedSuccessfully_First: "Page Type has been changed successfully from '",

    pageTypeChangedSuccessfully_Second: "' to '",

    adRejectedSuccessfully: "Ad has been rejected successfully by sending mail to respected Relationship Owner and FRD Journal Lead.",

    noTemplateInApproval: "There is no any template in order to download as PDF.",

    otpSixDigitValidator: "Only digits allowed with length 6"
}