import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { vAuditLog } from '@app/models';
import { CoreComponent } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@http({
    path: APPLICATION_URL_CONSTANTS.auditLogs,
})

export class AbstractAuditLog extends CoreComponent {
    auditLogFormGroup: IFormGroup<vAuditLog>;
    auditshowComponent: boolean;
    isShowGridColumn: boolean = false;
    spin: boolean;
    toastr: BaseToastr
    dialog: BaseDialog;
    modalView: ModalView;
    breadCrumb: BreadCrumb;

    constructor() {
        super();

        this.bindBreadCrumb();

        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();

        this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard;
        this.breadCrumb.name1 = CONSTANT_MESSAGES.home;
        this.breadCrumb.link1 = CONSTANT_MESSAGES.auditLogs;
        this.breadCrumb.title = CONSTANT_MESSAGES.auditLog;
        this.breadCrumb.pagename = CONSTANT_MESSAGES.auditLog;
    }
}