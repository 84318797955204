import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractEmailNotification } from '../domain/abstract-email-notification';
import { EmailNotification, vEmailNotification } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router, NavigationEnd } from '@angular/router';
import { EventModalComponent } from 'src/app/components/event/events/event-modal.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { EmailNotificationDetails } from '../modal/email-notification-details.component';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';
import { ActionType, RolePermissionModule } from 'src/app/enums/user-role-permission';
import { access } from '@rxweb/angular-router';
import { RxHttp } from '@rxweb/http';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { ValidationMessage } from "src/app/const/validation-msg/custom-validation";
import { pageDataQuery, Query, spParameter } from "src/app/viewModel/spGridClass";
import { dbAccessModule } from "src/app/domain/security/authorization-resolver";

@access({ accessLevel: RolePermissionModule.EmailNotifications, action: ActionType.GET })

@Component({
    selector: "app-email-notification-list",
    templateUrl: './email-notification-list.component.html'
})

export class EmailNotificationListComponent extends AbstractEmailNotification implements OnInit, OnDestroy {
    vEmailNotifications: vEmailNotification;
    emailNotificaitions: EmailNotification;
    subscription: Subscription;
    emailNotificationGrid: AppGrid;
    emailNotificationGridColumns: any[];
    rowCount: number = sessionStorage.getItem(CONSTANT_MESSAGES.emailNotification) != null ? parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.emailNotification)) : 10;
    pageIndex: number = 1;
    sortOrder: string = CONSTANT_MESSAGES.descending;
    orderByColumn: string = CONSTANT_MESSAGES.enCreatedOn;
    isFirstTime: boolean = true;
    totalCount: any;
    emailNotificationData: any;
    searchValue: string;
    isFromSearch: boolean = false;
    isFromGridSearch: boolean = false;
    isAccessedForDelete: boolean = true;

    constructor(private route: Router, public modalView: ModalView, public formBuilder: RxFormBuilder,
        private http: RxHttp, private apiCallService: ApiCallService) {
        super();

        this.modalView = modalView;

        this.isAccessedForDelete = dbAccessModule[RolePermissionModule.EmailNotifications][ActionType.DELETE]
    }

    ngOnInit(): void {
        this.showComponent = true;
        this.spin = true;

        this.bindGrid();
    }

    bindGrid(page: number = 0) {
        this.showComponent = true;
        let jsonObject = new spParameter();
        jsonObject.searchQuery = {};

        let pageQuery = new pageDataQuery();

        pageQuery.PageRow = this.rowCount;
        pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        jsonObject.pageQuery = JSON.stringify(pageQuery);

        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonObject);

        this.subscription = this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.emailNotifications, { 'searchParams': sendJson.query }).subscribe((t: any) => {
            this.isFirstTime = false;
            var parsedData = JSON.parse(t);
            this.emailNotificationData = parsedData[0].result.data;
            this.totalCount = parsedData[0].result.footer[0].totalCount;

            if (page == 0) {
                this.emailNotificationGrid = new AppGrid(this.emailNotificationData, vEmailNotification
                    , {
                        actions: {
                            onView: this.onView.bind(this),
                            onDelete: this.onDelete.bind(this),
                            onLink: this.onLink.bind(this)
                        }
                    }
                );

                this.spin = false;
                this.emailNotificationGrid.designClass.tableClass = "themeTable notificationTable table table-striped".split(' ');
                this.emailNotificationGrid.authorization = {
                    'preview': {
                        accessLevel: RolePermissionModule.EmailNotifications,
                        action: ActionType.GET
                    }, 'delete': {
                        accessLevel: RolePermissionModule.EmailNotifications,
                        action: ActionType.DELETE
                    }
                };
                this.emailNotificationGrid.maxPerPage = this.rowCount;

                if (this.emailNotificationData.length > 0) {
                    this.emailNotificationGrid.storeProcedure = {
                        length: this.totalCount,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                }
                else {
                    this.emailNotificationGrid.storeProcedure = {
                        length: 0,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                    this.emailNotificationGrid.updateSource([]);
                }
                this.emailNotificationGrid.design(document.getElementById("emailNotifications"));
            }
            else {
                if (this.emailNotificationData.length > 0) {
                    this.emailNotificationGrid.storeProcedure = {
                        length: this.totalCount,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: this.pageIndex,
                        onPageSorting: this.onPageSorting.bind(this)
                    }

                    this.emailNotificationGrid.updateSource(this.emailNotificationData);
                }
                else {
                    this.emailNotificationGrid.storeProcedure = {
                        length: 0,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: this.pageIndex,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                    this.emailNotificationGrid.updateSource([]);
                }
            }
            this.spin = false;
            this.isFirstTime = false;
        })
    }

    search(value) {
        this.spin = true;
        this.searchValue = value;
        this.isFromGridSearch = true;
        this.bindGrid(this.pageIndex);
    }

    onPageChanging(x) {
        this.spin = true;
        this.emailNotificationGrid.storeProcedure.nextPage = x;
        this.pageIndex = x;
        this.rowCount = this.emailNotificationGrid.maxPerPage;
        if (sessionStorage.getItem(CONSTANT_MESSAGES.emailNotification) != null && this.rowCount == parseInt(sessionStorage.getItem('emailNotification'))) {
            this.rowCount = parseInt(sessionStorage.getItem(CONSTANT_MESSAGES.emailNotification));
        } else {
            sessionStorage.setItem(CONSTANT_MESSAGES.emailNotification, this.rowCount.toString());
        }
        this.bindGrid(x);
    }

    onPageSorting(x, y, z) {
        this.sortOrder = y;

        this.orderByColumn = x;

        if (!this.isFirstTime) {
            this.sortOrder = y == false ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;
            this.bindGrid(this.pageIndex);
        }
    }

    onView(emailNotification: EmailNotification) {
        this.apiCallService.getByIdAPI(APPLICATION_URL_CONSTANTS.emailNotifications, emailNotification.emailNotificationId).subscribe((t: any) => {
            this.emailNotificationFormGroup = this.formBuilder.formGroup(EmailNotification, t) as IFormGroup<EmailNotification>;
            this.emailNotificationFormGroup.controls.emailNotificationId.setValue(t[0].emailNotificationId);
            this.emailNotificationFormGroup.controls.emailDetailId.setValue(t[0].emailDetailId);
            this.emailNotificationFormGroup.controls.userId.setValue(t[0].userId);
            this.emailNotificationFormGroup.controls.statusId.setValue(StatusEnum.Active);
            this.emailNotificationFormGroup.controls.isPreview.setValue(true);
            this.emailNotificationFormGroup.controls.createdBy.setValue(t[0].userId);
            this.emailNotificationFormGroup.controls.createdOn.setValue(t[0].createdOn);

            this.apiCallService.putAPI(APPLICATION_URL_CONSTANTS.emailNotifications, this.emailNotificationFormGroup.value, t[0].emailNotificationId)
                .subscribe(data => {
                    let date = new Date(t[0].createdOn);
                    let createdDate = (date.toLocaleString('default', { month: 'short' })) + ' ' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear() + ' | ' + (((date.getHours() < 10 ? '0' : '') + date.getHours()) + ':' + ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes()) + ':' + ((date.getSeconds() < 10 ? '0' : '') + date.getSeconds()));
                    let stringArray = new Array<string>();
                    stringArray.push(t[0].donorId)
                    stringArray.push(createdDate)
                    stringArray.push(t[0].subject)
                    stringArray.push(t[0].templateBody)

                    this.modalView.show(EmailNotificationDetails, { componentType: 'modal', params: stringArray }).then(t => {
                        if (t == undefined) {
                            window.location.reload();
                        }
                    });
                })
        })
    }

    markAsRead() {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.markAsRead }).then(t => {
            this.spin = true;
            if (t == 'yes') {

                let userId = this.user.userId;
                this.spin = true;
                this.apiCallService.postAPI(CUSTOM_URI.markAsReadEmailNotification, userId).subscribe((t: any) => {
                    this.spin = false;

                    this.toastr.success(ValidationMessage.emailNotificationMarkedAsReadSuccessfully);

                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                });
            }
            this.spin = false;
        });
    }

    deleteAll() {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.deleteAll }).then(t => {
            this.spin = true;
            if (t == 'yes') {

                this.spin = true;
                let userId = this.user.userId;
                this.apiCallService.postAPI(CUSTOM_URI.deleteAllEmailNotification, userId).subscribe((t: any) => {

                    this.spin = false;
                    this.toastr.success(ValidationMessage.allEmailNotificationDeletedSuccessfully);
                    setTimeout(() => {
                        this.route.navigateByUrl(CONSTANT_MESSAGES.dashboardPath);
                    }, 3000);
                });
            }
            this.spin = false;
        });
    }

    onDelete(emailNotification: EmailNotification) {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.delete }).then(t => {

            if (t == CONSTANT_MESSAGES.yes) {
                this.spin = true;

                this.apiCallService.deleteAPI(APPLICATION_URL_CONSTANTS.emailNotifications, null, emailNotification.emailNotificationId).subscribe(data => {
                    this.toastr.success(ValidationMessage.emailNotificationDeletedSuccessfully);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                })
            }
            this.spin = false;
        });
    }

    onLink(event: any) {

        this.route.navigateByUrl(CONSTANT_MESSAGES.eventsPath + event.eventId + CONSTANT_MESSAGES.adIntakeFormsPathForwardSlash + event.adIntakeFormId + CONSTANT_MESSAGES.intakeFormAdDesignTemplateWIP);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}