import { EventBase } from '../database-models/event-base';
import { DivisionBase } from '../database-models/division-base';
import { PageType } from '../extended-models/page-type';
import { EventHonoree } from '../extended-models/event-honoree';
import { EventTemplateBase } from '../database-models/event-template-base';
import { AdIntakeFormBase } from '../database-models/ad-intake-form-base';
import { prop, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class Event extends EventBase {

    //#region Generated Reference Properties
    //#region division Prop
        division: DivisionBase;
    //#endregion division Prop
    
    //#region status Prop
    @prop()
    status : boolean;
    //#endregion status Prop

    //#region status Prop
    @prop()
    isEventDateChanged : boolean = false;
    //#endregion status Prop

    //#region status Prop
    @prop()
    isJournalAdDeadlineDateChanged : boolean = false;
    //#endregion status Prop

    //#region pageTypes Prop
    @prop()
    @required()
    pageTypes: PageType[];
    //#endregion pageTypes Prop
    //#region eventHonoree Prop
    @prop()
    @required()
    eventHonoree: EventHonoree[];
    //#endregion eventHonoree Prop
    //#region eventTemplate Prop
    eventTemplate: EventTemplateBase[];
    //#endregion eventTemplate Prop
    //#region adIntakeForms Prop
    adIntakeForms: AdIntakeFormBase[];
    //#endregion adIntakeForms Prop

    //#endregion Generated Reference Properties



}