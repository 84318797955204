import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventTypeLookupBase {

//#region eventTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventTypeId', keyColumn: false})
        eventTypeId : string;
//#endregion eventTypeId Prop


//#region eventType Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventType', keyColumn: false})
        eventType : string;
//#endregion eventType Prop

}