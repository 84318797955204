import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Router, ActivatedRoute } from '@angular/router';
import { RxHttp } from '@rxweb/http';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { AbstractAdIntakeForm } from './domain/abstract-ad-intake-form';
import { EventLookupGroup } from 'src/app/viewModel/event-lookup-group';
import { EVENT_LOOKUPS } from 'src/app/const/uris/event-lookups.uri';
import { List } from '@rxweb/generics';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@Component({
    selector: "app-email-modal",
    templateUrl: './email-modal.component.html'
})

export class EmailModalComponent extends AbstractAdIntakeForm implements OnInit {
    // @Input() componentType:any;
    @Input() hide: Function;
    @Input() iswipGrid: boolean = false;
    @Input() componentType: any;
    @Input() eventId: any;
    showDeleteComponent: boolean;
    showComponent: boolean;
    adIntakeFormId: number;
    emailDetail: any;
    radioSelected: any;

    constructor(private formBuilder: RxFormBuilder, activatedRoute: ActivatedRoute,
        private route: Router, private http: RxHttp, private apiCallService: ApiCallService) {
        super(activatedRoute);

        activatedRoute.params.subscribe(t => {
            this.adIntakeFormId = t[CONSTANT_MESSAGES.adIntakeFormId];
        })
    }

    ngOnInit(): void {
        var emailData = new List<EmailData>();
        this.adIntakeFormId = this.componentType;

        if (this.iswipGrid) {
            this.lookup<EventLookupGroup>([
                { path: EVENT_LOOKUPS["wipEmailTemplateLookUps"], propName: "wipEmailTemplateLookUps" }
            ]).subscribe(Response => {
                var emailData = new List<EmailData>();
                // this.emailDetail = Response.wipEmailTemplateLookUps;

                this.apiCallService.getByIdAPI(APPLICATION_URL_CONSTANTS.events + this.eventId + APPLICATION_URL_CONSTANTS.adIntakeForms, this.adIntakeFormId, { "category": "getWIPTemplate" })
                    .subscribe(data => {
                        this.spin = false;

                        if (data != null)
                            this.emailDetail = data;
                    })
            });
        } else {
            this.checkTemplate();
        }

        this.showComponent = true;
    }

    checkTemplate(): any {
        var emailData = new List<EmailData>();

        this.apiCallService.getByIdAPI(APPLICATION_URL_CONSTANTS.events + this.eventId + APPLICATION_URL_CONSTANTS.adIntakeForms, this.adIntakeFormId, { "category": "getTemplateId" })
            .subscribe(data => {
                this.spin = false;

                if (data != null)
                    this.emailDetail = data;
            })
    }

    selectTemplate(): any {
        this.hide(this.radioSelected);
        // this.hide(id);
    }

    ngOnDestroy(): void {

    }
}

class EmailData {
    public emailTemplateId: number;
    public name: string;
    public isDisabled: boolean;
}