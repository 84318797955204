import {vAdCreatorLookupBase} from '../database-models/v-ad-creator-lookup-base';
//Generated Imports
export class vAdCreatorLookup extends vAdCreatorLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}