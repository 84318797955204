import { Component, OnInit, OnDestroy } from '@angular/core';
import { OTPModel, UserCredentialModel } from '../../../viewModel/user-credential-model';
import { Observable, Subscription, from, interval, pipe } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { LoginService } from '../login.service';
import { ApplicationBroadcaster } from '../../../temp-service/application-broadcaster';
import { BaseToastr } from '../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../domain/customize-design/dialog';

import { Router } from '@angular/router';
import { anonymous, middleware } from '@rxweb/angular-router'
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { WIPpathName } from 'src/app/components/start/app.component';
import { SetSystemTimeout } from 'src/app/enums/set-system-timeout';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';
import { map } from 'rxjs/operators';

// @middleware([LoggedInMiddleware])
@anonymous()

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent extends CoreComponent implements OnInit {
  spin: boolean = false;
  isApplied: boolean = false;
  timeOutId: number;
  userCredentialModel: UserCredentialModel;
  OTPModel: OTPModel;
  userCredentialFormGroup: IFormGroup<UserCredentialModel>;
  otpFormGroup: IFormGroup<OTPModel>;
  subscription: Subscription;
  currentYear: Date = new Date();
  showComponent: boolean = false;
  toastr: BaseToastr = new BaseToastr();
  dialog: BaseDialog;
  isOtpExpire: boolean = false;
  isUserVerified = false; //this will be true if credentials match and OTP generated.
  otpExpirationTime: Date;
  remainingTime$: Observable<string>;
  resendTimer$: Subscription;
  resendTimerCountdown: number = 0;


  constructor(private route: Router, private applicationBroadCaster: ApplicationBroadcaster, private loginService: LoginService,
    private formBuilder: RxFormBuilder, private storagedata: BrowserStorage) {
    super();

    this.storagedata = new BrowserStorage();
    this.showComponent = true;
  }

  ngOnInit(): void {
    this.userCredentialModel = new UserCredentialModel();
    this.OTPModel = new OTPModel();
    this.userCredentialFormGroup = this.formBuilder.formGroup(this.userCredentialModel) as IFormGroup<UserCredentialModel>;
    this.otpFormGroup = this.formBuilder.formGroup(this.OTPModel) as IFormGroup<OTPModel>;

    this.spin = false;

    var auth = this.storagedata.local.get(CONSTANT_MESSAGES.auth, false);

    var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

    if (auth) {
      element.classList.remove("login_page_view");

      this.route.navigate([CONSTANT_MESSAGES.dashboardPath])
    }

    this.timeOutId = window.setTimeout(() => {
      window.clearTimeout(this.timeOutId);

      this.isApplied = true;
      this.showComponent = true;
    }, 500)
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  login(): void {
    this.userCredentialFormGroup.submitted = true;
    this.isApplied = false;
    if (this.userCredentialFormGroup.valid) {
      if (this.isUserVerified && this.otpFormGroup.valid) {
        this.spin = true;
        let formValue = { ...this.userCredentialFormGroup.value, ...this.otpFormGroup.value }
        this.loginService.login(formValue).subscribe(response => {
          if (response.failedLogin) {
            console.log("Login Failed")
            this.toastr.error(response.validationMessage);

          }
          else {
            console.log("Login Sucesss")
            this.showComponent = false;
            //document.cookie = "requestContext='abc'";
            this.storagedata.local.save(CONSTANT_MESSAGES.auth, response.token, false);
            this.storagedata.local.save(CONSTANT_MESSAGES.key, response.key, false);
            this.storagedata.local.save(CONSTANT_MESSAGES.userName, response.fullName);
            this.storagedata.local.save(CONSTANT_MESSAGES.roleId, response.roleId);

            if (response.resetPasswordTime != null && response.resetPasswordTime != undefined)
              this.storagedata.local.save(CONSTANT_MESSAGES.resetPasswordTime, response.resetPasswordTime)

            this.toastr.success(ValidationMessage.loginSuccessful);

            this.timeOutId = window.setTimeout(() => {
              window.clearTimeout(this.timeOutId);

              this.applicationBroadCaster.loginBroadCast(true);
              this.applicationBroadCaster.userNameBroadCast(response.fullName);
            }, 50);

            if (response.resetPasswordTime == null || response.resetPasswordTime == undefined) {
              // window.location.href = "/changepassword"
              this.route.navigateByUrl(CONSTANT_MESSAGES.changePassword);
            }
            else {
              let diff = this.date_diff_indays(new Date(response.resetPasswordTime), new Date())
              let time = SetSystemTimeout.chanpasswordTime;

              if (diff > time) {
                //window.location.href = "/changepassword"
                this.route.navigateByUrl(CONSTANT_MESSAGES.changePassword);
              }
              else if (WIPpathName != null && WIPpathName != undefined) {
                //window.location.href = WIPpathName;
                this.route.navigateByUrl(WIPpathName);
              }
            }
          }
          this.spin = false;
        })
      }
      else if (!this.isUserVerified) {
        //OTP generation
        this.generateOTP();
      }
      else if (this.isUserVerified && !this.otpFormGroup.valid) {
        this.otpFormGroup.markAllAsTouched();
      }
    }
    else {
    }
  }

  generateOTP() {
    this.spin = true;
    this.loginService.verifyUser(this.userCredentialFormGroup.value).subscribe(res => {
      if (res.failedLogin) {
        console.log("OTP sent failed")
        this.toastr.error(res.validationMessage);
      }
      else {
        console.log("OTP sent sucess")
        this.startResendTimer();
        this.isOtpExpire = false;
        this.isUserVerified = true;
        this.otpExpirationTime = new Date(res.otpExpirationTime);
        this.remainingTime$ = interval(1000).pipe(
          map(() => this.calculateRemainingTime())
        );
        this.spin = false;
      }
    });
  }

  date_diff_indays(date1, date2) {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);

    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }

  forgotPassword(): void {
    this.route.navigate([CONSTANT_MESSAGES.forgotPassword])
  }

  calculateRemainingTime(): string {
    this.isOtpExpire = false;
    if (!this.otpExpirationTime) {
      this.isOtpExpire = true;
      return '00:00';
    }

    const now = new Date();
    const timeDifference = this.otpExpirationTime.getTime() - now.getTime();
    if (timeDifference <= 0) {
      this.isOtpExpire = true;
      return '00:00';
    }

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  startResendTimer() {
    this.resendTimerCountdown = 120; // 2 minutes in seconds

    // Start the timer
    this.resendTimer$ = interval(1000).subscribe(() => {
      if (this.resendTimerCountdown > 0) {
        this.resendTimerCountdown--;
      }
    });
  }
  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    const minutesDisplay: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsDisplay: string = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutesDisplay}:${secondsDisplay}`;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


}
