import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from '../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../domain/customize-design/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { anonymous } from '@rxweb/angular-router'
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { RxHttp } from '@rxweb/http';
import { UserCredentialResetPasswordModel } from 'src/app/viewModel/user-credential-reset-password';
import { User } from '@app/models';
import { ApiCallService } from 'src/app/temp-service/api-call-service';
import { APPLICATION_URL_CONSTANTS } from 'src/app/const/uris/application-url-constants';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';
import { ValidationMessage } from 'src/app/const/validation-msg/custom-validation';

@anonymous()

@Component({
    selector: 'app-login',
    templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent extends CoreComponent implements OnInit {
    spin: boolean = false;
    isApplied: boolean = false;
    timeOutId: number;
    userCredentialResetPasswordModel: UserCredentialResetPasswordModel;
    userCredentialFormGroup: IFormGroup<UserCredentialResetPasswordModel>;
    subscription: Subscription;
    currentYear: Date = new Date();
    showComponent: boolean = false;
    toastr: BaseToastr = new BaseToastr();
    dialog: BaseDialog;
    accessKey: string;
    email: string;
    user: User

    constructor(private route: Router, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private http: RxHttp, private apiCallService: ApiCallService) {
        super();

        this.activatedRoute.queryParams.subscribe(t => {
            this.accessKey = t[CONSTANT_MESSAGES.activationKey];

            this.email = t[CONSTANT_MESSAGES.emailId];
        })

        this.badRequest;
    }

    ngOnInit(): void {
        this.spin = true;

        this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.forgotPassword, { email: this.email, verificationCode: this.accessKey }).subscribe(response => {
            if (response.StatusCode == Number(CONSTANT_MESSAGES.badRequestStatusCode)) {
                this.toastr.error(response.data);

                this.route.navigateByUrl(CONSTANT_MESSAGES.donor_thankYou_resetPasswordError);
            }
            else {
                let userdetail = JSON.parse(response.data);

                this.userCredentialResetPasswordModel = new UserCredentialResetPasswordModel();
                this.userCredentialResetPasswordModel.email = this.email;
                this.userCredentialResetPasswordModel.verificationCode = this.accessKey;

                this.userCredentialResetPasswordModel.userId = userdetail.UserId;
            }

            this.userCredentialFormGroup = this.formBuilder.formGroup(this.userCredentialResetPasswordModel) as IFormGroup<UserCredentialResetPasswordModel>;

            this.showComponent = true;
            this.spin = false;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    resetPassword() {
        this.spin = true;

        this.apiCallService.putAPI(APPLICATION_URL_CONSTANTS.forgotPassword, this.userCredentialResetPasswordModel, this.userCredentialResetPasswordModel.userId).subscribe(data => {
            this.spin = false;
            this.toastr.success(ValidationMessage.resetPasswordSuccessfully);

            var element = document.getElementById(CONSTANT_MESSAGES.gtrac);

            element.classList.add("login-page-view");
            //this.route.navigate(['/login']);
            window.location.href = CONSTANT_MESSAGES.loginPath
        })
    }

    login(): void {
        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
        element.classList.add("login-page-view");

        window.location.href = CONSTANT_MESSAGES.loginPath
    }

    badRequest = (data) => {
        this.spin = false;

        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);

        var element = document.getElementById(CONSTANT_MESSAGES.gtrac);
        element.classList.add("login-page-view");

        this.route.navigate([CONSTANT_MESSAGES.loginPath]);
    }
}