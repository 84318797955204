import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vWIPListBase {

        //#region intakeTemplateId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'intakeTemplateId', keyColumn: true })
        intakeTemplateId: number;
        //#endregion intakeTemplateId Prop


        //#region adIntakeFormId Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: false })
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region oPSTemplateId Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'opsTemplateId', keyColumn: false })
        oPSTemplateId: boolean;
        //#endregion oPSTemplateId Prop


        //#region oPSTokenId Prop
        @gridColumn({ visible: false, columnIndex: 3, allowSorting: true, headerKey: 'opsTokenId', keyColumn: false })
        oPSTokenId: string;
        //#endregion oPSTokenId Prop


        //#region donorName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'donorName', keyColumn: false, headerTitle: "Donor Name" })
        donorName: string;
        //#endregion donorName Prop


        //#region adName Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'adName', keyColumn: false, headerTitle: "Ad Name" })
        adName: string;
        //#endregion adName Prop


        //#region imageURL Prop
        @gridColumn({ visible: false, columnIndex: 7, allowSorting: true, headerKey: 'imageURL', keyColumn: false, headerTitle: "Image" })
        imageURL: string;
        //#endregion imageURL Prop


        //#region thumbImageURL Prop
        @gridColumn({ class: ["text-center"], visible: true, columnIndex: 8, allowSorting: false, headerKey: 'thumbImageURL', keyColumn: false, headerTitle: "Thumbnail Image", configuredTemplate: { templateName: "thumbnailImage" } })
        thumbImageURL: string;
        //#endregion thumbImageURL Prop


        //#region version Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'version', keyColumn: false, headerTitle: "Version" })
        version: string;
        //#endregion version Prop


        //#region create_new_version Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerKey: 'create_new_version', keyColumn: false })
        create_new_version: string;
        //#endregion create_new_version Prop


        //#region design_url Prop
        @gridColumn({ visible: false, columnIndex: 10, allowSorting: true, headerKey: 'design_url', keyColumn: false })
        design_url: string;
        //#endregion design_url Prop


        //#region pdf_preview_url Prop
        @gridColumn({ visible: false, columnIndex: 11, allowSorting: true, headerKey: 'pdf_preview_url', keyColumn: false })
        pdf_preview_url: string;
        //#endregion pdf_preview_url Prop


        //#region uploadedBy Prop
        @gridColumn({ visible: false, columnIndex: 12, allowSorting: true, headerKey: 'uploadedBy', keyColumn: false, headerTitle: "Uploaded By" })
        uploadedBy: string;
        //#endregion uploadedBy Prop


        //#region uploadDate Prop
        @gridColumn({ visible: true, columnIndex: 13, allowSorting: true, headerKey: 'uploadDate', keyColumn: false, headerTitle: "Upload Date", configuredTemplate: { templateName: "dateTimeFormat" } })
        uploadDate: Date;
        //#endregion uploadDate Prop


        //#region uploadType Prop
        @gridColumn({ visible: true, columnIndex: 14, allowSorting: true, headerKey: 'uploadType', keyColumn: false, headerTitle: "Upload Type" })
        uploadType: string;
        //#endregion uploadType Prop


        //#region adDesignStatus Prop
        @gridColumn({ visible: true, columnIndex: 15, allowSorting: true, headerKey: 'adDesignStatus', keyColumn: false, headerTitle: "Ad Design Status" })
        adDesignStatus: string;
        //#endregion adDesignStatus Prop


        //#region uJAApproved Prop
        @gridColumn({ visible: true, columnIndex: 16, allowSorting: true, headerKey: 'ujaApproved', keyColumn: false, headerTitle: "UJA Approved" })
        ujaApproved: string;
        //#endregion uJAApproved Prop


        //#region donorApproved Prop
        @gridColumn({ visible: true, columnIndex: 17, allowSorting: true, headerKey: 'donorApproved', keyColumn: false, headerTitle: "Donor Approved" })
        donorApproved: string;
        //#endregion donorApproved Prop

        //#region rejectedBy Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'rejectedBy', keyColumn: false, headerTitle: "Rejected By", configuredTemplate: { templateName: "adRejectedBy" } })
        rejectedBy: string;
        //#endregion rejectedBy Prop

        //#region reason Prop
        @gridColumn({ visible: false, columnIndex: 19, allowSorting: true, headerKey: 'reason', keyColumn: false, headerTitle: "Reason" })
        reason: string;
        //#endregion reason Prop

}