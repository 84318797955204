import { TemplateConfig } from '@rxweb/grid';

export const DELETE_BUTTON_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction"],
        authorize: 'delete',
        childrens: [
            {
                li: {
                    childrens: [
                        {
                            a: {
                                class: ["delete"],
                                event: {
                                    click: "onDelete"
                                },
                                childrens: [
                                    {
                                        i: {
                                            class: "fa-fw uja-Delete".split(" ")
                                        }
                                    }
                                ],
                            }
                        }
                    ]
                }
            }
        ]
    }
}