import { prop } from '@rxweb/reactive-form-validators';
import { vEventPageTypeBase } from '../database-models/v-event-page-type-base';
//Generated Imports
export class vEventPageType extends vEventPageTypeBase {
    //#region Generated Reference Properties

    //#region adTypeId Prop
    @prop()
    Sequences: number;
    //#endregion adTypeId Prop

    //#endregion Generated Reference Properties
}