import {UserDivisionBase} from '../database-models/user-division-base';
import {DivisionBase} from '../database-models/division-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class UserDivision extends UserDivisionBase 
{




//#region Generated Reference Properties
//#region division Prop
division : DivisionBase;
//#endregion division Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties







































}