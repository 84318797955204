import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRepeatAdListBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region donorId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'donorId', keyColumn: false})
        donorId : number;
//#endregion donorId Prop


//#region donorName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'donorName', keyColumn: false})
        donorName : string;
//#endregion donorName Prop


//#region divisionName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'divisionName', keyColumn: false})
        divisionName : string;
//#endregion divisionName Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop


//#region applicationObjectName Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'applicationObjectName', keyColumn: false})
        applicationObjectName : string;
//#endregion applicationObjectName Prop


//#region oPSTokenId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'oPSTokenId', keyColumn: false})
        oPSTokenId : string;
//#endregion oPSTokenId Prop

}