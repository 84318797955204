import {IntakeFromHonoreeBase} from '../database-models/intake-from-honoree-base';
import {AdIntakeFormBase} from '../database-models/ad-intake-form-base';
//Generated Imports
export class IntakeFromHonoree extends IntakeFromHonoreeBase 
{




//#region Generated Reference Properties
//#region adIntakeForm Prop
adIntakeForm : AdIntakeFormBase;
//#endregion adIntakeForm Prop

//#endregion Generated Reference Properties







































}