import { required, email, maxLength } from '@rxweb/reactive-form-validators';

export class UserCredentialForgotPasswordModel {
    @required()
    @email()
    @maxLength({value:50})
    email: string = undefined;
    
     //#region userPassword Prop
     @maxLength({value:15})
     userPassword : string;
     //#endregion userPassword Prop
 
     //#region userConfirmPassword Prop
     @maxLength({value:15})
     userConfirmPassword : string;
     //#endregion userConfirmPassword Prop
     
     //#region verificationCode Prop
     verificationCode : string;
     //#endregion verificationCode Prop

     //#region isResetPassword Prop
     IsResetPassword : string;
     //#endregion isResetPassword Prop
}
