import {ApplicationExceptionLogBase} from '../database-models/application-exception-log-base';
//Generated Imports
export class ApplicationExceptionLog extends ApplicationExceptionLogBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}