import { prop, propObject, propArray, required, maxLength, range, minDate, numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventBase {

        //#region eventId Prop
        @prop()
        eventId: number;
        //#endregion eventId Prop


        //#region eventKey Prop
        @required()
        @maxLength({ value: 100 })
        eventKey: string;
        //#endregion eventKey Prop


        //#region campaignYear Prop
        // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        campaignYear: string;
        //#endregion campaignYear Prop


        //#region eventName Prop
        @required()
        @maxLength({ value: 100 })
        eventName: string;
        //#endregion eventName Prop


        //#region eventNumber Prop
        
        @maxLength({ value: 20 })
        eventNumber: string;
        //#endregion eventNumber Prop


        //#region eventLocation Prop
        @required()
        @maxLength({ value: 100 })
        eventLocation: string;
        //#endregion eventLocation Prop


        //#region eventDate Prop
        @required()
        //@minDate({ value: new Date() })
        eventDate: Date;
        //#endregion eventDate Prop


        //#region journalAdDeadlineDate Prop
        @required()
        //@minDate({ value: new Date(), conditionalExpression: function (x) { return x.eventDate > x.journalAdDeadlineDate } })
        //@minDate({value: new Date(), conditionalExpression:'x => x.eventDate > x.journalAdDeadlineDate' }) 
        journalAdDeadlineDate: Date;
        //#endregion journalAdDeadlineDate Prop


        //#region eventType Prop
        @maxLength({ value: 50 })
        eventType: string;
        //#endregion eventType Prop


        //#region adSpec Prop
        // @maxLength({ value: 500 })
        @required()
        adSpec: string;
        //#endregion adSpec Prop


        //#region fundraiserId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        fundraiserId: number;
        //#endregion fundraiserId Prop


        //#region fundriserPhone Prop
        @maxLength({ value: 20 })
        fundriserPhone: string;
        //#endregion fundriserPhone Prop


        //#region eventManagerId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        eventManagerId: number;
        //#endregion eventManagerId Prop


        //#region eventManagerPhone Prop
        @maxLength({ value: 20 })
        eventManagerPhone: string;
        //#endregion eventManagerPhone Prop


        //#region adCreatorId Prop
        @prop()
        adCreatorId: number;
        //#endregion adCreatorId Prop


        //#region adCreatorPhone Prop
        @maxLength({ value: 20 })
        adCreatorPhone: string;
        //#endregion adCreatorPhone Prop


        //#region otherManager Prop
        @prop()
        otherManager: number;
        //#endregion otherManager Prop


        //#region otherManagerPhone Prop
        @maxLength({ value: 20 })
        otherManagerPhone: string;
        //#endregion otherManagerPhone Prop


        //#region divisionId Prop
        @prop()
        @required()
        divisionId: number;
        //#endregion divisionId Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number = 1;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        createdOn: any = new Date(Date.UTC(96, 1, 2, 3, 4, 5));
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop











}