import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { AdIntakeFormModal } from './ad-intake-form-modal.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [AdIntakeFormModal],
    exports: [],
    providers: [],
    entryComponents: [AdIntakeFormModal]
})

export class AdIntakeFormModalSharedModule { }