import {vEmailTemplateInternalExternalLookupBase} from '../database-models/v-email-template-internal-external-lookup-base';
//Generated Imports
export class vEmailTemplateInternalExternalLookup extends vEmailTemplateInternalExternalLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}