import { ModuleContentBase } from '../database-models/module-content-base';
import { ApplicationModuleBase } from '../database-models/application-module-base';
//Generated Imports
export class ModuleContent extends ModuleContentBase {

    //#region Generated Reference Properties

    //#region applicationModule Prop
    applicationModule: ApplicationModuleBase;
    //#endregion applicationModule Prop

    //#endregion Generated Reference Properties

}