import { IntakeTemplateBase, IntakeFromAdDesignStatusBase, TemplateApprovalBase, } from '@app/database-models'
//Generated Imports
export class IntakeTemplate extends IntakeTemplateBase {

    //#region Generated Reference Properties

    //#region intakeFromAdDesignStatuses Prop
    intakeFromAdDesignStatuses: IntakeFromAdDesignStatusBase[];
    //#endregion intakeFromAdDesignStatuses Prop

    //#region templateApprovals Prop
    templateApprovals: TemplateApprovalBase[];
    //#endregion templateApprovals Prop

    //#region templateApprovals Prop
    fromOPS: boolean;
    //#endregion templateApprovals Prop

    //#region templateApprovals Prop
    fromDonor: boolean;
    //#endregion templateApprovals Prop

    //#region templateApprovals Prop
    sendApprovalId: number;
    //#endregion templateApprovals Prop

    //#region templateApprovals Prop
    comments: string;
    //#endregion templateApprovals Prop

    //#region templateApprovals Prop
    verificationCode: string;
    //#endregion templateApprovals Prop

    //#region templateApprovals Prop
    datasendToAWS: string
    //#endregion templateApprovals Prop

    //#region emailTemplateId Prop
    emailTemplateId: number
    //#endregion emailTemplateId Prop

    //#endregion Generated Reference Properties

}