import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractEmailTemplate } from '../domain/abstract-email-template';
import { EmailTemplate, vEmailTemplate } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { RoleGrid } from 'src/app/custom-templates/grid/footer-hide';
import { access } from '@rxweb/angular-router';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@access({ accessLevel: RolePermissionModule.EmailTemplates, action: ActionType.GET })

@Component({
    selector: "app-email-template-list",
    templateUrl: './email-template-list.component.html'
})

export class EmailTemplateListComponent extends AbstractEmailTemplate implements OnInit, OnDestroy {
    emailTemplates: vEmailTemplate;
    subscription: Subscription;
    emailTemplatesGrid: RoleGrid;
    emailTemplatesGridColumns: any[];
    searchValue: string;

    constructor(private router: Router, private apiCallService: ApiCallService) {
        super();
    }

    ngOnInit(): void {
        this.bindGrid();
    }

    bindGrid() {
        this.spin = true;

        this.subscription = this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.emailTemplates).subscribe((t: any) => {
            this.emailTemplates = t;
            this.spin = false;
            this.emailTemplatesGrid = new RoleGrid(t, vEmailTemplate, { actions: { onEdit: this.onEdit.bind(this) } });
            this.emailTemplatesGrid.authorization = {
                'edit': {
                    accessLevel: RolePermissionModule.EmailTemplates,
                    action: ActionType.PUT
                }
            };
            this.emailTemplatesGrid.maxPerPage = 50;
            this.emailTemplatesGrid.design(document.getElementById(CONSTANT_MESSAGES.emailTemplates));
            this.emailTemplatesGridColumns = this.emailTemplatesGrid.gridColumns.filter(x => x.keyColumn == false);
            this.showComponent = true;
        })
    }

    onEdit(emailTemplates: vEmailTemplate) {
        this.router.navigate([CONSTANT_MESSAGES.emailTemplatesPath, emailTemplates.emailTemplateId]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    search(value) {
        this.searchValue = value;
        this.emailTemplatesGrid.search = this.searchValue;
    }
}