import { TemplateConfig } from '@rxweb/grid';
import { ACTION_GRID_TEMPLATE } from './action';
import { EVENT_ACTION_GRID_TEMPLATE } from './eventAction';
import { RECORD_ACTIVE_GRID_TEMPLATE } from './record-active';
import { REDIRECT_LINK_GRID_TEMPLATE } from './redirect-link';
import { ADINTAKEFORM_GRID_TEMPLATE } from './adIntakeFormAction';
import { EMAIL_NOTIFICATION_GRID_TEMPLATE } from './emailNotificationAction';
import { EVENT_STATUS_GRID_TEMPLATE } from './event-status';
import { Thumbnail_Image_GRID_TEMPLATE } from './thumbnail-image';
import { WIP_Action_GRID_TEMPLATE } from './wipAction';
import { DELETE_ACTION_GRID_TEMPLATE } from './deleteAction';
import { EVENT_VIEW_ACTION_GRID_TEMPLATE } from './eventViewAction';
import { CHECKBOX_TEMPLATE_GRID_TEMPLATE } from './checkbox-template';
import { DELETE_BUTTON_TEMPLATE } from './adIntakeFormDeleteTemplate';
import { Date_FORMAT_GRID_TEMPLATE } from './grid-dateformate';
import { Date_TIME_FORMAT_GRID_TEMPLATE } from './grid-date-time-formate';
import { HEADER_CHECKBOX_GRID_TEMPLATE } from './header-checkbox-template';
import { MANDATORY_CHECKBOX_GRID_TEMPLATE } from './mandatory-Checkbox-Template';
import { USER_ACTION_GRID_TEMPLATE } from './user-action';
import { USER_DIVISION_TEXT_TEMPLATE } from './user-division-text-template';
import { NUMBER_DISPLAY } from './number-display';
import { AMOUNT_FORMAT_GRID_TEMPLATE } from './grid-amount';
import { REDIRECT_LINK_GRID_TEMPLATE_INTAKEFORM } from './redirect-link-intakeform';
import { REDIRECT_LINK_GRID_TEMPLATE_WIP } from './redirect-link-wip';
import { REDIRECT_LINK_NOTIFICATION_GRID_TEMPLATE } from './redirect-link-notification';
import { ACTION_GRID_TEMPLATE_EMAIL_TEMPLATE } from './action-email-template';
import { REDIRECT_LINK_APPROVAL_GRID_TEMPLATE } from './redirect-link-approval';
import { AD_REJECTED_BY_GRID_TEMPLATE } from './ad-rejected-by';
import { PINNED_EVENT_TEMPLATE } from './pinnedEvent-template';

export const GRID_CUSTOM_TEMPLATES: { [key: string]: TemplateConfig } = {
    action: ACTION_GRID_TEMPLATE,
    actionEmaiTemplate: ACTION_GRID_TEMPLATE_EMAIL_TEMPLATE,
    eventAction: EVENT_ACTION_GRID_TEMPLATE,
    adIntakeFormAction: ADINTAKEFORM_GRID_TEMPLATE,
    recordActive: RECORD_ACTIVE_GRID_TEMPLATE,
    redirectLink: REDIRECT_LINK_GRID_TEMPLATE,
    redirectLinkIntakeForm: REDIRECT_LINK_GRID_TEMPLATE_INTAKEFORM,
    redirectLinkWIP: REDIRECT_LINK_GRID_TEMPLATE_WIP,
    emailNotificationAction: EMAIL_NOTIFICATION_GRID_TEMPLATE,
    eventStatus: EVENT_STATUS_GRID_TEMPLATE,
    thumbnailImage: Thumbnail_Image_GRID_TEMPLATE,
    wipAction: WIP_Action_GRID_TEMPLATE,
    deleteAction: DELETE_ACTION_GRID_TEMPLATE,
    eventViewAction: EVENT_VIEW_ACTION_GRID_TEMPLATE,
    chckboxTemplate: CHECKBOX_TEMPLATE_GRID_TEMPLATE,
    pinnedEventTemplate: PINNED_EVENT_TEMPLATE,
    deleteButtonTemplate: DELETE_BUTTON_TEMPLATE,
    dateFormat: Date_FORMAT_GRID_TEMPLATE,
    dateTimeFormat: Date_TIME_FORMAT_GRID_TEMPLATE,
    bindCheckBox: HEADER_CHECKBOX_GRID_TEMPLATE,
    userAction: USER_ACTION_GRID_TEMPLATE,
    mandatoryCheckboxTemplate: MANDATORY_CHECKBOX_GRID_TEMPLATE,
    userChildDivision: USER_DIVISION_TEXT_TEMPLATE,
    numberDisplay: NUMBER_DISPLAY,
    amountFormat: AMOUNT_FORMAT_GRID_TEMPLATE,
    redirectNotificationLink: REDIRECT_LINK_NOTIFICATION_GRID_TEMPLATE,
    redirectApprovalLink: REDIRECT_LINK_APPROVAL_GRID_TEMPLATE,
    adRejectedBy: AD_REJECTED_BY_GRID_TEMPLATE
}
