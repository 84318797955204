import { TemplateConfig } from '@rxweb/grid';


export const USER_DIVISION_TEXT_TEMPLATE: TemplateConfig = {
    div: {
        class: ["divi"],
        childrens: [{
            span: {
                childrens: [{
                    span: {
                        attributes:{
                           style:" font-weight: bold",
                        },
                        childrens: [{
                            text: {
                                text: "Division: "
                            }
                        }]
                    }
                },
                {
                    span: {
                        childrens: [{
                            text: {
                                text:  function (e) { return this[e.name]}
                            }
                        }]
                    }
                }]
            }

        }]
    }
};
