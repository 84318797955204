import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPaginationDefaultBase {

//#region houseAndBrandAdsDignitariesLettersId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'houseAndBrandAdsDignitariesLettersId', keyColumn: true})
        houseAndBrandAdsDignitariesLettersId : number;
//#endregion houseAndBrandAdsDignitariesLettersId Prop


//#region brandTypeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'brandTypeId', keyColumn: false})
        brandTypeId : number;
//#endregion brandTypeId Prop


//#region sequence Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'sequence', keyColumn: false})
        sequence : number;
//#endregion sequence Prop


//#region imageURL Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'imageURL', keyColumn: false})
        imageURL : string;
//#endregion imageURL Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'modifiedBy', keyColumn: false})
        modifiedBy : any;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'modifiedOn', keyColumn: false})
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region brandType Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'brandType', keyColumn: false})
        brandType : string;
//#endregion brandType Prop


//#region imageData Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'imageData', keyColumn: false})
        imageData : string;
//#endregion imageData Prop

}