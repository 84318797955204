import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventRecordBase {

        //#region eventId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'eventId', keyColumn: true })
        eventId: number;
        //#endregion eventId Prop


        //#region campaignYear Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'campaignYear', keyColumn: false })
        campaignYear: string;
        //#endregion campaignYear Prop


        //#region campaignYearName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'campaignYearName', keyColumn: false })
        campaignYearName: string;
        //#endregion campaignYearName Prop


        //#region divisionId Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'divisionId', keyColumn: false })
        divisionId: any;
        //#endregion divisionId Prop


        //#region divisionName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'divisionName', keyColumn: false })
        divisionName: string;
        //#endregion divisionName Prop


        //#region eventKey Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'eventKey', keyColumn: false })
        eventKey: string;
        //#endregion eventKey Prop


        //#region eventName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'eventName', keyColumn: false })
        eventName: string;
        //#endregion eventName Prop


        //#region eventNumber Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'eventNumber', keyColumn: false })
        eventNumber: string;
        //#endregion eventNumber Prop


        //#region eventDate Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'eventDate', keyColumn: false })
        eventDate: any;
        //#endregion eventDate Prop


        //#region journalAdDeadlineDate Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'journalAdDeadlineDate', keyColumn: false })
        journalAdDeadlineDate: any;
        //#endregion journalAdDeadlineDate Prop


        //#region eventLocation Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'eventLocation', keyColumn: false })
        eventLocation: string;
        //#endregion eventLocation Prop


        //#region eventManagerId Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'eventManagerId', keyColumn: false })
        eventManagerId: number;
        //#endregion eventManagerId Prop


        //#region eventManager Prop
        @gridColumn({ visible: true, columnIndex: 10, allowSorting: true, headerKey: 'eventManager', keyColumn: false })
        eventManager: string;
        //#endregion eventManager Prop


        //#region eventManagerEmail Prop
        @gridColumn({ visible: true, columnIndex: 11, allowSorting: true, headerKey: 'eventManagerEmail', keyColumn: false })
        eventManagerEmail: string;
        //#endregion eventManagerEmail Prop


        //#region eventManagerPhone Prop
        @gridColumn({ visible: true, columnIndex: 14, allowSorting: true, headerKey: 'eventManagerPhone', keyColumn: false })
        eventManagerPhone: string;
        //#endregion eventManagerPhone Prop


        //#region adSpec Prop
        @gridColumn({ visible: true, columnIndex: 12, allowSorting: true, headerKey: 'adSpec', keyColumn: false })
        adSpec: string;
        //#endregion adSpec Prop


        //#region eventType Prop
        @gridColumn({ visible: true, columnIndex: 13, allowSorting: true, headerKey: 'eventType', keyColumn: false })
        eventType: string;
        //#endregion eventType Prop


        //#region fundraiserId Prop
        @gridColumn({ visible: true, columnIndex: 14, allowSorting: true, headerKey: 'fundraiserId', keyColumn: false })
        fundraiserId: number;
        //#endregion fundraiserId Prop


        //#region eventFundriser Prop
        @gridColumn({ visible: true, columnIndex: 15, allowSorting: true, headerKey: 'eventFundriser', keyColumn: false })
        eventFundriser: string;
        //#endregion eventFundriser Prop


        //#region eventFundriserEmail Prop
        @gridColumn({ visible: true, columnIndex: 16, allowSorting: true, headerKey: 'eventFundriserEmail', keyColumn: false })
        eventFundriserEmail: string;
        //#endregion eventFundriserEmail Prop


        //#region fundriserPhone Prop
        @gridColumn({ visible: true, columnIndex: 20, allowSorting: true, headerKey: 'fundriserPhone', keyColumn: false })
        fundriserPhone: string;
        //#endregion fundriserPhone Prop


        //#region adCreatorId Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'adCreatorId', keyColumn: false })
        adCreatorId: any;
        //#endregion adCreatorId Prop


        //#region adCreatorName Prop
        @gridColumn({ visible: true, columnIndex: 19, allowSorting: true, headerKey: 'adCreatorName', keyColumn: false })
        adCreatorName: string;
        //#endregion adCreatorName Prop


        //#region adCreatorEmail Prop
        @gridColumn({ visible: true, columnIndex: 20, allowSorting: true, headerKey: 'adCreatorEmail', keyColumn: false })
        adCreatorEmail: string;
        //#endregion adCreatorEmail Prop


        //#region adCreatorPhone Prop
        @gridColumn({ visible: true, columnIndex: 21, allowSorting: true, headerKey: 'adCreatorPhone', keyColumn: false })
        adCreatorPhone: string;
        //#endregion adCreatorPhone Prop


        //#region otherManager Prop
        @gridColumn({ visible: true, columnIndex: 25, allowSorting: true, headerKey: 'otherManager', keyColumn: false })
        otherManager: any;
        //#endregion otherManager Prop


        //#region otherManagerName Prop
        @gridColumn({ visible: true, columnIndex: 23, allowSorting: true, headerKey: 'otherManagerName', keyColumn: false })
        otherManagerName: string;
        //#endregion otherManagerName Prop


        //#region otherManagerEmail Prop
        @gridColumn({ visible: true, columnIndex: 24, allowSorting: true, headerKey: 'otherManagerEmail', keyColumn: false })
        otherManagerEmail: string;
        //#endregion otherManagerEmail Prop


        //#region otherManagerPhone Prop
        @gridColumn({ visible: true, columnIndex: 25, allowSorting: true, headerKey: 'otherManagerPhone', keyColumn: false })
        otherManagerPhone: string;
        //#endregion otherManagerPhone Prop


        //#region statusId Prop
        @gridColumn({ visible: true, columnIndex: 26, allowSorting: true, headerKey: 'statusId', keyColumn: false })
        statusId: number;
        //#endregion statusId Prop


        //#region createdOn Prop
        @gridColumn({ visible: true, columnIndex: 30, allowSorting: true, headerKey: 'createdOn', keyColumn: false })
        createdOn: any;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @gridColumn({ visible: true, columnIndex: 31, allowSorting: true, headerKey: 'createdBy', keyColumn: false })
        createdBy: number;
        //#endregion createdBy Prop


        //#region eventHonoree Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'eventHonoree', keyColumn: false })
        eventHonoree: string;
        //#endregion eventHonoree Prop


        //#region pageType Prop
        @gridColumn({ visible: true, columnIndex: 28, allowSorting: true, headerKey: 'pageType', keyColumn: false })
        pageType: string;
        //#endregion pageType Prop

}