import { vWIPListBase } from '../database-models/v-w-i-p-list-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';

@actionColumn({
    name: 'wipAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'wipAction' }, columnIndex: 0, headerTitle: "Action", class: "actionFild".split(' ')
})

export class vWIPList extends vWIPListBase {
    //#region Generated Reference Properties
    isSendDisabled: boolean = false;
    //#endregion Generated Reference Properties

    //#region Generated Reference Properties
    isEditDisabled: boolean = false;
    //#endregion Generated Reference Properties
}