import { TemplateConfig } from '@rxweb/grid';

export const BIND_EXCEL_BUTTON: TemplateConfig = {
    div: {
        class: ["btn-group","ml-2"],
        childrens: [
            {
                div: {
                    event: {
                        click:"exportToExcel"
                    },
                    class: ["btn", "btnGroup", "bg-white"],
                    attributes: {
                        title: "Export to Excel"
                    },
                    childrens: [
                        {
                            i: {
                                class: ["fa", "fa-file-excel-o", "text-light-green", "pr-1"]
                            },
                            text: {
                                text:"Excel"
                            }
                        }
                    ]
                }
            }
        ]
    }
}
