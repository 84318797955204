import {vUserEmailTemplateBase} from '../database-models/v-user-email-template-base';
//Generated Imports
export class vUserEmailTemplate extends vUserEmailTemplateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}