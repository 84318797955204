import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDivisionLookupBase {

//#region divisionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'divisionId', keyColumn: true})
        divisionId : number;
//#endregion divisionId Prop


//#region divisionName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'divisionName', keyColumn: false})
        divisionName : string;
//#endregion divisionName Prop

}