import { vUserRecordBase } from '../database-models/v-user-record-base';
//Generated Imports
import { required, prop } from '@rxweb/reactive-form-validators';

export class vUserRecord extends vUserRecordBase {

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties


    //#region isMyProfile Prop
    @required()
    status: boolean = false;
    //#endregion isMyProfile Prop

}