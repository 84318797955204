import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { DomSanitizer } from '@angular/platform-browser';
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@Component({
    selector: "app-image-modal",
    templateUrl: './image-modal.component.html'
})

export class ImageModalComponent implements OnInit {
    // @Input() componentType:any;
    @Input() hide: Function;
    @Input() Image: any;
    @Input() Title: string;
    imagePdfUrl: ImagePdfUrl
    showComponent: boolean;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.Image
        this.imagePdfUrl = new ImagePdfUrl();

        if (this.Image.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.ivbor || this.Image.substring(0, 5) == CONSTANT_MESSAGES.subStringOfPNG) {
            this.imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePngAndBase64 + this.Image);
            this.imagePdfUrl.imgShowOrNot = true;
            this.imagePdfUrl.pdfShowOrNot = false;
        } else if (this.Image.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.subStringOfJPEG) {
            this.imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimeJpegAndBase64 + this.Image);
            this.imagePdfUrl.imgShowOrNot = true;
            this.imagePdfUrl.pdfShowOrNot = false;
        } else if (this.Image.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.jvber) {
            this.imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + this.Image);
            this.imagePdfUrl.imgShowOrNot = false;
            this.imagePdfUrl.pdfShowOrNot = true;
        } else if (this.Image.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.sukqa) {
            this.imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimeTiffAndBase64 + this.Image);
            this.imagePdfUrl.imgShowOrNot = true;
            this.imagePdfUrl.pdfShowOrNot = false;
        }

        this.showComponent = true;
    }

    ngOnDestroy(): void {
    }
}

class ImagePdfUrl {
    url: any;
    pdfShowOrNot: boolean;
    imgShowOrNot: boolean;
}