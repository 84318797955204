import { TemplateConfig } from '@rxweb/grid';

export const EVENT_VIEW_ACTION_GRID_TEMPLATE: TemplateConfig = {
    ul: {
        class: ["formAction"],
        childrens: [
            {
                li: {
                    authorize: "adManagementPer",
                    childrens: [
                        {
                            a: {
                                class: ["ad-management"],
                                event: {
                                    click: "adManagement"
                                },
                                childrens: [
                                    {
                                        i: {
                                            class: "fa fa-file-o".split(" ")
                                        }
                                    }
                                ],
                            }
                        }
                    ]
                }
            }
        ]
    }
}