import { gridColumn } from '@rxweb/grid';
import { actionColumn } from '@rxweb/grid';

export class AdDesignStatusReportModel {

    //#region adIntakeFormId Prop
    @gridColumn({  isAscending: true, visible: false, columnIndex: 0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true })
    adIntakeFormId: number;
    //#endregion adIntakeFormId Prop


    //#region adDesignStatus Prop
    @gridColumn({ headerCellClass:["left-0","w-250"],class:["left-0","w-250"],isAscending: true, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'adDesignStatus', keyColumn: false, headerTitle: "Ad Design Status" })
    adDesignStatus: string;
    //#endregion adDesignStatus Prop

    //#region adType Prop
    @gridColumn({ headerCellClass:["left-250","w-250"],class:["left-250","w-250"],isAscending: true, visible: true, columnIndex: 2, allowSorting: true, headerKey: 'adType', keyColumn: false, headerTitle: "Ad Type" })
    adType: string;
    //#endregion adType Prop

    //#region pageType Prop
    @gridColumn({ headerCellClass:["left-500","w-200"],class:["left-500","w-200"],isAscending: true, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'pageType', keyColumn: false, headerTitle: "Page Type" })
    pageType: number;
    //#endregion pageType Prop

    //#region journalAdAlphaName Prop
    @gridColumn({ isAscending: true, visible: false, columnIndex: 4, allowSorting: true, headerKey: 'journalAdAlphaName', keyColumn: false, headerTitle: "Journal Ad Alpha Name" })
    journalAdAlphaName: string;
    //#endregion journalAdAlphaName Prop

    //#region donorId Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'donorId', keyColumn: false, headerTitle: "Donor ID", configuredTemplate: { templateName: "numberDisplay" } })
    donorId: number;
    //#endregion donorId Prop

    //#region donorName Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'donorName', keyColumn: false, headerTitle: "Donor Name" })
    donorName: string;
    //#endregion donorName Prop

    //#region relationshipOwner Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 7, allowSorting: true, headerKey: 'relationshipOwner', keyColumn: false, headerTitle: "Relationship Owner" })
    relationshipOwner: string;
    //#endregion relationshipOwner Prop

    //#region adContactName Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 8, allowSorting: true, headerKey: 'adContactName', keyColumn: false, headerTitle: "Ad Contact Name" })
    adContactName: number;
    //#endregion adContactName Prop

    //#region adContactPhone Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 9, allowSorting: true, headerKey: 'adContactPhone', keyColumn: false, headerTitle: "Ad Contact Phone" })
    adContactPhone: string;
    //#endregion adContactPhone Prop

    //#region adContactEmailAddress Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 10, allowSorting: true, headerKey: 'adContactEmailAddress', keyColumn: false, headerTitle: "Ad Contact Email Address" })
    adContactEmailAddress: string;
    //#endregion adContactEmailAddress Prop

     //#region mostRecentEmailSent Prop
     @gridColumn({ isAscending: true, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'mostRecentEmailSent', keyColumn: false, headerTitle: "Most Recent Email Sent" })
     mostRecentEmailSent: Date;
     //#endregion mostRecentEmailSent Prop

     //#region emailSentDate Prop
     @gridColumn({ isAscending: true, visible: true, columnIndex: 12, allowSorting: true, headerKey: 'emailSentDate', keyColumn: false, headerTitle: "Email Sent Date", configuredTemplate: { templateName: "dateTimeFormat" } })
     emailSentDate: Date;
     //#endregion emailSentDate Prop

}