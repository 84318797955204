import { prop } from "@rxweb/reactive-form-validators";
import { gridColumn } from "@rxweb/grid"
export class ApplicationDataBase {

        //#region adIntakeFormId Prop
        @prop()
        applicationDataId: number;
        //#endregion adIntakeFormId Prop


        //#region donorId Prop
        @prop()
        keyName: string;
        //#endregion donorId Prop


        //#region donorName Prop
        @prop()
        keyValue: string;
        //#endregion donorName Prop


}