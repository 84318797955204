import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractReportTemplate } from '../domain/abstract-report';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { spParameter, SearchQuery, downloadDocument, QueryForDownload, Query, PdfExcelDetail } from 'src/app/viewModel/spGridClass';
import { GridWithExcelPdf } from 'src/app/custom-templates/grid/add-excel-pdf';
import { AdManagementOverviewReportModel } from 'src/app/viewModel/ad-management-overview';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { List } from '@rxweb/generics';
import { AdDonorByPageTypeReportModel } from 'src/app/viewModel/ad-donor-by-page-type';
import { AdDesignStatusReportModel } from 'src/app/viewModel/ad-design-status-report';
import { AdManagementIntakeFormStatusReportModel } from 'src/app/viewModel/ad-management-intake-form-status';
import { ActionType, RolePermissionModule } from 'src/app/enums/user-role-permission';
import { access } from '@rxweb/angular-router';
import { ApiCallService } from 'src/app/temp-service/api-call-service';
import { APPLICATION_URL_CONSTANTS } from 'src/app/const/uris/application-url-constants';
import { CUSTOM_URI } from 'src/app/const/uris/custom.uri';
import { CONSTANT_MESSAGES } from 'src/app/const/constant-messages/constant-messages';

@access({ accessLevel: RolePermissionModule.Reports, action: ActionType.GET })

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',

})

export class ReportComponent extends AbstractReportTemplate implements OnInit, OnDestroy {
  subscription: Subscription;
  AdManagementOverviewGrid: GridWithExcelPdf;
  eventId: number;
  pageEventName: string;
  showComponent: boolean = false;
  searchValue: string = "";
  rowCount: number = 15;
  pageIndex: number = 1;
  sortOrder: string = CONSTANT_MESSAGES.descending;
  orderByColumn: string
  pagingSource: any = [15, 25, 50, 100];
  totalCount: number;
  adManagementOverviewdata: any;
  isFirstTime: boolean = true;
  spin: boolean = false;
  pdfExcelDetail = new PdfExcelDetail();
  toastr: BaseToastr;
  tab: string = CONSTANT_MESSAGES.overview;
  fileName: string;
  reportCount: any;
  bindDropdown: any[];
  selectedValue: any;

  constructor(public activatedRoute: ActivatedRoute, private apiCallService: ApiCallService) {
    super();
    this.toastr = new BaseToastr();
    this.orderByColumn = CONSTANT_MESSAGES.aiDonorId;

    this.activatedRoute.params.subscribe(t => {
      this.eventId = t[CONSTANT_MESSAGES.eventId];
    })

    this.spin = false
  }

  ngOnInit() {
    this.spin = true;
    this.selectedValue = 0;
    this.bindDropdown = new Array<any>();
    this.bindGrid();
  }

  selectTab(value: string) {
    this.tab = value;
    this.AdManagementOverviewGrid.refresh(CONSTANT_MESSAGES.adManagementOverview);
    this.bindDropdown = new Array<any>();
    this.orderByColumn = CONSTANT_MESSAGES.aiDonorId;
    this.bindGrid();
    this.selectedValue = 0;
  }

  bindGrid(page: number = 0) {
    this.showComponent = true;

    let jsonObject = new spParameter();

    jsonObject.searchQuery = new SearchQuery();
    jsonObject.searchQuery[CONSTANT_MESSAGES.searchDetails] = this.searchValue;

    if (this.selectedValue == 'NA') this.selectedValue = null;
    jsonObject.searchQuery[CONSTANT_MESSAGES.selectedValue] = this.selectedValue;

    let pageQuery = new pageDataQuery();
    pageQuery.PageRow = this.rowCount;
    pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
    pageQuery.OrderColumn = this.orderByColumn;
    pageQuery.OrderType = this.sortOrder;
    jsonObject.pageQuery = JSON.stringify(pageQuery);

    let sendJson = new Query();
    sendJson.query = JSON.stringify(jsonObject);

    sendJson.eventId = this.eventId;
    sendJson.category = this.tab;

    this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.searchAdIntakeForms, sendJson).subscribe((t: any) => {
      this.spin = false;
      this.isFirstTime = false;
      this.selectedValue = 0;
      var parsedData = JSON.parse(t);

      this.reportCount = parsedData[0].result.sumData;
      this.adManagementOverviewdata = parsedData[0].result.data;
      this.totalCount = parsedData[0].result.footer[0].totalCount;

      if (page == 0) {
        this.setGrid();

        this.AdManagementOverviewGrid.maxPerPage = this.rowCount;
        this.AdManagementOverviewGrid.pagingSource = this.pagingSource;
        this.AdManagementOverviewGrid.footerDesignClass.dropDownTemplateClass.rootClass = "col-md-auto d-flex flex-wrap align-items-center justify-content-center mb-3 mb-md-0".split(' ');
        this.AdManagementOverviewGrid.maxPerPage = this.rowCount;

        if (this.adManagementOverviewdata.length > 0) {
          this.AdManagementOverviewGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
        }
        else {
          this.AdManagementOverviewGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.AdManagementOverviewGrid.updateSource([]);
        }

        this.AdManagementOverviewGrid.design(document.getElementById(CONSTANT_MESSAGES.adManagementOverview));
      }
      else {
        if (this.adManagementOverviewdata.length > 0) {
          this.AdManagementOverviewGrid.storeProcedure = {
            length: this.totalCount,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.AdManagementOverviewGrid.updateSource(this.adManagementOverviewdata);
        }
        else {
          this.AdManagementOverviewGrid.storeProcedure = {
            length: 0,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.pageIndex,
            onPageSorting: this.onPageSorting.bind(this)
          }

          this.AdManagementOverviewGrid.updateSource([]);
        }

        this.isFirstTime = false;
        this.showComponent = true
        // this.eventsGrid.reDesign();
      }
    })

    this.spin = false;
  }

  search(value) {
    this.spin = true;
    this.searchValue = value;
    this.AdManagementOverviewGrid.refresh(CONSTANT_MESSAGES.adManagementOverview);
    this.bindGrid();
  }

  onChangeDropdown(e: any) {
    this.selectedValue = Number(e.target.value);
    // this.AdManagementOverviewGrid.refresh("adManagementOverview");
    this.bindGrid(this.pageIndex);
  }

  onPageChanging(x) {
    this.AdManagementOverviewGrid.storeProcedure.nextPage = x;
    this.pageIndex = x;
    this.rowCount = this.AdManagementOverviewGrid.maxPerPage;
    this.bindGrid(x);
  }

  onPageSorting(x, y, z) {
    this.sortOrder = y;

    if (x == CONSTANT_MESSAGES.adIntakeFormIdVariable)
      this.orderByColumn = CONSTANT_MESSAGES.aiDonorId

    if (!this.isFirstTime) {
      this.sortOrder = y == false ? CONSTANT_MESSAGES.descending : CONSTANT_MESSAGES.ascending;
      this.bindGrid(this.pageIndex);
    }
  }

  exportToExcel() {
    this.spin = true;

    let jsonObject = new spParameter();

    jsonObject.searchQuery = new SearchQuery();
    jsonObject.searchQuery[CONSTANT_MESSAGES.searchDetails] = this.searchValue;

    let pageQuery = new pageDataQuery();

    pageQuery.PageRow = this.rowCount;
    //pageQuery.PageIndex = page == 0 ? this.pageIndex : page;
    pageQuery.PageIndex = 1;

    let orderByColumnExcel = "";

    switch (this.orderByColumn) {
      case CONSTANT_MESSAGES.donorId:
        orderByColumnExcel = CONSTANT_MESSAGES.aiDonorId
        break;
      case CONSTANT_MESSAGES.donorName:
        orderByColumnExcel = CONSTANT_MESSAGES.DonorName
        break;
      case CONSTANT_MESSAGES.relationshipOwner:
        orderByColumnExcel = CONSTANT_MESSAGES.RelationshipOwner
        break;
      case CONSTANT_MESSAGES.pageType:
        orderByColumnExcel = CONSTANT_MESSAGES.PageTye
        break;
      case CONSTANT_MESSAGES.adType:
        orderByColumnExcel = CONSTANT_MESSAGES.AdType
        break;
      case CONSTANT_MESSAGES.compUpgradeAd:
        orderByColumnExcel = CONSTANT_MESSAGES.CompOrUpgradeAd
        break;
      case CONSTANT_MESSAGES.adDesignStatus:
        orderByColumnExcel = CONSTANT_MESSAGES.AdDesignStatus
        break;
      case CONSTANT_MESSAGES.intakeFormStatus:
        orderByColumnExcel = CONSTANT_MESSAGES.IntakeFormStatus
        break;
      case CONSTANT_MESSAGES.mostRecentEmailSent:
        orderByColumnExcel = CONSTANT_MESSAGES.MostRecentEmailSent
        break;
      case CONSTANT_MESSAGES.emailSentDate:
        orderByColumnExcel = CONSTANT_MESSAGES.EmailSentDate
        break;
      case CONSTANT_MESSAGES.adContactName:
        orderByColumnExcel = CONSTANT_MESSAGES.AdContactName
        break;
      case CONSTANT_MESSAGES.adContactPhone:
        orderByColumnExcel = CONSTANT_MESSAGES.AdContactPhone
        break;
      case CONSTANT_MESSAGES.adContactEmailAddress:
        orderByColumnExcel = CONSTANT_MESSAGES.AdContactEmailAddress
        break;
      case CONSTANT_MESSAGES.updatedBy:
        orderByColumnExcel = CONSTANT_MESSAGES.UpdatedBy
        break;
      case CONSTANT_MESSAGES.updatedDate:
        orderByColumnExcel = CONSTANT_MESSAGES.UpdatedDate
        break;
      case CONSTANT_MESSAGES.updatedActionType:
        orderByColumnExcel = CONSTANT_MESSAGES.UpdatedActionType
        break;
      // case "journalAdAlphaName":
      //   orderByColumnExcel = "Journal Ad Alpha Name"
      //   break;
      case CONSTANT_MESSAGES.adIntakeFormIdDisplay:
        orderByColumnExcel = CONSTANT_MESSAGES.AdIntakeFormId
        break;
      default:
        orderByColumnExcel = CONSTANT_MESSAGES.donorId
        break;
    }

    pageQuery.OrderColumn = orderByColumnExcel;
    pageQuery.OrderType = this.sortOrder;

    jsonObject.pageQuery = JSON.stringify(pageQuery);

    let sendJson = new QueryForDownload();
    //sendJson.query = "";
    sendJson.query = JSON.stringify(jsonObject);
    sendJson.category = this.tab;
    sendJson.eventId = this.eventId;
    sendJson.isDownload = true;
    sendJson.type = CONSTANT_MESSAGES.excel;
    sendJson.fileName = this.fileName + CONSTANT_MESSAGES.excelExtension;

    this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.searchAdIntakeForms, sendJson).subscribe((Response: any) => {
      this.spin = false;

      downloadDocument(Response, this.fileName, CONSTANT_MESSAGES.mimeExcel);
    });
  }

  requestDataPdfExcelOverview(exportAdManagementOverviewDetails: any) {
    let exportAdManagementOverviewList = new List<ExportAdManagementOverview>();

    exportAdManagementOverviewDetails.forEach(element => {
      let exportAdManagementOverviewGrid = new ExportAdManagementOverview();

      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.donorIdForReport] = element.donorId == 0 ? "NA" : element.donorId;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.DonorName] = element.donorName;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.RelationshipOwner] = element.relationshipOwner;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.PageTye] = element.pageType;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.AdType] = element.adType;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.CompOrUpgradeAd] = element.compUpgradeAd;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.AdDesignStatus] = element.adDesignStatus;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.IntakeFormStatus] = element.intakeFormStatus;
      //exportAdManagementOverviewGrid["Most Recent Email Sent"] = element.mostRecentEmailSent;

      if (element.emailSentDate != null) {
        let tempEmailSentDate = new Date(element.emailSentDate).toDateString().split(' ');
        let tempEmailSentTime = new Date(element.emailSentDate).toTimeString().split(' ');
        exportAdManagementOverviewGrid[CONSTANT_MESSAGES.EmailSentDate] = tempEmailSentDate[1] + ' ' + tempEmailSentDate[2] + ', ' + tempEmailSentDate[3] + ' | ' + tempEmailSentTime[0];
      } else {
        exportAdManagementOverviewGrid[CONSTANT_MESSAGES.EmailSentDate] = "NA";
      }

      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.AdContactName] = element.adContactName;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.AdContactPhone] = element.adContactPhone;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.AdContactEmailAddress] = element.adContactEmailAddress;
      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.UpdatedBy] = element.updatedBy;

      let tempUpdateDate = new Date(element.updatedDate).toDateString().split(' ');

      if (element.updatedDate == null || element.updatedDate == "NA") {
        exportAdManagementOverviewGrid[CONSTANT_MESSAGES.UpdatedDate] = "NA";
      } else {
        exportAdManagementOverviewGrid[CONSTANT_MESSAGES.UpdatedDate] = tempUpdateDate[1] + ' ' + tempUpdateDate[2] + ', ' + tempUpdateDate[3];
      }

      exportAdManagementOverviewGrid[CONSTANT_MESSAGES.UpdatedActionType] = element.updatedActionType;

      exportAdManagementOverviewList.add(exportAdManagementOverviewGrid);
    });

    return exportAdManagementOverviewList;
  }

  requestDataPdfExcelPageTypeReport(exportAdManagementPageTypeDetails: any) {
    let exportAdManagementPageTypeList = new List<ExportAdManagementPageType>();

    exportAdManagementPageTypeDetails.forEach(element => {
      let exportAdManagementPageTypeGrid = new ExportAdManagementPageType();

      //exportAdManagementPageTypeGrid["Journal Ad Alpha Name"] = element.journalAdAlphaName;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.donorIdForReport] = element.donorId == 0 ? "NA" : element.donorId;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.DonorName] = element.donorName;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.RelationshipOwner] = element.relationshipOwner;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.AdContactName] = element.adContactName;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.AdContactPhone] = element.adContactPhone;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.AdContactEmailAddress] = element.adContactEmailAddress;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.PageTye] = element.pageType;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.AdType] = element.adType;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.AdDesignStatus] = element.adDesignStatus;
      exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.MostRecentEmailSent] = element.mostRecentEmailSent;

      if (element.emailSentDate != null) {
        let tempEmailSentDate = new Date(element.emailSentDate).toDateString().split(' ');
        let tempEmailSentTime = new Date(element.emailSentDate).toTimeString().split(' ');

        exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.EmailSentDate] = tempEmailSentDate[1] + ' ' + tempEmailSentDate[2] + ', ' + tempEmailSentDate[3] + ' | ' + tempEmailSentTime[0];
      } else {
        exportAdManagementPageTypeGrid[CONSTANT_MESSAGES.EmailSentDate] = "NA";
      }

      exportAdManagementPageTypeList.add(exportAdManagementPageTypeGrid);
    });

    return exportAdManagementPageTypeList;
  }

  requestDataPdfExcelAdDesignStatusReport(exportAdManagementAdDesignStatusDetails: any) {
    let exportAdManagementAdDesignStatusList = new List<ExportAdManagementAdDesignStatus>();

    exportAdManagementAdDesignStatusDetails.forEach(element => {
      let exportAdManagementAdDesignStatusGrid = new ExportAdManagementAdDesignStatus();

      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.AdDesignStatus] = element.adDesignStatus;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.AdType] = element.adType;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.PageTye] = element.pageType;
      //exportAdManagementAdDesignStatusGrid["Journal Ad Alpha Name"] = element.journalAdAlphaName;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.donorIdForReport] = element.donorId == 0 ? "NA" : element.donorId;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.DonorName] = element.donorName;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.RelationshipOwner] = element.relationshipOwner;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.AdContactName] = element.adContactName;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.AdContactPhone] = element.adContactPhone;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.AdContactEmailAddress] = element.adContactEmailAddress;
      exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.MostRecentEmailSent] = element.mostRecentEmailSent;

      if (element.emailSentDate != null) {
        let tempEmailSentDate = new Date(element.emailSentDate).toDateString().split(' ');
        let tempEmailSentTime = new Date(element.emailSentDate).toTimeString().split(' ');
        exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.EmailSentDate] = tempEmailSentDate[1] + ' ' + tempEmailSentDate[2] + ', ' + tempEmailSentDate[3] + ' | ' + tempEmailSentTime[0];
      } else {
        exportAdManagementAdDesignStatusGrid[CONSTANT_MESSAGES.EmailSentDate] = "NA";
      }

      exportAdManagementAdDesignStatusList.add(exportAdManagementAdDesignStatusGrid);
    });

    return exportAdManagementAdDesignStatusList;
  }

  requestDataPdfExcelIntakeStatusReport(exportAdManagementIntakeStatusDetails: any) {
    let exportAdManagementIntakeStatusList = new List<ExportAdManagementIntakeFormStatus>();

    exportAdManagementIntakeStatusDetails.forEach(element => {
      let exportAdManagementIntakeStatusGrid = new ExportAdManagementIntakeFormStatus();

      //exportAdManagementIntakeStatusGrid["Journal Ad Alpha Name"] = element.journalAdAlphaName;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.donorIdForReport] = element.donorId == 0 ? "NA" : element.donorId;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.DonorName] = element.donorName;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.RelationshipOwner] = element.relationshipOwner;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.PageTye] = element.pageType;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.AdType] = element.adType;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.IntakeFormStatus] = element.intakeFormStatus;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.AdContactName] = element.adContactName;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.AdContactPhone] = element.adContactPhone;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.AdContactEmailAddress] = element.adContactEmailAddress;
      exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.MostRecentEmailSent] = element.mostRecentEmailSent;

      if (element.emailSentDate != null) {
        let tempEmailSentDate = new Date(element.emailSentDate).toDateString().split(' ');
        let tempEmailSentTime = new Date(element.emailSentDate).toTimeString().split(' ');
        exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.EmailSentDate] = tempEmailSentDate[1] + ' ' + tempEmailSentDate[2] + ', ' + tempEmailSentDate[3] + ' | ' + tempEmailSentTime[0];
      } else {
        exportAdManagementIntakeStatusGrid[CONSTANT_MESSAGES.EmailSentDate] = "NA";
      }

      exportAdManagementIntakeStatusList.add(exportAdManagementIntakeStatusGrid);
    });

    return exportAdManagementIntakeStatusList;
  }

  exportToPdf() {
    
    this.spin = true;

    let list = this.adManagementOverviewdata;

    this.pdfExcelDetail.FileName = this.fileName + CONSTANT_MESSAGES.pdfExtention;
    this.pdfExcelDetail.Type = CONSTANT_MESSAGES.pdfType;

    let returnedRequestData: any;

    switch (this.tab) {
      case CONSTANT_MESSAGES.pageTypeReport:
        returnedRequestData = this.requestDataPdfExcelPageTypeReport(list)
        break;
      case CONSTANT_MESSAGES.adDesignStatusReport:
        returnedRequestData = this.requestDataPdfExcelAdDesignStatusReport(list)
        break;
      case CONSTANT_MESSAGES.intakeStatusReport:
        returnedRequestData = this.requestDataPdfExcelIntakeStatusReport(list)
        break;
      default:
        returnedRequestData = this.requestDataPdfExcelOverview(list)
        break;
    }
    //let returnedRequestData = this.requestDataPdfExcel(list);
    this.pdfExcelDetail.Result = JSON.stringify(returnedRequestData);

    return this.apiCallService.postAPI(CUSTOM_URI.postPdfExcel, this.pdfExcelDetail).subscribe(Response => {
      this.spin = false;

      downloadDocument(Response, this.pdfExcelDetail.FileName, CONSTANT_MESSAGES.mimePdf);
    }, error => {
      this.spin = false;
      this.toastr.error(error);
    })
  }

  setGrid() {
    //this.bindDropdown = new Array<any>();
    if (this.tab == CONSTANT_MESSAGES.pageTypeReport) {
      this.AdManagementOverviewGrid = new GridWithExcelPdf(this.adManagementOverviewdata, AdDonorByPageTypeReportModel,
        {
          actions:
            { exportToExcel: this.exportToExcel.bind(this), exportToPdf: this.exportToPdf.bind(this) }
        }
      );

      this.fileName = CONSTANT_MESSAGES.journalAdDonorsByPageType;
      this.bindDropdown.push({ "Id": 0, "Text": CONSTANT_MESSAGES.allPages });

      this.reportCount.forEach(element => {
        if (element.Text == 'NA')
          this.bindDropdown.push({ "Id": 'NA', "Text": element.Text });
        else
          this.bindDropdown.push({ "Id": element.PageTypeId, "Text": element.Text });
      });

      this.AdManagementOverviewGrid.designClass.tableClass = "themeTable div-table pageTypeReportTable table div-table-striped table-striped journal-add-table".split(' ');
    }
    else if (this.tab == CONSTANT_MESSAGES.adDesignStatusReport) {
      this.AdManagementOverviewGrid = new GridWithExcelPdf(this.adManagementOverviewdata, AdDesignStatusReportModel,
        {
          actions:
            { exportToExcel: this.exportToExcel.bind(this), exportToPdf: this.exportToPdf.bind(this) }
        }
      );

      this.fileName = CONSTANT_MESSAGES.journalAdByAdDesignStatus;
      this.bindDropdown.push({ "Id": 0, "Text": CONSTANT_MESSAGES.allDesignStatus });

      this.reportCount.forEach(element => {
        this.bindDropdown.push({ "Id": element.ApplicationObjectId, "Text": element.Text });
      });

      this.AdManagementOverviewGrid.designClass.tableClass = "themeTable div-table pageTypeReportTable table div-table-striped table-striped journal-add-table".split(' ');
    }
    else if (this.tab == CONSTANT_MESSAGES.intakeStatusReport) {
      this.AdManagementOverviewGrid = new GridWithExcelPdf(this.adManagementOverviewdata, AdManagementIntakeFormStatusReportModel,
        {
          actions:
            { exportToExcel: this.exportToExcel.bind(this), exportToPdf: this.exportToPdf.bind(this) }
        }
      );

      this.fileName = CONSTANT_MESSAGES.journalAdByIntakeFormStatus;
      this.bindDropdown.push({ "Id": 0, "Text": CONSTANT_MESSAGES.allIntakeFormStatus });

      this.reportCount.forEach(element => {
        this.bindDropdown.push({ "Id": element.ApplicationObjectId, "Text": element.Text });
      });

      this.AdManagementOverviewGrid.designClass.tableClass = "themeTable div-table pageTypeReportTable table div-table-striped table-striped journal-add-table".split(' ');
    }
    else {
      this.AdManagementOverviewGrid = new GridWithExcelPdf(this.adManagementOverviewdata, AdManagementOverviewReportModel,
        {
          actions:
            { exportToExcel: this.exportToExcel.bind(this), exportToPdf: this.exportToPdf.bind(this) }
        }
      );

      this.fileName = CONSTANT_MESSAGES.journalAdManagementOverview;
      this.AdManagementOverviewGrid.designClass.tableClass = "themeTable div-table overviewReportTable table div-table-striped table-striped journal-add-table".split(' ');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}

class ExportAdManagementOverview {
  'Donor ID': number;
  'Donor Name': string;
  'Relationship Owner': string;
  'Page Type': string;
  'Ad Type': string;
  "Comp or Updade Ad": string;
  'Ad Design Status': string;
  'Intake Form Status': string;
  'Email Sent Date': string;
  'Ad Contact Name': string;
  'Ad Contact Phone': string;
  'Ad Contact Email Address': string;
  'Updated By': string;
  'Updated Date': string;
  'Updated Action Type': string;
}

class ExportAdManagementPageType {
  //'Journal Ad Alpha Name': string;
  'Donor ID': number;
  'Donor Name': string;
  'Relationship Owner': string;
  'Ad Contact Name': string;
  'Ad Contact Phone': string;
  'Ad Contact Email Address': string;
  'Page Type': string;
  'Ad Type': string;
  'Ad Design Status': string;
  'Most Recent Email Sent': string;
  'Email Sent Date': string;
}

class ExportAdManagementAdDesignStatus {
  'Ad Design Status': string;
  'Ad Type': string;
  'Page Type': string;
  //'Journal Ad Alpha Name': string;
  'Donor ID': number;
  'Donor Name': string;
  'Relationship Owner': string;
  'Ad Contact Name': string;
  'Ad Contact Phone': string;
  'Ad Contact Email Address': string;
  'Most Recent Email Sent': string;
  'Email Sent Date': string;
}

class ExportAdManagementIntakeFormStatus {
  //'Journal Ad Alpha Name': string;
  'Donor ID': number;
  'Donor Name': string;
  'Relationship Owner': string;
  'Page Type': string;
  'Ad Type': string;
  'Intake Form Status': string;
  'Ad Contact Name': string;
  'Ad Contact Phone': string;
  'Ad Contact Email Address': string;
  'Most Recent Email Sent': string;
  'Email Sent Date': string;
}

class pageDataQuery {
  PageIndex: number;
  PageRow: number;
  OrderColumn: string;
  OrderType: string;
}