import { EmailTemplateBase } from '../database-models/email-template-base';
import { EmailDetailBase } from '../database-models/email-detail-base';
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class EmailTemplate extends EmailTemplateBase {

    //#region status Prop
    @prop()
    status: boolean;
    //#endregion status Prop

    //#region Generated Reference Properties
    //#region emailDetails Prop
    emailDetails: EmailDetailBase[];
    //#endregion emailDetails Prop

    //#endregion Generated Reference Properties

}