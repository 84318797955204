import { TemplateConfig } from '@rxweb/grid';

export const EVENT_STATUS_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [function (e) {
            switch(this[e.name]){
                case 'In Progress':
                return "bg-blue text-white label".split(" ");
               
                case 'Completed':
                    return "bg-light-green text-white label".split(" ");
                    
                case 'Planning':
                    return "bg-orange text-white label".split(" ");
            }
        }],
        childrens: [{
            text: {
                text: function (e) 
                { 
                    return this[e.name];
                    // switch(this[e.name]){
                    //     case 'In Progress':
                    //     return "In Progress";
                       
                    //     case 'Completed':
                    //         return "Completed";
                            
                    //     case 'Planning':
                    //         return "Planning";
                    // }
                }
            }
        }]
    }
}
