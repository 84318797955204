import { prop, propObject, propArray, required, maxLength, range, numeric, email } from "@rxweb/reactive-form-validators"

export class AdIntakeFormBase {

        //#region adIntakeFormId Prop
        @prop()
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region donorId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @numeric()
        @required()
        donorId: number;
        //#endregion donorId Prop


        //#region donorName Prop
        @required()
        @maxLength({ value: 100 })
        donorName: string;
        //#endregion donorName Prop


        //#region companyName Prop
        @numeric()
        @required()
        @range({ minimumNumber: 0, maximumNumber: 99999999 })
        amount: number;
        //#endregion companyName Prop


        //#region node Prop
        @maxLength({ value: 50 })
        node: string;
        //#endregion node Prop


        //#region donorPhoneNumber Prop
        //@required()
        @maxLength({ value: 25 })
        donorPhoneNumber: string;
        //#endregion donorPhoneNumber Prop


        //#region donorEmail Prop
        @email()
        @required()
        @maxLength({ value: 100 })
        donorEmail: string;
        //#endregion donorEmail Prop


        //#region fundraiserId Prop
        @required()
        @prop()
        fundraiserId: number;
        //#endregion fundraiserId Prop


        //#region fundraiserContactPhoneNumber Prop
        //@required()
        @maxLength({ value: 25 })
        fundraiserContactPhoneNumber: string;
        //#endregion fundraiserContactPhoneNumber Prop


        //#region donorPreferredSignatureAttribute Prop
        @maxLength({ value: 200 })
        //@required()
        donorPreferredSignatureAttribute: string;
        //#endregion donorPreferredSignatureAttribute Prop


        //#region adContactIsSameAsDonor Prop
        @required()
        adContactIsSameAsDonor: boolean;
        //#endregion adContactIsSameAsDonor Prop


        //#region adContactDonorID Prop
        @maxLength({ value: 50 })
        adContactDonorID: string;
        //#endregion adContactDonorID Prop


        //#region adContactName Prop
        @required()
        @maxLength({ value: 100 })
        adContactName: string;
        //#endregion adContactName Prop


        //#region adContactPhoneNumber Prop
        @required()
        @maxLength({ value: 25 })
        adContactPhoneNumber: string;
        //#endregion adContactPhoneNumber Prop


        //#region adContactEmailAddress Prop
        @required()
        @email()
        @maxLength({ value: 100 })
        adContactEmailAddress: string;
        //#endregion adContactEmailAddress Prop


        //#region donorRelationship Prop
        @required()
        @maxLength({ value: 100 })
        donorRelationship: string;
        //#endregion donorRelationship Prop


        //#region relationshipOwner Prop
        @required()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        relationshipOwner: number;
        //#endregion relationshipOwner Prop


        //#region name Prop
        //@maxLength({ value: 50 })
        @required()
        name: number;
        //#endregion name Prop


        //#region phoneNumber Prop
        @maxLength({ value: 25 })
        phoneNumber: string;
        //#endregion phoneNumber Prop


        //#region otherEmailId Prop
        @maxLength({ value: 50 })
        otherEmailId: string;
        //#endregion otherEmailId Prop


        //#region adCreatorNameId Prop
        @prop()
        adCreatorNameId: number;
        //#endregion adCreatorNameId Prop


        //#region allowAdCreatorToSendEmails Prop
        @required()
        allowAdCreatorToSendEmails: boolean;
        //#endregion allowAdCreatorToSendEmails Prop


        //#region journalAdAlphaName Prop
        @maxLength({ value: 250 })
        journalAdAlphaName: string;
        //#endregion journalAdAlphaName Prop


        //#region journalAdCopy Prop
        @prop()
        @maxLength({ value: 250 })
        journalAdCopy: string;
        //#endregion journalAdCopy Prop


        //#region journalAdCopyLogo Prop
        @prop()
        journalAdCopyLogo: string;
        //#endregion journalAdCopyLogo Prop


        //#region compUpgradId Prop
        @prop()
        compUpgradId: number;
        //#endregion compUpgradId Prop


        //#region comments Prop
        @maxLength({ value: 500 })
        comments: string;
        //#endregion comments Prop


        //#region adDesignTemplateTypeId Prop
        @required()
        @prop()
        adDesignTemplateTypeId: number;
        //#endregion adDesignTemplateTypeId Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region intakeFormStatus Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        intakeFormStatus: number;
        //#endregion intakeFormStatus Prop


        //#region eventId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        eventId: number;
        //#endregion eventId Prop


        //#region pageTypeId Prop
        @required()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        pageTypeId: number;
        //#endregion pageTypeId Prop


        //#region sequence Prop
        @prop()
        sequence: number;
        //#endregion sequence Prop


        //#region verificationCode Prop
        @prop()
        verificationCode: any;
        //#endregion verificationCode Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop

        //#region donorAlphaName Prop
        @prop()
        @required()
        @maxLength({ value: 100 })
        donorAlphaName: string;
        //#endregion donorAlphaName Prop

        //Added on 31/03/2022 for Enhancement Point
        //#region isFromPaginationToWIP Prop
        isFromPaginationToWIP: boolean;
}