import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDivisionBase {

        //#region divisionId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'divisionId', keyColumn: true })
        divisionId: number;
        //#endregion divisionId Prop


        //#region divisionName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'divisionName', keyColumn: false, headerTitle: "Division Name" })
        divisionName: string;
        //#endregion divisionName Prop


        //#region description Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'description', keyColumn: false, headerTitle: "Description" })
        description: string;
        //#endregion description Prop

}