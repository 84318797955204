import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { EmailNotification } from '@app/models';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { CoreComponent } from '@rxweb/angular-router';

@http({
    path: "api/EmailNotifications",
})

export class AbstractEmailNotification extends CoreComponent {
    emailNotificationFormGroup: IFormGroup<EmailNotification>
    breadCrumb: BreadCrumb;
    showComponent : boolean;
    spin : boolean;
    modalView: ModalView;
    toastr: BaseToastr = new BaseToastr();

    constructor() {
        super();
        this.bindBreadCrumb();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.name2 = "Email Management"
        this.breadCrumb.title = 'Email'
        this.breadCrumb.pagename = 'Email Notification'
    }
}
