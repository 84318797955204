import { vEventRecordBase, } from '@app/database-models'
import { required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class vEventRecord extends vEventRecordBase {

    //#region Generated Reference Properties
    @required()
    status: boolean = false;
    //#endregion Generated Reference Properties

}