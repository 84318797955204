import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, email, RxwebValidators, date } from '@rxweb/reactive-form-validators';
import { Event, EventHonoree, vEventHonoree, PageType } from '@app/models';
import { AbstractEvent } from '../domain/abstract-event';
import { EventLookupGroup } from 'src/app/viewModel/event-lookup-group';
import { EVENT_LOOKUPS } from 'src/app/const/uris/event-lookups.uri';
import { StatusEnum } from 'src/app/enums/status.enum';
import { AppGrid } from 'src/app/domain/app-grid';
import { RoleGrid } from 'src/app/custom-templates/grid/footer-hide';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { EventModalComponent } from '../event-modal.component';
//import { ModalComponent } from 'src/app/components/shared/modal/modal.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { access } from '@rxweb/angular-router';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { ImageModalComponent } from '../../wip/image-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { ValidationMessage } from "src/app/const/validation-msg/custom-validation";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@access({ accessLevel: RolePermissionModule.Event, action: ActionType.POST })

@Component({
    selector: "app-event-add",
    templateUrl: './event-add.component.html'
})

export class EventAddComponent extends AbstractEvent implements OnInit, OnDestroy {
    event: Event;
    subscription: Subscription;
    eventLookupGroup: EventLookupGroup;
    eventHonoreeGroup: IFormGroup<EventHonoree>
    pageTypeGroup: IFormGroup<PageType>
    showHonoree: boolean = false;
    eventHonoree: EventHonoree;
    honoreeArray: EventHonoree[];
    pageType: PageType;
    pageTypeArray: PageType[];
    HonoreeGrid: AppGrid;
    PageTypeGrid: RoleGrid;
    showPageType: boolean = false;
    eventManagerEmail: string;
    eventFundraiserEmail: string;
    adCreatorEmail: string;
    otherManagerEmail: string;
    arrayIndex: number;
    selectDateErrorMessage: string;
    showDateErrorMessage: boolean = false;
    showJournalErrorMessage: boolean = false;
    selectedJournalErrorMessage: string;
    value: string;
    showSequenceError: boolean = false;
    fileType: string;
    showAmountField: boolean = true;
    pdfPagesCount: number = 0;
    showEventNameErrorMessage: boolean = false;
    selectEventNameErrorMessage: string;
    specialChar: string = "/" + " \\ : * ? < > | " + '"';
    defaultPageColor: string = "#3a9fd9";
    defaultSettings: any = [
    ];

    constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private sanitizer: DomSanitizer, private route: Router,
        public datePipe: DatePipe, private apiCallService: ApiCallService) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.spin = true;
        this.event = new Event();

        // //Uncomment below code for Ad Type.
        // let AdTypeEnum_DisplayName: { [index: number]: string } = {};
        // AdTypeEnum_DisplayName[AdTypeEnum.NonPaidAd] = "Non Paid Ad";
        // AdTypeEnum_DisplayName[AdTypeEnum.PaidAd] = "Paid Ad";
        // this.adTypes = Object.entries(AdTypeEnum_DisplayName).filter(e => !isNaN(e[0] as any)).map(e => ({ adType: e[1], adTypeId: e[0] }));

        this.eventFormGroup = this.formBuilder.formGroup(this.event) as IFormGroup<Event>;
        this.eventFormGroup.controls.statusId.setValue(StatusEnum.Active);
        this.eventFormGroup.controls.eventId.setValue(0);

        this.bindApplicationData();

        this.eventLookupGroup = new EventLookupGroup();
        this.lookup<EventLookupGroup>([
            { path: EVENT_LOOKUPS["campaignYearLookUps"], propName: "campaignYearLookUps" },
            { path: EVENT_LOOKUPS["divisionLookUps"], propName: "divisionLookUps" },
            { path: EVENT_LOOKUPS["userLookUps"], propName: "userLookUps" }]).
            subscribe((response: any) => {
                this.showComponent = true;
                this.spin = false;
                this.eventLookupGroup = response;
                this.honoreeArray = new Array<EventHonoree>();
                this.pageTypeArray = new Array<PageType>();

                this.event.eventId = 0;
                this.event.status = true;

                this.eventFormGroup.controls.eventHonoree.setValidators([RxwebValidators.required({ message: ValidationMessage.addAtleastOneHonoree })]);
                this.eventFormGroup.controls.pageTypes.setValidators([RxwebValidators.required({ message: ValidationMessage.addAtleaestPageType })]);

                this.eventHonoree = new EventHonoree();
                this.eventHonoree.eventId = 0;
                this.eventHonoree.statusId = StatusEnum.Active;
                this.eventHonoreeGroup = this.formBuilder.formGroup(this.eventHonoree) as IFormGroup<EventHonoree>;

                this.pageType = new PageType();

                this.pageTypeGroup = this.formBuilder.formGroup(this.pageType) as IFormGroup<PageType>;

                this.pageTypeGroup.controls.pageTypeColor.setValue(this.defaultPageColor);
                this.pageTypeGroup.controls.isHalfPage.setValue(false);

                this.showHonoree = false;
            })
    }

    campaignYearChg() {
        this.checkDate(this.eventFormGroup.controls.eventDate.value);
        this.checkJournalDate(this.eventFormGroup.controls.journalAdDeadlineDate.value);
    }

    selectColor() {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.defaultcolor }).then(t => {
            if (t) {
                this.pageTypeGroup.controls.pageTypeColor.setValue(t);
                this.defaultPageColor = t;
            }
        });
    }

    checkDate(selectedItem: any) {
        this.showDateErrorMessage = false;
        this.value = this.eventFormGroup.controls.campaignYear.value;

        if (this.value != null) {
            var campaignYearValue = this.value.split('-');

            if (selectedItem) {
                let sdate = new Date(selectedItem);
                let tdate = new Date();
                let firstValue = new Date(campaignYearValue[0]);
                let secondValue = new Date(campaignYearValue[1]);
                secondValue.setDate(secondValue.getDate() + 30);

                if (sdate < firstValue || sdate > secondValue) {
                    this.selectDateErrorMessage = ValidationMessage.selectDateAccordingToSelectedCampaignYear;
                    this.showDateErrorMessage = true;
                }
                else if (sdate < tdate) {
                    this.selectDateErrorMessage = ValidationMessage.selectDateGreaterThanToday;
                    this.showDateErrorMessage = true;
                }
            }
        }
    }

    checkJournalDate(selectedItem: any) {
        this.showJournalErrorMessage = false;
        this.value = this.eventFormGroup.controls.campaignYear.value;

        if (this.value != null) {
            var campaignYearValue = this.value.split('-');

            if (selectedItem) {
                let sdate = new Date(selectedItem)
                let firstValue = new Date(campaignYearValue[0]);
                let secondValue = new Date(campaignYearValue[1]);
                secondValue.setDate(secondValue.getDate() + 30);
                let tdate = new Date();
                let eventDate = new Date(this.eventFormGroup.controls.eventDate.value);

                /* if (sdate < firstValue || sdate > secondValue) {
                    this.selectedJournalErrorMessage = ValidationMessage.selectDateAccordingToSelectedCampaignYear;
                    this.showJournalErrorMessage = true;
                }
                else */ if (sdate > eventDate) {
                    this.selectedJournalErrorMessage = ValidationMessage.selectDateLessThanEventDate;
                    this.showJournalErrorMessage = true;
                }
                else if (sdate < tdate) {
                    this.selectedJournalErrorMessage = ValidationMessage.selectDateGreaterThanToday;
                    this.showJournalErrorMessage = true;
                }
            }
        }
    }

    AddHonoree() {
        this.eventHonoreeGroup.submitted = true;
        this.eventHonoreeGroup.controls.statusId.setValue(1);
        this.eventHonoreeGroup.controls.eventId.setValue(1);
        this.eventHonoreeGroup.controls.eventHonoreeId.setValue(0);
        this.eventHonoreeGroup.controls.isModified.setValue(false);

        if (this.eventHonoreeGroup.valid) {
            this.honoreeArray.push(this.eventHonoreeGroup.value);
            this.showHonoree = true;
            this.eventHonoreeGroup.markAsUntouched();
            this.eventHonoreeGroup.resetForm();
            this.eventHonoreeGroup.submitted = false;
            this.eventFormGroup.controls.eventHonoree.setValue(this.eventHonoreeGroup)
        }
    }

    AddPageType() {
        if (this.pdfPagesCount != 0 && this.pdfPagesCount > 1) {
            this.toastr.warning(ValidationMessage.uploadSinglePagePDFOnly)

            return false;
        }

        this.showSequenceError = false;
        this.pageTypeGroup.submitted = true;
        this.pageTypeGroup.controls.statusId.setValue(1);
        this.pageTypeGroup.controls.eventId.setValue(1);
        this.pageTypeGroup.controls.pageTypeId.setValue(0);
        this.pageTypeGroup.controls.isModified.setValue(false);

        if (this.pageTypeGroup.controls.isHalfPage.value == null)
            this.pageTypeGroup.controls.isHalfPage.setValue(false);

        // let count = 0;

        // this.binaryStringList.forEach(element => {
        //     if (element.sequence == this.pageTypeGroup.value.sequences) {
        //         //element.binaryString == this.binaryString;
        //         element.binaryString = this.base64textString.changingThisBreaksApplicationSecurity
        //         count++;
        //     }
        // });

        // if (count != 1) {
        //     //#region Uncomment below code for Ad Type.
        //     // if (this.pageTypeGroup.controls.adTypeId.value == AdTypeEnum.NonPaidAd) {
        //     //     this.pageTypeGroup.controls.fillerAdDesign.setValue("N/A");
        //     // }
        //     // else {
        //     //this.pageTypeGroup.controls.fillerAdDesign.setValue(this.base64textString);
        //     //#endregion

        //     let designString = new fillAdDesignBinaryString();
        //     designString.sequence = this.pageTypeGroup.value.sequences;
        //     //designString.binaryString = this.binaryString;
        //     designString.binaryString = this.base64textString.changingThisBreaksApplicationSecurity;
        //     this.binaryStringList.push(designString);
        //     // }
        // }

        if (this.pageTypeGroup.valid) {
            if (this.pageTypeGroup.controls.arrayIndex.value != null) {
                let index = this.pageTypeGroup.controls.arrayIndex.value;
                var isExists = this.pageTypeArray.find(t => t.sequences == this.pageTypeGroup.value.sequences && t.sequences != this.pageTypeArray[index].sequences);

                if (isExists == null || isExists == undefined) {
                    this.pageTypeArray[index].pageTypeName = this.pageTypeGroup.value.pageTypeName;
                    // //Uncomment below code for Ad Type.
                    // this.pageTypeArray[index].adTypeId = this.pageTypeGroup.value.adTypeId;
                    this.pageTypeArray[index].pageTypeColor = this.pageTypeGroup.value.pageTypeColor;
                    this.pageTypeArray[index].isHalfPage = this.pageTypeGroup.value.isHalfPage;

                    if (this.pageTypeGroup.controls.isHalfPage.value)
                        this.pageTypeGroup.controls.isHalfPage.disable();
                    else
                        this.pageTypeGroup.controls.isHalfPage.enable();

                    // this.pageTypeArray[index].fillerAdDesign = this.pageTypeGroup.value.fillerAdDesign;
                    // this.binaryStringList.forEach(element => {
                    //     if (element.sequence == this.pageTypeGroup.value.sequences)
                    //         element.binaryString = "N/A";
                    // });

                    this.pageTypeArray[index].amount = this.pageTypeGroup.value.amount;
                    this.pageTypeArray[index].sequences = this.pageTypeGroup.value.sequences;
                    this.pageTypeArray[index].comments = this.pageTypeGroup.value.comments;
                    this.pageTypeArray[index].arrayIndex = null;

                    // this.base64textString = "";
                }
                else {
                    this.showSequenceError = true;
                    return false;
                }
            }
            else {
                var isExists = this.pageTypeArray.find(t => t.sequences == this.pageTypeGroup.value.sequences);

                if (isExists == null || isExists == undefined) {
                    this.pageTypeArray.push(this.pageTypeGroup.value);

                    if (this.pageTypeGroup.controls.isHalfPage.value)
                        this.pageTypeGroup.controls.isHalfPage.disable();
                    else
                        this.pageTypeGroup.controls.isHalfPage.enable();

                    // this.base64textString = "";
                }
                else {
                    this.showSequenceError = true;
                    return false;
                }
            }

            var compareSequence = function (val1: PageType, val2: PageType) {
                if (val1.sequences > val2.sequences) { return 1; }
                if (val1.sequences < val2.sequences) { return -1; }

                return 0;
            }

            this.pageTypeArray = this.pageTypeArray.sort(compareSequence);
            this.showPageType = true;
            this.pageTypeGroup.markAsUntouched();
            this.pageTypeGroup.resetForm();
            this.pageTypeGroup.submitted = false;
            this.pageTypeGroup.controls.pageTypeColor.setValue("#3a9fd9");
            this.eventFormGroup.controls.pageTypes.setValue(this.pageTypeGroup);

            //After adding page type validation should be in reset form.
            this.pageTypeGroup.controls.amount.setValidators([
                RxwebValidators.required({ message: ValidationMessage.canNotLeaveEmpty }),
                RxwebValidators.numeric(),
                RxwebValidators.range({ minimumNumber: 0, maximumNumber: 99999999, message: ValidationMessage.enterAppropriateValueInThisField })
            ]);

            this.pageTypeGroup.controls.amount.updateValueAndValidity();
            this.pageTypeGroup.controls.amount.setValue(null);
            this.pageTypeGroup.controls.amount.enable();
            this.showAmountField = true;
            this.setHalfPageDisabled();
            this.pageTypeGroup.controls.pageTypeColor.enable();
        }
    }

    deleteHonoree(value: any) {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.delete }).then(t => {
            if (t == CONSTANT_MESSAGES.yes) {
                this.honoreeArray.splice(value, 1);
                if (this.honoreeArray.length == 0) {
                    this.showHonoree = false
                }
            }
        });
    }

    deletePageType(index: any) {
        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.delete }).then(t => {
            if (t == CONSTANT_MESSAGES.yes) {
                this.pageTypeGroup.controls.arrayIndex.setValue(null);

                //It allows record going to be added just after having deleted half page.
                this.pageTypeArray.forEach(element => {
                    if (element.isHalfPage) {
                        this.pageTypeGroup.controls.isHalfPage.enable();
                    }
                });

                this.pageTypeArray.splice(index, 1)

                if (this.pageTypeArray.length == 0) {
                    this.showPageType = false
                    this.eventFormGroup.controls.pageTypes.setValue(null)
                }
            }
        });
    }

    editPageType(value: any) {
        var data = this.pageTypeArray[value];
        this.pageTypeGroup.controls.pageTypeName.setValue(data.pageTypeName);

        // //Uncomment below code for Ad Type.
        // for (let i = 0; i < this.adTypes.length; i++) {
        //     if (data.adTypeId == this.adTypes[i].adTypeId) {
        //         this.dbAdTypeName = this.adTypes[i].adType;
        //         this.pageTypeGroup.controls.adTypeId.setValue(data.adTypeId);
        //         this.base64textString = "";
        //     }
        // }

        this.showSequenceError = false;

        //this.colorPickerValue = data.pageTypeColor;
        if (data.pageTypeColor == undefined) {
            this.pageTypeGroup.controls.pageTypeColor.setValue("#ffffff");
            this.pageTypeGroup.controls.pageTypeColor.disable();
        }
        else {
            this.pageTypeGroup.controls.pageTypeColor.setValue(data.pageTypeColor);
            this.pageTypeGroup.controls.pageTypeColor.enable();
        }

        this.pageTypeGroup.controls.amount.setValue(data.amount);

        if (data.isHalfPage) {
            this.pageTypeGroup.controls.isHalfPage.setValue(data.isHalfPage);
            this.pageTypeGroup.controls.isHalfPage.enable();
        } else {
            //this.pageTypeGroup.controls.isHalfPage.disable();
            this.setHalfPageDisabled()
            this.pageTypeGroup.controls.isHalfPage.setValue(false);
        }

        // if (data.fillerAdDesign != "N/A") {
        //     this.base64textString = data.fillerAdDesign;
        //     this.pageTypeGroup.controls.fillerAdDesign.setValue(data.fillerAdDesign);
        // }

        //#region Uncomment below code for Ad Type.
        // //For Non-Paid Page Types
        // if (data.amount == 0) {
        //     this.pageTypeGroup.controls.amount.clearValidators();
        //     this.pageTypeGroup.controls.amount.updateValueAndValidity();
        //     this.pageTypeGroup.controls.amount.setValue(0);
        //     this.showAmountField = false;
        // } else {
        //     this.pageTypeGroup.controls.amount.setValidators([
        //         RxwebValidators.required({ message: "You can't leave this empty" }),
        //         RxwebValidators.numeric(),
        //         RxwebValidators.range({ minimumNumber: 0, maximumNumber: 99999999, message: 'You need to enter appropriate value in this field.' })
        //     ]);
        //     this.pageTypeGroup.controls.amount.updateValueAndValidity();
        //     this.showAmountField = true;
        // }
        //#endregion

        this.pageTypeGroup.controls.sequences.setValue(data.sequences);
        this.pageTypeGroup.controls.comments.setValue(data.comments);
        this.pageTypeGroup.controls.arrayIndex.setValue(value);
    }

    addEmail(selectedItem: any) {
        if (selectedItem.sourceItem != undefined) {
            // var id = selectedItem.item.key;
            // var emailId = this.eventLookupGroup.userLookUps.find(x => x.userId == id).email
            // this.eventManagerEmail = emailId;
            this.eventManagerEmail = selectedItem.sourceItem.email;
        } else {
            this.eventManagerEmail = "";
        }
    }

    addFundraiserEmail(selectedItem: any) {
        if (selectedItem.sourceItem != undefined) {
            // var id = selectedItem.item.key;
            // var emailId = this.eventLookupGroup.userLookUps.find(x => x.userId == id).email
            // this.eventFundraiserEmail = emailId;
            this.eventFundraiserEmail = selectedItem.sourceItem.email;
        } else {
            this.eventFundraiserEmail = "";
        }
    }

    addAdCreatorEmail(selectedItem: any) {
        if (selectedItem.sourceItem != undefined) {
            // var id = selectedItem.item.key;
            // var emailId = this.eventLookupGroup.userLookUps.find(x => x.userId == id).email
            // this.adCreatorEmail = emailId;
            this.adCreatorEmail = selectedItem.sourceItem.email;
        } else {
            this.adCreatorEmail = "";
        }
    }

    addOtherManagerEmail(selectedItem: any) {
        if (selectedItem.sourceItem != undefined) {
            // var id = selectedItem.item.key;
            // var emailId = this.eventLookupGroup.userLookUps.find(x => x.userId == id).email
            // this.otherManagerEmail = emailId;
            this.otherManagerEmail = selectedItem.sourceItem.email;
        } else {
            this.otherManagerEmail = "";
        }
    }

    setStatusValue() {
        if (this.eventFormGroup.controls.status.value) {
            this.eventFormGroup.controls.statusId.setValue(StatusEnum.Active);
        } else {
            this.eventFormGroup.controls.statusId.setValue(StatusEnum.Inactive);
        }
    }

    addEvent() {
        this.setStatusValue();
        this.eventFormGroup.submitted = true;
        this.eventFormGroup.value.eventHonoree = this.honoreeArray;

        let reUsingArray = JSON.parse(JSON.stringify(this.pageTypeArray));

        this.pageTypeArray.forEach(element => {
            if (element.pageTypeColor == undefined || element.pageTypeColor == null)
                element.pageTypeColor = "255,255,255";
            else
                element.pageTypeColor = this.hexToRgb(element.pageTypeColor);

            // this.binaryStringList.forEach(e => {
            //     if (e.sequence == element.sequences)
            //         element.fillerAdDesign = e.binaryString;
            // });

            // //Uncomment below code for Ad Type.
            // if (element.adTypeId == AdTypeEnum.NonPaidAd)
            //     element.fillerAdDesign = null;
        });

        this.eventFormGroup.value.pageTypes = this.pageTypeArray


        if (this.eventFormGroup.valid && !this.showJournalErrorMessage && !this.showDateErrorMessage) {
            this.spin = true;

            // // While doing post call it is showing different behaviour so had to do below code, please comment below foreach to see that behaviour.
            // this.reUsingPageTypeArray(reUsingArray);
            this.pageTypeArray = reUsingArray;

            this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.eventsApiPath, this.eventFormGroup.value).subscribe(data => {
                this.spin = false;

                this.toastr.success(ValidationMessage.dataAdded)
                this.route.navigate([CONSTANT_MESSAGES.eventBackSlash]);
            });
        }
        // else {
        //     // While doing post call it is showing different behaviour so had to do below code, please comment below foreach to see that behaviour.
        //     this.reUsingPageTypeArray(reUsingArray);
        this.pageTypeArray = reUsingArray;
        // }
    }

    // handleFileSelect(evt) {
    //     this.fileType = "";
    //     var files = evt.target.files;
    //     var file = files[0];
    //     let ext = file.name.split('.').pop(),
    //         fileType = file.type;
    //     ext = ext.toLowerCase();

    //     if (file.size >= (5000 * 1024)) {
    //         this.toastr.error(ValidationMessage.fileSizeExceeds);
    //         return;
    //     }

    //     if ([CONSTANT_MESSAGES.pdf, CONSTANT_MESSAGES.jpg, CONSTANT_MESSAGES.png, CONSTANT_MESSAGES.jpeg].indexOf(ext) != -1) {
    //         this.pdfPagesCount = 0;
    //         if (file) {
    //             this.fileType = ext;
    //             var reader = new FileReader();
    //             if ([CONSTANT_MESSAGES.jpg, CONSTANT_MESSAGES.png, CONSTANT_MESSAGES.jpeg].indexOf(ext) != -1) {
    //                 reader.onload = this._handleReaderLoaded.bind(this);
    //                 reader.readAsBinaryString(file);
    //             }
    //             else {
    //                 reader.onload = this._handleReaderLoaded.bind(this);
    //                 reader.readAsBinaryString(file);
    //             }
    //         }
    //     }
    //     else if ([CONSTANT_MESSAGES.tiff, CONSTANT_MESSAGES.tif].indexOf(ext) != -1) {
    //         this.toastr.error(ValidationMessage.tiffFilesAreNotSupported);
    //     }
    //     else {
    //         this.toastr.error(ValidationMessage.supportPdfJpgJpeg);
    //     }
    // }

    // _handleReaderLoaded(readerEvt) {
    //     var binaryString = readerEvt.target.result;
    //     this.binaryString = btoa(binaryString)

    //     if (this.binaryString.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.ivbor || this.binaryString.substring(0, 5) == CONSTANT_MESSAGES.subStringOfPNG)
    //         this.base64textString = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePngAndBase64 + this.binaryString);
    //     else if (this.binaryString.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.subStringOfJPEG)
    //         this.base64textString = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimeJpegAndBase64 + this.binaryString);
    //     else if (this.binaryString.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.jvber)
    //         this.base64textString = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + this.binaryString);

    //     this.pageTypeGroup.controls.fillerAdDesign.setValue(this.base64textString);
    //     // this.pageTypeGroup.value.fillerAdDesign = this.base64textString;
    //     // this.pageTypeGroup.controls.isModifiedJournalAdCopyLogo.setValue(true);

    //     let base64Json = { "base64": this.binaryString }

    //     this.spin = true;

    //     this.apiCallService.postAPI(CUSTOM_URI.countPagesInPDF, base64Json).subscribe((response: number) => {
    //         this.pdfPagesCount = response;
    //         this.spin = false;
    //     })
    // }

    // onImgClick(value) {
    //     if (value.changingThisBreaksApplicationSecurity == undefined)
    //         value = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + this.binaryString);

    //     this.modalView.show(ImageModalComponent, {
    //         Image: value.changingThisBreaksApplicationSecurity.split(',')[1],
    //         Title: ValidationMessage.uploadedFillerAdDesign
    //     }).then(t => {
    //         if (t != null || t != undefined) {
    //         }
    //     });
    // }

    setHalfPageValue() {
        if (this.pageTypeGroup.controls.isHalfPage.value) {
            this.pageTypeGroup.controls.isHalfPage.setValue(true);
            this.pageTypeGroup.controls.pageTypeColor.setValue("#ffffff");
            this.pageTypeGroup.controls.pageTypeColor.disable();
        }
        else {
            this.pageTypeGroup.controls.isHalfPage.setValue(false);
            this.pageTypeGroup.controls.pageTypeColor.enable();
        }
    }

    setHalfPageDisabled() {
        this.pageTypeArray.forEach(element => {
            if (element.isHalfPage) {
                this.pageTypeGroup.controls.isHalfPage.disable();
            }
        });
    }

    // RemoveLogo() {
    //     this.pageTypeGroup.controls.fillerAdDesign.setValue("");
    //     this.base64textString = "";
    //     this.binaryString = null;
    // }

    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let rgb = parseInt(result[1], 16) + "," + parseInt(result[2], 16) + ',' + parseInt(result[3], 16);
        return rgb;
    }

    onEventName(value) {
        var format = /[*?:"|<>\\/]/;
        if (format.test(value)) {
            this.showEventNameErrorMessage = true;
            this.selectEventNameErrorMessage = "Please do not add following characters" + this.specialChar;
        }
        else {
            this.showEventNameErrorMessage = false;
        }
    }

    // rgbTohex(rgb) {
    //     let rgbElemnet = rgb.split(",");
    //     var r = this.hexCode(rgbElemnet[0].split("(")[1]);
    //     var g = this.hexCode(rgbElemnet[1]);
    //     var b = this.hexCode(rgbElemnet[2].split(")")[0]);
    //     return "#" + r + g + b;
    // }

    // hexCode(rgb) {
    //     var hex = Number(rgb).toString(16);
    //     if (hex.length < 2) { hex = "0" + hex; }
    //     return hex;
    // }

    // //Uncomment below code for Ad Type.
    // isNonPaidAd() {
    //     if (this.pageTypeGroup.value.adTypeId == AdTypeEnum.NonPaidAd) {
    //         this.pageTypeGroup.controls.amount.clearValidators();
    //         this.pageTypeGroup.controls.amount.updateValueAndValidity();
    //         this.pageTypeGroup.controls.amount.setValue(0);
    //         this.base64textString = "";
    //         this.showAmountField = false;
    //     }
    //     else if (this.pageTypeGroup.value.adTypeId == AdTypeEnum.PaidAd) {
    //         this.pageTypeGroup.controls.amount.setValidators([
    //             RxwebValidators.required({ message: "You can't leave this empty" }),
    //             RxwebValidators.numeric(),
    //             RxwebValidators.range({ minimumNumber: 0, maximumNumber: 99999999, message: 'You need to enter appropriate value in this field.' })
    //         ]);
    //         this.pageTypeGroup.controls.amount.updateValueAndValidity();
    //         //this.pageTypeGroup.controls.amount.setValue(null);
    //         // this.pageTypeGroup.controls.amount.enable();
    //         this.showAmountField = true;
    //     }
    // }

    // binaryStringList = [];

    // reUsingPageTypeArray(reUsingArray: any) {
    //     reUsingArray.forEach(element => {
    //         this.binaryStringList.forEach(e => {
    //             if (e.sequence == element.sequences)
    //                 element.fillerAdDesign.changingThisBreaksApplicationSecurity = e.binaryString;
    //         });
    //     });

    //     this.pageTypeArray = reUsingArray;
    // }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    bindApplicationData() {
        this.subscription = this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.applicationdatas).subscribe((t: any) => {
            this.spin = false;
            this.eventFormGroup.controls.adSpec.setValue(t.filter(data => data.keyName == CONSTANT_MESSAGES.adSpecKey)[0].keyValue);

        })
    }

    editorKeyupHandler(value: any, control: string, disclaimerFormGroup: any): void {
        disclaimerFormGroup.controls[control].setValue(value);
    }
}

// class fillAdDesignBinaryString {
//     sequence: number;
//     binaryString: string;
// }