import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RxHttp } from '@rxweb/http';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { EmailNotificationListComponent } from '../../master/email-notifications/list/email-notification-list.component';
import { AuthorizationResolver } from 'src/app/domain/security/authorization-resolver';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent extends CoreComponent implements OnInit {

    isSideBarActive:boolean=true;
    loginUserName: string;
    emailNotificationsCount : number;
    spin = false
    accessEmailNotification : any = EmailNotificationListComponent

    constructor(
      private applicationBroadCaster: ApplicationBroadcaster,
      private route: Router,
      private storagedata: BrowserStorage,
      private http: RxHttp,
      private readonly authorizationResolver: AuthorizationResolver) {
        super();
        this.storagedata = new BrowserStorage();
        this.applicationBroadCaster.userNameSubscriber.subscribe(t => {
            this.loginUserName = t;
        });
        this.applicationBroadCaster.emailNotificationsSubscriber.subscribe(t => {
            this.emailNotificationsCount = t;
        });
    }

    ngOnInit() {
        var auth = this.storagedata.local.get("auth",false);
        if (auth) {
            this.loginUserName = this.storagedata.local.get('userName');
        }
        if(window.innerHeight<769)
            this.isSideBarActive=false;
    }

    isToggleSideBar() {
        this.isSideBarActive = !this.isSideBarActive
        if (this.isSideBarActive) {
            document.body.className = "fixed-sidebar fixed-nav fixed-nav-basic pace-done mini-navbar"
        }
        else {
            document.body.className = "fixed-sidebar fixed-nav fixed-nav-basic pace-done"
        }
    }

    logout() {

        this.spin = true
        this.post({ path: "api/Authorize/logout", body: { "AudienceType": "web", "LanguageCode": "en" } }).subscribe(data => {
            this.storagedata.local.clearAll();
            this.authorizationResolver.clearPermissions();
            window.setTimeout(() => {
                this.spin = false
                this.route.navigate(["/login"])
                var element = document.getElementById("gtrac");
                element.classList.add("login_page_view");
            }, 1000);
        })
    }


}
