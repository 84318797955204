import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { User } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent } from "@rxweb/angular-router"
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { BreadCrumb } from '../../../../viewModel/bread-crumb';
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";


@http({
    path: APPLICATION_URL_CONSTANTS.users,
})

export class AbstractUser extends CoreComponent {

    user: User;
    userFormGroup: IFormGroup<User>
    showComponent: boolean = false;
    isShowGridColumn: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr
    dialog: BaseDialog;
    modalView: ModalView;
    breadCrumb: BreadCrumb;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();

        this.breadCrumb.name1 = CONSTANT_MESSAGES.home;
        this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard;

        this.breadCrumb.name2 = CONSTANT_MESSAGES.userManagement

        this.breadCrumb.title = CONSTANT_MESSAGES.users;
        this.breadCrumb.pagename = CONSTANT_MESSAGES.users;
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);

        if (messages.errors) {
            this.toastr.warning(messages.errors.email);
        }
        else {
            this.toastr.error(messages[0]);
        }
    }
}