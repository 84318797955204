import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EmailTemplateBase {

        //#region emailTemplateId Prop
        @prop()
        emailTemplateId: number;
        //#endregion emailTemplateId Prop


        //#region name Prop
        @required()
        @maxLength({ value: 500 })
        name: string;
        //#endregion name Prop


        //#region emailTemplateType Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        emailTemplateType: number;
        //#endregion emailTemplateType Prop


        //#region templateBody Prop
        @required()
        templateBody: string;
        //#endregion templateBody Prop


        //#region mailFrom Prop
        @maxLength({ value: 100 })
        mailFrom: string;
        //#endregion mailFrom Prop


        //#region mailTo Prop
        @maxLength({ value: 3000 })
        mailTo: string;
        //#endregion mailTo Prop


        //#region subject Prop
        @required()
        @maxLength({ value: 500 })
        subject: string;
        //#endregion subject Prop


        //#region cC Prop
        @maxLength({ value: 3000 })
        cc: string;
        //#endregion cC Prop


        //#region eventManager Prop
        @prop()
        eventManager: boolean;
        //#endregion eventManager Prop

        //#region eventFundraiser Prop
        @prop()
        eventFundraiser: boolean;
        //#endregion eventFundraiser Prop


        //#region adCreator Prop
        @prop()
        adCreator: boolean;
        //#endregion adCreator Prop


        //#region fundraiser Prop
        @prop()
        fundraiser: boolean;
        //#endregion fundraiser Prop


        //#region otherManager Prop
        @prop()
        otherManager: boolean;
        //#endregion otherManager Prop


        //#region sequence Prop
        @prop()
        sequence: number;
        //#endregion sequence Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region adDesignStatus Prop
        @prop()
        adDesignStatus: number;
        //#endregion adDesignStatus Prop


        //#region mostRecentEmail Prop
        @prop()
        mostRecentEmail: number;
        //#endregion mostRecentEmail Prop


        //#region systemGeneratedEmail Prop
        @prop()
        systemGeneratedEmail: number;
        //#endregion systemGeneratedEmail Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop



}