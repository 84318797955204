import {ApplicationUserTokenBase} from '../database-models/application-user-token-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class ApplicationUserToken extends ApplicationUserTokenBase 
{




//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties









































}