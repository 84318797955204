import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAdJournalRelationShipOwnerLookupBase {

//#region relationshipOwnerId Prop
        // @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'relationshipOwnerId', keyColumn: true})
        // relationshipOwnerId : number;
//#endregion relationshipOwnerId Prop


//#region relationshipOwnerUserId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'relationshipOwnerUserId', keyColumn: false})
        relationshipOwnerUserId : any;
//#endregion relationshipOwnerUserId Prop


//#region relationshipOwnerName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'relationshipOwnerName', keyColumn: false})
        relationshipOwnerName : string;
//#endregion relationshipOwnerName Prop

}