import {vUploadDesignBase} from '../database-models/v-upload-design-base';
//Generated Imports
export class vUploadDesign extends vUploadDesignBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}