import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventPageTypeBase {

        //#region eventId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'eventId', keyColumn: true })
        eventId: number;
        //#endregion eventId Prop


        //#region pageTypeId Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'pageTypeId', keyColumn: false })
        pageTypeId: number;
        //#endregion pageTypeId Prop


        //#region pageTypeName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'pageTypeName', keyColumn: false })
        pageTypeName: string;
        //#endregion pageTypeName Prop


        //#region amount Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'amount', keyColumn: false })
        amount: any;
        //#endregion amount Prop

        //#region adTypeId Prop
        // @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'adTypeId', keyColumn: false})
        isHalfPage: boolean;
        //#endregion adTypeId Prop

}