import { required, email, maxLength, pattern, compare } from '@rxweb/reactive-form-validators';

export class UserCredentialResetPasswordModel {
    
    userId: number
    
    @required()
    @email()
    @maxLength({ value: 50 })
    email: string = undefined;

    //#region userPassword Prop
    @pattern({ expression: { 'onlypassword': /^(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,15}$/ } })
    @maxLength({ value: 15 })
    @required()
    userPassword: string;
    //#endregion userPassword Prop

    //#region userConfirmPassword Prop
    @pattern({ expression: { 'onlypassword': /^(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,15}$/ } })
    @maxLength({ value: 15 })
    @required()
    @compare({ fieldName: 'userPassword' })
    userConfirmPassword: string;
    //#endregion userConfirmPassword Prop

    //#region verificationCode Prop
    verificationCode: string;
    //#endregion verificationCode Prop

    //#region isResetPassword Prop
    IsResetPassword: string;
    //#endregion isResetPassword Prop
}
