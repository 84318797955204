import { CONSTANT_MESSAGES } from "../const/constant-messages/constant-messages";

export class SearchDetails {
    searchDetails: string;
}

export class Query {
    query: any;
    category: string;
    eventId: number;
}

export class SearchQuery {
    public searchQuery: any;
}

export class spParameter {
    searchQuery: any;
    pageQuery: string;
}

export class pageDataQuery {
    PageIndex: number;
    PageRow: number;
    OrderColumn: string;
    OrderType: string;
}

export class QueryForDownload {
    query: any;
    category: string;
    eventId: number;
    isDownload: boolean = false;
    type: string = '';
    fileName: string = '';
}

export class PdfExcelDetail {
    Result: string;
    Type: string;
    FileName: string;
}

export function downloadDocument(data: any, fileName: string, fileType: string) {
    var binaryString = window.atob(data.result);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);

    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    var link = document.createElement('a');

    var blob = new Blob([bytes], { type: fileType });

    link.href = window.URL.createObjectURL(blob);
    
    var containsDot = fileName.indexOf('.') !== -1;
    if(containsDot){
        var extension = fileType == CONSTANT_MESSAGES.mimePdf ? CONSTANT_MESSAGES.pdfExtention : CONSTANT_MESSAGES.excelExtension;
        fileName = fileName + extension;
    }
    if (fileType == CONSTANT_MESSAGES.mimeExcel && fileName.indexOf('.xlsx') == -1)
        fileName = fileName + CONSTANT_MESSAGES.excelExtension;
    link.download = fileName;
    
    document.body.appendChild(link);

    link.click();
}