import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vOPSReplaceDataBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region donorName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'donorName', keyColumn: false})
        donorName : string;
//#endregion donorName Prop


//#region donorSignature Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'donorSignature', keyColumn: false})
        donorSignature : string;
//#endregion donorSignature Prop


//#region journalAdCopy Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'journalAdCopy', keyColumn: false})
        journalAdCopy : string;
//#endregion journalAdCopy Prop


//#region journalAdAlphaName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'journalAdAlphaName', keyColumn: false})
        journalAdAlphaName : string;
//#endregion journalAdAlphaName Prop


//#region eventHonoree Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'eventHonoree', keyColumn: false})
        eventHonoree : string;
//#endregion eventHonoree Prop


//#region eventTemplate Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'eventTemplate', keyColumn: false})
        eventTemplate : string;
//#endregion eventTemplate Prop


//#region imgUrl Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'imgUrl', keyColumn: false})
        imgUrl : string;
//#endregion imgUrl Prop


//#region imageData Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'imageData', keyColumn: false})
        imageData : string;
//#endregion imageData Prop

}