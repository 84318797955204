import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_NOTIFICATION_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onLink"
        },
        childrens: [{
          div: {
            class: [function (e) {
              if (this.emailTemplateId == 16 || this.emailTemplateId == 17) {
                return "anchor-hover"
              }
            },function (e) {
              if (this.emailTemplateId == 16 || this.emailTemplateId == 17) {
                return "underline"
              }
            }],
            childrens: [{
              text: {
                text: function (e) { return this[e.name] }
              }
            }]
          }
        }]
      }
    }],
  }
};
