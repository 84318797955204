import { TemplateConfig } from '@rxweb/grid';

export const CHECKBOX_TEMPLATE_GRID_TEMPLATE: TemplateConfig = {
  ul: {
    class: ["formAction", "text-center"],
    childrens: [
      {
        li: {
          authorize: "add",
          childrens: [
            {
              div: {
                class: ["custom-control", "custom-checkbox"],
                style: { "margin-bottom": "-3px" },
                childrens: [
                  {
                    input: {
                      event: {
                        input: "onCheckBoxSelect"
                      },
                      class: ["custom-control-input"],
                      attributes: {
                        id: function (e) {
                          return "approval" + this.adIntakeFormId;
                        },
                        type: "checkbox",
                        checked: function (e) {
                          return this.isChecked;
                        },
                        value: function (e) {
                          return this.isChecked;
                        },
                      }
                    },
                    label: {
                      attributes: {
                        for: function (e) {
                          return "approval" + this.adIntakeFormId;
                        },
                      },
                      class: ["sr-only", "custom-control-label", "d-flex", "align-items-center"]
                    }
                  }
                ]
              }
            }
          ]
        }
      },
      {
        li: {
          authorize: "repeat",
          childrens: [
            {
              a: {
                authorize: "reject",
                event: {
                  click: "onReject"
                },
                childrens: [
                  {
                    i: {
                      class: "fa fa-close".split(" "),
                      style: { "font-size": "30px", "color": "#ff2950ba" }
                    }
                  }
                ],
              }
            }
          ],
          style: { "margin-left": "-5px;" }
        }
      }
    ]
  }
};
