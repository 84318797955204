import { TemplateConfig } from '@rxweb/grid';

export const Thumbnail_Image_GRID_TEMPLATE: TemplateConfig = {
  img: {
    event: {
      click: "onImgClick"
    },
    attributes: {
      width: 40,
      height: 40,
      src: function (e) {
        return this[e.name];
      },
    }
  },
};
