import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { ImageModalComponent } from './image-modal.component';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [ImageModalComponent],
    exports:[ImageModalComponent],
    providers: [],
    entryComponents:[ImageModalComponent]
})
export class WIPSharedModule { }

