import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { EmailModalComponent } from './email-modal.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [EmailModalComponent],
    exports: [EmailModalComponent],
    providers: [],
    entryComponents: [EmailModalComponent]
})

export class AdIntakeFormSharedModule { }