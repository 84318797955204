import {vEventLocationBase} from '../database-models/v-event-location-base';
//Generated Imports
export class vEventLocation extends vEventLocationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}