import {EventHonoreeBase} from '../database-models/event-honoree-base';
import {EventBase} from '../database-models/event-base';
//Generated Imports
export class EventHonoree extends EventHonoreeBase 
{




//#region Generated Reference Properties
//#region event Prop
event : EventBase;
//#endregion event Prop

//#endregion Generated Reference Properties












































}