import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_APPROVAL_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onLink"
        },
        childrens: [{
          div: {
            style: {
              "text-decoration": "underline",
              "cursor": "pointer",
            },
            class: ["anchor-hover"],
            childrens: [{
              text: {
                text: function (e) { return this[e.name] }
              }
            }]
          }
        }]
      }
    }],
  }
};
