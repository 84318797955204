import {vIntakeFormTemplateBase} from '../database-models/v-intake-form-template-base';
//Generated Imports
export class vIntakeFormTemplate extends vIntakeFormTemplateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}