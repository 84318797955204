import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractDivision } from '../domain/abstract-division';
import { Division, vDivision } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { DivisionAddComponent } from '../add/division-add.component';
import { access } from '@rxweb/angular-router';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@access({ accessLevel: RolePermissionModule.Division, action: ActionType.GET })

@Component({
    selector: "app-division-list",
    templateUrl: './division-list.component.html'
})

export class DivisionListComponent extends AbstractDivision implements OnInit, OnDestroy {
    divisions: vDivision;
    subscription: Subscription;
    divisionsGrid: AppGrid;
    divisionsGridColumns: any[];
    divisionAdd: any = DivisionAddComponent;
    searchValue: string;

    constructor(private route: Router, private apiCallService: ApiCallService) {
        super();
    }

    ngOnInit(): void {
        this.spin = true;

        this.bindGrid();
    }

    bindGrid() {
        this.subscription = this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.divisions).subscribe((t: any) => {
            this.spin = false;
            this.divisions = t;

            this.divisionsGrid = new AppGrid(t, vDivision, {
                actions: {
                    onEdit: this.onEdit.bind(this)
                }
            });
            this.divisionsGrid.authorization = {
                'edit': {
                    accessLevel: RolePermissionModule.Division,
                    action: ActionType.PUT
                }
            };
            this.divisionsGrid.design(document.getElementById(CONSTANT_MESSAGES.divisions));

            this.divisionsGridColumns = this.divisionsGrid.gridColumns.filter(x => x.keyColumn == false);
        })
    }

    onEdit(division: Division) {
        this.route.navigate([CONSTANT_MESSAGES.divisionPath, division.divisionId]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    search(value) {
        this.searchValue = value;
        this.divisionsGrid.search = this.searchValue
    }
}