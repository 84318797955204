import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailNotificationBase {

        //#region emailDetailId Prop
        @gridColumn({ headerCellClass: [ "w-160"], class: ["w-160"],isAscending: false, visible: false, columnIndex: 0, allowSorting: true, headerKey: 'emailNotificationId', keyColumn: true })
        //emailDetailId: number;
        emailNotificationId: number;
        //#endregion emailDetailId Prop

        //#region userId Prop
        @gridColumn({ isAscending: false, visible: false, columnIndex: 1, allowSorting: false, headerKey: 'userId', keyColumn: false })
        userId: number;
        //#endregion userId Prop

        //#region divisionName Prop
        // @gridColumn({ isAscending: false, visible: false, columnIndex: 2, allowSorting: true, headerKey: 'divisionName', keyColumn: false, headerTitle: "Division Name" })
        // divisionName: string;
        //#endregion divisionName Prop

        //#region eventName Prop
        @gridColumn({ headerCellClass: [ "w-205"], class: ["w-205"], isAscending: false, visible: true, columnIndex: 2, allowSorting: true, headerKey: 'eventName', keyColumn: false, headerTitle: "Event Name" })
        eventName: string;
        //#endregion eventName Prop

        //#region subject Prop
        @gridColumn({ headerCellClass: [ "w-400"], class: ["w-400"], isAscending: false, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'subject', keyColumn: false, headerTitle: "Subject", configuredTemplate: { templateName: "redirectNotificationLink" } })
        subject: string;
        //#endregion subject Prop

        //#region donorId Prop
        // @gridColumn({ isAscending: false, visible: false, columnIndex: 4, allowSorting: true, headerKey: 'donorId', keyColumn: false, headerTitle: "DonorId" })
        // donorId: number;
        //#endregion donorId Prop

        //#region donorName Prop
        @gridColumn({ headerCellClass: [ "w-205"], class: ["w-205"], isAscending: false, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'donorName', keyColumn: false, headerTitle: "Donor Name" })
        donorName: string;
        //#endregion donorName Prop

        //#region createdOn Prop
        @gridColumn({ headerCellClass: [ "w-200"], class: ["w-200"], isAscending: false, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'createdOn', keyColumn: false, headerTitle: "Date & Time", configuredTemplate: { templateName: "dateTimeFormat" } })
        createdOn: any;
        //#endregion createdOn Prop

        //#region from Prop
        @gridColumn({ headerCellClass: [ "w-250"], class: ["w-250"],isAscending: false, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'from', keyColumn: false, headerTitle: "Sender Name" })
        from: string;
        //#endregion from Prop

        //#region to Prop
        @gridColumn({ headerCellClass: [ "w-250"], class: ["w-250"],isAscending: false, visible: true, columnIndex: 7, allowSorting: true, headerKey: 'to', keyColumn: false, headerTitle: "To" })
        to: string;
        //#endregion to Prop

        //#region cC Prop
        @gridColumn({ headerCellClass: [ "w-250"], class: ["w-250"],isAscending: false, visible: true, columnIndex: 8, allowSorting: true, headerKey: 'cc', keyColumn: false, headerTitle: "CC" })
        cc: string;
        //#endregion cC Prop

        //#region isPreview Prop
        @gridColumn({ isAscending: false, visible: false, columnIndex: 9, allowSorting: true, headerKey: 'isPreview', keyColumn: false })
        isPreview: boolean;
        //#endregion isPreview Prop

}