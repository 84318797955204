import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ApplicationBroadcaster {
    private loginSubject: Subject<boolean> = new Subject<boolean>();

    public loginSubscriber: Observable<boolean>;

    private allTypeSubject: Subject<boolean> = new Subject<any>();

    public allTypeSubscriber: Observable<any>;

    private userNameSubject: Subject<string> = new Subject<any>();

    public userNameSubscriber: Observable<any>;

    private emailNotificationsSubject: Subject<number> = new Subject<any>();

    public emailNotificationsSubscriber: Observable<any>;

    constructor() {
        this.loginSubscriber = this.loginSubject.asObservable();
        this.allTypeSubscriber = this.allTypeSubject.asObservable();
        this.userNameSubscriber = this.userNameSubject.asObservable();
        this.emailNotificationsSubscriber = this.emailNotificationsSubject.asObservable();
    }

    loginBroadCast(value: boolean): void {
        this.loginSubject.next(value);
    }

    userNameBroadCast(value:string): void {
        this.userNameSubject.next(value);
    }

    emailNotificationsCount(value : number) : void {
        this.emailNotificationsSubject.next(value);
    }

}
