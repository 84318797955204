import { Component, OnInit, Input } from '@angular/core';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { DivisionListComponent } from '../../master/divisions/list/division-list.component';
import { EventListComponent } from '../../event/events/list/event-list.component';
import { UserListComponent } from '../../user/users/list/user-list.component';
import { RoleListComponent } from '../../user/roles/list/role-list.component';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { EventViewComponent } from '../../event/events/view/event-view.component';
import { EmailTemplateListComponent } from '../../master/email-templates/list/email-template-list.component';
import { HouseAndBrandAdsDignitariesLetterListComponent } from '../../event/house-and-brand-ads-dignitaries-letters/list/house-and-brand-ads-dignitaries-letter-list.component';
import { ReportComponent } from '../../event/report/view/report.component';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { AuditLogListComponent } from '../../audit-log/audit-logs/list/audit-log-list.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})

export class SideBarComponent extends CoreComponent implements OnInit {
  dashboardList: any = DashboardComponent
  //reportView : any = ReportViewComponent
  userList: any = UserListComponent
  roleList: any = RoleListComponent
  divisionList: any = DivisionListComponent
  emailTemplateList: any = EmailTemplateListComponent
  eventList: any = EventListComponent
  houseBrandList: any = HouseAndBrandAdsDignitariesLetterListComponent
  reportList: any = ReportComponent
  auditLogList: any = AuditLogListComponent

  isMenuHide: boolean;
  activeMenu: number;
  activeSubMenu: number;
  //@Input() applyActive: boolean;
  applyActive: boolean;
  dashboard: boolean = false;
  adJournal: boolean = false;
  reports: boolean = false;
  userManagement: boolean = false;
  masterData: boolean = false;
  event: boolean = false;
  preProd: boolean = false;
  auditLog: boolean = false;
  accessUserManagement: boolean = true

  constructor(private applicationBroadCaster: ApplicationBroadcaster, private storagedata: BrowserStorage) {
    super();
    this.storagedata = new BrowserStorage();
  }

  ngOnInit() {
    //this.accessUserManagement = dbAccessModule[RolePermissionModule.User][ActionType.GET] || dbAccessModule[RolePermissionModule.Role][ActionType.GET] ? true : false
    this.applicationBroadCaster.allTypeSubscriber.subscribe(t => {
      var sidebarApplyActive = t;
      if (sidebarApplyActive) {
        this.dashboard = false;
        this.adJournal = false;
        this.reports = false;
        this.userManagement = false;
        this.masterData = false;
        this.event = false;
        this.preProd = false;
        this.auditLog = false;
      }
    })
  }

  activeMenuItem(menu): void {
    this.activeMenu = menu;
    this.storagedata.local.save("activeMenu", menu);
    switch (menu) {
      case 1:
        this.setOthersFalse('Dashboard');
        this.showHide('Dashboard', !this.dashboard);
        break;
      case 2:
        this.setOthersFalse('adJournal');
        this.showHide('adJournal', !this.adJournal);
        break;
      case 3:
        this.setOthersFalse('reports');
        this.showHide('reports', !this.reports);
        break;
      case 4:
        this.setOthersFalse('userManagement');
        this.showHide('userManagement', !this.userManagement);
        break;
      case 5:
        this.setOthersFalse('masterData');
        this.showHide('masterData', !this.masterData);
        break;
      case 6:
        this.setOthersFalse('event');
        this.showHide('event', !this.event);
        break;
      case 7:
        this.setOthersFalse('preProd');
        this.showHide('preProd', !this.preProd);
        break;
      case 8:
        this.setOthersFalse('auditLog');
        this.showHide('auditLog', !this.auditLog);
        break;
    }
  }

  showHide(itemClicked: string, status: boolean, isCalledFromUI: boolean = false): void {
    if (isCalledFromUI && !this.applyActive) return;

    switch (itemClicked) {
      case 'dashboard':
        this.dashboard = status;
        break;
      case 'adJournal':
        this.adJournal = status;
        break;
      case 'reports':
        this.reports = status;
        break;
      case 'userManagement':
        this.userManagement = status;
        break;
      case 'masterData':
        this.masterData = status;
        break;
      case 'event':
        this.event = status;
        break;
      case 'preProd':
        this.preProd = status;
        break;
      case 'auditLog':
        this.auditLog = status;
        break;
    }
  }

  setOthersFalse(activeItemName: string): void {
    switch (activeItemName) {
      case 'dashboard':
        this.adJournal = false;
        this.reports = false;
        this.userManagement = false;
        this.masterData = false;
        this.event = false;
        this.preProd = false;
        this.auditLog = false;
        break;

      case 'adJournal':
        this.dashboard = false;
        this.reports = false;
        this.userManagement = false;
        this.masterData = false;
        this.event = false;
        this.preProd = false;
        this.auditLog = false;
        break;

      case 'reports':
        this.dashboard = false;
        this.adJournal = false;
        this.userManagement = false;
        this.masterData = false;
        this.event = false;
        this.preProd = false;
        this.auditLog = false;
        break;

      case 'userManagement':
        this.dashboard = false;
        this.adJournal = false;
        this.reports = false;
        this.masterData = false;
        this.event = false;
        this.preProd = false;
        this.auditLog = false;
        break;

      case 'masterData':
        this.dashboard = false;
        this.adJournal = false;
        this.reports = false;
        this.userManagement = false;
        this.event = false;
        this.preProd = false;
        this.auditLog = false;
        break;

      case 'event':
        this.dashboard = false;
        this.adJournal = false;
        this.reports = false;
        this.userManagement = false;
        this.masterData = false;
        //this.event = false;
        this.preProd = false;
        this.auditLog = false;
        break;

      case 'preProd':
        this.dashboard = false;
        this.adJournal = false;
        this.reports = false;
        this.userManagement = false;
        this.masterData = false;
        this.event = false;
        this.auditLog = false;
        break;

      case 'auditLog':
        this.dashboard = false;
        this.adJournal = false;
        this.reports = false;
        this.userManagement = false;
        this.masterData = false;
        this.event = false;
        this.preProd = false;
        break;
    }
  }
}