import { vApprovalListBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators'
//Generated Imports

import { actionColumn, gridColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredHeaderTemplate: { templateName: 'bindCheckBox' },
    headerCellClass: ["w-50", "text-center"], columnIndex: 0, class: ["w-auto", "actionFild"], configuredTemplate: { templateName: 'chckboxTemplate' }
})

export class vApprovalList extends vApprovalListBase {

    //#region Generated Reference Properties
    @gridColumn({ headerCellClass: ["w-50"], class: ["w-50"], isAscending: false, visible: false, columnIndex: 14, allowSorting: false, headerKey: 'isChecked', keyColumn: false })
    isChecked: boolean;
    //#endregion Generated Reference Properties

}