import { TemplateConfig } from '@rxweb/grid';

export const HEADER_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    authorize: "add",
    class:["custom-control", "custom-checkbox","m-0"],
    childrens: [
      {
        input: {
            event: {
              click: "onHeaderCheckBoxSelect"   
          },
          
          class:["custom-control-input"],
          attributes: {
            id:function (e) {
              
              return "approvalHeader"+ this[e.name];
            },
            type: "checkbox",
            value: function (e) {
              return this[e.name];
            },
            checked: '$onChecked'
          }
        },
        label:{
          attributes:{
            for:function (e) {
              return "approvalHeader"+ this[e.name];
            },
          },
          class:["sr-only", "custom-control-label", "d-flex", "align-items-center"]
        },
      }
    ]
  },
};
