import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractIntakeFormSorting } from '../domain/abstract-intake-form-sorting';
import { IntakeFormSorting } from "@app/models";
import { Subscription } from 'rxjs';
import { EventLookupGroup } from 'src/app/viewModel/event-lookup-group';
import { EVENT_LOOKUPS } from 'src/app/const/uris/event-lookups.uri';
import { EventSelectionHeader } from 'src/app/viewModel/eventSelectionHeader';
import { DomSanitizer } from '@angular/platform-browser';
import { BrandTypeEnum } from 'src/app/enums/brand-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { access } from '@rxweb/angular-router';
import { MandatoryHouseAndBrandDignitaryLetters } from 'src/app/models/extended-models/mandatory-house-and-brand-dignitary-letters';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { AdTypeEnum } from 'src/app/enums/adType.enum';
import { EventModalComponent } from "../../events/event-modal.component";
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { ValidationMessage } from "src/app/const/validation-msg/custom-validation";
import { downloadDocument } from "src/app/viewModel/spGridClass";
import { DownloadPdfModalComponent } from '../list/download-modal.component';
import { DownloadPdfTypeEnum } from "src/app/enums/download-pdf-type.enum";
declare const $: any;

@access({ accessLevel: RolePermissionModule.Pagination, action: ActionType.GET })

@Component({
    selector: "app-intake-form-sorting-list",
    templateUrl: './intake-form-sorting-list.component.html'
})

export class IntakeFormSortingListComponent extends AbstractIntakeFormSorting implements OnInit, OnDestroy {
    intakeFormSortings: List<IntakeFormSorting>;
    subscription: Subscription;
    eventSelectionHeader: EventSelectionHeader;
    eventDivisionLookup: EventLookupGroup;
    filteredEventsData: any[];
    selectedEventId: number;
    pageTypeData: any;
    filteredPageTypeData: any;
    houseBrandPages: any;
    tabCategory: string;
    imageOrPdf: boolean;
    pdfDetail = new PdfDetail();
    selectedEventName: string;
    eventId: number;
    spin: boolean = false;
    selectedPageTypeId: number = 0;
    selectedCategory: string = "";
    image_Confirmed_PdfUrlList: List<ImagePdfUrl>;
    image_Not_Confirmed_PdfUrlList: List<ImagePdfUrl>;
    image_Mandatory_PdfUrlList: List<ImagePdfUrl>;
    newlyUploadedIdList: List<Number>;
    confirmedIdList: List<Number>;
    notConfirmedIdList: List<Number>;
    intakeFormSorting: IntakeFormSorting;
    responsedData: any;
    showMandatoryDiv: boolean;
    sendLastetIdList: number[];
    sendLastetMandatoryIdList: number[];
    updatePageSequences: UpdatePageSequences;
    mandatoryHouseAndBrandDignitaryLettersObj: MandatoryHouseAndBrandDignitaryLetters;
    isNotConfirmedImageSwitched: boolean;
    isConfirmedImageSwitched: boolean;
    accessCreatePDF: boolean = true;
    changedPageTypeId: number;
    intakeFormSortingItem: IntakeFormSorting;
    paid = AdTypeEnum.PaidAd;
    isPageTypeHalf: boolean = false;
    adIntakeFormId_PageTypeId = new AdIntakeFormId_PageTypeId();
    selectedPageTypeName: string = CONSTANT_MESSAGES.houseAndBrandAds;

    constructor(private sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,
        private route: Router, modalView: ModalView, private apiCallService: ApiCallService) {
        super();

        this.activatedRoute.params.subscribe(t => {
            this.eventId = t[CONSTANT_MESSAGES.eventId];
        })

        this.accessCreatePDF = dbAccessModule[RolePermissionModule.CreateDownloadPDF][ActionType.GET]
        this.showComponent = false;
        this.showMandatoryDiv = false;
        this.isConfirmedImageSwitched = false;
        this.isNotConfirmedImageSwitched = false;
        this.bindEventSelectHeader();
        this.modalView = modalView
    }

    ngOnInit(): void {
        this.eventDivisionLookup = new EventLookupGroup();
        this.image_Confirmed_PdfUrlList = new List<ImagePdfUrl>();
        this.image_Not_Confirmed_PdfUrlList = new List<ImagePdfUrl>();
        this.image_Mandatory_PdfUrlList = new List<ImagePdfUrl>();
        this.updatePageSequences = new UpdatePageSequences();
        this.mandatoryHouseAndBrandDignitaryLettersObj = new MandatoryHouseAndBrandDignitaryLetters();
        this.sendLastetIdList = new Array();
        this.sendLastetMandatoryIdList = new Array();
        this.confirmedIdList = new List<Number>();
        this.notConfirmedIdList = new List<Number>();
        this.filteredEventsData = new Array<any>();
        this.spin = true;

        setTimeout(() => {
            this.DragDropImages();
        }, 50)
    }

    DragDropImages() {
        let intakeFormSortingObj = new IntakeFormSorting();
        intakeFormSortingObj.eventId = Number(this.eventId);

        // Below code will be commented when Non Paid will be introduced.
        $('#sortable3').sortable({
            containment: "#sortableDiv",
            scroll: false,
            update: (e, ui) => {
                this.sendLastetMandatoryIdList = new Array();
                $("#sortable3 div div div").each(
                    (index, value) => {
                        $(this).attr("data-sort", index + 1);
                        this.sendLastetMandatoryIdList.push(Number(value.id));

                        if ($('#sortable3 div div div').length == (index + 1)) {
                            this.updatePageSequencesMandatoryFunction();
                        }
                    }
                )
            }
        });

        $("#sortable1").sortable({
            containment: "#sortableDiv",
            scroll: false,
            connectWith: "#sortable2",
            update: (e, ui) => {
                this.sendLastetIdList = new Array();

                $('#sortable1 div div div').each(
                    (index, value) => {
                        $(this).attr("data-sort", index + 1);
                        this.sendLastetIdList.push(Number(value.id));

                        if (!this.confirmedIdList.contains(Number(value.id))) {
                            this.isConfirmedImageSwitched = true;
                            $("#spinnerID").attr("style", "display:block");
                            // Below If and Else will be commented when Non Paid will be introduced.
                            if (this.selectedCategory != "") {
                                intakeFormSortingObj.houseAndBrandAdsDignitariesLettersId = Number(value.id);
                                let brandTypeId = this.selectedCategory == CONSTANT_MESSAGES.houseAndBrandAds ? BrandTypeEnum.HouseForwardSlashBrandAds : BrandTypeEnum.DignitaryLetters;
                                intakeFormSortingObj.brandTypeId = brandTypeId;
                            } else {
                                intakeFormSortingObj.houseAndBrandAdsDignitariesLettersId = null;
                                intakeFormSortingObj.brandTypeId = null;
                            }

                            intakeFormSortingObj.sequence = (index + 1);
                            intakeFormSortingObj.pageTypeId = this.selectedPageTypeId != 0 ? this.selectedPageTypeId : null;
                            intakeFormSortingObj.adIntakeFormId = this.selectedPageTypeId != 0 ? Number(value.id) : null;

                            this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.intakeFormSortings, intakeFormSortingObj).subscribe((t: any) => {
                                this.sendLastetIdList = new Array();
                                this.isConfirmedImageSwitched = false;
                                this.notConfirmedIdList.remove(Number(value.id));
                                //value.id = t;
                                if (this.selectedPageTypeId != 0) {
                                    // Below If and Else will be commented when Non Paid will be introduced.
                                    //if (this.responsedData[0].adIntakeFormId)
                                    this.responsedData.find(t => t.adIntakeFormId == Number(value.id)).intakeFormSortingId = t;
                                    // else
                                    //     this.responsedData.find(t => t.nonPaidId == Number(value.id)).intakeFormSortingId = t;
                                }

                                this.confirmedIdList.add(Number(value.id));

                                $("#spinnerID").attr("style", "display:none");

                                this.toastr.success(ValidationMessage.sequenceIsUpdated)
                            });
                        }

                        if ($('#sortable1 div div div').length == (index + 1) &&
                            $('#sortable1 div div div').length == this.confirmedIdList.count()
                            && !this.isConfirmedImageSwitched && !this.isNotConfirmedImageSwitched
                        ) {
                            this.updatePageSequencesFunction();
                        }
                    }
                )
            },
        }).disableSelection();

        $("#sortable2").sortable({
            connectWith: "#sortable1",
            containment: "#sortableDiv",
            scroll: false,
            update: (e, ui) => {
                $('#sortable2 div div div').each(
                    (index, value) => {
                        $(this).attr("data-sort", index + 1);

                        if (!this.notConfirmedIdList.contains(Number(value.id))) {
                            this.isNotConfirmedImageSwitched = true;

                            if (this.selectedCategory != "") {
                                $("#spinnerID").attr("style", "display:block");
                                // Comment below API for Non Paid.
                                this.apiCallService.deleteAPI(CUSTOM_URI.deletePageSequeceHouseAndBrandDignitaries, Number(value.id)).subscribe((t: any) => {
                                    //this.responsedData
                                    this.isNotConfirmedImageSwitched = false;
                                    this.notConfirmedIdList.add(Number(value.id));
                                    this.confirmedIdList.remove(Number(value.id));
                                    $("#spinnerID").attr("style", "display:none");
                                    this.toastr.success(ValidationMessage.sequenceIsUpdated)
                                });
                            }
                            else {
                                $("#spinnerID").attr("style", "display:block");
                                let intakeFormSortingId = this.responsedData.find(t => t.adIntakeFormId == Number(value.id)).intakeFormSortingId;

                                // Uncomment below line when Non Paid will be introduced.
                                // let intakeFormSortingId = 0;
                                // if (this.responsedData[0].nonPaidId)
                                //     intakeFormSortingId = this.responsedData.find(t => t.nonPaidId == Number(value.id)).intakeFormSortingId;
                                // else
                                //     intakeFormSortingId = this.responsedData.find(t => t.adIntakeFormId == Number(value.id)).intakeFormSortingId;

                                this.apiCallService.deleteAPI(APPLICATION_URL_CONSTANTS.intakeFormSortings, null, intakeFormSortingId).subscribe((t: any) => {
                                    //this.responsedData
                                    this.isNotConfirmedImageSwitched = false;
                                    this.notConfirmedIdList.add(Number(value.id));
                                    this.confirmedIdList.remove(Number(value.id));
                                    $("#spinnerID").attr("style", "display:none");
                                    this.toastr.success(ValidationMessage.sequenceIsUpdated)
                                });
                            }
                        }
                    }
                );
            },
        }).disableSelection();
    }

    // Comment below entire function for Non Paid.
    updatePageSequencesMandatoryFunction() {
        $("#spinnerID").attr("style", "display:block");
        this.mandatoryHouseAndBrandDignitaryLettersObj.sequences = this.sendLastetMandatoryIdList;
        this.mandatoryHouseAndBrandDignitaryLettersObj.sequence = 0;
        this.mandatoryHouseAndBrandDignitaryLettersObj.eventId = this.eventId;
        this.mandatoryHouseAndBrandDignitaryLettersObj.HouseAndBrandDignitaryLettersId = 0;
        this.mandatoryHouseAndBrandDignitaryLettersObj.brandTypeId = this.selectedCategory == CONSTANT_MESSAGES.houseAndBrandAds
            ? BrandTypeEnum.HouseForwardSlashBrandAds : BrandTypeEnum.DignitaryLetters;
        this.mandatoryHouseAndBrandDignitaryLettersObj.createdBy = this.user.userId;
        this.mandatoryHouseAndBrandDignitaryLettersObj.createdOn = new Date();

        this.apiCallService.postAPI(CUSTOM_URI.updateMandatoryHouseAndBrandDignitaryLetters, this.mandatoryHouseAndBrandDignitaryLettersObj)
            .subscribe((t: any) => {
                this.sendLastetIdList = new Array();
                $("#spinnerID").attr("style", "display:none");
                this.toastr.success(ValidationMessage.sequenceIsUpdated)
            });
    }

    updatePageSequencesFunction() {
        $("#spinnerID").attr("style", "display:block");
        this.updatePageSequences.eventId = Number(this.eventId);

        // Comment If without its body part and Else with body part for Non Paid.
        if (this.selectedCategory != "")
            this.updatePageSequences.houseAndBrandTypeAdsDignatariesLetters = this.selectedCategory == CONSTANT_MESSAGES.houseAndBrandAds ?
                BrandTypeEnum.HouseForwardSlashBrandAds : BrandTypeEnum.DignitaryLetters;
        else
            this.updatePageSequences.houseAndBrandTypeAdsDignatariesLetters = 0;

        this.updatePageSequences.pageTypeId = this.selectedPageTypeId;
        this.updatePageSequences.sequences = this.sendLastetIdList;

        this.apiCallService.postAPI(CUSTOM_URI.updatePageSequences, this.updatePageSequences).subscribe((t: any) => {
            this.sendLastetIdList = new Array();

            $("#spinnerID").attr("style", "display:none");

            this.toastr.success(ValidationMessage.sequenceIsUpdated)
        });
    }

    bindEventSelectHeader() {
        this.lookup<EventLookupGroup>([
            { path: EVENT_LOOKUPS["eventNameLookUps"], propName: "eventNameLookUps" },
            { path: EVENT_LOOKUPS["eventPageType"], propName: "eventPageType" },
            { path: EVENT_LOOKUPS["paginationDefault"], propName: "paginationDefault" }
        ]).subscribe(Response => {
            this.pageTypeData = Response.eventPageType;
            this.houseBrandPages = Response.paginationDefault;
            this.selectedEventName = Response.eventNameLookUps.find(t => t.eventId == this.eventId).eventName;
            // commnet below code for Non Paid if below commented code is going to be uncommented.
            this.SearchEventsDetails(CONSTANT_MESSAGES.houseAndBrandAds);

            // // Uncommnet below code for Non Paid
            // this.filteredPageTypeData = this.pageTypeData.filter(t => t.eventId == this.eventId);
            // this.SearchEventsDetails(this.filteredPageTypeData[0]);
        });
    }

    SearchEventsDetails(category: string) {
        this.confirmedIdList = new List<Number>();
        this.notConfirmedIdList = new List<Number>();
        this.spin = true;
        // Comment below two lines of code for Non Paid.
        this.selectedCategory = category;
        this.showMandatoryDiv = true;

        setTimeout(() => {
            this.DragDropImages();
        }, 50)

        this.sendLastetIdList = new Array();
        this.isConfirmedImageSwitched = false;
        this.isNotConfirmedImageSwitched = false;

        this.apiCallService.getAPI(CUSTOM_URI.getHouseAndBrandDignitaries, { category: category, id: this.eventId }).subscribe((t: any) => {
            let filteredData = t['result'].filter(t => t.brandTypeId == BrandTypeEnum.HouseForwardSlashBrandAds);
            this.image_Confirmed_PdfUrlList = new List<ImagePdfUrl>();
            this.image_Not_Confirmed_PdfUrlList = new List<ImagePdfUrl>();

            this.responsedData = filteredData;

            filteredData.forEach(element => {
                let imagePdfUrl = new ImagePdfUrl();

                if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.ivbor || element.dataFromAWS.substring(0, 5) == CONSTANT_MESSAGES.subStringOfPNG) {
                    imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePngAndBase64 + element.dataFromAWS);
                    imagePdfUrl.imgShowOrNot = true;
                    imagePdfUrl.pdfShowOrNot = false;
                } else if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.subStringOfJPEG) {
                    imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimeJpegAndBase64 + element.dataFromAWS);
                    imagePdfUrl.imgShowOrNot = true;
                    imagePdfUrl.pdfShowOrNot = false;
                }
                else if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.jvber) {
                    imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + element.dataFromAWS);
                    imagePdfUrl.imgShowOrNot = false;
                    imagePdfUrl.pdfShowOrNot = true;
                }

                imagePdfUrl.colorCode = true;
                imagePdfUrl.eventId = this.eventId;

                if (element.houseAndBrandAdsDignitariesLettersId != null)
                    imagePdfUrl.id = element.houseAndBrandAdsDignitariesLettersId

                if (element.isMandatory) {
                    this.image_Mandatory_PdfUrlList.add(imagePdfUrl);
                } else {
                    if (element.isConfirmedImage) {
                        this.confirmedIdList.add(element.houseAndBrandAdsDignitariesLettersId);
                        this.image_Confirmed_PdfUrlList.add(imagePdfUrl);
                    }
                    else {
                        this.notConfirmedIdList.add(element.houseAndBrandAdsDignitariesLettersId);
                        this.image_Not_Confirmed_PdfUrlList.add(imagePdfUrl);
                    }
                }
            });

            this.filteredPageTypeData = this.pageTypeData.filter(t => t.eventId == this.eventId).sort((a, b) => {
                return a.sequences - b.sequences
            });

            for (let index = 0; index < this.houseBrandPages.length; index++) {
                const element = this.houseBrandPages[index];

                if (BrandTypeEnum.DignitaryLetters == element.brandTypeId) {
                    this.filteredPageTypeData.unshift({ "pageTypeId": element.brandTypeId, "eventId": this.eventId, "pageTypeName": CONSTANT_MESSAGES.tributeLetters })
                    break;
                }
            }

            for (let index = 0; index < this.houseBrandPages.length; index++) {
                const element = this.houseBrandPages[index];

                if (BrandTypeEnum.HouseForwardSlashBrandAds == element.brandTypeId) {
                    this.filteredPageTypeData.unshift({ "pageTypeId": element.brandTypeId, "eventId": this.eventId, "pageTypeName": CONSTANT_MESSAGES.houseAndBrandAds })
                    break;
                }
            }

            this.isPageTypeHalf = false;
            this.spin = false;
        });

        this.showComponent = true;
    }

    tabChanged(category: string, pageTypeId: number, isHalfpage: boolean, CallingFunctionForSorting_Changed_PageType: string = "") {
        this.spin = true;
        this.sendLastetIdList = new Array();

        this.isConfirmedImageSwitched = false;
        this.isNotConfirmedImageSwitched = false;

        if (category == CONSTANT_MESSAGES.houseAndBrandAds || category == CONSTANT_MESSAGES.tributeLetters) {
            setTimeout(() => {
                this.DragDropImages();
            }, 50)

            this.confirmedIdList = new List<Number>();
            this.notConfirmedIdList = new List<Number>();
            this.selectedCategory = category;
            this.selectedPageTypeId = 0;
            this.showMandatoryDiv = true;

            this.selectedPageTypeName = category;

            this.image_Confirmed_PdfUrlList = new List<ImagePdfUrl>();
            this.image_Not_Confirmed_PdfUrlList = new List<ImagePdfUrl>();
            this.image_Mandatory_PdfUrlList = new List<ImagePdfUrl>();

            this.apiCallService.getAPI(CUSTOM_URI.getHouseAndBrandDignitaries, { category: category, id: this.eventId }).subscribe((t: any) => {
                this.responsedData = t['result'];

                t['result'].forEach(element => {
                    let imagePdfUrl = new ImagePdfUrl();

                    if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.ivbor || element.dataFromAWS.substring(0, 5) == CONSTANT_MESSAGES.subStringOfPNG) {
                        imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePngAndBase64 + element.dataFromAWS);
                        imagePdfUrl.imgShowOrNot = true;
                        imagePdfUrl.pdfShowOrNot = false;
                    } else if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.subStringOfJPEG) {
                        imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimeJpegAndBase64 + element.dataFromAWS);
                        imagePdfUrl.imgShowOrNot = true;
                        imagePdfUrl.pdfShowOrNot = false;
                    } else if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.jvber) {
                        imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + element.dataFromAWS);
                        imagePdfUrl.imgShowOrNot = false;
                        imagePdfUrl.pdfShowOrNot = true;
                    }

                    imagePdfUrl.colorCode = true;
                    imagePdfUrl.eventId = this.eventId;

                    if (element.houseAndBrandAdsDignitariesLettersId != null)
                        imagePdfUrl.id = element.houseAndBrandAdsDignitariesLettersId

                    if (element.isMandatory) {
                        this.image_Mandatory_PdfUrlList.add(imagePdfUrl);
                    } else {
                        if (element.isConfirmedImage) {
                            this.confirmedIdList.add(element.houseAndBrandAdsDignitariesLettersId);
                            this.image_Confirmed_PdfUrlList.add(imagePdfUrl);
                        }
                        else {
                            this.notConfirmedIdList.add(element.houseAndBrandAdsDignitariesLettersId);
                            this.image_Not_Confirmed_PdfUrlList.add(imagePdfUrl);
                        }
                    }
                });

                this.isPageTypeHalf = false;
                this.spin = false;
            });
        }
        else {
            setTimeout(() => {
                this.DragDropImages();
            }, 50)

            this.selectedPageTypeId = pageTypeId;
            this.selectedCategory = "";
            this.confirmedIdList = new List<Number>();
            this.notConfirmedIdList = new List<Number>();
            this.showMandatoryDiv = false;

            this.selectedPageTypeName = this.filteredPageTypeData.find(c => c.pageTypeId == this.selectedPageTypeId).pageTypeName;

            this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.intakeFormSortings, { "Id": pageTypeId, "EventId": this.eventId }).subscribe((t: any) => {
                this.image_Confirmed_PdfUrlList = new List<ImagePdfUrl>();
                this.image_Not_Confirmed_PdfUrlList = new List<ImagePdfUrl>();

                this.responsedData = t;

                t.forEach(element => {

                    let imagePdfUrl = new ImagePdfUrl();

                    if (element.imageData.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.ivbor || element.imageData.substring(0, 5) == CONSTANT_MESSAGES.subStringOfPNG) {
                        imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePngAndBase64 + element.imageData);
                        imagePdfUrl.imgShowOrNot = true;
                        imagePdfUrl.pdfShowOrNot = false;
                    } else if (element.imageData.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.subStringOfJPEG) {
                        imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimeJpegAndBase64 + element.imageData);
                        imagePdfUrl.imgShowOrNot = true;
                        imagePdfUrl.pdfShowOrNot = false;
                    } else if (element.imageData.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.jvber) {
                        imagePdfUrl.url = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + element.imageData);
                        imagePdfUrl.imgShowOrNot = false;
                        imagePdfUrl.pdfShowOrNot = true;
                    }

                    imagePdfUrl.colorCode = element.colorCode == 0 ? false : true;
                    imagePdfUrl.eventId = this.eventId;
                    imagePdfUrl.donorName = element.donorName;

                    if (element.adIntakeFormId != null)
                        imagePdfUrl.id = element.adIntakeFormId

                    if (element.isConfirmedImage) {
                        this.confirmedIdList.add(element.adIntakeFormId);
                        this.image_Confirmed_PdfUrlList.add(imagePdfUrl);
                    }
                    else {
                        this.notConfirmedIdList.add(element.adIntakeFormId);
                        this.image_Not_Confirmed_PdfUrlList.add(imagePdfUrl);
                    }
                });

                this.isPageTypeHalf = isHalfpage;

                this.spin = false;

                if (CallingFunctionForSorting_Changed_PageType.includes(CONSTANT_MESSAGES.sorted))
                    this.toastr.success(ValidationMessage.sortedAscendingFirst + CallingFunctionForSorting_Changed_PageType + ValidationMessage.sortedAscedningSecond)
                else if (CallingFunctionForSorting_Changed_PageType.includes(CONSTANT_MESSAGES.changed))
                    this.toastr.success(CallingFunctionForSorting_Changed_PageType);
            });
        }
    }

    redirectToAdIntakeForm(adIntakeFormId: number) {
        //Added on 31/03/2022 for Enhancement Points            
        this.apiCallService.postAPI(CUSTOM_URI.updateIntakeFormPaginationToWIP, { "adIntakeFormId": adIntakeFormId, "isFromPaginationToWIP": true }).subscribe((t: any) => {
            if (this.selectedCategory == "")
                this.route.navigateByUrl(CONSTANT_MESSAGES.eventsPath + this.eventId + CONSTANT_MESSAGES.adIntakeFormsPathForwardSlash + adIntakeFormId + CONSTANT_MESSAGES.intakeFormAdDesignTemplateWIP);

        });
        //End

        // Uncommnet below If for Non Paid.
        //Comment on 31/03/2022 for Phase 2 Enhancement
        // if (this.selectedCategory == "")
        //     this.route.navigateByUrl(CONSTANT_MESSAGES.eventsPath + this.eventId + CONSTANT_MESSAGES.adIntakeFormsPathForwardSlash + adIntakeFormId + CONSTANT_MESSAGES.intakeFormAdDesignTemplateWIP);
        //End
        // }
    }

    //For Point #5
    opendownloadModal(downloadPdfType: DownloadPdfTypeEnum) {
        let downloadType = downloadPdfType == DownloadPdfTypeEnum.DigitalPdf ? "Digital" : "Print - Ready"
        let downloadTypeLabel = downloadPdfType == DownloadPdfTypeEnum.DigitalPdf ? "Digital" : "Print - Ready"
        this.modalView.show(DownloadPdfModalComponent, { componentType: CONSTANT_MESSAGES.downloadmodal, eventId: this.eventId, downloadType: downloadType, DownloadTypeLabel: downloadTypeLabel }).then(t => {
            if (t.DownloadTypeValue == 1) {
                this.pdfDetail.DownloadType = 1;
                // this.createAndDownloadDigitalPDF();
            }
            else if (t.DownloadTypeValue == 2) {
                this.pdfDetail.PageNumbers = t.PageNumbers;
                this.pdfDetail.PageTypeId = t.PageTypeId;
                this.pdfDetail.DownloadType = t.DownloadTypeValue;
            }
            else if (t.DownloadTypeValue == 3) {
                this.pdfDetail.PageNumbers = t.PageNumbers;
                this.pdfDetail.PageTypeId = t.PageTypeId;
                this.pdfDetail.DownloadType = t.DownloadTypeValue;
                // this.createAndDownloadDigitalPDF();
            }
            if (downloadPdfType == DownloadPdfTypeEnum.DigitalPdf) {
                this.createAndDownloadDigitalPDF();
            }
            else {
                this.createAndDownloadPrintReadyPDF()
            }
        });
    }

    createAndDownloadDigitalPDF() {
        this.spin = true;

        this.pdfDetail.FileName = this.selectedEventName + CONSTANT_MESSAGES.digital + CONSTANT_MESSAGES.pdfExtention
        this.pdfDetail.Type = CONSTANT_MESSAGES.finalDigitalPdf;
        this.pdfDetail.Result = this.eventId.toString();

        this.apiCallService.postAPI(CUSTOM_URI.postPdfExcel, this.pdfDetail).subscribe(Response => {
            downloadDocument(Response, this.selectedEventName + CONSTANT_MESSAGES.digital, CONSTANT_MESSAGES.mimePdf);
            this.spin = false;
        })
    }

    createAndDownloadPrintReadyPDF() {

        this.spin = true;
        this.pdfDetail.FileName = this.selectedEventName + CONSTANT_MESSAGES.printReady + CONSTANT_MESSAGES.pdfExtention;
        this.pdfDetail.Type = CONSTANT_MESSAGES.finalPrintReadyPdf;
        this.pdfDetail.Result = this.eventId.toString();

        this.apiCallService.postAPI(CUSTOM_URI.postPdfExcel, this.pdfDetail).subscribe(Response => {

            downloadDocument(Response, this.selectedEventName + CONSTANT_MESSAGES.printReady, CONSTANT_MESSAGES.mimePdf);
            this.spin = false;
        })
    }

    //#region Uncommented below code for Non Paid
    // onEdit(selectedItem: ImagePdfUrl) {
    //     this.responsedData.forEach((item: IntakeFormSorting) => {
    //         if (item.adIntakeFormId == selectedItem.id) {
    //             this.get({ params: [item.intakeFormSortingId] }).subscribe((r: IntakeFormSorting) => {
    //                 this.intakeFormSortingItem = r[0];
    //             });
    //         }
    //         this.modalView.show(IntakeFormSortingModal, { eventId: selectedItem.eventId, selectedPageTypeId: this.selectedPageTypeId }).then(t => {
    //             if (t) {
    //                 this.changedPageTypeId = t;
    //                 if (this.intakeFormSortingItem && !this.intakeFormSortingItem.isNonPaid) {
    //                     this.intakeFormSortingItem.pageTypeId = this.changedPageTypeId;
    //                     this.spin = true;
    //                     this.put({ body: this.intakeFormSortingItem, params: [item.adIntakeFormId] }).subscribe(response => {
    //                         //    console.log(response);
    //                         this.spin = false;
    //                         this.toastr.success("Page Type updated successfully.");
    //                         setTimeout(() => {
    //                             window.location.reload();
    //                         }, 3000);
    //                     });
    //                 }
    //                 else if (!selectedItem.isNonPaid) {
    //                     this.intakeFormSortingItem = new IntakeFormSorting();
    //                     this.intakeFormSortingItem.intakeFormSortingId = 0;
    //                     this.intakeFormSortingItem.pageTypeId = this.changedPageTypeId;
    //                     this.intakeFormSortingItem.adIntakeFormId = selectedItem.id;
    //                     this.intakeFormSortingItem.eventId = selectedItem.eventId;
    //                     this.intakeFormSortingItem.sequence = 1;
    //                     // this.intakeFormSortingItem.houseAndBrandAdsDignitariesLettersId = 0;
    //                     // this.intakeFormSortingItem.brandTypeId = null;
    //                     this.intakeFormSortingItem.adIntakeForm = null;
    //                     this.intakeFormSortingItem.createdBy = this.user.userId;
    //                     this.intakeFormSortingItem.isNonPaid = false;
    //                     this.intakeFormSortingItem.createdOn = new Date();
    //                     this.spin = true;
    //                     this.put({ body: this.intakeFormSortingItem, params: [this.intakeFormSortingItem.adIntakeFormId] }).subscribe(response => {
    //                         //    console.log(response);
    //                         this.spin = false;
    //                         this.toastr.success("Page Type updated successfully.");
    //                         setTimeout(() => {
    //                             window.location.reload();
    //                         }, 3000);
    //                     });
    //                 }
    //                 else {
    //                     this.spin = true;
    //                     let nonPaidItem;
    //                     this.responsedData.forEach(e => {
    //                         if (e.nonPaidId == selectedItem.id) nonPaidItem = e;
    //                     });
    //                     if (this.confirmedIdList.contains(nonPaidItem.nonPaidId)) {
    //                         this.intakeFormSortingItem = new IntakeFormSorting();
    //                         this.intakeFormSortingItem.intakeFormSortingId = nonPaidItem.intakeFormSortingId;
    //                         this.intakeFormSortingItem.pageTypeId = this.changedPageTypeId;
    //                         this.intakeFormSortingItem.adIntakeFormId = nonPaidItem.nonPaidId;
    //                         this.intakeFormSortingItem.eventId = selectedItem.eventId;
    //                         this.intakeFormSortingItem.sequence = 1;
    //                         // this.intakeFormSortingItem.houseAndBrandAdsDignitariesLettersId = 0;
    //                         // this.intakeFormSortingItem.brandTypeId = null;
    //                         this.intakeFormSortingItem.isNonPaid = true;
    //                         this.intakeFormSortingItem.adIntakeForm = null;
    //                         this.intakeFormSortingItem.createdBy = this.user.userId;
    //                         this.intakeFormSortingItem.createdOn = new Date();
    //                         this.put({ body: this.intakeFormSortingItem, params: [nonPaidItem.nonPaidId] }).subscribe(res => {
    //                             this.spin = false;
    //                             this.toastr.success("Page Type Updated succesfully.");
    //                             setTimeout(() => {
    //                                 window.location.reload();
    //                             }, 1000);
    //                         });
    //                     }
    //                     else {
    //                         nonPaidItem.pageTypeId = this.changedPageTypeId;
    //                         nonPaidItem.datasendToAWS = "aws";
    //                         this.put({ path: 'api/NonPaid', body: nonPaidItem, params: [nonPaidItem.nonPaidId] }).subscribe(res => {
    //                             this.spin = false;
    //                             this.toastr.success("Page Type updated successfully.");
    //                             setTimeout(() => {
    //                                 window.location.reload();
    //                             }, 1000);
    //                         });
    //                     }
    //                 }
    //             }
    //             this.spin = false;
    //         });
    //     });
    // }
    //#endregion

    //#region Created below function/method when users wants to update the Ads which are in Confirmed Section in Ascending order.
    sortingPageTypesAscendingOrder() {
        let selectedPageType = this.filteredPageTypeData.find(c => c.pageTypeId == this.selectedPageTypeId);

        this.modalView.show(EventModalComponent, { componentType: CONSTANT_MESSAGES.sortingPageTypesAscendingOrder + selectedPageType.pageTypeName }).then(t => {
            if (t == CONSTANT_MESSAGES.yes) {
                this.spin = true;

                this.apiCallService.postAPI(CUSTOM_URI.sortingPageTypeAscendingOrder, { "eventId": this.eventId, "pageTypeId": this.selectedPageTypeId })
                    .subscribe((t: any) => {
                        this.tabChanged(selectedPageType.pageTypeName, this.selectedPageTypeId, selectedPageType.isHalfPage, selectedPageType.pageTypeName);
                    });
            }
        });
    }
    //#endregion

    //#region Page type changes to ads in Pagination after approval process.
    getPageTypeExceptCurrent(adIntakeFormId: any) {
        this.spin = true;

        this.apiCallService.postAPI(CUSTOM_URI.getPageTypeExceptCurrent, adIntakeFormId).subscribe((Response: any) => {
            let parsedJSON_Response_PageTypeExceptCurrent = JSON.parse(Response)[0];
            this.spin = false;

            this.modalView.show(EventModalComponent,
                { componentType: CONSTANT_MESSAGES.getPageTypeExceptCurrent, parsedJSON_Response_PageTypeExceptCurrent: parsedJSON_Response_PageTypeExceptCurrent })
                .then((changedPageTypeId: number) => {
                    if (changedPageTypeId > 0) {
                        this.spin = true;

                        this.adIntakeFormId_PageTypeId.adIntakeFormId = adIntakeFormId;
                        this.adIntakeFormId_PageTypeId.pageTypeId = changedPageTypeId;

                        this.apiCallService.postAPI(CUSTOM_URI.changePageType, this.adIntakeFormId_PageTypeId).subscribe((response: any) => {
                            let selectedPageType = this.filteredPageTypeData.find(c => c.pageTypeId == changedPageTypeId);

                            let successMessage_PageTypeChanged = ValidationMessage.pageTypeChangedSuccessfully_First + parsedJSON_Response_PageTypeExceptCurrent.PageTypeName
                                + ValidationMessage.pageTypeChangedSuccessfully_Second + selectedPageType.pageTypeName + "'."

                            this.tabChanged(selectedPageType.pageTypeName, selectedPageType.pageTypeId, selectedPageType.isHalfPage, successMessage_PageTypeChanged);
                        });
                    }
                });
        })
    }
    //#endregion

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}

class ImagePdfUrl {
    url: any;
    pdfShowOrNot: boolean;
    imgShowOrNot: boolean;
    colorCode: boolean;
    eventId: number;
    id: number;
    isNonPaid: boolean;
    isHalfPage: boolean;
    donorName: string;
}

class PdfDetail {
    Result: string;
    Type: string;
    FileName: string;

    //Added on 24/03/2022
    DownloadType: number;
    PageTypeId: number;
    PageNumbers: string;
}

class UpdatePageSequences {
    sequences: number[];
    houseAndBrandTypeAdsDignatariesLetters: number;
    eventId: number;
    pageTypeId: number;
}

class AdIntakeFormId_PageTypeId {
    adIntakeFormId: number;
    pageTypeId: number
}