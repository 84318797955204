import { NgModule } from '@angular/core';

import { RxFocusModule } from "@rxweb/angular/focus"
import { RxSpinnerModule } from "@rxweb/angular/spinner"
import { RxDatePickerModule } from "@rxweb/angular/datepicker"
import { RxMaskModule } from "@rxweb/angular/mask"
import { RxSelectModule } from "@rxweb/angular/select"
import { RxTagModule } from "@rxweb/angular/tag"

@NgModule({
    exports: [RxFocusModule, RxSpinnerModule, RxDatePickerModule, RxMaskModule, RxSelectModule, RxTagModule]
})
export class SharedModule { }
