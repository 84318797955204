import {TemplateApprovalBase} from '../database-models/template-approval-base';
import {EmailDetailBase} from '../database-models/email-detail-base';
import {IntakeTemplateBase} from '../database-models/intake-template-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class TemplateApproval extends TemplateApprovalBase 
{




//#region Generated Reference Properties
//#region emailDetail Prop
emailDetail : EmailDetailBase;
//#endregion emailDetail Prop
//#region intakeTemplate Prop
intakeTemplate : IntakeTemplateBase;
//#endregion intakeTemplate Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties








































}