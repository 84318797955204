import {ApplicationTimeZoneBase} from '../database-models/application-time-zone-base';
//Generated Imports
export class ApplicationTimeZone extends ApplicationTimeZoneBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties









































}