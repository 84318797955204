import {vDivisionRecordBase} from '../database-models/v-division-record-base';
//Generated Imports
export class vDivisionRecord extends vDivisionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties








































}