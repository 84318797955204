import { Component, OnInit, OnDestroy } from "@angular/core"
import { AbstractRole } from '../domain/abstract-role';
import { Role, vRole } from "@app/models";
import { Subscription } from 'rxjs';
import { RoleGrid } from '../../../../custom-templates/grid/footer-hide';
import { Router } from '@angular/router';
import { access } from '@rxweb/angular-router';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

@access({ accessLevel: RolePermissionModule.Role, action: ActionType.GET })

@Component({
    selector: "app-role-list",
    templateUrl: './role-list.component.html'
})

export class RoleListComponent extends AbstractRole implements OnInit, OnDestroy {
    roles: vRole[];
    subscription: Subscription;
    rolesGrid: RoleGrid;

    constructor(private route: Router, private apiCallService: ApiCallService) {
        super();
        this.showComponent = false;
    }

    ngOnInit(): void {
        this.bindGrid();
    }

    bindGrid() {
        this.spin = true;
        this.showComponent = true;

        this.subscription = this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.roles).subscribe((t: any) => {
            this.roles = t;
            this.showComponent = true;
            this.spin = false;
            //this.rolesGrid.footerTemplate = {div:{class:["abc"]}};
            this.rolesGrid = new RoleGrid(t, vRole, {
                actions: {
                    onEdit: this.onEdit.bind(this)
                }
            });
            this.rolesGrid.authorization = {
                'edit': {
                    accessLevel: RolePermissionModule.Role,
                    action: ActionType.PUT
                }
            };
            this.rolesGrid.design(document.getElementById("roles"));
        })
    }

    onEdit(roles: vRole) {
        this.route.navigate([CONSTANT_MESSAGES.rolesAsPath, roles.roleId]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}