import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LanguageBase {

//#region languageId Prop
        @prop()
        languageId : number;
//#endregion languageId Prop


//#region languageName Prop
        @required()
        @maxLength({value:100})
        languageName : string;
//#endregion languageName Prop


//#region languageCode Prop
        @required()
        @maxLength({value:2})
        languageCode : string;
//#endregion languageCode Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}