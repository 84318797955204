import { EventTemplateBase } from '../database-models/event-template-base';
import { EventBase } from '../database-models/event-base';
import { prop } from '@rxweb/reactive-form-validators';

//Generated Imports
export class EventTemplate extends EventTemplateBase {

    //#region modifiedBy Prop
    @prop()
    opsTemplateId: string;
    //#endregion modifiedBy Prop


    //#region Generated Reference Properties
    //#region event Prop
    event: EventBase;
    //#endregion event Prop

    //#endregion Generated Reference Properties

}