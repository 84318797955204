import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, RxFormArray } from '@rxweb/reactive-form-validators';
import { AdIntakeForm } from '@app/models';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ActivatedRoute } from '@angular/router';
import { CoreComponent } from '@rxweb/angular-router';
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { ApplicationModule } from "@angular/core";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";

// @http({
//     path: "api/events/:eventId/AdIntakeForms",
// })

export class AbstractAdIntakeForm extends CoreComponent {
    adIntakeFormFormGroup: IFormGroup<AdIntakeForm>
    breadCrumb: BreadCrumb;
    spin: boolean = false;
    showComponent: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr
    dialog: BaseDialog;
    isShowGridColumn: boolean = false;
    path: string;
    eventId: number;

    constructor(private activatedRoute: ActivatedRoute) {
        super();

        this.activatedRoute.params.subscribe(t => {
            this.eventId = t['eventId'];
        })

        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;

        this.path = APPLICATION_URL_CONSTANTS.events + this.eventId + APPLICATION_URL_CONSTANTS.adIntakeForms;
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();

        this.breadCrumb.name1 = CONSTANT_MESSAGES.home;
        this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard
        this.breadCrumb.name2 = CONSTANT_MESSAGES.eventsList
        this.breadCrumb.link2 = CONSTANT_MESSAGES.eventView
        this.breadCrumb.title = CONSTANT_MESSAGES.adManagement
        this.breadCrumb.pagename = CONSTANT_MESSAGES.adManagement
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.error(messages[0]);
    }
}