import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractHouseAndBrandAdsDignitariesLetter } from '../domain/abstract-house-and-brand-ads-dignitaries-letter';
import { HouseAndBrandAdsDignitariesLetter } from "@app/models";
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BRAND_TYPE } from 'src/app/collections/brand-type.collection';
import { DomSanitizer } from '@angular/platform-browser';
import { StatusEnum } from 'src/app/enums/status.enum';
import { BrandTypeEnum } from 'src/app/enums/brand-type.enum';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ImageModalComponent } from '../../wip/image-modal.component';
import { AppGrid } from 'src/app/domain/app-grid';
import { HouseAndBrandAdsDignitariesLetterModel } from 'src/app/viewModel/houseAndBrandAdsDignitariesLetterModel';
import { EventModalComponent } from '../../events/event-modal.component';
import { RolePermissionModule, ActionType } from 'src/app/enums/user-role-permission';
import { access } from '@rxweb/angular-router';
import { HouseAndBrandAdsDignitariesLetterAddComponent } from '../add/house-and-brand-ads-dignitaries-letter-add.component';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { dbAccessModule } from "src/app/domain/security/authorization-resolver";
import { ApiCallService } from "src/app/temp-service/api-call-service";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";
import { CUSTOM_URI } from "src/app/const/uris/custom.uri";
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { ValidationMessage } from "src/app/const/validation-msg/custom-validation";

@access({ accessLevel: RolePermissionModule.HouseBrandAdsAndDignitariesLetters, action: ActionType.GET })

@Component({
    selector: "app-house-and-brand-ads-dignitaries-letter-list",
    templateUrl: './house-and-brand-ads-dignitaries-letter-list.component.html'
})

export class HouseAndBrandAdsDignitariesLetterListComponent extends AbstractHouseAndBrandAdsDignitariesLetter implements OnInit, OnDestroy {
    houseAndBrandAdsDignitariesLetters: List<HouseAndBrandAdsDignitariesLetter>;
    subscription: Subscription;
    brandType: any[];
    base64textString: any = "";
    binaryString: any;
    selectedBrandTypeId: number;
    modalView: ModalView;
    houseAndBrandAdsDignitariesLettersGrid: AppGrid;
    houseAndBrandAdsDignitariesLettersGridColumns: any[];
    houseBrandAdd: any = HouseAndBrandAdsDignitariesLetterAddComponent
    toastr: BaseToastr
    fileType: string;
    pdfPagesCount: number = 0;
    isAccessedForAdd: boolean = true;

    constructor(private formBuilder: RxFormBuilder, private sanitizer: DomSanitizer, modalView: ModalView, private apiCallService: ApiCallService) {
        super();
        this.houseAndBrandAdsDignitariesLetters = new List<HouseAndBrandAdsDignitariesLetter>();
        this.modalView = modalView;
        this.toastr = new BaseToastr();

        this.isAccessedForAdd = dbAccessModule[RolePermissionModule.HouseBrandAdsAndDignitariesLetters][ActionType.POST]
    }

    ngOnInit(): void {
        this.showComponent = true;
        this.spin = true;
        this.houseAndBrandAdsDignitariesLetterFormGroup = this.formBuilder.formGroup(new HouseAndBrandAdsDignitariesLetter()) as IFormGroup<HouseAndBrandAdsDignitariesLetter>;
        this.brandType = BRAND_TYPE;

        this.apiCallService.getAPI(APPLICATION_URL_CONSTANTS.houseAndBrandAdsDignitariesLettes).subscribe((t: any) => {
            this.spin = false;
            this.houseAndBrandAdsDignitariesLetterFormGroup.controls.houseAndBrandAdsDignitariesLettersId.setValue(0);
            this.houseAndBrandAdsDignitariesLetterFormGroup.controls.brandTypeId.setValue(0);
            this.houseAndBrandAdsDignitariesLetterFormGroup.controls.isMandatory.setValue(0);
            this.houseAndBrandAdsDignitariesLetters = t.result;

            t.result.forEach(element => {
                if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.ivbor || element.dataFromAWS.substring(0, 5) == CONSTANT_MESSAGES.subStringOfPNG) {
                    element.dataFromAWS = CONSTANT_MESSAGES.mimePngAndBase64 + element.dataFromAWS;
                    element.Image = element.dataFromAWS;
                } else if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.subStringOfJPEG) {
                    element.dataFromAWS = CONSTANT_MESSAGES.mimeJpegAndBase64 + element.dataFromAWS;
                    element.Image = element.dataFromAWS;
                } else if (element.dataFromAWS.substring(0, 5).toUpperCase() == CONSTANT_MESSAGES.jvber) {
                    element.Image = CONSTANT_MESSAGES.mimePdfAndBase64 + element.dataFromAWS;
                    element.dataFromAWS = CONSTANT_MESSAGES.staticPdfPathAsPng;
                }

                element.brandTypeId = element.brandTypeId == BrandTypeEnum.HouseForwardSlashBrandAds ? CONSTANT_MESSAGES.houseOrBrandAds : CONSTANT_MESSAGES.tributeLetters;
            });

            this.houseAndBrandAdsDignitariesLettersGrid = new AppGrid(t.result, HouseAndBrandAdsDignitariesLetterModel, {
                actions:
                    { onDelete: this.onDelete.bind(this), onImgClick: this.onImgClick.bind(this) }
            });

            this.houseAndBrandAdsDignitariesLettersGrid.authorization = {
                'delete':
                    { accessLevel: RolePermissionModule.HouseBrandAdsAndDignitariesLetters, action: ActionType.DELETE }
            };

            this.houseAndBrandAdsDignitariesLettersGrid.designClass.tableClass = "themeTable email-temp-list table table-striped mb-0".split(' ');
            this.houseAndBrandAdsDignitariesLettersGrid.design(document.getElementById(CONSTANT_MESSAGES.houseAndBrandAdsDignitariesLetters));
            this.houseAndBrandAdsDignitariesLettersGridColumns = this.houseAndBrandAdsDignitariesLettersGrid.gridColumns.filter(x => x.keyColumn == false);
        })
    }

    onDelete(houseAndBrandAdsDignitariesLetter: any) {
        this.modalView.show(EventModalComponent, { componentType: 'delete' }).then(t => {
            if (t == 'yes') {
                this.spin = true;
                this.apiCallService.deleteAPI(APPLICATION_URL_CONSTANTS.houseAndBrandAdsDignitariesLettes, null, houseAndBrandAdsDignitariesLetter.houseAndBrandAdsDignitariesLettersId)
                    .subscribe(data => {
                        this.spin = false;
                        window.location.reload();
                    })
            }
        });
    }

    handleFileSelect(evt) {
        // var files = evt.target.files;
        // var file = files[0];
        // if (files && file) {
        //     var reader = new FileReader();
        //     reader.onload = this._handleReaderLoaded.bind(this);
        //     reader.readAsBinaryString(file);
        // }
        this.fileType = "";
        var files = evt.target.files;
        var file = files[0];
        let ext = file.name.split('.').pop(),
            fileType = file.type;
        ext = ext.toLowerCase()
        if (file.size >= (5000 * 1024)) {
            this.toastr.error(CONSTANT_MESSAGES.fileSizeExceeds);
            return;
        }
        if ([CONSTANT_MESSAGES.pdf, CONSTANT_MESSAGES.jpg, CONSTANT_MESSAGES.png, CONSTANT_MESSAGES.jpeg].indexOf(ext) != -1) {
            this.pdfPagesCount = 0;
            if (file) {
                this.fileType = ext;
                var reader = new FileReader();
                if ([CONSTANT_MESSAGES.jpg, CONSTANT_MESSAGES.png, CONSTANT_MESSAGES.jpeg].indexOf(ext) != -1) {
                    reader.onload = this._handleReaderLoaded.bind(this);
                    reader.readAsBinaryString(file);
                }
                else {
                    reader.onload = this._handleReaderLoaded.bind(this);
                    reader.readAsBinaryString(file);
                }
            }
        }
        else if ([CONSTANT_MESSAGES.tiff, CONSTANT_MESSAGES.tif].indexOf(ext) != -1) {
            this.toastr.error(ValidationMessage.tiffFilesAreNotSupported);
        }
        else {
            this.toastr.error(ValidationMessage.supportPdfJpgJpeg);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.binaryString = btoa(binaryString)
        if (this.fileType == CONSTANT_MESSAGES.pdf) {
            this.base64textString = CONSTANT_MESSAGES.staticPdfPathAsPng;
        }
        else {
            this.base64textString = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePngAndBase64 + this.binaryString);
        }

        let base64Json = { "base64": this.binaryString }

        this.spin = true;

        this.apiCallService.postAPI(CUSTOM_URI.countPagesInPDF, base64Json).subscribe((response: number) => {
            this.pdfPagesCount = response;
            this.spin = false;
        })
    }

    onChangeBrandType(e: any) {
        this.selectedBrandTypeId = BRAND_TYPE.filter(t => t.id == Number(e))[0].id;
        this.houseAndBrandAdsDignitariesLetterFormGroup.controls.brandTypeId.setValue(this.selectedBrandTypeId);
    }

    onImgClick(value) {
        let url = "";

        if (value.Image == undefined || value.Image == "" || value.Image == null) {
            value = this.sanitizer.bypassSecurityTrustResourceUrl(CONSTANT_MESSAGES.mimePdfAndBase64 + this.binaryString);
            url = value.changingThisBreaksApplicationSecurity.split(',')[1];
        } else {
            url = value.Image.split(',')[1];
        }

        this.modalView.show(ImageModalComponent, { Image: url, Title: CONSTANT_MESSAGES.thumbnailImage }).then(t => {
            if (t != null || t != undefined) {
            }
        });
    }

    add_AdsAndLetters() {
        if (this.pdfPagesCount != 0 && this.pdfPagesCount > 1) {
            this.toastr.warning(ValidationMessage.uploadSinglePagePDFOnly)
            return false;
        }

        this.houseAndBrandAdsDignitariesLetterFormGroup.controls.datasendToAWS.setValue(this.binaryString);
        this.houseAndBrandAdsDignitariesLetterFormGroup.controls.statusId.setValue(StatusEnum.Active);
        this.houseAndBrandAdsDignitariesLetterFormGroup.controls.createdBy.setValue(this.user.userId);
        this.houseAndBrandAdsDignitariesLetterFormGroup.controls.createdOn.setValue(new Date());
        this.houseAndBrandAdsDignitariesLetterFormGroup.controls.sequence.setValue(1);

        if (this.houseAndBrandAdsDignitariesLetterFormGroup.valid && this.binaryString != "") {
            this.spin = true;
            this.apiCallService.postAPI(APPLICATION_URL_CONSTANTS.houseAndBrandAdsDignitariesLettes, this.houseAndBrandAdsDignitariesLetterFormGroup.value)
                .subscribe(res => {
                    this.spin = false;
                    window.location.reload();
                });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}