import {vRoleBase} from '../database-models/v-role-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["text-center"]
})
export class vRole extends vRoleBase {

    //#region Generated Reference Properties

//#endregion Generated Reference Properties



}