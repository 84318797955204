import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRoleBase {

//#region roleId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'roleId', keyColumn: true})
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: false, headerKey: 'roleName', keyColumn: false, headerTitle: "Role Name"})
        roleName : string;
//#endregion roleName Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: false, headerKey: 'description', keyColumn: false, headerTitle: "Description"})
        description : string;
//#endregion description Prop

}