import { EmailNotificationBase } from '../database-models/email-notification-base';
import { EmailDetailBase } from '../database-models/email-detail-base';
import { UserBase } from '../database-models/user-base';
//Generated Imports
export class EmailNotification extends EmailNotificationBase {

    //#region Generated Reference Properties

    //#region emailDetail Prop
    emailDetail: EmailDetailBase;
    //#endregion emailDetail Prop
    //#region user Prop
    user: UserBase;
    //#endregion user Prop

    //#endregion Generated Reference Properties

}