import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHouseAdsAndLetterBase {

//#region houseAndBrandAdsDignitariesLettersId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'houseAndBrandAdsDignitariesLettersId', keyColumn: true})
        houseAndBrandAdsDignitariesLettersId : number;
//#endregion houseAndBrandAdsDignitariesLettersId Prop


//#region adsAndLetters Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'adsAndLetters', keyColumn: false})
        adsAndLetters : string;
//#endregion adsAndLetters Prop


//#region thumbnailImage Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'thumbnailImage', keyColumn: false})
        thumbnailImage : string;
//#endregion thumbnailImage Prop

}