import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { HouseAndBrandAdsDignitariesLetter } from '@app/models';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { CoreComponent } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { APPLICATION_URL_CONSTANTS } from "../../../../const/uris/application-url-constants";

@http({
    path: APPLICATION_URL_CONSTANTS.houseAndBrandAdsDignitariesLettes,
})

export class AbstractHouseAndBrandAdsDignitariesLetter extends CoreComponent {
    houseAndBrandAdsDignitariesLetterFormGroup: IFormGroup<HouseAndBrandAdsDignitariesLetter>
    breadCrumb: BreadCrumb
    spin: boolean;
    filteredEventSelectionData: any;
    showComponent: boolean;
    toastr: BaseToastr;

    constructor() {
        super();
        this.bindBreadCrumb();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.name2 = "Pre Production"
        this.breadCrumb.title = 'PDF'
        this.breadCrumb.pagename = 'PDF'
    }
}