import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIntakeFormListDetailBase {

//#region adIntakeFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true})
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region adDesignStatus Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'adDesignStatus', keyColumn: false})
        adDesignStatus : string;
//#endregion adDesignStatus Prop


//#region intakeFormStatus Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'intakeFormStatus', keyColumn: false})
        intakeFormStatus : string;
//#endregion intakeFormStatus Prop


//#region mostRecentEmailSent Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'mostRecentEmailSent', keyColumn: false})
        mostRecentEmailSent : string;
//#endregion mostRecentEmailSent Prop


//#region emailSentDate Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'emailSentDate', keyColumn: false})
        emailSentDate : string;
//#endregion emailSentDate Prop


//#region updatedBy Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'updatedBy', keyColumn: false})
        updatedBy : string;
//#endregion updatedBy Prop


//#region updatedDate Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'updatedDate', keyColumn: false})
        updatedDate : string;
//#endregion updatedDate Prop


//#region previousStatus Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'previousStatus', keyColumn: false})
        previousStatus : string;
//#endregion previousStatus Prop

}