import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailSenderBase {

         //#region eventId Prop
         @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'eventId', keyColumn: true })
         eventId: number;
         //#endregion eventId Prop

        //#region eventName Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'eventName', keyColumn: false })
        eventName: string;
        //#endregion eventName Prop


        //#region adSpec Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'adSpec', keyColumn: false })
        adSpec: string;
        //#endregion adSpec Prop


        //#region eventDate Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'eventDate', keyColumn: false })
        eventDate: any;
        //#endregion eventDate Prop


        //#region journalAdDeadlineDate Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'journalAdDeadlineDate', keyColumn: false })
        journalAdDeadlineDate: any;
        //#endregion journalAdDeadlineDate Prop


        //#region eventLocation Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'eventLocation', keyColumn: false })
        eventLocation: string;
        //#endregion eventLocation Prop


        //#region adIntakeFormId Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'adIntakeFormId', keyColumn: true })
        adIntakeFormId: number;
        //#endregion adIntakeFormId Prop


        //#region amount Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'amount', keyColumn: false })
        amount: string;
        //#endregion amount Prop


        //#region divisionName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'divisionName', keyColumn: false })
        divisionName: string;
        //#endregion divisionName Prop


        //#region pageTypeName Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'pageTypeName', keyColumn: false })
        pageTypeName: string;
        //#endregion pageTypeName Prop


        //#region donorId Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'donorId', keyColumn: false })
        donorId: number;
        //#endregion donorId Prop


        //#region adCreatorNameId Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'adCreatorNameId', keyColumn: false })
        adCreatorNameId: any;
        //#endregion adCreatorNameId Prop


        //#region donorName Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'donorName', keyColumn: false })
        donorName: string;
        //#endregion donorName Prop


        //#region donorEmail Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'donorEmail', keyColumn: false })
        donorEmail: string;
        //#endregion donorEmail Prop


        //#region donorNumber Prop
        @gridColumn({ visible: true, columnIndex: 12, allowSorting: true, headerKey: 'donorNumber', keyColumn: false })
        donorNumber: string;
        //#endregion donorNumber Prop


        //#region donorFundraiserEmail Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'donorFundraiserEmail', keyColumn: false })
        donorFundraiserEmail: string;
        //#endregion donorFundraiserEmail Prop


        //#region donorFundraiserName Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'donorFundraiserName', keyColumn: false })
        donorFundraiserName: string;
        //#endregion donorFundraiserName Prop


        //#region donorFundraiserNumber Prop
        @gridColumn({ visible: true, columnIndex: 15, allowSorting: true, headerKey: 'donorFundraiserNumber', keyColumn: false })
        donorFundraiserNumber: string;
        //#endregion donorFundraiserNumber Prop


        //#region eventManagerEmail Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'eventManagerEmail', keyColumn: false })
        eventManagerEmail: string;
        //#endregion eventManagerEmail Prop


        //#region eventManagerName Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'eventManagerName', keyColumn: false })
        eventManagerName: string;
        //#endregion eventManagerName Prop


        //#region eventManagerNumber Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'eventManagerNumber', keyColumn: false })
        eventManagerNumber: string;
        //#endregion eventManagerNumber Prop


        //#region eventFundraiserEmail Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'eventFundraiserEmail', keyColumn: false })
        eventFundraiserEmail: string;
        //#endregion eventFundraiserEmail Prop


        //#region eventFundraiserName Prop
        @gridColumn({ visible: true, columnIndex: 10, allowSorting: true, headerKey: 'eventFundraiserName', keyColumn: false })
        eventFundraiserName: string;
        //#endregion eventFundraiserName Prop


        //#region eventFundraiserNumber Prop
        @gridColumn({ visible: true, columnIndex: 21, allowSorting: true, headerKey: 'eventFundraiserNumber', keyColumn: false })
        eventFundraiserNumber: string;
        //#endregion eventFundraiserNumber Prop


        //#region relationshipOwner Prop
        @gridColumn({ visible: true, columnIndex: 11, allowSorting: true, headerKey: 'relationshipOwner', keyColumn: false })
        relationshipOwner: string;
        //#endregion relationshipOwner Prop


        //#region relationshipOwnerId Prop
        @gridColumn({ visible: true, columnIndex: 23, allowSorting: true, headerKey: 'relationshipOwnerId', keyColumn: false })
        relationshipOwnerId: number;
        //#endregion relationshipOwnerId Prop


        //#region otherRelationshipOwnerEmail Prop
        @gridColumn({ visible: true, columnIndex: 24, allowSorting: true, headerKey: 'otherRelationshipOwnerEmail', keyColumn: false })
        otherRelationshipOwnerEmail: string;
        //#endregion otherRelationshipOwnerEmail Prop


        //#region otherRelationshipOwnerNumber Prop
        @gridColumn({ visible: true, columnIndex: 25, allowSorting: true, headerKey: 'otherRelationshipOwnerNumber', keyColumn: false })
        otherRelationshipOwnerNumber: string;
        //#endregion otherRelationshipOwnerNumber Prop


        //#region otherRelationshipOwnerName Prop
        @gridColumn({ visible: true, columnIndex: 26, allowSorting: true, headerKey: 'otherRelationshipOwnerName', keyColumn: false })
        otherRelationshipOwnerName: string;
        //#endregion otherRelationshipOwnerName Prop


        //#region adCreatorName Prop
        @gridColumn({ visible: true, columnIndex: 16, allowSorting: true, headerKey: 'adCreatorName', keyColumn: false })
        adCreatorName: string;
        //#endregion adCreatorName Prop


        //#region adCreatorEmail Prop
        @gridColumn({ visible: true, columnIndex: 17, allowSorting: true, headerKey: 'adCreatorEmail', keyColumn: false })
        adCreatorEmail: string;
        //#endregion adCreatorEmail Prop


        //#region otherManagerName Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'otherManagerName', keyColumn: false })
        otherManagerName: string;
        //#endregion otherManagerName Prop


        //#region otherManagerEmail Prop
        @gridColumn({ visible: true, columnIndex: 19, allowSorting: true, headerKey: 'otherManagerEmail', keyColumn: false })
        otherManagerEmail: string;
        //#endregion otherManagerEmail Prop


        //#region otherManagerNumber Prop
        @gridColumn({ visible: true, columnIndex: 31, allowSorting: true, headerKey: 'otherManagerNumber', keyColumn: false })
        otherManagerNumber: string;
        //#endregion otherManagerNumber Prop


        //#region adContactName Prop
        @gridColumn({ visible: true, columnIndex: 20, allowSorting: true, headerKey: 'adContactName', keyColumn: false })
        adContactName: string;
        //#endregion adContactName Prop


        //#region adContactEmail Prop
        @gridColumn({ visible: true, columnIndex: 21, allowSorting: true, headerKey: 'adContactEmail', keyColumn: false })
        adContactEmail: string;
        //#endregion adContactEmail Prop


        //#region adContactNumber Prop
        @gridColumn({ visible: true, columnIndex: 34, allowSorting: true, headerKey: 'adContactNumber', keyColumn: false })
        adContactNumber: string;
        //#endregion adContactNumber Prop


        //#region adIntakeCreatedByName Prop
        @gridColumn({ visible: true, columnIndex: 24, allowSorting: true, headerKey: 'adIntakeCreatedByName', keyColumn: false })
        adIntakeCreatedByName: string;
        //#endregion adIntakeCreatedByName Prop


        //#region adIntakeCreatedByEmail Prop
        @gridColumn({ visible: true, columnIndex: 25, allowSorting: true, headerKey: 'adIntakeCreatedByEmail', keyColumn: false })
        adIntakeCreatedByEmail: string;
        //#endregion adIntakeCreatedByEmail Prop


        //#region intakeTemplateId Prop
        @gridColumn({ visible: true, columnIndex: 37, allowSorting: true, headerKey: 'intakeTemplateId', keyColumn: false })
        intakeTemplateId: any;
        //#endregion intakeTemplateId Prop


        //#region version Prop
        @gridColumn({ visible: true, columnIndex: 38, allowSorting: true, headerKey: 'version', keyColumn: false })
        version: string;
        //#endregion version Prop

}