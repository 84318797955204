import { Injectable } from "@angular/core";
import { CoreComponent } from "@rxweb/framework";
import { RxHttp } from "@rxweb/http";
import { HttpRequestConfig } from "@rxweb/http/interface/http-request-config";

@Injectable({
    providedIn: 'root',
})

export class ApiCallService extends CoreComponent {
    constructor(private http: RxHttp) {
        super();
    }

    getAPI(path: string, queryParams?: any) {
        return this.http.get<any>({ path: path, queryParams: queryParams });
    }

    getByIdAPI(path: string, id: number, queryParams?: any) {
        return this.http.get<any>({ path: path, params: [id], queryParams: queryParams });
    }

    postAPI(path: string, body: any) {
        return this.http.post<any>({ path: path, body: body });
    }

    putAPI(path: string, body: any, id: number) {
        return this.http.put({ path: path, body: body, params: [id] });
    }

    deleteAPI(path: string, body?: any, id?: number) {
        return this.http.delete({ path: path, body: body, params: [id] });
    }
}