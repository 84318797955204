import {vGetLastEmailDetailBase} from '../database-models/v-get-last-email-detail-base';
//Generated Imports
export class vGetLastEmailDetail extends vGetLastEmailDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







}