import { DownloadPdfModalComponent } from './list/download-modal.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { IntakeFormSortingListComponent } from './list/intake-form-sorting-list.component';
import { RxSpinnerModule } from '@rxweb/angular/spinner'

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, RxSpinnerModule],
    declarations: [IntakeFormSortingListComponent,DownloadPdfModalComponent],
    providers: [],
    exports: [IntakeFormSortingListComponent,DownloadPdfModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [DownloadPdfModalComponent]
})

export class IntakeFormSortingSharedModule { }