import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDonorFundriserLookupBase {

//#region donorFundriserId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'donorFundriserId', keyColumn: false})
        donorFundriserId : any;
//#endregion donorFundriserId Prop


//#region donorFundriser Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'donorFundriser', keyColumn: false})
        donorFundriser : string;
//#endregion donorFundriser Prop

}