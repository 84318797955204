import { http } from "@rxweb/http";
import { IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
import { Event } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { CoreComponent } from '@rxweb/angular-router';
import { CONSTANT_MESSAGES } from "src/app/const/constant-messages/constant-messages";
import { APPLICATION_URL_CONSTANTS } from "src/app/const/uris/application-url-constants";

@http({
    path: APPLICATION_URL_CONSTANTS.eventWithoutBackSlash,
})

export class AbstractEvent extends CoreComponent {
    eventFormGroup: IFormGroup<Event>
    showComponent: boolean;
    isShowGridColumn: boolean = false;
    spin: boolean;
    toastr: BaseToastr
    dialog: BaseDialog;
    modalView: ModalView;
    breadCrumb: BreadCrumb;
    // eventSearchGroup:IFormGroup<EventSearch>
    // eventSearch:EventSearch
    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    // get eventHonoreeFormArray(): FormArray {
    //     let eventHonoreeFormArray = this.eventFormGroup.controls["eventHonoree"] as IAbstractControl;
    //     let data = <FormArray>eventHonoreeFormArray["controls"]
    //     return data;
    //   }
    //   get pageTypeFormArray():FormArray{
    //     let pageTypeFormArray = this.eventFormGroup.controls["pageTypes"] as IAbstractControl;
    //     let data = <FormArray>pageTypeFormArray["controls"]
    //     return data;
    //   }
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();

        this.breadCrumb.name1 = CONSTANT_MESSAGES.home
        this.breadCrumb.link1 = CONSTANT_MESSAGES.dashboard

        this.breadCrumb.name2 = CONSTANT_MESSAGES.eventManagement

        this.breadCrumb.title = CONSTANT_MESSAGES.eventsAsTitle
        this.breadCrumb.pagename = CONSTANT_MESSAGES.eventsAsTitle
    }
}