import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserDivisionBase {

//#region userDivisionId Prop
        @prop()
        userDivisionId : number;
//#endregion userDivisionId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region divisionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        divisionId : number;
//#endregion divisionId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : Date;
//#endregion modifiedOn Prop





}