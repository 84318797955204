import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IntakeFromAdDesignStatusBase {

//#region intakeFromAdDesignStatusId Prop
        @prop()
        intakeFromAdDesignStatusId : number;
//#endregion intakeFromAdDesignStatusId Prop


//#region adIntakeFormId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        adIntakeFormId : number;
//#endregion adIntakeFormId Prop


//#region adDesignStatusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        adDesignStatusId : number;
//#endregion adDesignStatusId Prop


//#region intakeTemplateId Prop
        @prop()
        intakeTemplateId : number;
//#endregion intakeTemplateId Prop


//#region sendApprovalTypeId Prop
        @prop()
        sendApprovalTypeId : number;
//#endregion sendApprovalTypeId Prop


//#region templateApprovedById Prop
        @prop()
        templateApprovedById : number;
//#endregion templateApprovedById Prop


//#region comments Prop
        @maxLength({value:500})
        comments : string;
//#endregion comments Prop


//#region approvalSentDate Prop
        @prop()
        approvalSentDate : Date;
//#endregion approvalSentDate Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop





}